import { castArray, compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button, styled, Stack } from '@mui/material';
import React, { useState } from 'react';

import RelatedContentModal from '@@containers/RelatedContent/Modal';
import Spacer from '@@components/Spacer';
import ContentItemList from '@@containers/RelatedContent/ContentItemList';
import { TitleHeader } from '@@containers/ContentItem/ContentDetails';
import { InfoBar } from '@@containers/ContentItem/styles';
import Icon from '@@components/Icon';

const StyledContentItemList = styled(ContentItemList)(({ theme }) => ({
    [`${TitleHeader}, ${InfoBar}`]: {
        color: theme.palette.primary.dark,
    },
}));

type Props = {
    readOnly?: boolean;
    columns?: TableColumn[];
    value: number[];
    onChange: (value: number[] | null) => void;
};

const RelatedContent = (props: Props) => {
    const { columns, onChange, readOnly, value } = props;

    const [isOpenModal, setIsOpenModal] = useState(false);
    const { t } = useTranslation();

    const handleClickOnSelectRelatedContent = () => {
        openModal();
    };

    const handleSaveOfModal = (selectedContent) => {
        onChange?.(selectedContent);

        return selectedContent;
    };

    const handleCloseModal = () => {
        setIsOpenModal(false);
    };

    const openModal = () => {
        setIsOpenModal(true);
    };

    const renderModal = (options) => (
        <RelatedContentModal
            isOpen
            multiple
            {...options}
            disableRestoreFocus
            value={value || []}
            onClose={handleCloseModal}
            onSubmit={handleSaveOfModal}
            onSubmitSuccess={handleCloseModal}
            params={{ size: 5 }}
        />
    );

    return (
        <React.Fragment>
            {
                // If the editor is wrapped by a label, this hidden checkbox will receive the
                // click event (if the label has been clicked).
                // This hidden checkbox prevents other elements (for example the select content
                // button) from receiving the click event (which would cause weird behaviour).
            }
            <input type="checkbox" style={{ display: 'none' }} />

            {renderModal({ isOpen: isOpenModal, disableUrlSync: true })}

            <React.Fragment>
                <StyledContentItemList
                    columns={columns}
                    orderable={!readOnly}
                    readOnly={readOnly}
                    value={value}
                    onChange={onChange}
                />

                {compact(castArray(value)).length > 0 && <Spacer md v />}
            </React.Fragment>

            {!readOnly && (
                <Stack direction="row" justifyContent="center">
                    <Button
                        startIcon={<Icon name="plus-sharp" />}
                        variant="outlined"
                        onClick={handleClickOnSelectRelatedContent}
                    >
                        {t('editor.plugin.dynamicTeaser.form.addContents')}
                    </Button>
                </Stack>
            )}
        </React.Fragment>
    );
};

export default RelatedContent;

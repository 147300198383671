import { DEFAULT_BLOCK } from '@@editor/constants';
import { ELEMENT_TYPES, SeparatorElement } from '@@editor/helpers/Element';
import renderElement from '@@editor/plugins/utils/renderElement';
import { Editor, Element } from '@@editor/helpers';

import Separator from './Separator';

const NODE_TYPE = ELEMENT_TYPES.SEPARATOR;

export const withSeparator = (editor) => {
    const { isVoid } = editor;

    return Object.assign(editor, {
        // Overwrite default `isVoid` implementation defined in `withLayoutDefaults` as long as layout plugins
        // are not editable by default. When they will become editable by default, we do not need this
        // overruling anymore.
        isVoid: (element) => (element.type === NODE_TYPE ? true : isVoid(element)),
        renderElement: renderElement(editor, [[NODE_TYPE, Separator]]),
        insertSeparator: (at) => {
            Editor.insertElement(
                editor,
                Element.create<SeparatorElement>({
                    ...DEFAULT_BLOCK,
                    type: NODE_TYPE,
                }),
                {
                    at,
                },
            );
        },
    });
};

export default withSeparator;

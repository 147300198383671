export const TENANT_IMAGE_KEY = '32x32LogoUrl';

export const EXCLUDE_CATEGORIES_NOT_REQUIRED = { exclude: 'categoriesNotRequired' } as const;

// We should try to avoid hardcoding ids from BE as much as possible. In fact this should never be done!
// Here we have a huge exception, because we could not find another viable solution. BE ensured, that tenant and
// tenant group ids will never ever change and are equivalent on each system (dev, staging, prod etc.)!

export const TAMEDIA_NEWS_TENANT_GROUP_ID = 1;

export const TAGES_ANZEIGER_TENANT_ID = 2;
export const TWENTY_MIN_DE_TENANT_ID = 6;
export const TWENTY_MIN_FR_TENANT_ID = 7;
export const L_ESSENTIEL_DE_TENANT_ID = 19;
export const L_ESSENTIEL_FR_TENANT_ID = 18;
export const LE_MATIN_TENANT_ID = 4;

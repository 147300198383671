import { initContract } from '@ts-rest/core';
import { initQueryClient } from '@ts-rest/react-query';
import { z } from 'zod';
import { useStore } from 'react-redux';

import config from '@@config';
import { paginated } from '@@api/utils/schemas/utils';
import {
    Agency,
    LoginPayloadResponse,
    NewUser,
    Preference,
    UnityUser,
    User,
} from '@@api/services/auth/schemas';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { deserializeUser, serializeUser } from '@@api/services/auth/transformers';
import { PaginationParams, SearchParams } from '@@api/utils/schemas/queryParams';
import { LoginHeaders, PatchDefaultHeaders } from '@@api/headers';
import { RootState } from '@@scripts/store/store';
import { USER_SOURCES } from '@@containers/users/constants';
import { commonResponses } from '@@api/commonResponses';
import { getQueryKeys } from '@@api/utils/queryKeys';

const contract = initContract();

const router = contract.router(
    {
        users: contract.router({
            getAll: {
                method: 'GET',
                path: '/resources/users',
                query: z.object({
                    ...SearchParams.pick({ q: true }).shape,
                    ...PaginationParams.shape,
                    serviceUser: z.boolean().optional(),
                    userSource: z.nativeEnum(USER_SOURCES).optional(),
                }),
                responses: {
                    200: paginated(UnityUser.transform(deserializeUser)),
                },
            },
            preference: contract.router({
                get: {
                    method: 'GET',
                    path: '/resources/users/:id/preferences',
                    pathParams: z.object({
                        id: z.string().uuid(),
                    }),
                    responses: {
                        200: Preference,
                    },
                },
                put: {
                    method: 'PUT',
                    path: '/resources/users/:id/preferences',
                    pathParams: z.object({
                        id: z.string().uuid(),
                    }),
                    body: Preference,
                    responses: {
                        200: Preference,
                    },
                },
                patch: {
                    method: 'PATCH',
                    path: '/resources/users/:id/preferences',
                    pathParams: z.object({
                        id: z.string().uuid(),
                    }),
                    headers: PatchDefaultHeaders,
                    // We use deepPartial here even though it is deprecated, as Zod doesn't provide any alternative so far
                    // https://github.com/colinhacks/zod/issues/2854
                    body: Preference.deepPartial(),
                    responses: {
                        200: Preference,
                    },
                },
            }),
            profile: contract.router({
                get: {
                    method: 'GET',
                    path: '/resources/users/:id/profile',
                    pathParams: z.object({
                        id: z.string().uuid(),
                    }),
                    responses: {
                        200: UnityUser.transform(deserializeUser),
                    },
                },
                put: {
                    method: 'PUT',
                    path: '/resources/users/:id/profile',
                    pathParams: z.object({
                        id: z.string().uuid(),
                    }),
                    body: User.transform(serializeUser),
                    responses: {
                        200: UnityUser.transform(deserializeUser),
                    },
                },
            }),
            external: {
                post: {
                    method: 'POST',
                    path: '/resources/users/external',
                    body: NewUser.transform(serializeUser),
                    responses: {
                        // Backend uses 200 while our tests use 201
                        200: UnityUser.transform(deserializeUser),
                        201: UnityUser.transform(deserializeUser),
                    },
                },
            },
        }),
        agency: contract.router({
            getAll: {
                method: 'GET',
                path: '/resources/agencies',
                query: z.object({
                    ...PaginationParams.pick({ size: true }).shape,
                    serviceUser: z.boolean().optional(),
                }),
                responses: {
                    200: paginated(Agency),
                },
            },
            get: {
                method: 'GET',
                path: '/resources/agencies/:id',
                pathParams: z.object({
                    id: z.string().uuid(),
                }),
                responses: {
                    200: Agency,
                },
            },
        }),
        oauth: contract.router({
            token: {
                method: 'POST',
                path: '/oauth/token',
                contentType: 'application/x-www-form-urlencoded',
                body: contract.type<string | URLSearchParams>(),
                responses: {
                    200: LoginPayloadResponse,
                    400: z.any(),
                },
                headers: LoginHeaders,
            },
        }),
    },
    {
        commonResponses,
    },
);

export type AuthRouter = typeof router;

const authClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.authUrl,
        ...clientDefaults(state),
    };

    const client = initQueryClient(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export const useAuthClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return authClient(state);
};

import { styled, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

import Icon from '@@components/Icon';

const ButtonsWrapper = styled('div')<{ $isPlaceholder?: boolean }>(({ $isPlaceholder, theme }) => ({
    position: 'absolute',
    [$isPlaceholder ? 'top' : 'bottom']: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.layer1,
    display: 'flex',
    gap: `calc(${theme.spacing(1)} / 2)`,
}));

type Props = {
    onClickDelete: (args: unknown) => void;
    onClickEdit: (args: unknown) => void;
    onClickPreview: (args: unknown) => void;
    className?: string;
    isPlaceholder?: boolean;
    readOnly?: boolean;
};

const HoverUICollapsed = ({
    className,
    isPlaceholder,
    onClickPreview,
    onClickEdit,
    onClickDelete,
    readOnly,
}: Props) => {
    const { t } = useTranslation();

    return (
        <ButtonsWrapper className={className} $isPlaceholder={isPlaceholder}>
            <IconButton
                size="small"
                title={t('contentitem.previewButton')}
                onClick={onClickPreview}
            >
                <Icon name="eye-regular" size="small" />
            </IconButton>

            <IconButton size="small" title={t('contentitem.editButton')} onClick={onClickEdit}>
                <Icon name="pen-regular" size="small" />
            </IconButton>

            {!readOnly && (
                <IconButton
                    size="small"
                    title={t('contentitem.deleteButton')}
                    onClick={onClickDelete}
                >
                    <Icon name="trash-can" size="small" />
                </IconButton>
            )}
        </ButtonsWrapper>
    );
};

export default HoverUICollapsed;

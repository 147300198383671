import {
    Transforms,
    Text,
    NodeEntry,
    InsertNodeOperation,
    SetNodeOperation,
    RemoveNodeOperation,
    Path,
} from 'slate';
import React from 'react';
import { isEqual, noop, omit } from 'lodash';
import { flushSync } from 'react-dom';

import { DEFAULT_BLOCK, HOTKEYS } from '@@editor/constants';
import { Editor, Element, Node, Operation, ReactEditor } from '@@editor/helpers';
import {
    containsInvalidLinebreaks,
    sanitizeInvalidLinebreaks,
    removeNonPrintables,
    containsNonPrintables,
} from '@@utils/string';
import { BLOCK_TYPES, ELEMENT_TYPES } from '@@editor/helpers/Element';

import handleHotkey from '../utils/handleHotkey';
import renderElement from '../utils/renderElement';
import CaptionElement from '../serializable/embed/components/ElementFooter/CaptionElement';
import CreditElement from '../serializable/embed/components/ElementFooter/CreditElement';

const checkForCrossheadOrFooter = (editor: Editor) => {
    let isCrossheadOrFooter = false;

    if (editor.selection) {
        const [firstMatch] = Editor.nodes(editor, {
            match: (node) =>
                node.type === BLOCK_TYPES.CROSSHEAD || node.type === BLOCK_TYPES.FOOTER,
        });

        isCrossheadOrFooter = Boolean(firstMatch);
    }

    return isCrossheadOrFooter;
};

type NodeMutation = {
    path: Path | null;
    operation: InsertNodeOperation | SetNodeOperation | RemoveNodeOperation;
};

const INTERNAL_PROPERTIES = ['data.progress', 'data.isLoading'];

const propagateElementMutations = (editor: Editor) => {
    const mutations: NodeMutation[] = [];

    editor.operations.forEach((operation) => {
        mutations.forEach((mutation) => {
            if (mutation.path) {
                // eslint-disable-next-line no-param-reassign
                mutation.path = Path.transform(mutation.path, operation);
            }
        });

        if (
            operation.type === 'insert_node' ||
            operation.type === 'set_node' ||
            operation.type === 'remove_node'
        ) {
            mutations.push({ path: operation.path, operation });
        }
    });

    mutations.forEach(({ path, operation }) => {
        if (path) {
            const node = operation.type === 'remove_node' ? operation.node : Node.get(editor, path);

            if (Element.isElement(node)) {
                if (
                    operation.type === 'set_node' &&
                    !isEqual(
                        omit(operation.properties, INTERNAL_PROPERTIES),
                        omit(operation.newProperties, INTERNAL_PROPERTIES),
                    )
                ) {
                    if (editor.onAfterUpdateElement) {
                        editor.onAfterUpdateElement(node, operation.properties);
                    }
                } else if (operation.type === 'insert_node') {
                    if (editor.onAfterInsertElement) {
                        editor.onAfterInsertElement(node);
                    }
                } else if (operation.type === 'remove_node') {
                    if (editor.onAfterRemoveElement) {
                        editor.onAfterRemoveElement(node);
                    }
                }
            }
        }
    });
};

const resetElementIdAndDragId = ({ id, ...node }) => {
    const newId = id ? { id: null } : null;

    return { ...node, ...newId };
};

export const withFallbacks = (editor) =>
    Object.assign(editor, {
        // Fallback if no plugin is decorating anything
        decorate: () => [],
        // Render lowest editor/editable
        renderEditor: ({ children }) => children,
        // Fallback if no plugin is rendering specific element
        renderElement: ({ attributes, element }) => (
            <p {...attributes}>Unknown element type: {element.type}</p>
        ),
        // Default leaf rendering
        renderLeaf: ({ attributes, children, renderersUsed }) =>
            renderersUsed > 0 ? children : <span {...attributes}>{children}</span>,
        // Fallback if no plugin is handling `handleHotkey`
        handleHotkey: () => {},
        onChangeValue: noop,
        onEditorMount: noop,
        onEditorUnmount: noop,
    });

export const withDefaults = (editor: Editor) => {
    const {
        apply,
        insertBreak,
        isBlock,
        isInline,
        isVoid,
        normalizeNode,
        deleteBackward,
        deleteForward,
        useInlineEditing,
        onChange,
        insertFragmentData,
    } = editor;

    return Object.assign(editor, {
        // SLATE SPECIFIC PROPERTIES

        // Schema-specific node behaviors

        isBlock: (element) => (Element.isBlockElement(element) ? true : isBlock(element)),

        isInline: (element) => (Element.isInlineElement(element) ? true : isInline(element)),

        isVoid: (element) => {
            if (
                useInlineEditing &&
                (Element.isImageRelatedElement(element) ||
                    Element.isEmbeddedContentRelatedElement(element))
            ) {
                return false;
            }

            if (Element.isEmbedElement(element)) {
                return true;
            }

            if (
                Element.isLayoutElement(element) &&
                ![
                    ELEMENT_TYPES.QUOTE,
                    ELEMENT_TYPES.QUOTE_TEXT,
                    ELEMENT_TYPES.QUOTE_CAPTION,
                    ELEMENT_TYPES.SEPARATOR,
                    ELEMENT_TYPES.INFOBOX,
                    ELEMENT_TYPES.INFOBOX_TITLE,
                    ELEMENT_TYPES.INFOBOX_CONTENT,
                    ELEMENT_TYPES.INTERVIEW_SEGMENT,
                    ELEMENT_TYPES.INTERVIEW_SEGMENT_QUESTION,
                    ELEMENT_TYPES.INTERVIEW_SEGMENT_ANSWER,
                    ELEMENT_TYPES.DYNAMIC_TEASER,
                    ELEMENT_TYPES.DYNAMIC_TEASER_TITLE,
                    ELEMENT_TYPES.SUMMARY_LIST,
                    ELEMENT_TYPES.SUMMARY_LIST_SUMMARY,
                ].includes(element.type)
            ) {
                return false;
            }

            return isVoid(element);
        },

        normalizeNode: (nodeEntry: NodeEntry) => {
            const [node, path] = nodeEntry;

            if (Editor.isEditor(node)) {
                const children = Array.from(Node.children(node, path));

                if (!children.length) {
                    // if the state is empty, add a default block
                    Transforms.insertNodes(editor, Element.create(DEFAULT_BLOCK), {
                        at: [children.length],
                    });

                    return;
                }
            }

            // If the user started to modify a template element, we want to convert it into a non-template element
            if (
                Element.isTemplateElement(node) &&
                !Element.isEmptyTemplateElement(node, {
                    isInlineEdited: !Editor.isVoid(editor, node),
                })
            ) {
                Transforms.setNodes(
                    editor,
                    {
                        data: { ...node.data, templateElement: false },
                    },
                    { at: path },
                );

                return;
            }

            // If text has non printable characters, remove them.
            // If the text contains some invalid \r line-feeds replace them with \n
            if (Text.isText(node)) {
                if (containsNonPrintables(node.text) || containsInvalidLinebreaks(node.text)) {
                    Transforms.insertText(
                        editor,
                        removeNonPrintables(sanitizeInvalidLinebreaks(node.text)),
                        {
                            at: path,
                        },
                    );

                    return;
                }
            }

            // Fall back to the original `normalizeNode` to enforce other constraints.
            // has to be invoked only if no other custom changes happened
            normalizeNode(nodeEntry);
        },

        // Important note: This onChange is not the same as the onChange on the `Slate` component! They are fired in a
        // different moment and we need to be aware, what logic to put where. Look at the slate source code, in order
        // to understand! One little hint, which might not always be true, bust mostly: Logic that affects the internal
        // document should be placed here. Logic that affects what we are propagating to the outside world on the
        // onChange handler on the `Slate` component.
        onChange: () => {
            const willSelectionOnlyChange = editor.operations.every(Operation.isSelectionOperation);
            const willValueChange = !willSelectionOnlyChange;

            if (willValueChange) {
                editor.onChangeValue();
            }

            if (process.env.NODE_ENV === 'development') {
                const duplicateKeys = ReactEditor.findDuplicateKeyIds(editor);

                if (duplicateKeys.size > 0) {
                    throw new Error(
                        `Duplicate keys found in editor state! Please have a look at \`Element.create\`. List of duplicate keys: ${Array.from(
                            duplicateKeys,
                        )}`,
                    );
                }
            }

            // propagateElementMutations call below needs the latest state of the editor, so we need to flush the
            // state before calling it.
            flushSync(() => {
                onChange();
            });

            propagateElementMutations(editor);
        },

        // Overrideable core actions

        apply: (operation) => {
            if (!operation.preventIdOverwrite) {
                // By default slate is splitting an existing node when inserting a break (newline),
                // which also means, data is copied from the existing node to the new one. Therefore we
                // need to reset the id for the new, at this place selected, node (BE will generate a new id
                // for the new node during save)
                if (operation.type === 'split_node') {
                    apply({
                        ...operation,
                        properties: resetElementIdAndDragId(operation.properties),
                    });

                    return;
                }

                // By default slate is inserting the copied node, which also means its `id` will be
                // duplicated. That's not good, since those values must be unique per editor. Therefore we
                // need to reset the id for the new node (BE will generate a new id for the new node during save)
                if (operation.type === 'insert_node') {
                    apply({
                        ...operation,
                        node: resetElementIdAndDragId(operation.node),
                    });

                    return;
                }
            }

            apply(operation);
        },

        // Deletion forward or backward is overridden here because by default if a empty block follows a void element
        // when deleting backward the empty block is removed but the void element is too (and we do not want this)
        // The same happens when a empty block is before a void and a user deletes forward. The empty block is removed
        // but the following void is too.
        // With this fix only the empty block is being removed.
        deleteBackward: (unit) =>
            Editor.isSelectionOnEmptyLine(editor) && Editor.isSelectionPrecededByVoidNode(editor)
                ? Transforms.removeNodes(editor)
                : deleteBackward(unit),

        deleteForward: (unit) =>
            Editor.isSelectionOnEmptyLine(editor) && Editor.isSelectionFollowedByVoidNode(editor)
                ? Transforms.removeNodes(editor)
                : deleteForward(unit),

        insertBreak: () => {
            if (Editor.isVoidNodeOnlySelected(editor)) {
                Editor.insertElement(editor, Element.create(DEFAULT_BLOCK));
            } else {
                const isCrossheadOrFooter = checkForCrossheadOrFooter(editor);

                if (
                    editor.selection &&
                    editor.selection.anchor.path.length > 2 &&
                    isCrossheadOrFooter
                ) {
                    return;
                }

                insertBreak();

                if (isCrossheadOrFooter) {
                    editor.toParagraph();
                }
            }
        },
        insertFragmentData: (data: DataTransfer) => {
            if (Editor.isInlineElementSelected(editor)) {
                return false;
            }

            return insertFragmentData(data);
        },

        // UNITY SPECIFIC PROPERTIES

        handleHotkey: handleHotkey(editor, [
            [HOTKEYS.SELECT_ALL, (editor: Editor) => Transforms.select(editor, [])],
            [
                HOTKEYS.DELETE_BLOCK,
                (editor: Editor) => {
                    if (editor.selection) {
                        const matches = Editor.elements(editor, {
                            at: editor.selection,
                            match: Element.isBlockElement,
                            mode: 'highest',
                            reverse: true,
                        });

                        if (matches) {
                            for (const [, path] of matches) {
                                Transforms.removeNodes(editor, { at: path });
                            }
                        }
                    }
                },
            ],
        ]),

        renderElement: renderElement(editor, [
            [ELEMENT_TYPES.EMBED_CAPTION, CaptionElement],
            [ELEMENT_TYPES.EMBED_CREDIT, CreditElement],
        ]),

        showEmbedModal: (type, element = null, at = editor.selection && editor.selection.focus) => {
            const nextFormParams = {
                type,
                element,
                at,
            };

            editor.setData({
                isEmbedModalVisible: {
                    [type]: nextFormParams,
                },
            });
        },

        hideEmbedModal: (type) => {
            editor.unsetData(['isEmbedModalVisible', type]);

            requestAnimationFrame(() => {
                ReactEditor.focus(editor);
            });
        },

        isEmbedModalVisible: (type) => editor.getDataIn(['isEmbedModalVisible', type]),

        toggleMark: (mark: string, single?: boolean) => Editor.toggleMark(editor, mark, single),
    });
};

export default withDefaults;

import { isEqual, noop, omit } from 'lodash';
// This is the only place in this stack where we use `useForm` directly! Use `useReactHookForm`
// in all other places.
// eslint-disable-next-line no-restricted-imports
import { TriggerConfig, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useReducer } from 'react';

import usePrevious from '@@hooks/usePrevious';

import { mergeFormFieldValues } from './utils';
import useReactHookFormPersist from './useReactHookFormPersist';
import { ReactHookFormBaseProps, UseReactHookFormReturn } from './types';
import { createActions, reducer } from './ReactHookFormMetaContext';
import { REVALIDATION_MODE, VALIDATION_MODE } from './constants';

type TriggerOptions = TriggerConfig & {
    force?: boolean;
};

const useReactHookForm = (props: ReactHookFormBaseProps): UseReactHookFormReturn => {
    const {
        // When set to true, the form will retain the value of
        // dirty fields and update every registered field which is still pristine when
        // reinitializing. When this option is not set (the default), reinitializing the form
        // replaces all field values. This option is useful in situations where the form has
        // live updates or continues to be editable after form submission; it prevents
        // reinitialization from overwriting user changes.
        keepDirtyOnReinitialize = true,
        values: initialValues,
        onSubmit,
        onDelete = noop,
        onCancel = noop,
        formName,
        persist = false,
        mode = VALIDATION_MODE.onSubmit,
        reValidateMode = REVALIDATION_MODE.onChange,
        atoms,
        isSubmitting: isMutationSubmitting = false,
    } = props;

    const formMetaInitialState = { formName, registeredFields: [], registeredFieldArrays: [] };
    const [formMetaState, formMetaDispatch] = useReducer(reducer, formMetaInitialState);
    const { registeredFields, registeredFieldArrays } = formMetaState;
    const formMetaActions = createActions(formMetaDispatch);

    const prevInitialValues = usePrevious(initialValues);

    const form = useForm({
        values: initialValues,
        criteriaMode: 'all',
        shouldFocusError: false,
        shouldUnregister: false,
        mode,
        reValidateMode,
        ...(keepDirtyOnReinitialize
            ? {
                  resetOptions: {
                      keepDirty: true,
                      keepDirtyValues: true,
                      keepTouched: true,
                  },
              }
            : {}),
    });

    const {
        formState: { dirtyFields, isSubmitting, isDirty },
        reset,
        getValues,
    } = form;

    useEffect(() => {
        if (
            prevInitialValues !== null &&
            !isEqual(prevInitialValues, initialValues) &&
            !isSubmitting
        ) {
            if (keepDirtyOnReinitialize) {
                const newValues = mergeFormFieldValues(
                    getValues(),
                    initialValues,
                    dirtyFields,
                    registeredFields,
                    registeredFieldArrays,
                    atoms,
                );

                // Then re-apply values of dirty fields in a second step (since dirty values
                // got reset during the first reset)
                reset(newValues, {
                    keepErrors: true,
                    keepDefaultValues: true,
                    keepValues: false,
                    keepDirty: false,
                    keepDirtyValues: false,
                    keepIsSubmitted: true,
                    keepTouched: true,
                    keepIsValid: true,
                    keepSubmitCount: true,
                });
            } else {
                reset(initialValues);
            }
        }
    }, [
        prevInitialValues,
        initialValues,
        isSubmitting,
        reset,
        keepDirtyOnReinitialize,
        dirtyFields,
        getValues,
        registeredFields,
        registeredFieldArrays,
        atoms,
    ]);

    const { clearPersistedForm } = useReactHookFormPersist(formName, form, { persist });

    const handleSubmit: UseReactHookFormReturn['componentProps']['onSubmit'] = useCallback(
        (values) => {
            onSubmit(values, form, {
                submit: form.handleSubmit(handleSubmit),
                clearPersistedForm,
            });
        },
        [clearPersistedForm, form, onSubmit],
    );

    const handleDelete: UseReactHookFormReturn['componentProps']['onDelete'] = useCallback(() => {
        onDelete(form, { clearPersistedForm });
    }, [onDelete, clearPersistedForm, form]);

    const handleCancel = useCallback(() => {
        reset();

        onCancel();
        clearPersistedForm();
    }, [onCancel, reset, clearPersistedForm]);

    // In order to support our form field validation strategy (only validate fields on change and
    // after the form has been tried to be submitted at least once), we need to make `setValue` a bit smarter
    // https://github.com/react-hook-form/react-hook-form/projects/1#card-90869999
    const setValue = useCallback(
        (name, value, options) => {
            let shouldValidate;

            if (
                (!mode || mode === 'onSubmit') &&
                (!reValidateMode || reValidateMode === 'onChange')
            ) {
                shouldValidate = form.formState.isSubmitted && !form.formState.isSubmitSuccessful;
            }

            form.setValue(name, value, {
                shouldDirty: true,
                shouldValidate,
                ...options,
            });
        },
        [form, mode, reValidateMode],
    );

    // In order to support our form field validation strategy (only validate fields on change and
    // after the form has been tried to be submitted at least once), we need to make `trigger` a bit smarter
    // https://github.com/react-hook-form/react-hook-form/projects/1#card-90869999
    const trigger = useCallback(
        (name, options: TriggerOptions = {}) => {
            const { force } = options;

            if (
                !force &&
                (!mode || mode === 'onSubmit') &&
                !(form.formState.isSubmitted && !form.formState.isSubmitSuccessful)
            ) {
                return Promise.resolve(false);
            }

            return form.trigger(name, omit(options, 'force'));
        },
        [form, mode],
    );

    const enhancedForm = {
        ...form,
        setValue,
        submit: form.handleSubmit(handleSubmit),
        trigger,
    };

    const formMeta = useMemo(
        () => ({ formMetaState, ...formMetaActions }),
        [formMetaState, formMetaActions],
    );

    return {
        form: enhancedForm,
        formMeta,
        componentProps: {
            ...props,
            dirty: isDirty,
            pristine: !isDirty,
            submitting: isSubmitting || isMutationSubmitting,
            handleSubmit: form.handleSubmit,
            onSubmit: handleSubmit,
            onDelete: handleDelete,
            onCancel: handleCancel,
        },
    };
};

export default useReactHookForm;

import React from 'react';

import PreviewImage from '@@containers/PreviewImage';
import { type Tenant } from '@@api/services/tenant/schemas';
import { useTenantClient } from '@@api/services/tenant/client';

const TENANT_IMAGE_SIZES = {
    S: 16,
    M: 24,
    L: 32,
} as const;

type Props = {
    size?: 'S' | 'M' | 'L';
    id?: Tenant['id'];
    natural?: boolean;
    src?: string;
    tenant?: string;
};

const TenantImage = (props: Props) => {
    const { id, src, size = 'M', tenant } = props;
    const { client: tenantClient, queryKeys: tenantKeys } = useTenantClient();

    const params = { id: id! };
    const { data: tenantData } = tenantClient.tenant.get.useQuery(
        tenantKeys.tenant.get({ params }),
        { params },
        { enabled: Boolean(id && !src) },
    );
    const data = tenantData?.body;

    const imageSrc = src || data?.logoUrl;
    const transformations = {
        maxHeight: TENANT_IMAGE_SIZES[size],
    };
    const title = tenant || data?.name;

    return (
        <PreviewImage {...props} src={imageSrc} transformations={transformations} title={title} />
    );
};

export default TenantImage;

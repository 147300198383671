/**
 * * This comment is for translation auto extract
 * t('embed.infobox.default')
 * t('embed.infobox.important')
 * t('embed.infobox.summary')
 */
export const CONTAINER_TYPE_MAPPINGS = {
    /* eslint-disable camelcase */
    _infobox_default: 'embed.infobox.default',
    _infobox_important: 'embed.infobox.important',
    _infobox_summary: 'embed.infobox.summary',
    /* eslint-enable camelcase */
};

export const DEFAULT_CONTAINER_TYPE = '_infobox_default';
export const DEFAULT_INFOBOX_COLLAPSED_VALUE = true;

import PropTypes from 'prop-types';

/*
 * utils
 */

export const MeasureType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

/*
 * resource handling
 */

export const IdType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

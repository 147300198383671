import { z } from 'zod';

const SpellAdviceType = z.enum(['SPELLING', 'GRAMMATICAL', 'OTHER', 'TYPOGRAPHICAL']);

export type SpellAdviceType = z.infer<typeof SpellAdviceType>;

const SpellAdvice = z.object({
    type: SpellAdviceType,
    errorMessage: z.string(),
    offset: z.number(),
    length: z.number(),
    proposals: z.array(z.string()),
});

export type SpellAdvice = z.infer<typeof SpellAdvice>;

const SpellCheckTexts = z.record(z.string());

export type SpellCheckTexts = z.infer<typeof SpellCheckTexts>;

const SpellCheckSpellAdvices = z.record(z.array(SpellAdvice));

export type SpellCheckSpellAdvices = z.infer<typeof SpellCheckSpellAdvices>;

export const SpellCheckRequestBody = z.object({
    texts: SpellCheckTexts,
});

export type SpellCheckRequestBody = z.infer<typeof SpellCheckRequestBody>;

export const SpellCheckResponseBody = z.object({
    spellAdvices: SpellCheckSpellAdvices,
});

export type SpellCheckResponseBody = z.infer<typeof SpellCheckResponseBody>;

export const ProposeToDictionaryRequestBody = z.object({
    text: z.string(),
});

export type ProposeToDictionaryRequestBody = z.infer<typeof ProposeToDictionaryRequestBody>;

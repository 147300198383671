export const DATE = 'L';
export const SHORT_DATE = 'll';
export const DATETIME = 'LLL';
export const SHORT_DATETIME = 'lll';
export const NUMBERED_DATETIME = 'L LT';
export const FULL_SHORT_DATETIME = 'llll';
export const TIME = 'LT';
export const TIME_WITH_SEC = 'LTS';

export const WEEKDAY_DATETIME = 'dddd L';

// This is the only place in this stack where we use `ReactEditor` directly! Use this helper
// in all other places.
// eslint-disable-next-line no-restricted-imports
import { ReactEditor as SlateReactEditor } from 'slate-react';
import { Range } from 'slate';

import { getElementPosition } from '@@utils/getElementPosition';
import { isElementNode } from '@@utils/DOM';

import { Editor } from './Editor';
import { Element } from './Element';
import { Node } from './Node';

type Position = { top: number; left: number; width: number; height: number };

export type ReactEditor = SlateReactEditor;

interface IReactEditor {
    findDuplicateKeyIds: (editor: Editor) => Set<string>;
    findDuplicateKeyIdsInDom: (editor: Editor) => Set<string>;
    getStartElementDOMPosition: (editor: Editor, options?) => Position | undefined;
    isElementSelected: (editor: Editor, element: Element) => boolean;
    toDOMPosition: (editor: Editor, node: Node, options?) => Position;
    isCursorOnLastLine: (editor: Editor) => boolean;
    isCursorOnFirstLine: (editor: Editor) => boolean;
}

export const ReactEditor: typeof SlateReactEditor & IReactEditor = {
    ...SlateReactEditor,

    // Find nodes with the same key. This can happen if we insert nodes into the document tree with the same
    // object as the origin (since slate is internally identifying nodes by object reference). We must avoid that!
    findDuplicateKeyIds: (editor) => {
        const keyIds = new Set<string>();
        const duplicateKeyIds = new Set<string>();
        const nodeEntries = Node.nodes(editor);

        for (const [node] of nodeEntries) {
            const keyId = ReactEditor.findKey(editor, node).id;

            if (!keyIds.has(keyId)) {
                keyIds.add(keyId);
            } else if (!duplicateKeyIds.has(keyId)) {
                duplicateKeyIds.add(keyId);
            }
        }

        return duplicateKeyIds;
    },

    findDuplicateKeyIdsInDom: (editor) => {
        const keyIds = new Set<string>();
        const duplicateKeyIds = new Set<string>();
        const editorElement = ReactEditor.toDOMNode(editor, editor);

        const nodeIterator = document.createNodeIterator(editorElement, NodeFilter.SHOW_ELEMENT, {
            acceptNode(node) {
                // Let's not go into nested rich text editors
                return isElementNode(node) && node.getAttribute('data-slate-editor') === 'true'
                    ? NodeFilter.FILTER_REJECT
                    : NodeFilter.FILTER_ACCEPT;
            },
        });

        let currentNode = nodeIterator.nextNode();

        while (currentNode) {
            if (
                isElementNode(currentNode) &&
                currentNode.getAttribute('data-slate-node') === 'element'
            ) {
                const keyId = currentNode.getAttribute('data-slate-node-key-id');

                if (keyId === null) {
                    throw new Error('No slate node key id was found for element');
                } else {
                    if (!keyIds.has(keyId)) {
                        keyIds.add(keyId);
                    } else if (!duplicateKeyIds.has(keyId)) {
                        duplicateKeyIds.add(keyId);
                    }
                }
            }

            currentNode = nodeIterator.nextNode();
        }

        return duplicateKeyIds;
    },

    getStartElementDOMPosition: (editor, options) => {
        const startElement = Editor.startElement(editor, { mode: 'highest' });

        if (startElement) {
            const [node] = startElement;
            const position = ReactEditor.toDOMPosition(editor, node, options);

            if (position.top >= 0) {
                return position;
            }
        }
    },

    isElementSelected: (editor, element) => {
        const elementPath = ReactEditor.findPath(editor, element);

        return Boolean(
            editor.selection &&
                Range.includes(Editor.range(editor, elementPath), editor.selection.focus),
        );
    },

    toDOMPosition: (editor, node, options) => {
        const domNode = ReactEditor.toDOMNode(editor, node);
        // @ts-ignore
        const position: Position = getElementPosition(domNode, options);

        return position;
    },
    isCursorOnLastLine: (editor) => {
        const { selection } = editor;

        if (!selection) {
            return false;
        }

        const parentElement = ReactEditor.toDOMNode(
            editor,
            Node.get(editor, selection.anchor.path),
        );

        const domRange = window.getSelection()?.getRangeAt(0);

        if (!domRange) {
            return false;
        }

        const rect = domRange.getBoundingClientRect();
        const parentRect = parentElement.getBoundingClientRect();

        return parentRect.bottom === rect.bottom;
    },
    isCursorOnFirstLine: (editor) => {
        const { selection } = editor;

        if (!selection) {
            return false;
        }

        const parentElement = ReactEditor.toDOMNode(
            editor,
            Node.get(editor, selection.anchor.path),
        );

        const domRange = window.getSelection()?.getRangeAt(0);

        if (!domRange) {
            return false;
        }

        const rect = domRange.getBoundingClientRect();
        const parentRect = parentElement.getBoundingClientRect();

        return parentRect.top === rect.top;
    },
};

import { useSelected } from 'slate-react';
import React from 'react';
import { styled, Stack } from '@mui/material';

import { Editor } from '@@editor/helpers';
import { ElementAttributes, SeparatorElement } from '@@editor/helpers/Element';
import ElementWrapper from '@@editor/toolbars/ElementWrapper/ElementWrapper';

const StackWrapper = styled(Stack)<{ selected: boolean; $isArticleEditor?: boolean }>(
    ({ selected, $isArticleEditor, theme }) => ({
        backgroundColor: selected ? theme.palette.primary.dark : 'transparent',
        margin: `${theme.spacing(2)} auto ${theme.spacing(2)}`,
        maxWidth: $isArticleEditor ? theme.fixed.editor.textElement.width : 'auto',
        width: $isArticleEditor
            ? `calc(100% - 2 * ${theme.fixed.editor.elementWrapper.width})`
            : 'auto',
    }),
);

const Hr = styled('hr')<{ selected: boolean }>(({ selected, theme }) => ({
    borderWidth: `${theme.borders[1]} 0 0`,
    borderColor: selected ? theme.palette.common.white : theme.palette.text.primary,
    width: '100%',
}));

type Props = {
    attributes: ElementAttributes;
    children: React.ReactNode;
    editor: Editor;
    element: SeparatorElement;
};

const Separator = ({ attributes, children, editor, element }: Props) => {
    const selected = useSelected();

    return (
        <ElementWrapper
            {...{ attributes, children, editor, element }}
            widthType="text"
            toolbarConfig={{ actions: ['delete'] }}
        >
            <div contentEditable={false}>
                <StackWrapper
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    selected={selected}
                    $isArticleEditor={editor.isArticleEditor}
                >
                    <Hr selected={selected} />
                </StackWrapper>

                {children}
            </div>
        </ElementWrapper>
    );
};

export default Separator;

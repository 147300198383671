import {
    ControllerRenderProps,
    FieldPath,
    FieldValues,
    UseControllerProps,
    UseControllerReturn,
    useController,
} from 'react-hook-form';
import { useCallback, useEffect } from 'react';

import { useReactHookFormMetaContext } from '@@containers/ReactHookForm/ReactHookFormMetaContext';
import { asyncTransformValidate } from '@@form/utils/transformers';

import useCachedValidation, { UseCachedValidationProps } from './useCachedValidation';
import useReactHookFormFieldError, { FormFieldError } from './useReactHookFormFieldError';
import useTransform, { UseTransformOptions } from './useTransform';

export type UseFormFieldReturn<
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerRenderProps<TFieldValues>, 'onChange' | 'onBlur'> & {
    error?: FormFieldError;
    onChange: UseControllerReturn<TFieldValues, TName>['field']['onChange'];
    onBlur: React.FocusEventHandler;
};

export type UseFormFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues> &
    UseCachedValidationProps & {
        onChange?: (
            value: UseControllerReturn<TFieldValues, TName>['field']['value'],
            onFieldChange: UseControllerReturn<TFieldValues, TName>['field']['onChange'],
        ) => void;
        onBlur?: React.FocusEventHandler;
    } & {
        transform?: UseTransformOptions<TFieldValues, TName>['transform'];
    };

export const useFormField = <TFieldValues extends FieldValues>(
    props: UseFormFieldProps<TFieldValues>,
): UseFormFieldReturn<TFieldValues> => {
    const {
        name,
        novalidate,
        validate,
        validateCacheKey,
        required,
        requiredCustom,
        onChange,
        onBlur,
        transform,
        defaultValue,
    } = props;

    const { registerField, unregisterField } = useReactHookFormMetaContext();

    useEffect(() => {
        registerField(name);

        return () => {
            unregisterField(name);
        };
    }, []);

    const error = useReactHookFormFieldError(name);

    const validationRules = useCachedValidation(
        asyncTransformValidate,
        {
            novalidate,
            validate,
            validateCacheKey,
            required,
            requiredCustom,
        },
        {
            validate: undefined,
        },
    );

    // We are using the hook instead of the Controller component because it is much faster.
    // When this hook is wrapped around the editor, the Controller component creates a big performance
    // bottleneck.
    const { field } = useController<TFieldValues>({
        rules: { validate: validationRules },
        name,
        defaultValue,
    });

    const handleChange = useCallback(
        (e) => {
            // We want to allow external onChange handlers, other than the
            // one provided by React Hook Form.
            if (onChange) {
                onChange(e, field.onChange);
            } else if (!e?.defaultPrevented) {
                field.onChange(e);
            }
        },
        [field, onChange],
    );

    const handleBlur = useCallback(
        (e) => {
            field.onBlur();

            // We want to allow external onBlur handlers, other than the
            // one provided by React Hook Form.
            if (onBlur) {
                onBlur(e);
            }
        },
        [field, onBlur],
    );

    const { value: transformedValue, onChange: transformedOnChange } = useTransform({
        value: field.value,
        onChange: handleChange,
        transform,
    });

    const formFieldProps = {
        ...field,
        error,
        value: transformedValue,
        onChange: transformedOnChange,
        onBlur: handleBlur,
    };

    return formFieldProps;
};

export default useFormField;

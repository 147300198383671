import { pickBy, mapValues, set, get } from 'lodash';

// removes all null, undefined and empty object entries
export const cleanObject = (obj, cleanArraysAndObjects = true) =>
    pickBy(obj, (v) => {
        if (v !== null && typeof v !== 'undefined') {
            if (v === '') {
                return;
            }
            if (cleanArraysAndObjects && typeof v === 'object' && Object.keys(v).length <= 0) {
                return;
            }

            return true;
        }
    });

type MakeOptionResolvable<T> = T | ((props) => T);

export type MakeOptionsResolvable<T> = {
    [P in keyof T]: MakeOptionResolvable<T[P]>;
};

/**
 * resolve options
 *
 * @desc    For HOC options having lambda depending on props to be evaluated
 * @param   {Object}  options  - HOC options
 * @param   {Object}  props    - props in HOC
 * @returns {Object}  - HOC options Object evaluated
 */
export const resolveOptions = <
    ResolvedOptions extends object,
    Options = MakeOptionsResolvable<ResolvedOptions>,
>(
    options: Options,
    props,
): ResolvedOptions =>
    // @ts-expect-error lodash typings are not working in this case
    mapValues(
        // @ts-expect-error lodash typings are not working in this case
        options,
        (option: ValueOf<Options>): ValueOf<ResolvedOptions> =>
            typeof option === 'function' ? option(props, options) : option,
    );

export const pickDeep = (object, paths) => {
    const newObject = {};

    paths.forEach((path) => {
        set(newObject, path, get(object, path));
    });

    return newObject;
};

export const notNull = (arg?) => arg !== null;

export const setArrayParam = (
    searchParams: URLSearchParams,
    name: string,
    values: (string | null)[] = [],
    type: 'append' | 'replace',
) => {
    if (values.length === 0) {
        searchParams.delete(name);
    } else {
        values.forEach((value, index) => {
            if (!value) {
                return;
            }

            if (index === 0 && type === 'replace') {
                searchParams.set(name, value);
            } else {
                searchParams.append(name, value);
            }
        });
    }

    return searchParams;
};

import React, { createContext, useContext } from 'react';

import { ButtonsType } from './Buttons';

type Props = {
    handleClickOnResetButton: () => void | Promise<void>;
    handleClickOnCloseButton: () => void;
    handleClickOnRestoreButton: () => void | Promise<void>;
    handleClickOnDeleteButton: () => Promise<void>;
    handleClickOnSubmitButton: () => void;
    pristine: boolean;
    submitting: boolean;
    children?: React.ReactNode;
    dirty: boolean;
    hasActiveCancelButton?: boolean;
    hasButtonGroup?: boolean;
    hasCancelButton?: boolean;
    hasCloseButton?: boolean;
    hasDeleteButton?: boolean;
    hasRestoreButton?: boolean;
    isEditMode?: boolean;
    alwaysShowCancelButton?: boolean;
    renderCancelButton?: (buttons: ButtonsType) => React.ReactElement;
    renderDeleteButton?: (buttons: ButtonsType) => React.ReactElement;
    renderRestoreButton?: (buttons: ButtonsType) => React.ReactElement;
    renderSubmitButton?: (buttons: ButtonsType) => React.ReactElement;
};

const ButtonsContext = createContext<Props>({
    handleClickOnResetButton: () => {},
    handleClickOnCloseButton: () => {},
    handleClickOnRestoreButton: () => {},
    handleClickOnDeleteButton: () => Promise.resolve(),
    handleClickOnSubmitButton: () => {},
    pristine: true,
    submitting: false,
    dirty: false,
});

export const ButtonsProvider = ({ children, ...props }: Props) => (
    <ButtonsContext.Provider value={props}>{children}</ButtonsContext.Provider>
);

export const useButtonsContext = () => useContext(ButtonsContext);

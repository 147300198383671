import isState from '@@editor/utils/isState';
import toText from '@@editor/utils/toText';

const MAX_LENGTH_ERROR_MESSAGE = 'validator.message.maxLength';
// t('validator.message.maxLength');

export default (maxLength, message = MAX_LENGTH_ERROR_MESSAGE) =>
    (value) => {
        if (isState(value)) {
            const text = toText(value);

            if (text.length > maxLength) {
                return [message, { maxLength }];
            }
        } else if (typeof value === 'string') {
            if (value.length > maxLength) {
                return [message, { maxLength }];
            }
        }
    };

import React, { useMemo } from 'react';
import { Descendant } from 'slate';
import { styled } from '@mui/material';
import { withTranslation } from 'react-i18next';

import usePostSpellCheck from '@@form/hooks/usePostSpellCheck';
import { getInputPlaceholderStyle } from '@@editor/plugins/serializable/styles';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { PLUGIN_CONFIG_TEMPLATES } from '@@editor/constants';
import setupPlugins from '@@editor/plugins/setup';
import useFetchMetadata from '@@form/hooks/useFetchMetadata';
import { type Tenant } from '@@api/services/tenant/schemas';
import { ParagraphElement } from '@@editor/helpers/Element';

import RichTextEditorBase from './RichTextEditorBase';

type Props = {
    hideStickyToolbar?: boolean;
    readOnly?: boolean;
    $templateElement?: boolean;
    placeholderText?: string;
    onBlur?: (e?: React.FocusEvent) => void;
    onChange?: (value: Descendant[]) => void;
    onFocus?: (e?: React.FocusEvent) => void;
    multiline?: boolean;
    tenantIds: Tenant['id'][];
    value?: ParagraphElement[];
};

const PollRichTextEditor = (props: Props) => {
    const { multiline, tenantIds } = props;
    const spellCheck = usePostSpellCheck();
    const fetchMetadata = useFetchMetadata();

    const plugins = useMemo(() => {
        let pluginConfig: any = [
            PLUGIN_CONFIG_TEMPLATES.autoReplaceText,
            PLUGIN_NAMES.PARAGRAPH,
            PLUGIN_CONFIG_TEMPLATES.specialCharacters,
            PLUGIN_CONFIG_TEMPLATES.softHyphen,
            PLUGIN_NAMES.SPELL_CHECKER,
            PLUGIN_NAMES.ITALIC,
            PLUGIN_NAMES.BOLD,
            PLUGIN_NAMES.LINK,
        ];

        if (!multiline) {
            pluginConfig = [...pluginConfig, PLUGIN_NAMES.SINGLE_LINE];
        }

        return setupPlugins(pluginConfig, {
            optionsPerPlugin: {
                [PLUGIN_NAMES.SPELL_CHECKER]: {
                    spellCheck,
                    tenantIds,
                },
                [PLUGIN_NAMES.LINK]: {
                    fetchMetadata,
                },
                [PLUGIN_NAMES.INSERT_HTML]: {
                    fetchMetadata,
                },
            },
        });
    }, [multiline, spellCheck, fetchMetadata]);

    const stickyToolbarButtons = [
        PLUGIN_CONFIG_TEMPLATES.specialCharacters,
        PLUGIN_CONFIG_TEMPLATES.softHyphen,
        { name: PLUGIN_NAMES.BOLD },
        { name: PLUGIN_NAMES.ITALIC },
        { name: PLUGIN_NAMES.LINK },
    ];
    let conditionalProps = {};

    if (!multiline) {
        conditionalProps = {
            ...conditionalProps,
            minRows: 1,
        };
    }

    return (
        <RichTextEditorBase
            {...conditionalProps}
            {...props}
            plugins={plugins}
            stickyToolbarButtons={stickyToolbarButtons}
            hideFloatingToolbar
            spellCheck
        />
    );
};

const SimplePollRichTextEditor = withTranslation()(PollRichTextEditor);

type EmbedPollEditorProps = {
    $templateElement?: boolean;
    $placeholderText?: string;
};

export const EmbedPollHeadingEditor = styled(SimplePollRichTextEditor)<EmbedPollEditorProps>(
    ({ $templateElement, $placeholderText, theme }) => ({
        ...theme.typography.editorSecondaryMedium,
        padding: theme.spacing(0, 0, 2),
        ...($templateElement &&
            getInputPlaceholderStyle({ theme, placeholderText: $placeholderText })),
    }),
);

export const EmbedPollContentEditor = styled(SimplePollRichTextEditor)<EmbedPollEditorProps>(
    ({ $templateElement, $placeholderText, theme }) => ({
        ...theme.typography.editorPrimarySmall,
        padding: theme.spacing(0, 0, 2),
        ...($templateElement &&
            getInputPlaceholderStyle({ theme, placeholderText: $placeholderText })),
    }),
);

export default SimplePollRichTextEditor;

import { isEqual, pick } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';

type UseMouseStateOptions = {
    useTimeout?: boolean;
    include?: string[];
    exclude?: string[];
};

const nodeListContains = (nodeList: NodeList, needle: HTMLElement | null) =>
    Array.from(nodeList).some((node) => node.contains(needle));

const useMouseState = (options: UseMouseStateOptions = {}) => {
    const { useTimeout = false, include = ['isActive', 'isHovered'], exclude = [] } = options;
    const status = include.filter((i) => !exclude.includes(i));
    const [state, setState] = useState({ isActive: false, isHovered: false });
    const prevDomNode = useRef<HTMLElement | null>(null);

    const setStateProxy = (nextState: Partial<typeof state>) => {
        setState((state) => {
            const mergedState = pick({ ...state, ...nextState }, status) as typeof state;

            // This is needed for `status` working correctly (avoiding re-rendering of
            // component when only excluded values have been changed)
            if (!isEqual(state, mergedState)) {
                return mergedState;
            }

            return state;
        });
    };

    const activeDomNode = useRef<HTMLElement | null>(null);
    const hoveredDomNode = useRef<HTMLElement | null>(null);

    const observer = useMemo(() => {
        if (window.MutationObserver) {
            return new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (mutation.type === 'childList' && mutation.removedNodes.length) {
                        if (nodeListContains(mutation.removedNodes, activeDomNode.current)) {
                            activeDomNode.current = null;

                            setStateProxy({ isActive: false });
                        }

                        if (nodeListContains(mutation.removedNodes, hoveredDomNode.current)) {
                            hoveredDomNode.current = null;

                            setStateProxy({ isHovered: false });
                        }
                    }
                });
            });
        }
    }, []);

    const handleMouseOver = (e: MouseEvent) => {
        hoveredDomNode.current = e.target as HTMLElement;
    };

    const handleMouseEnter = (e: MouseEvent) => {
        hoveredDomNode.current = e.target as HTMLElement;

        // Set is hovered to false, as long as the primary button was not pressed.
        // A pressed primary button indicates that the user is performing a drag action.
        // So far, we do not want to have hovered effects in this case
        setStateProxy({ isHovered: e.buttons !== 1 });
    };

    const handleMouseOut = () => {
        hoveredDomNode.current = null;
    };

    const handleMouseLeave = () => {
        activeDomNode.current = null;
        hoveredDomNode.current = null;

        setStateProxy({ isActive: false, isHovered: false });
    };

    const handleMouseDown = (e: MouseEvent) => {
        activeDomNode.current = e.target as HTMLElement;

        const updateState = () => {
            setStateProxy({ isActive: true });
        };

        // The setTimeout is needed because sometimes race conditions happen between event processing
        // and state updating:
        // 1. slate.js got a problem when isActive was updated in the same moment the actual
        // dom element got the mousedown. After adding a setTimeout here, the problem vanished.
        // The downside to this workaround is that there is a slight delay when an element
        // got a mousedown, until it receives the isActive prop (which causes a minimal visual
        // delay in rendering the element in the mousedown state). This is why we do not enable
        // this globally, but only by using the config option "useTimeout".
        if (useTimeout) {
            requestAnimationFrame(updateState);
        } else {
            updateState();
        }
    };

    const handleMouseUp = () => {
        activeDomNode.current = null;

        const updateState = () => {
            setStateProxy({ isActive: false });
        };

        // Please consider the comment in the handleMouseDown functions
        if (useTimeout) {
            requestAnimationFrame(updateState);
        } else {
            updateState();
        }
    };

    const refCallback = useCallback((domNode: HTMLElement | null) => {
        if (domNode === null) {
            if (prevDomNode.current instanceof HTMLElement) {
                prevDomNode.current.removeEventListener('mouseover', handleMouseOver);
                prevDomNode.current.removeEventListener('mouseenter', handleMouseEnter);
                prevDomNode.current.removeEventListener('mouseout', handleMouseOut);
                prevDomNode.current.removeEventListener('mouseleave', handleMouseLeave);
                prevDomNode.current.removeEventListener('mousedown', handleMouseDown);
                prevDomNode.current.removeEventListener('mouseup', handleMouseUp);
            }

            if (observer) {
                observer.disconnect();
            }
        } else if (domNode instanceof HTMLElement) {
            prevDomNode.current = domNode;

            domNode.addEventListener('mouseover', handleMouseOver);
            domNode.addEventListener('mouseenter', handleMouseEnter);
            domNode.addEventListener('mouseout', handleMouseOut);
            domNode.addEventListener('mouseleave', handleMouseLeave);
            domNode.addEventListener('mousedown', handleMouseDown);
            domNode.addEventListener('mouseup', handleMouseUp);

            if (observer) {
                observer.observe(domNode, { childList: true });
            }
        }

        setStateProxy({
            isActive:
                domNode === activeDomNode.current ||
                (domNode instanceof HTMLElement &&
                    activeDomNode.current instanceof HTMLElement &&
                    domNode.contains(activeDomNode.current)),
            isHovered:
                domNode === hoveredDomNode.current ||
                (domNode instanceof HTMLElement &&
                    hoveredDomNode.current instanceof HTMLElement &&
                    domNode.contains(hoveredDomNode.current)),
        });
    }, []);

    return [state, refCallback] as const;
};

export default useMouseState;

import { createElement } from './../deserializeNodes';

const legacyTypes = ['slideshow'];

const isLegacyImportedNode = (nodeOrElement) =>
    nodeOrElement && legacyTypes.some((type) => type === nodeOrElement.type);

const legacyImportRules = {
    deserialize: (element) => {
        if (!isLegacyImportedNode(element)) {
            return;
        }

        const { type, ...rest } = element;
        const data = rest;

        return createElement(type, data);
    },

    serialize: (node) => {
        if (!isLegacyImportedNode(node)) {
            return;
        }

        const { type, data } = node;

        return {
            type,
            ...data,
        };
    },
};

export default legacyImportRules;

import React from 'react';

import { HOTKEYS } from '@@editor/constants';
import renderLeaf from '@@editor/plugins/utils/renderLeaf';
import handleHotkey from '@@editor/plugins/utils/handleHotkey';
import { Editor } from '@@editor/helpers';

const MARK = 'code';

type Props = {
    attributes: UnknownObject;
    children: React.ReactNode;
};

const CodeMark = (props: Props) => <code {...props.attributes}>{props.children}</code>;

export const withCode = (editor: Editor) =>
    Object.assign(editor, {
        renderLeaf: renderLeaf(editor, [[MARK, CodeMark]]),
        handleHotkey: handleHotkey(editor, [
            [HOTKEYS.CODE, (editor: Editor) => editor.toggleMark(MARK)],
        ]),
    });

export default withCode;

import { Key } from 'slate-react/dist/utils/key';

import { ELEMENT_TYPES } from '@@editor/helpers/Element';
import { Editor, Node, ReactEditor } from '@@editor/helpers';
import { PluginOptions } from '@@editor/typings/UnityPlugins';
import makeState from '@@editor/utils/makeState';
import getVideocmsPreviewImage from '@@editor/selectors/getVideocmsPreviewImage';
import getElvisIdByImage from '@@editor/selectors/getElvisIdByImage';
import { UploadFile } from '@@api/hooks/useUploadFile';

import { uploadFileHelper } from './uploadFileHelper';

/*
 * caption gets serialized as it is not serialized on previewImage and maybe on other places as well.
 */

export const withFileUpload = (
    editor: Editor,
    options: PluginOptions & { uploadFile: UploadFile },
): Editor => {
    const uploadsInProgress = new Set<Key['id']>();

    const uploadVideoCms = uploadFileHelper({
        uploadFile: options.uploadFile,
        updateEmbedData: (payload) => ({
            previewImage: {
                url: payload.src,
                elvisId: payload.id,
                caption: makeState(payload.title),
            },
        }),
        updateSource: false,
    });

    const uploadImage = (uploadImageOptions) =>
        uploadFileHelper({
            uploadFile: options.uploadFile,
            updateEmbedData: ({ src, id, caption, name, credit }) => ({
                url: src,
                elvisId: id,
                caption: makeState(caption),
                name,
                credit,
            }),
            ...uploadImageOptions,
        });

    const uploadFileByNode = (editor: Editor, node: Node, previousNode?: Node) => {
        const key = ReactEditor.findKey(editor, node);

        switch (node.type) {
            case ELEMENT_TYPES.VIDEOCMS: {
                const previewImage = getVideocmsPreviewImage(node);

                if (!previewImage?.elvisId && previewImage?.url) {
                    if (!uploadsInProgress.has(key.id)) {
                        uploadsInProgress.add(key.id);

                        uploadVideoCms(editor, node, previewImage.url)
                            .then(() => {
                                uploadsInProgress.delete(key.id);
                            })
                            .catch((e) => console.log(e));
                    }
                }

                break;
            }

            case ELEMENT_TYPES.IMAGE:
                if (!getElvisIdByImage(node) && node.data.originalSrc) {
                    if (!uploadsInProgress.has(key.id)) {
                        uploadsInProgress.add(key.id);

                        uploadImage({ updateSource: !previousNode })(
                            editor,
                            node,
                            node.data.originalSrc,
                            previousNode,
                        )
                            .then(() => {
                                uploadsInProgress.delete(key.id);
                            })
                            .catch((e) => console.log(e));
                    }
                }

                break;
        }
    };

    return Object.assign(editor, {
        onAfterUpdateElement: (node: Node, previousNode?: Node) => {
            uploadFileByNode(editor, node, previousNode);
        },
        onAfterInsertElement: (node) => {
            uploadFileByNode(editor, node);
        },
    });
};

export default withFileUpload;

import React from 'react';

import { HOTKEYS } from '@@editor/constants';
import renderLeaf from '@@editor/plugins/utils/renderLeaf';
import handleHotkey from '@@editor/plugins/utils/handleHotkey';
import { Editor } from '@@editor/helpers';

const MARK = 'italic';

type Props = {
    attributes: UnknownObject;
    children: React.ReactNode;
};

const ItalicMark = (props: Props) => <em {...props.attributes}>{props.children}</em>;

export const withItalic = (editor: Editor) =>
    Object.assign(editor, {
        renderLeaf: renderLeaf(editor, [[MARK, ItalicMark]]),
        handleHotkey: handleHotkey(editor, [
            [HOTKEYS.ITALIC, (editor: Editor) => editor.toggleMark(MARK)],
        ]),
    });

export default withItalic;

import React from 'react';
import { FormProvider } from 'react-hook-form';

import Form from '@@form/components/Form/Form';
import NavPrompt from '@@router/components/NavPrompt';
import useModalLeavePrompt from '@@form/hooks/useModalLeavePrompt';

import { ReactHookFormBaseProps } from './types';
import useGeneratedFormName from './useGeneratedFormName';
import { ReactHookFormMetaProvider } from './ReactHookFormMetaContext';
import useReactHookForm from './useReactHookForm';

export type Props = ReactHookFormBaseProps & { formNameDependencies?: UnknownObject };

const NamedForm = (props: Props) => {
    const { modalLeavePrompt, navPromptCondition, resetOnConfirmNavPrompt, ...otherProps } = props;
    const { children } = otherProps;

    const { form, formMeta, componentProps } = useReactHookForm(otherProps);
    const { isDirty, isSubmitting } = form.formState;

    useModalLeavePrompt({ modalLeavePrompt, formContext: form });

    return (
        <FormProvider {...form}>
            <ReactHookFormMetaProvider {...formMeta}>
                <Form {...componentProps}>
                    {typeof children === 'function' ? children(form) : children}
                </Form>
            </ReactHookFormMetaProvider>

            {(isDirty || isSubmitting) && (
                <NavPrompt
                    blockerCondition={navPromptCondition}
                    resetOnConfirm={resetOnConfirmNavPrompt}
                />
            )}
        </FormProvider>
    );
};

const ReactHookForm = (props: Props) => {
    const { formName, formNameDependencies = {}, ...otherProps } = props;
    const { isLoading } = otherProps;

    const generatedFormName = useGeneratedFormName({
        name: formName,
        dependsOn: { ...(isLoading !== undefined && { isLoading }), ...formNameDependencies },
    });

    // We have a separate component because we need the key to change when the formName changes
    // Without this, useReactHookForm will not fully re-render and the form will not be reset properly
    return <NamedForm key={generatedFormName} {...otherProps} formName={generatedFormName} />;
};

export default ReactHookForm;

import { noop } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import invariant from 'tiny-invariant';

import { not } from '@@utils/function';
import { filterChildren } from '@@utils/React';
import ToggleState from '@@components/toggle/ToggleState';

export type ToggleProps = {
    activeIndex?: number;
    onChange?: (index: number, value: any) => void;
    className?: string;
    children?: ReactNode;
};

const isToggleState = (child) => child.type === ToggleState;

const getToggleStateChildren = filterChildren(isToggleState);
const getNonToggleStateChildren = filterChildren(not(isToggleState));

const Toggle = ({ children, activeIndex = 0, onChange = noop, className }: ToggleProps) => {
    const nonToggleStateChildren = getNonToggleStateChildren(children);

    invariant(
        nonToggleStateChildren.length === 0,
        'Toggle component can only have children of type ToggleState. Some other type of children were present and have been discarded.',
    );

    const toggleStateChildren = getToggleStateChildren(children);
    const maxStateIndex = toggleStateChildren.length - 1;

    invariant(
        !(activeIndex > maxStateIndex || activeIndex < 0),
        `Index ${activeIndex} does not exists. Index must be between 0 and ${maxStateIndex}.`,
    );

    const [stateIndex, setStateIndex] = useState(activeIndex);

    useEffect(() => {
        setStateIndex(activeIndex);
    }, [activeIndex]);

    const toggleNextState = () => {
        setStateIndex((current) => {
            const newIndex = current < maxStateIndex ? current + 1 : 0;

            // @ts-ignore
            onChange(newIndex, toggleStateChildren[newIndex].props.value);

            return newIndex;
        });
    };

    return (
        <div className={className} onClick={toggleNextState}>
            {toggleStateChildren && toggleStateChildren[stateIndex]}
        </div>
    );
};

export default Toggle;

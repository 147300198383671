import { parseUrl, toURL } from '@@utils/URL';

const document = window.document;

const isTiktokPostUrl = (value) =>
    /^http(?:s)?:\/\/(?:www\.)?tiktok\.com\/[^<]*\/video\/.+/.test(value);

export const isTiktokUrl = (value) => {
    const url = toURL(value);

    if (url instanceof URL) {
        return /tiktok\.com/.test(url.hostname);
    }
};

export const isTiktokEmbedCode = (code) => {
    const { url } = parseTiktokEmbedCode(code);

    if (url instanceof URL) {
        return true;
    }
};

export const parseTiktokEmbedCode = (code) => {
    const el = document.createElement('div');

    el.innerHTML = code;

    const [blockquote] = el.getElementsByTagName('blockquote');

    if (blockquote instanceof HTMLElement) {
        if (blockquote.className.match(/tiktok-embed/)) {
            const cite = blockquote.getAttribute('cite');
            const link = isTiktokPostUrl(cite) ? cite : undefined;

            if (link) {
                const tiktokUrl = parseUrl(link);

                if (tiktokUrl) {
                    return {
                        url: tiktokUrl,
                        dataset: blockquote.dataset,
                    };
                }
            }
        }
    }

    return {};
};

import { z } from 'zod';

export const unityVariantsShape = <
    T extends z.ZodType<AnyObject> | z.ZodType<string> | z.ZodType<Array<unknown>>,
>(
    variant: T,
) => z.record(z.string(), variant);

export type UnityVariants<
    T extends z.ZodType<object> | z.ZodType<string> | z.ZodType<Array<unknown>>,
> = z.infer<ReturnType<typeof unityVariantsShape<T>>>;

export const variantsShape = <T extends z.ZodRawShape>(variant: T | z.ZodObject<T>) => {
    const verifiedVariant = variant instanceof z.ZodObject ? variant : z.object(variant);

    return z.array(verifiedVariant.extend({ variantId: z.number() }));
};

export type LocalVariants<T extends z.ZodRawShape = z.ZodRawShape> = z.infer<
    ReturnType<typeof variantsShape<T>>
>;

const Sort = z.union([
    z.array(
        z.object({
            ascending: z.boolean(),
            descending: z.boolean(),
            direction: z.string(),
            ignoreCase: z.boolean(),
            nullHandling: z.string(),
            property: z.string(),
        }),
    ),
    z.object({
        sorted: z.boolean(),
        empty: z.boolean(),
        unsorted: z.boolean(),
    }),
]);

export const paginated = <T extends z.ZodTypeAny>(schema: T) =>
    z.object({
        content: z.array(schema),
        empty: z.boolean().optional(),
        first: z.boolean(),
        last: z.boolean(),
        number: z.number(),
        numberOfElements: z.number().optional(),
        pageable: z
            .union([
                z.object({
                    offset: z.number(),
                    pageNumber: z.number(),
                    pageSize: z.number(),
                    paged: z.boolean(),
                    sort: Sort,
                    unpaged: z.boolean(),
                }),
                z.string(),
            ])
            .optional(),
        sort: Sort.optional(),
        size: z.number().nullable(),
        totalElements: z.number(),
        totalPages: z.number().nullable(),
    });

import { get } from 'lodash';
import React from 'react';
import { styled } from '@mui/material';

import { EmbedElement } from '@@editor/helpers/Element';
import CaptionEditor from '@@form/components/RichTextEditor/CaptionEditor';
import isEmptyState from '@@editor/utils/isEmptyState';

const CaptionTitle = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.editorSecondarySmall,
    fontWeight: 'bold',
}));

const Credit = styled('span')(({ theme }) => ({
    ...theme.typography.editorSecondaryXsmall,
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
}));

const StyledCaptionEditor = styled(CaptionEditor)(({ theme }) => ({
    ...theme.typography.editorSecondarySmall,
}));

const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.fixed.editor.embed.backgroundColor,
    padding: theme.spacing(2),
}));

type Props = {
    children: React.ReactNode | null;
    element: EmbedElement;
    withCaption?: boolean;
};

export const ElementFooter = (props: Props) => {
    const { children, element, withCaption } = props;

    const embed = get(element, 'data.embed', {});
    const { caption, captionTitle, credit } = embed;

    const hasCaptionTitle = withCaption !== false && Boolean(captionTitle);
    const hasCaption = withCaption !== false && Boolean(caption) && !isEmptyState(caption);

    if (!hasCaptionTitle && !hasCaption && !credit) {
        return <>{children}</>;
    }

    return (
        <Wrapper>
            {hasCaptionTitle && <CaptionTitle>{captionTitle}</CaptionTitle>}

            {hasCaption && (
                <StyledCaptionEditor
                    value={caption}
                    readOnly
                    hideStickyToolbar
                    hideFloatingToolbar
                />
            )}

            {credit && <Credit>{credit}</Credit>}

            {children}
        </Wrapper>
    );
};

export default ElementFooter;

import { flow, map, partialRight, pick } from 'lodash';

import { SnippetElement, ELEMENT_TYPES, Element } from '@@editor/helpers/Element';
import { isUnitySnippet, UnitySnippet } from '@@editor/typings/UnityElements';
import transformValues from '@@editor/serialization/transformValues';
import addDefaultElementIfEmptyState from '@@editor/utils/addDefaultElementIfEmptyState';

import { createElement } from '../deserializeNodes';
import { CURRENT_VERSION } from '../constants';

const snippetRules = {
    deserialize: (element: UnitySnippet, next): SnippetElement | undefined => {
        if (!isUnitySnippet(element)) {
            return;
        }

        const values = pick(element, ['content']);

        return createElement<SnippetElement>(
            ELEMENT_TYPES.SNIPPET,
            transformValues<typeof values, SnippetElement['data']>(values, [
                ['content', flow(addDefaultElementIfEmptyState, partialRight(map, next))],
            ]),
        );
    },

    serialize: (node: Element, next): UnitySnippet | undefined => {
        if (!Element.isSnippetElement(node)) {
            return;
        }

        const { type, data } = node;

        return {
            version: CURRENT_VERSION,
            type,
            ...transformValues<typeof data, Omit<UnitySnippet, 'version' | 'type'>>(data, [
                ['content', partialRight(map, next)],
            ]),
        };
    },
};

export default snippetRules;

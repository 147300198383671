import { get } from 'lodash';
import { ellipsis, stripUnit } from 'polished';
import { TFunction } from 'i18next';
import React from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';

import { SlideshowElement } from '@@editor/helpers/Element';
import Icon from '@@components/Icon';
import useUnitySearch from '@@containers/Search/useUnitySearch';
import SelectionTableField from '@@form/fields/SelectionTable';
import Pagination from '@@components/Pagination';
import DateTime from '@@lib/dateTime/DateTime';
import PreviewImage from '@@containers/PreviewImage';
import PublicationStatus from '@@constants/PublicationStatus';
import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { getDefaultTeaserVariant } from '@@containers/Teaser/utils';
import UnitySearch from '@@containers/Search/UnitySearch';
import ButtonGroup from '@@form/components/ButtonGroup';
import Spacer from '@@components/Spacer';
import { SlideshowRouter, useSlideshowClient } from '@@api/services/slideshow/client';
import { getQueryParams } from '@@api/utils/getQueryParams';
import { defaultQueryPlaceholderData } from '@@api/utils/defaultPlaceholderData';
import { UseReactHookFormProps } from '@@containers/ReactHookForm/types';
import { getAuthorIdFromSearchValue } from '@@components/AuthorSearchableAutocomplete/utils';

import { getSlideshowId } from './../utils';

const PREVIEW_IMAGE = {
    WIDTH: '100%',
    HEIGHT: '56px',
};

const TableWrapper = styled('div')({
    minHeight: '360px',
});

const CreatorName = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    ...ellipsis('140px'),
}));

const ImgWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(3, 0),
    position: 'relative',

    '& img': {
        width: '171px',
        height: '96px',
        objectFit: 'cover',
        borderRadius: '2px',
    },
}));

// element data needs to be transformed back into form data
const transformElementToFormValues = (element) => {
    // default
    if (!element) {
        return { embed: null };
    }

    const data = element.data;

    return {
        embed: {
            id: getSlideshowId(data),
        },
    };
};

type Props = {
    className: string;
    element: SlideshowElement;
    multiple: boolean;
    onCancel: VoidFunction;
    onClickFieldAction: VoidFunction;
    onSubmit: UseReactHookFormProps['onSubmit'];
    t: TFunction;
};

const SlideshowForm = ({
    onCancel,
    onSubmit,
    className,
    multiple,
    element,
    onClickFieldAction,
    t,
}: Props) => {
    const search = useUnitySearch({
        moreFilters: true,
        fields: {
            q: true,
            userIds: true,
        },
    });

    const page = search.pageNumbers[0];
    const SIZE = 10;

    const params = getQueryParams<SlideshowRouter['slideshow']['getAll']>({
        q: search.debouncedValues.q,
        userIds: getAuthorIdFromSearchValue(search.values.userIds),
        sort: search.debouncedValues.q ? null : 'createdAt,desc',
        size: SIZE,
        page: page - 1,
        publicationStatus: search.values.publicationStatus || PublicationStatus.PUBLISHED,
    });

    const { client: slideshowClient, queryKeys: slideshowKeys } = useSlideshowClient();

    const { data } = slideshowClient.slideshow.getAll.useQuery(
        slideshowKeys.slideshow.getAll({ query: params }),
        {
            query: params,
        },
        {
            keepPreviousData: true,
            placeholderData: defaultQueryPlaceholderData(SIZE),
        },
    );
    const content = data?.body.content || [];
    const firstPageNumber = 1;
    const lastPageNumber = data?.body.totalPages || 0;

    const handleClickOnCancel = () => onCancel();

    const renderButtons = (Buttons) => (
        <ButtonGroup>
            <Buttons.CancelButton />
            <Buttons.SubmitButton>{t('relatedcontent.submitButton')}</Buttons.SubmitButton>
        </ButtonGroup>
    );

    const renderImage = ({ record }) => (
        <ImgWrapper>
            <PreviewImage
                src={getDefaultTeaserVariant(get(record, 'metadata.teasers'))?.image.url}
                transformations={{
                    maxWidth: 2 * (stripUnit(PREVIEW_IMAGE.WIDTH) as number),
                }}
            />
        </ImgWrapper>
    );

    const renderTitle = ({ value, record }) => (
        <Box padding={3}>
            <Typography variant="title3">{value || ''}</Typography>
            <Spacer v sm />

            <Typography variant="small" color="primary.light" component="div">
                <Stack direction="row" alignItems="center">
                    <Icon name="images-regular" />
                    <Spacer h sm />
                    {record.slideCount || 0}
                </Stack>
            </Typography>
        </Box>
    );

    const renderCreator = ({ value }) => {
        if (value && value.firstName && value.lastName) {
            const fullName = `${value.firstName} ${value.lastName}`;

            return <CreatorName title={fullName}>{fullName}</CreatorName>;
        }
    };

    const renderDate = ({ value }) => (
        <Box padding={3}>
            <DateTime date={value} type="past" />
        </Box>
    );

    return (
        <Stack>
            <UnitySearch {...search.unitySearchProps} />

            <ReactHookForm
                formName="SlideshowSelectionForm"
                values={transformElementToFormValues(element)}
                onSubmit={onSubmit}
                onCancel={handleClickOnCancel}
                renderButtons={renderButtons}
                hasCancelButton
                hasActiveCancelButton
                alwaysShowCancelButton
                className={className}
            >
                <Box marginTop={3} marginX={-4}>
                    <TableWrapper>
                        <SelectionTableField
                            name="embed.id"
                            columns={[
                                {
                                    type: 'text',
                                    width: '171px',
                                    title: t('slideshow.editorPlugin.modal.image'),
                                    render: renderImage,
                                },
                                {
                                    fieldName: 'metadata.contentName',
                                    title: t('slideshow.editorPlugin.modal.contentName'),
                                    width: '289px',
                                    verticalAlign: 'top',
                                    render: renderTitle,
                                },
                                {
                                    fieldName: 'updatedAt',
                                    title: t('slideshow.editorPlugin.modal.updated'),
                                    width: '147px',
                                    verticalAlign: 'top',
                                    render: renderDate,
                                },
                                {
                                    type: 'text',
                                    fieldName: 'metadata.creator',
                                    title: t('slideshow.editorPlugin.modal.author'),
                                    width: '136px',
                                    verticalAlign: 'top',
                                    render: renderCreator,
                                },
                            ]}
                            tableData={content}
                            multiple={multiple}
                            onClickFieldAction={onClickFieldAction}
                        />
                    </TableWrapper>

                    <Spacer v lg />

                    <Pagination
                        current={page}
                        first={firstPageNumber}
                        last={lastPageNumber}
                        getPageHref={search.getPageHref}
                        onChange={search.handlePageChange}
                    />
                </Box>
            </ReactHookForm>
        </Stack>
    );
};

export default SlideshowForm;

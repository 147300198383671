import React, { ReactNode, useEffect, useRef } from 'react';

import { FacebookElement } from '@@editor/helpers/Element';
import Loader from '@@components/Loader';
import { useLoader } from '@@hooks/useLoader';

type RenderProps = {
    'data-width': 'auto';
    src: FacebookElement['data']['src'];
    height: FacebookElement['data']['payload']['height'] | 'auto';
} & FacebookElement['data']['payload'];

type Props = {
    element: FacebookElement;
    children: (props: RenderProps) => ReactNode;
};

const FacebookEmbed = ({ children, element }: Props) => {
    const xfbmlDomNode = useRef<HTMLDivElement>(null);
    const isUnmounted = useRef(true);

    const { isLoading, onLoad } = useLoader();

    useEffect(() => {
        isUnmounted.current = false;

        const renderEmbed = () => {
            // Component might be unmounted by the time facebook sdk has been instantiated, so
            // check if reference to domNode is still available first
            if (!isUnmounted.current && xfbmlDomNode.current) {
                // @ts-ignore
                window.FB.XFBML.parse(xfbmlDomNode.current, () => {
                    onLoad();
                });
            }
        };

        // @ts-ignore
        window._fbCallbacks.push(renderEmbed);

        return () => {
            isUnmounted.current = true;
        };
    }, []);

    const data = element.data;
    const payload = { ...data.payload, 'data-width': 'auto' };
    const height = payload.height || 'auto';

    return (
        <Loader isLoading={isLoading} keepChildrenInDom>
            <div ref={xfbmlDomNode}>{children({ ...payload, height, src: data.src })}</div>
        </Loader>
    );
};

export default FacebookEmbed;

import { ELEMENT_TYPES } from '@@editor/helpers/Element';
import { CURRENT_VERSION } from '@@editor/serialization/UnitySerializer/constants';

const addDefaultElementIfEmptyState = (value) => {
    const emptyElement = {
        type: ELEMENT_TYPES.TEXT,
        items: [{ text: '', version: CURRENT_VERSION, type: ELEMENT_TYPES.TEXTITEM }],
        version: CURRENT_VERSION,
    };

    if (Array.isArray(value) && value.length === 0) {
        return [emptyElement];
    }

    if (value === null) {
        return emptyElement;
    }

    return value;
};

export default addDefaultElementIfEmptyState;

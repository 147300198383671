import React from 'react';
import { ThemeProvider } from '@mui/material';

import { Element, ELEMENT_TYPES } from '@@editor/helpers/Element';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import renderElement from '@@editor/plugins/utils/renderElement';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import makeState from '@@editor/utils/makeState';
import { Editor, ReactEditor } from '@@editor/helpers';
import deleteForward from '@@editor/plugins/utils/deleteForward';
import {
    normalizeInlineEditableElement,
    preventDeleteBackward,
    preventDeleteForward,
    preventDeleteFragment,
    preventInsertBreak,
} from '@@editor/plugins/utils/inlineEditing';
import deleteBackward from '@@editor/plugins/utils/deleteBackward';
import deleteFragment from '@@editor/plugins/utils/deleteFragment';
import insertBreak from '@@editor/plugins/utils/insertBreak';
import normalizeNode from '@@editor/plugins/utils/normalizeNode';

import { createGenerateLayoutBlock } from '../utils';
import EmbedWrapper from './../../embed/components/EmbedWrapper';
import DynamicTeaser from './DynamicTeaser';
import EditorWithEmbedModal from '../../embed/components/EditorWithEmbedModal';
import DynamicTeaserForm from './DynamicTeaserForm';
import DynamicTeaserTitleElement from './DynamicTeaserTitleElement';
import InlineDynamicTeaser from './InlineDynamicTeaser';
import { editorPlaceholderTheme } from '../../styles';

const TYPE = PLUGIN_NAMES.DYNAMIC_TEASER;
const NODE_TYPE = ELEMENT_TYPES.DYNAMIC_TEASER;
const INLINE_EDITABLE_CHILDREN_TYPES = [ELEMENT_TYPES.DYNAMIC_TEASER_TITLE];

const DynamicTeaserNode = (props) => {
    const { editor, element } = props;
    const { useInlineEditing } = editor;

    const generateTheme = (theme) =>
        Element.isTemplateElement(element) ? editorPlaceholderTheme(theme) : theme;

    const isInlineEdited = !Editor.isVoid(editor, element);

    return (
        <ThemeProvider theme={generateTheme}>
            <EmbedWrapper
                {...props}
                type={TYPE}
                component={isInlineEdited ? InlineDynamicTeaser : DynamicTeaser}
                placeholderComponent={isInlineEdited && InlineDynamicTeaser}
                contentEditable={isInlineEdited}
                widthType={useInlineEditing ? 'small' : 'full'}
                toolbarConfig={{
                    infos: {
                        iconName: PLUGIN_ICON_NAMES[TYPE],
                        title: editor.t('editor.plugin.dynamicTeaser.frame.title'),
                    },
                    actions: [
                        {
                            type: 'edit',
                            iconName: useInlineEditing ? 'gear' : 'pen-regular',
                            onClick: (e) => {
                                e.preventDefault();

                                return requestAnimationFrame(() =>
                                    editor.showEmbedModal(
                                        TYPE,
                                        element,
                                        ReactEditor.findPath(editor, element),
                                    ),
                                );
                            },
                        },
                        'delete',
                    ],
                    tooltips: [
                        {
                            // t('editor.plugin.dynamicTeaser.tooltip')
                            title: 'editor.plugin.dynamicTeaser.tooltip',
                        },
                    ],
                }}
            />
        </ThemeProvider>
    );
};

const generateLayoutBlock = createGenerateLayoutBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
});

export const internalWithDynamicTeaser = (editor, options) => {
    const { isVoid, useInlineEditing } = editor;

    return Object.assign(editor, {
        isVoid: (element) => {
            if (useInlineEditing && Element.isDynamicTeaserRelatedElement(element)) {
                return false;
            }

            return element.type === NODE_TYPE ? true : isVoid(element);
        },
        deleteForward: deleteForward(editor, [
            [preventDeleteForward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteBackward: deleteBackward(editor, [
            [preventDeleteBackward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteFragment: deleteFragment(editor, [
            [preventDeleteFragment, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        insertBreak: insertBreak(editor, [
            [preventInsertBreak, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        normalizeNode: normalizeNode(editor, [
            [
                normalizeInlineEditableElement,
                {
                    type: ELEMENT_TYPES.DYNAMIC_TEASER,
                    allowedChildrenTypes: INLINE_EDITABLE_CHILDREN_TYPES,
                },
            ],
        ]),
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    formProps={{ inlineEdited: useInlineEditing }}
                    formComponent={DynamicTeaserForm}
                    generateEmbedBlock={generateLayoutBlock}
                    type={TYPE}
                />
            ),
            options,
        ),
        renderElement: renderElement(
            editor,
            [
                [NODE_TYPE, DynamicTeaserNode],
                [ELEMENT_TYPES.DYNAMIC_TEASER_TITLE, DynamicTeaserTitleElement],
            ],
            options,
        ),
        insertDynamicTeaser: (at) => {
            if (useInlineEditing) {
                generateLayoutBlock(
                    editor,
                    { title: makeState(), image: null, metadataIds: [] },
                    {
                        at,
                        replace: false,
                    },
                );
            } else {
                editor.showEmbedModal(PLUGIN_NAMES.DYNAMIC_TEASER, undefined, at);
            }
        },
    });
};

export const withDynamicTeaser = (editor, options) =>
    internalWithDynamicTeaser(editor, {
        ...options,
        nodeType: NODE_TYPE,
    });

export default withDynamicTeaser;

import React from 'react';

import getIsUrl from '../utils/validators/customUrl';
import TextInputField, { TextInputFieldProps } from './TextInput';

type Props = Omit<TextInputFieldProps, 'validate'> & {
    allowInsecure?: boolean;
    allowMailto?: boolean;
    validate?: Validator[];
};

const UrlField = (props: Props) => {
    const { allowInsecure, allowMailto, validate, ...rest } = props;

    const validation: Validator[] = [getIsUrl({ allowInsecure, allowMailto })];

    if (validate) {
        validation.push(...validate);
    }

    return <TextInputField type="url" placeholder="https://" validate={validation} {...rest} />;
};

export default UrlField;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

import { Editor, Element, ReactEditor, Node } from '@@editor/helpers';
import { ElementAttributes, EmbedElement } from '@@editor/helpers/Element';
import { getInputPlaceholderStyle } from '@@editor/plugins/serializable/styles';

type Props = {
    attributes: ElementAttributes;
    children: React.ReactNode;
    editor: Editor;
    element: EmbedElement;
};

type StyledInfoboxContentProps = {
    $templateElement: boolean;
    $placeholderText: string;
};

export const StyledInfoboxContent = styled('div')<StyledInfoboxContentProps>(
    ({ $templateElement, $placeholderText, theme }) => ({
        ...theme.typography.editorSecondaryMedium,
        ...($templateElement &&
            getInputPlaceholderStyle({ theme, placeholderText: $placeholderText })),
    }),
);

const InfoboxContentElement = (props: Props) => {
    const { children, editor, element, attributes } = props;
    const path = ReactEditor.findPath(editor, element);
    const parentNode = Node.parent(editor, path);

    const { t } = useTranslation();

    return (
        <StyledInfoboxContent
            {...attributes}
            $templateElement={
                Element.isTemplateElement(parentNode) &&
                Element.isEmptyInfoboxContentElement(element)
            }
            $placeholderText={t('editor.plugin.infobox.form.content')}
        >
            {children}
        </StyledInfoboxContent>
    );
};

export default InfoboxContentElement;

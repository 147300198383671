export const PERCENT_100 = 100;
export const DEFAULT_FOCUS_POINT = { x: 0.5, y: 0.5 };
export const DEFAULT_CROP_MARKS = { x: 0.0, y: 0.0, width: 1.0, height: 1.0 };

export const DEFAULT_IMAGE_SIZES = {
    loaderWidth: '100%',
    loaderHeight: '400px',
    maxWidth: '100%',
    maxHeight: '400px',
};

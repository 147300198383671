import React from 'react';

import { ELEMENT_TYPES, MIME_TYPES } from '@@editor/helpers/Element';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { parseInstagramEmbedCode } from '@@utils/socialMedia/instagram';
import instagramEmbedCode from '@@form/utils/validators/instagramEmbedCode';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import { InsertElementOptions } from '@@editor/helpers/Editor';

import { generateEmbedBlockData } from './utils';
import { createGenerateEmbedBlock } from './../utils';
import Post from './components/Post';
import EditorWithEmbedModal from './../components/EditorWithEmbedModal';
import EmbedWrapper from './../components/EmbedWrapper';

const TYPE = PLUGIN_NAMES.INSTAGRAM;
const NODE_TYPE = ELEMENT_TYPES.INSTAGRAM;

const mimetypeConfigs = {
    [MIME_TYPES.INSTAGRAM]: {
        Component: Post,
    },
};

const extendedStyles = {
    [`&[data-type="${TYPE}"] .instagram-media`]: {
        margin: '0 auto !important',
    },
};

const InstagramNode = (props) => (
    <EmbedWrapper
        {...props}
        type={TYPE}
        mimetypeConfigs={mimetypeConfigs}
        extendedStyles={extendedStyles}
        toolbarConfig={{
            infos: {
                iconName: PLUGIN_ICON_NAMES[TYPE],
                title: 'Instagram',
            },
            actions: ['edit', 'delete'],
        }}
    />
);

const generateEmbedBlock = createGenerateEmbedBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
    generateEmbedBlockData,
    parseEmbedCode: parseInstagramEmbedCode,
});

const validate = [instagramEmbedCode];

const internalWithInstagram = (editor, options) => {
    const { insertData } = editor;

    return Object.assign(editor, {
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    validate={validate}
                    generateEmbedBlock={generateEmbedBlock}
                    type={TYPE}
                    formProps={{
                        withIncludeCaption: true,
                    }}
                />
            ),
            options,
        ),
        renderElement: renderElement(editor, [[NODE_TYPE, InstagramNode]], options),
        insertData: (data: DataTransfer, options: InsertElementOptions) => {
            const text = data.getData('text/plain');

            if (text && !instagramEmbedCode(text)) {
                generateEmbedBlock(editor, text);
            } else {
                insertData(data, options);
            }
        },
    });
};

export const withInstagram = (editor, options) =>
    internalWithInstagram(editor, {
        ...options,
        nodeType: NODE_TYPE,
    });

export default withInstagram;

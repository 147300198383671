export const getDomNodesFromString = <T extends keyof HTMLElementTagNameMap>(
    string: string,
    tagName: T,
): HTMLCollectionOf<HTMLElementTagNameMap[T]> => {
    const wrapper = document.createElement('div');

    wrapper.innerHTML = string;
    const elements = wrapper.getElementsByTagName<T>(tagName);

    return elements;
};

export const getSrcFromIframeString = (string: string) => {
    const iframes = getDomNodesFromString<'iframe'>(string, 'iframe');
    const iframe = iframes[0];
    const iframeSrc = iframe?.getAttribute('src');

    return iframeSrc;
};

export const isNode = (object?: any): object is Node =>
    object != null && typeof object.nodeName === 'string';

export const isElementNode = (object?: any): object is Element =>
    object != null && object.nodeType === Node.ELEMENT_NODE && typeof object.tagName === 'string';

export const isHtmlElement = (object?: any): object is HTMLElement =>
    object != null &&
    object.nodeType === Node.ELEMENT_NODE &&
    typeof object.dataset === 'object' &&
    typeof object.style === 'object';

export const getPreviousElementSibling = (element: HTMLElement, selector?: string) => {
    let sibling = element.previousElementSibling;

    while (sibling) {
        if (!selector || sibling.matches(selector)) {
            return sibling as HTMLElement;
        }

        sibling = sibling.previousElementSibling;
    }

    return null;
};

export const getNextElementSibling = (element: HTMLElement, selector?: string) => {
    let sibling = element.nextElementSibling;

    while (sibling) {
        if (!selector || sibling.matches(selector)) {
            return sibling as HTMLElement;
        }

        sibling = sibling.nextElementSibling;
    }

    return null;
};

export const getFocusableDomNodes = (
    parentNode,
    { excludeNegativeTabIndex = false, inputFieldsOnly = false } = {},
) => {
    const sharedFieldSelectors = ['input', 'select', 'textarea', '[contenteditable="true"]'];

    let selector = (
        inputFieldsOnly
            ? [...sharedFieldSelectors, '[tabindex]:not(button):not([href])']
            : [...sharedFieldSelectors, '[tabindex]', 'button', '[href]']
    ).join(',');

    if (excludeNegativeTabIndex) {
        selector += ':not([tabindex="-1"])';
    }

    return Array.from(parentNode.querySelectorAll(selector)).filter((focusableDomNode: any) => {
        const computedStyle = window.getComputedStyle(focusableDomNode);

        // TODO: Check also for disabled state
        return computedStyle.display !== 'none' && computedStyle.visibility !== 'hidden';
    });
};

const getFocusableDomNodeByIndex = (...args) =>
    // @ts-ignore
    getFocusableDomNodes(...args).slice(args[2], args[2] + 1)[0];

export const focusInput = (domNode, index = 0) => {
    if (domNode) {
        let focusableDomNode;

        focusableDomNode = getFocusableDomNodeByIndex(
            domNode,
            {
                excludeNegativeTabIndex: true,
                inputFieldsOnly: true,
            },
            index,
        );

        if (!focusableDomNode) {
            return;
        }

        const isRadioInput = focusableDomNode.type === 'radio';
        const isContentEditable = focusableDomNode.getAttribute('contenteditable') === 'true';

        if (isRadioInput) {
            const checkedInputRadio = domNode.querySelector('input:checked');

            if (checkedInputRadio && checkedInputRadio.name === focusableDomNode.name) {
                focusableDomNode = checkedInputRadio;
            }
        }

        // Unfortunately slate.js cannot handle focus(), so we use click() which works beatifully
        if (isContentEditable && focusableDomNode.click) {
            focusableDomNode.click();
        } else if (focusableDomNode.select) {
            focusableDomNode.select();
        } else if (focusableDomNode.focus) {
            focusableDomNode.focus();
        }
    }
};

import { UnityState } from '@@editor/typings/UnityElements';
import { Element } from '@@editor/helpers';

import { SCHEMA } from './constants';
import UnitySerializer from './UnitySerializer/UnitySerializer';

const serializeState = (state: Element[], options?): UnityState => {
    const unitySerializer = new UnitySerializer(options);

    return {
        [SCHEMA.UNITY]: unitySerializer.serialize(state),
    };
};

export default serializeState;

import React from 'react';

import { ELEMENT_TYPES } from '@@editor/helpers/Element';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import { Element } from '@@editor/helpers';

import { createGenerateLayoutBlock } from '../utils';
import EditorWithEmbedModal from './../../embed/components/EditorWithEmbedModal';
import EmbedWrapper from './../../embed/components/EmbedWrapper';
import Quote from './Quote';
import QuoteForm from './QuoteForm';

const TYPE = PLUGIN_NAMES.QUOTE;
const NODE_TYPE = ELEMENT_TYPES.QUOTE;

const QuoteNode = (props) => (
    <EmbedWrapper
        {...props}
        type={TYPE}
        component={Quote}
        placeholderText={props.editor.t('editor.plugin.quote.placeholderText')}
        placeholderIconName={PLUGIN_ICON_NAMES[TYPE]}
        toolbarConfig={{
            infos: {
                iconName: PLUGIN_ICON_NAMES[TYPE],
                title: 'Quote',
            },
            actions: ['edit', 'delete'],
        }}
    />
);

const generateLayoutBlock = createGenerateLayoutBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
});

const internalWithQuote = (editor, options) => {
    const { isVoid, useInlineEditing } = editor;

    return Object.assign(editor, {
        // TODO: this is hardcoded for now, as the quote is the only layout plugin with
        // CRUD capability right now.
        isVoid: (element: Element) => {
            if (useInlineEditing && Element.isQuoteRelatedElement(element)) {
                return false;
            }

            return element.type === NODE_TYPE ? true : isVoid(element);
        },
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    formComponent={QuoteForm}
                    generateEmbedBlock={generateLayoutBlock}
                    type={TYPE}
                />
            ),
            options,
        ),
        renderElement: renderElement(editor, [[NODE_TYPE, QuoteNode]], options),
    });
};

export const withQuote = (editor, options) =>
    internalWithQuote(editor, {
        ...options,
        nodeType: NODE_TYPE,
    });

export default withQuote;

import React from 'react';

import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { Editor } from '@@editor/helpers';
import { EmbeddedPollElement, MIME_TYPES } from '@@editor/helpers/Element';
import useEmbeddedComponentAsNode from '@@editor/plugins/serializable/embeddedComponent/useEmbeddedComponentAsNode';
import Poll from '@@editor/plugins/serializable/poll/components/Poll';

import { TYPE } from '../constants';
import EmbedWrapper from '../../components/EmbedWrapper';

type Props = {
    editor: Editor;
    element: EmbeddedPollElement;
};

const Component = (props) => {
    const elementAsNode = useEmbeddedComponentAsNode(props.element.data);

    return elementAsNode ? <Poll {...props} element={elementAsNode} /> : null;
};

const mimetypeConfigs = {
    [MIME_TYPES.EMBEDDED_POLL]: {
        Component,
    },
};

const EmbeddedPollNode = (props: Props) => {
    const { editor, element } = props;

    return (
        <EmbedWrapper
            {...props}
            type={TYPE}
            mimetypeConfigs={mimetypeConfigs}
            toolbarConfig={{
                infos: {
                    iconName: PLUGIN_ICON_NAMES[TYPE],
                    title: props.editor.t('editor.plugin.poll.frame.title'),
                },
                actions: [
                    {
                        type: 'edit',
                        iconName: 'pen-regular',
                        onClick: (e) => {
                            e.preventDefault();

                            requestAnimationFrame(() =>
                                editor.showEmbedModal(PLUGIN_NAMES.EMBEDDED_COMPONENT, element),
                            );
                        },
                    },
                    'delete',
                ],
            }}
        />
    );
};

export default EmbeddedPollNode;

import { useState, useEffect, useRef } from 'react';

type Props = {
    onLoad?: (...args) => void;
    onError?: (...args) => void;
};

export type UseLoaderHandlers = {
    onLoad: (...args) => void;
    onError: (...args) => void;
};

type UseLoaderReturn = {
    isLoading: boolean;
} & UseLoaderHandlers;

export const useLoader = ({ onLoad, onError }: Props = {}): UseLoaderReturn => {
    const [isLoading, setIsLoading] = useState(true);
    const isUnmounted = useRef(true);

    useEffect(() => {
        isUnmounted.current = false;

        return () => {
            isUnmounted.current = true;
        };
    }, []);

    const handleOnLoad = (...args) => {
        if (!isUnmounted.current) {
            setIsLoading(false);
        }

        if (onLoad) {
            onLoad(...args);
        }
    };

    const handleOnError = (...args) => {
        if (!isUnmounted.current) {
            setIsLoading(false);
        }

        if (onError) {
            onError(...args);
        }
    };

    return { isLoading, onLoad: handleOnLoad, onError: handleOnError };
};

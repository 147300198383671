/* eslint-disable max-lines */
import { cloneDeep, get, isEmpty } from 'lodash';
import { Ref } from 'react';
// This is the only place in this stack where we use `Element` directly! Use the helpers
// in all other places.
// eslint-disable-next-line no-restricted-imports
import { BaseElement as SlateBaseElement, Element as SlateElement, Node, BaseText } from 'slate';

import IMAGE_MIME_TYPES, { ImageMimeType } from '@@constants/ImageMimeTypes';
import { type FocusPoint, type CropMarks, type Image } from '@@api/utils/schemas/schemas';
import { Comment } from '@@api/services/content/schemas/comment';

export const GENERAL_EMBED_TYPE = 'embed';
export const LAYOUT_TYPE = 'layout';

export const EMBED_TYPES = {
    EMBEDDED_CONTENT: 'embed.embeddedcontent',
    EMBEDDED_IFRAME: 'embed.iframe',
    EMBEDDED_INFOBOX: 'embed.infobox',
    EMBEDDED_POLL: 'embed.poll',
    EMBEDDED_SNIPPET: 'embed.snippet',
    FACEBOOK: 'embed.facebook',
    GOOGLE_MAPS: 'embed.googlemaps',
    IMAGE: 'embed.image',
    EMBED_CAPTION: 'embed.caption',
    EMBED_CREDIT: 'embed.credit',
    INSTAGRAM: 'embed.instagram',
    FRONTEND_COMPONENT: 'embed.frontendComponent',
    OPTA: 'embed.opta',
    TIKTOK: 'embed.tiktok',
    SLIDESHOW: 'embed.slideshow',
    CS_SLIDESHOW: 'slideshow',
    TWITTER: 'embed.twitter',
    VIDEOCMS: 'embed.videocms',
    VIDEOCMS_LIVESTREAM: 'embed.videocmsLivestream',
    YOUTUBE: 'embed.youtube',
    ZATTOO: 'embed.zattoo',
} as const;

export type EmbedType = ValueOf<typeof EMBED_TYPES>;

export const BLOCK_TYPES = {
    PARAGRAPH: 'paragraph',
    CROSSHEAD: 'crosshead',
    TITLE_HEADER: 'title-header',
    TITLE: 'title',
    LEAD: 'lead',
    FOOTER: 'footer',
    LIST_ITEM: 'list-item',
    ORDERED_LIST: 'ordered-list',
    UNORDERED_LIST: 'unordered-list',
    POLL: 'poll',
    POLL_QUESTION: 'poll-question',
    POLL_ANSWER: 'poll-answer',
    POLLITEM: 'poll-item',
    SNIPPET: 'snippet',
    QUOTE: 'layout.quote',
    QUOTE_TEXT: 'layout.quote-text',
    QUOTE_CAPTION: 'layout.quote-caption',
    SUMMARY_LIST: 'summary-list',
    SUMMARY_LIST_SUMMARY: 'summary-list.summary',
    SEPARATOR: 'layout.separator',
    INFOBOX: 'layout.infobox',
    INFOBOX_TITLE: 'layout.infobox-title',
    INFOBOX_CONTENT: 'layout.infobox-content',
    INTERVIEW_SEGMENT: 'layout.interview-segment',
    INTERVIEW_SEGMENT_QUESTION: 'layout.interview-segment-question',
    INTERVIEW_SEGMENT_ANSWER: 'layout.interview-segment-answer',
    DYNAMIC_TEASER: 'layout.dynamic-teaser',
    DYNAMIC_TEASER_TITLE: 'layout.dynamic-teaser-title',
    ...EMBED_TYPES,
} as const;

export const TEXT_TYPES = {
    PARAGRAPH: 'paragraph',
    CROSSHEAD: 'crosshead',
    TITLE_HEADER: 'title-header',
    TITLE: 'title',
    LEAD: 'lead',
    FOOTER: 'footer',
    ORDERED_LIST: 'ordered-list',
    UNORDERED_LIST: 'unordered-list',
} as const;

export const INLINE_TYPES = {
    INTERNAL_LINK: 'internalLink',
    EXTERNAL_LINK: 'link',
} as const;

export const ELEMENT_TYPES = {
    TEXT: 'text',
    TEXTITEM: 'text-item',
    ...BLOCK_TYPES,
    ...INLINE_TYPES,
} as const;

export const ANY_LINK = [ELEMENT_TYPES.INTERNAL_LINK, ELEMENT_TYPES.EXTERNAL_LINK];

export type ElementType = ValueOf<typeof ELEMENT_TYPES>;

const FACEBOOK_MIME_TYPES = {
    FACEBOOK_POST: 'x-facebook/post',
    FACEBOOK_COMMENT: 'x-facebook/comment',
    FACEBOOK_PAGE: 'x-facebook/page',
    FACEBOOK_VIDEO: 'x-facebook/video',
} as const;

export type FacebookMimeType = ValueOf<typeof FACEBOOK_MIME_TYPES>;

export const MIME_TYPES = {
    EMBEDDED_CONTENT: 'x-iframe/*',
    EMBEDDED_IFRAME: 'x-unity/iframe',
    EMBEDDED_INFOBOX: 'x-unity/infobox',
    EMBEDDED_POLL: 'x-unity/poll',
    EMBEDDED_SNIPPET: 'x-unity/snippet',
    GOOGLE_MAPS: 'x-google/maps',
    INSTAGRAM: 'x-instagram/post',
    FRONTEND_COMPONENT: 'x-unity/frontend-component',
    SLIDESHOW: 'x-unity/slideshow',
    TWITTER: 'x-twitter/tweet',
    TIKTOK: 'x-tiktok/post',
    OPTA: 'x-unity/opta',
    VIDEOCMS: 'x-videocms/video',
    VIDEOCMS_LIVESTREAM: 'x-videocms/livestream',
    YOUTUBE: 'x-youtube/video',
    ZATTOO: 'x-zattoo/video',
    ...FACEBOOK_MIME_TYPES,
    ...IMAGE_MIME_TYPES,
} as const;

export type MimeType = ValueOf<typeof MIME_TYPES>;

export type CustomText = BaseText & {
    bold?: boolean;
    italic?: boolean;
    underlined?: boolean;
    superscript?: boolean;
    subscript?: boolean;
    commentId?: Comment['id'];
    added?: boolean;
    removed?: boolean;
};

export type CrossheadStyle =
    | '_crosshead_default'
    | '_crosshead_subsection'
    | '_crosshead_listicle'
    | '_crosshead_listicle_unordered';

type BaseElement = SlateBaseElement & {
    type: string;
    id?: Id;
};

type BaseData = {
    displayError?: boolean;
    templateElement?: boolean;
    pollItemId?: string;
};

export type ParagraphElement = BaseElement & {
    type: typeof ELEMENT_TYPES.PARAGRAPH;
    data?: BaseData;
    children: (CustomText | LinkElement)[];
};

export type CrossheadElement = BaseElement & {
    type: typeof ELEMENT_TYPES.CROSSHEAD;
    data: BaseData & {
        style: { crossheadType?: CrossheadStyle } | null;
    };
    children: CustomText[];
};

export type TitleHeaderElement = BaseElement & {
    type: typeof ELEMENT_TYPES.TITLE_HEADER;
    data?: BaseData;
    children: CustomText[];
};

export type TitleElement = BaseElement & {
    type: typeof ELEMENT_TYPES.TITLE;
    data?: BaseData;
    children: CustomText[];
};

export type LeadElement = BaseElement & {
    type: typeof ELEMENT_TYPES.LEAD;
    data?: BaseData;
    children: CustomText[];
};

export type FooterElement = BaseElement & {
    type: typeof ELEMENT_TYPES.FOOTER;
    data?: BaseData;
    children: (CustomText | LinkElement)[];
};

export type ListItemElement = BaseElement & {
    type: typeof ELEMENT_TYPES.LIST_ITEM;
    data?: BaseData;
    children: (CustomText | LinkElement)[];
};
export type OrderedListElement = BaseElement & {
    type: typeof ELEMENT_TYPES.ORDERED_LIST;
    data?: BaseData;
    children: ListItemElement[];
};
export type UnorderedListElement = BaseElement & {
    type: typeof ELEMENT_TYPES.UNORDERED_LIST;
    data?: BaseData;
    children: ListItemElement[];
};

export type ListElement = OrderedListElement | UnorderedListElement;

export type InternalLinkElement = BaseElement & {
    type: typeof ELEMENT_TYPES.INTERNAL_LINK;
    data: BaseData & {
        metadataId: number;
    };
    children: CustomText[];
};

export type ExternalLinkElement = BaseElement & {
    type: typeof ELEMENT_TYPES.EXTERNAL_LINK;
    data: BaseData & {
        href: string;
    };
    children: CustomText[];
};

export type LinkElement = InternalLinkElement | ExternalLinkElement;

export type TextElement =
    | ParagraphElement
    | CrossheadElement
    | TitleHeaderElement
    | TitleElement
    | LeadElement
    | FooterElement
    | ListElement
    | ListItemElement
    | LinkElement;

export type ElementAttributes = {
    'data-slate-node'?: 'element';
    'data-slate-void'?: boolean;
    ref?: Ref<any>;
};

export type EmbedData = {
    src?: URL | string;
    originalSrc?: string;
    iframe?: string;
    captionTitle?: string;
    caption?: Element[];
    credit?: string;
    naturalWidth?: number;
    naturalHeight?: number;
    width?: number;
    height?: number;
    focusPoint?: FocusPoint;
    cropMarks?: CropMarks;
    url?: string;
    elvisId?: string | null;
    name?: string;
    previewImage?: Image;
    fallBackHeight?: string;
    id?: string;
};

export type EmbedBaseData = BaseData & {
    align?: string;
    mimetype?: MimeType;
    embed?: EmbedData;
    contentAttributes?: EmbedData;
    fit?: string;
    isLoading?: boolean;
    payload?: any;
    options?: {
        showText?: boolean | string | null;
        allowFullScreen?: boolean | string | null;
        smallHeader?: boolean;
        showFacepile?: boolean;
        dataset?: DOMStringMap[];
        nodeType?: string;
        withCaption?: boolean;
        color?: string;
        lang?: string;
        // required only for embeddedcontent
        height?: {
            mobile?: number;
            desktop?: number;
        };
    };
    originalSrc?: string;
    src?: URL | string;
};

export type QuestionAnswers = EmbedBaseData & {
    question: ParagraphElement[];
    answers: ParagraphElement[][];
};

export type PollElement = BaseElement & {
    type: typeof ELEMENT_TYPES.POLL;
    data: QuestionAnswers;
    children: CustomText[];
};

export type PollQuestionElement = BaseElement & {
    type: typeof ELEMENT_TYPES.POLL_QUESTION;
    data: BaseData;
    children: CustomText[];
};

export type PollAnswerElement = BaseElement & {
    type: typeof ELEMENT_TYPES.POLL_ANSWER;
    data: BaseData;
    children: CustomText[];
};

type PollRelatedElement = PollElement | PollQuestionElement | PollAnswerElement;

export type QuoteElement = BaseElement & {
    type: typeof ELEMENT_TYPES.QUOTE;
    data: BaseData & {
        quote: Element[];
        caption: Element[];
    };
    children: (CustomText | QuoteTextElement | QuoteCaptionElement)[];
};

export type QuoteTextElement = BaseElement & {
    type: typeof ELEMENT_TYPES.QUOTE_TEXT;
    data: BaseData;
    children: CustomText[];
};

export type QuoteCaptionElement = BaseElement & {
    type: typeof ELEMENT_TYPES.QUOTE_CAPTION;
    data: BaseData;
    children: CustomText[];
};

export type SummaryListElement = BaseElement & {
    type: typeof ELEMENT_TYPES.SUMMARY_LIST;
    data: BaseData & {
        summary: UnorderedListElement[];
    };
    children: (CustomText | SummaryListSummaryElement)[];
};

export type SummaryListSummaryElement = BaseElement & {
    type: typeof ELEMENT_TYPES.SUMMARY_LIST_SUMMARY;
    data: BaseData;
    children: UnorderedListElement[];
};

export type SeparatorElement = BaseElement & {
    type: typeof ELEMENT_TYPES.SEPARATOR;
    data?: EmptyObject;
    children: CustomText[];
};

export type ContainerType = '_infobox_default' | '_infobox_important' | '_infobox_summary';

export type InfoboxElementData = EmbedBaseData & {
    title: Element[];
    content: Element[];
    style: { containerType: ContainerType };
    collapsed: boolean;
};

export type InfoboxElement = BaseElement & {
    type: typeof ELEMENT_TYPES.INFOBOX;
    data: InfoboxElementData;
    children: (CustomText | InfoboxTitleElement | InfoboxContentElement)[];
};

export type InfoboxTitleElement = BaseElement & {
    type: typeof ELEMENT_TYPES.INFOBOX_TITLE;
    data: Element[];
    children: CustomText[];
};
export type InfoboxContentElement = BaseElement & {
    type: typeof ELEMENT_TYPES.INFOBOX_CONTENT;
    data: Element[];
    children: (CustomText | ImageElement | TextElement | EmbedElement)[];
};

export type InterviewSegmentElement = BaseElement & {
    type: typeof ELEMENT_TYPES.INTERVIEW_SEGMENT;
    data: QuestionAnswers;
    children: (CustomText | InterviewSegmentQuestionElement | InterviewSegmentAnswerElement)[];
};

export type InterviewSegmentQuestionElement = BaseElement & {
    type: typeof ELEMENT_TYPES.INTERVIEW_SEGMENT_QUESTION;
    data: BaseData;
    children: CustomText[];
};

export type InterviewSegmentAnswerElement = BaseElement & {
    type: typeof ELEMENT_TYPES.INTERVIEW_SEGMENT_ANSWER;
    data: BaseData;
    children: CustomText[];
};

export type DynamicTeaserTitleElement = BaseElement & {
    type: typeof ELEMENT_TYPES.DYNAMIC_TEASER_TITLE;
    data: BaseData;
    children: CustomText[];
};
export type DynamicTeaserElement = BaseElement & {
    type: typeof ELEMENT_TYPES.DYNAMIC_TEASER;
    data: BaseData & {
        title: Element[];
        image?: Image;
        metadataIds: number[];
    };
    children: (CustomText | DynamicTeaserTitleElement)[];
};

export type LayoutElement =
    | QuoteElement
    | QuoteTextElement
    | QuoteCaptionElement
    | SeparatorElement
    | InfoboxElement
    | InfoboxTitleElement
    | InfoboxContentElement
    | InterviewSegmentElement
    | InterviewSegmentQuestionElement
    | InterviewSegmentAnswerElement
    | DynamicTeaserElement
    | DynamicTeaserTitleElement
    | SummaryListElement
    | SummaryListSummaryElement;

export type EmbeddedContentElement = BaseElement & {
    type: typeof ELEMENT_TYPES.EMBEDDED_CONTENT;
    data: EmbedBaseData & {
        mimetype: typeof MIME_TYPES.EMBEDDED_CONTENT;
    };
    children: (CustomText | EmbedCaptionElement | EmbedCreditElement)[];
};
export type EmbeddedIframeElement = BaseElement & {
    type: typeof ELEMENT_TYPES.EMBEDDED_IFRAME;
    data: EmbedBaseData & {
        mimetype: typeof MIME_TYPES.EMBEDDED_IFRAME;
    };
    children: CustomText[];
};

export type SnippetElementData = EmbedBaseData & {
    content: Element[];
};

export type SnippetElement = BaseElement & {
    type: typeof ELEMENT_TYPES.SNIPPET;
    data: SnippetElementData;
    children: CustomText[];
};

export type EmbeddedSnippetElement = BaseElement & {
    type: typeof ELEMENT_TYPES.EMBEDDED_SNIPPET;
    data: EmbedBaseData & {
        mimetype: typeof MIME_TYPES.EMBEDDED_SNIPPET;
    };
    children: CustomText[];
};

export type EmbeddedInfoboxElement = BaseElement & {
    type: typeof ELEMENT_TYPES.EMBEDDED_INFOBOX;
    data: InfoboxElementData & {
        mimetype: typeof MIME_TYPES.EMBEDDED_INFOBOX;
    };
    children: CustomText[];
};

export type EmbeddedPollElement = BaseElement & {
    type: typeof ELEMENT_TYPES.EMBEDDED_POLL;
    data: QuestionAnswers & {
        mimetype: typeof MIME_TYPES.EMBEDDED_POLL;
    };
    children: CustomText[];
};

export type FacebookElement = BaseElement & {
    type: typeof ELEMENT_TYPES.FACEBOOK;
    data: EmbedBaseData & {
        mimetype: FacebookMimeType;
    };
    children: CustomText[];
};

export type GoogleMapsElement = BaseElement & {
    type: typeof ELEMENT_TYPES.GOOGLE_MAPS;
    data: EmbedBaseData & {
        mimetype: typeof MIME_TYPES.GOOGLE_MAPS;
    };
    children: CustomText[];
};

export type ImageElement = BaseElement & {
    type: typeof ELEMENT_TYPES.IMAGE;
    data: EmbedBaseData & {
        mimetype: ImageMimeType;
    };
    coverIframes?: boolean;
    children: (CustomText | EmbedCaptionElement | EmbedCreditElement)[];
};

export type EmbedCaptionElement = BaseElement & {
    type: typeof ELEMENT_TYPES.EMBED_CAPTION;
    data: EmbedBaseData;
    coverIframes?: boolean;
    children: CustomText[];
};

export type EmbedCreditElement = BaseElement & {
    type: typeof ELEMENT_TYPES.EMBED_CREDIT;
    data: EmbedBaseData;
    coverIframes?: boolean;
    children: CustomText[];
};

export type InstagramElement = BaseElement & {
    type: typeof ELEMENT_TYPES.INSTAGRAM;

    data: EmbedBaseData & {
        mimetype: typeof MIME_TYPES.INSTAGRAM;
    };
    coverIframes?: boolean;
    children: CustomText[];
};

export type SlideshowElement = BaseElement & {
    type: typeof ELEMENT_TYPES.SLIDESHOW;
    data: EmbedBaseData & {
        mimetype: typeof MIME_TYPES.SLIDESHOW;
    };
    coverIframes?: boolean;
    children: CustomText[];
};

export type CSSlideshowElement = BaseElement & {
    type: typeof ELEMENT_TYPES.CS_SLIDESHOW;
    data: EmbedBaseData & {
        slides: ImageElement[];
    };
    coverIframes?: boolean;
    children: CustomText[];
};

export type TwitterElement = BaseElement & {
    type: typeof ELEMENT_TYPES.TWITTER;
    data: EmbedBaseData & {
        mimetype: typeof MIME_TYPES.TWITTER;
    };
    coverIframes?: boolean;
    children: CustomText[];
};

export type TiktokElement = BaseElement & {
    type: typeof ELEMENT_TYPES.TIKTOK;
    data: EmbedBaseData & {
        mimetype: typeof MIME_TYPES.TIKTOK;
    };
    coverIframes?: boolean;
    children: CustomText[];
};

export type OptaElement = BaseElement & {
    type: typeof ELEMENT_TYPES.OPTA;
    data: EmbedBaseData & {
        mimetype: typeof MIME_TYPES.OPTA;
    };
    coverIframes?: boolean;
    children: CustomText[];
};

export type FrontentComponentElement = BaseElement & {
    type: typeof ELEMENT_TYPES.FRONTEND_COMPONENT;
    data: EmbedBaseData & {
        mimetype: typeof MIME_TYPES.FRONTEND_COMPONENT;
        embed: EmbedData & {
            properties: Array<Record<string, string>>;
            label: string;
            alt: string;
        };
    };
    coverIframes?: boolean;
    children: CustomText[];
};

export type VideocmsElement = BaseElement & {
    type: typeof ELEMENT_TYPES.VIDEOCMS;
    data: EmbedBaseData & {
        mimetype: typeof MIME_TYPES.VIDEOCMS;
    };
    coverIframes?: boolean;
    children: CustomText[];
};

export type VideocmsLivestreamElement = BaseElement & {
    type: typeof ELEMENT_TYPES.VIDEOCMS_LIVESTREAM;
    data: EmbedBaseData & {
        mimetype: typeof MIME_TYPES.VIDEOCMS_LIVESTREAM;
    };
    coverIframes?: boolean;
    children: CustomText[];
};

export type YoutubeElement = BaseElement & {
    type: typeof ELEMENT_TYPES.YOUTUBE;
    data: EmbedBaseData & {
        mimetype: typeof MIME_TYPES.YOUTUBE;
    };
    coverIframes?: boolean;
    children: CustomText[];
};

export type ZattooElement = BaseElement & {
    type: typeof ELEMENT_TYPES.ZATTOO;
    data: EmbedBaseData & {
        mimetype: typeof MIME_TYPES.ZATTOO;
    };
    coverIframes?: boolean;
    children: CustomText[];
};

export type EmbedElement =
    | EmbeddedContentElement
    | EmbeddedIframeElement
    | EmbeddedInfoboxElement
    | EmbeddedPollElement
    | EmbeddedSnippetElement
    | FacebookElement
    | GoogleMapsElement
    | ImageElement
    | EmbedCaptionElement
    | EmbedCreditElement
    | InstagramElement
    | FrontentComponentElement
    | SlideshowElement
    | CSSlideshowElement
    | TwitterElement
    | TiktokElement
    | OptaElement
    | VideocmsElement
    | VideocmsLivestreamElement
    | YoutubeElement
    | ZattooElement;

export type Element =
    | TextElement
    | PollRelatedElement
    | LayoutElement
    | EmbedElement
    | SnippetElement;

type IsEmptyOptions = { isInlineEdited?: boolean };

interface IElement {
    create: <T>(element: T) => T;
    isLoading: (element: Element) => boolean;
    containsLoadingElement: (root: Node) => boolean;
    isBlockList: (value: any) => boolean;
    isBlockElement: (value: any) => boolean;
    isInlineElement: (value: any) => boolean;
    isEmbedElement: (value: any) => value is EmbedElement;
    isLayoutElement: (value: any) => value is LayoutElement;
    isLinkElement: (value: any) => value is LinkElement;
    isExternalLinkElement: (value: any) => value is ExternalLinkElement;
    isInternalLinkElement: (value: any) => value is InternalLinkElement;
    isOrderedListElement: (value: any) => value is OrderedListElement;
    isUnorderedListElement: (value: any) => value is UnorderedListElement;
    isListElement: (value: any) => boolean;
    isListItemElement: (value: any) => boolean;
    isCrossheadElement: (value: any) => value is CrossheadElement;
    isFooterElement: (value: any) => value is FooterElement;
    isParagraphElement: (value: any) => value is ParagraphElement;
    isTitleElement: (value: any) => value is TitleElement;
    isTitleHeaderElement: (value: any) => value is TitleHeaderElement;
    isLeadElement: (value: any) => value is LeadElement;
    isEmptyParagraphElement: (value: any) => boolean;
    isPollElement: (value: any) => value is PollElement;
    isPollRelatedElement: (
        value: any,
    ) => value is PollElement | PollQuestionElement | PollAnswerElement;
    isPollQuestionElement: (value: any) => value is PollQuestionElement;
    isPollAnswerElement: (value: any) => value is PollAnswerElement;
    isEmptyPollQuestionElement: (value: any) => boolean;
    isEmptyPollAnswerElement: (value: any) => boolean;
    isSnippetElement: (value: any) => value is SnippetElement;
    isInterviewSegmentElement: (value: any) => value is InterviewSegmentElement;
    isInterviewSegmentRelatedElement: (
        value: any,
    ) => value is
        | InterviewSegmentElement
        | InterviewSegmentQuestionElement
        | InterviewSegmentAnswerElement;
    isEmptyInterviewSegmentElement: (value: any, options?: IsEmptyOptions) => boolean;
    isInterviewSegmentQuestionElement: (value: any) => value is InterviewSegmentQuestionElement;
    isInterviewSegmentAnswerElement: (value: any) => value is InterviewSegmentAnswerElement;
    isEmptyInfoboxTitleElement: (value: any) => boolean;
    isEmptyInfoboxContentElement: (value: any) => boolean;
    isEmptyInterviewSegmentQuestionElement: (value: any) => boolean;
    isEmptyInterviewSegmentAnswerElement: (value: any) => boolean;
    isQuoteElement: (value: any) => value is QuoteElement;
    isQuoteTextElement: (value: any) => value is QuoteTextElement;
    isQuoteCaptionElement: (value: any) => value is QuoteCaptionElement;
    isQuoteRelatedElement: (
        value: any,
    ) => value is QuoteElement | QuoteTextElement | QuoteCaptionElement;
    isEmptyQuoteElement: (value: any, options?: IsEmptyOptions) => boolean;
    isEmptyQuoteTextElement: (value: any) => boolean;
    isEmptyQuoteCaptionElement: (value: any) => boolean;
    isTextElement: (value: any) => boolean;
    isEmptyTextElement: (value: any) => boolean;
    isImageElement: (value: any) => value is ImageElement;
    isImageRelatedElement: (
        value: any,
    ) => value is ImageElement | EmbedCaptionElement | EmbedCreditElement;
    isEmptyImageElement: (value: any, options?: IsEmptyOptions) => boolean;
    isDynamicTeaserElement: (value: any) => value is DynamicTeaserElement;
    isDynamicTeaserRelatedElement: (
        value: any,
    ) => value is DynamicTeaserElement | DynamicTeaserTitleElement;
    isEmptyDynamicTeaserElement: (value: any, options?: IsEmptyOptions) => boolean;
    isDynamicTeaserTitleElement: (value: any) => value is DynamicTeaserTitleElement;
    isEmptyDynamicTeaserTitleElement: (value: any) => boolean;
    isEmbedCaptionElement: (value: any) => value is EmbedCaptionElement;
    isEmbedCreditElement: (value: any) => value is EmbedCreditElement;
    isEmptyEmbedCaptionElement: (value: any) => boolean;
    isEmptyEmbedCreditElement: (value: any) => boolean;
    isInfoboxElement: (value: any) => value is InfoboxElement;
    isInfoboxTitleElement: (value: any) => value is InfoboxTitleElement;
    isInfoboxContentElement: (value: any) => value is InfoboxContentElement;
    isEmptyInfoboxElement: (value: any) => boolean;
    isEmbeddedContentElement: (value: any) => value is EmbeddedContentElement;
    isEmbeddedContentRelatedElement: (
        value: any,
    ) => value is EmbeddedContentElement | EmbedCaptionElement | EmbedCreditElement;
    isEmptyEmbeddedContentElement: (value: any, options?: IsEmptyOptions) => boolean;
    isSummaryListElement: (value: any) => value is SummaryListElement;
    isSummaryListSummaryElement: (value: any) => value is SummaryListSummaryElement;
    isSummaryListRelatedElement: (
        value: any,
    ) => value is SummaryListElement | SummaryListSummaryElement;
    isEmptySummaryListElement: (value: any) => boolean;
    isEmptySummaryListSummaryElement: (value: any) => boolean;
    isPluginWithCharacterCount: (element: Element) => boolean;
    isWithoutCharacterCount: (element: Element) => boolean;
    isEmptyElement: (value: any, options?: IsEmptyOptions) => boolean;
    isTemplateElement: (value: any) => boolean;
    isEmptyTemplateElement: (value: any, options?: IsEmptyOptions) => boolean;
}

export const Element: typeof SlateElement & IElement = {
    ...SlateElement,

    // Since slate is managing nodes internally by reference, we need to make sure to deep clone any node before
    // inserting it into the document tree. Otherwise nodes get mixed up.
    // If you insert for example a `DEFAULT_BLOCK` on the first line of the slate document and on the last,
    // they would get, internally, the same key, since it is the same object reference (`DEFAULT_BLOCK` constant,
    // located in the `@@editor/constants.js` file).
    // So always use this function before inserting an element! Example: `Editor.insertElement(editor, Element.create(...))`
    create: (element) => cloneDeep(element),

    isLoading: (element) => Boolean(get(element, ['data', 'isLoading'])),

    containsLoadingElement: (root) => {
        const elements = Node.elements(root);

        for (const [element] of elements) {
            if (Element.isLoading(element)) {
                return true;
            }
        }

        return false;
    },

    isBlockList: (value) => Element.isElementList(value) && Element.isBlockElement(value[0]),

    isBlockElement: (value) =>
        Boolean(
            value?.type &&
                Object.values(BLOCK_TYPES).some((blockType) => value.type.startsWith(blockType)),
        ),

    isInlineElement: (value) =>
        Object.values(INLINE_TYPES).some((inlineType) => inlineType === value?.type),

    isEmbedElement: (value): value is EmbedElement =>
        value?.type
            ? // Unfortunately we need an exception here for slideshow since backend did not talk
              // to frontend before their implementation, so it does not apply to our standards. So unity
              // slideshow have a type of `embed.slideshow` and contentstation slideshow have a type of `slideshow`
              value.type.startsWith(GENERAL_EMBED_TYPE) || value.type === ELEMENT_TYPES.CS_SLIDESHOW
            : false,

    isLayoutElement: (value): value is LayoutElement =>
        value?.type ? value.type.startsWith(LAYOUT_TYPE) : false,

    isLinkElement: (value): value is LinkElement =>
        value?.type === INLINE_TYPES.EXTERNAL_LINK || value?.type === INLINE_TYPES.INTERNAL_LINK,

    isExternalLinkElement: (value): value is ExternalLinkElement =>
        value?.type === INLINE_TYPES.EXTERNAL_LINK,

    isInternalLinkElement: (value): value is InternalLinkElement =>
        value?.type === INLINE_TYPES.INTERNAL_LINK,

    isOrderedListElement: (value): value is OrderedListElement =>
        value?.type === BLOCK_TYPES.ORDERED_LIST,

    isUnorderedListElement: (value): value is UnorderedListElement =>
        value?.type === BLOCK_TYPES.UNORDERED_LIST,

    isListElement: (value) =>
        Element.isOrderedListElement(value) || Element.isUnorderedListElement(value),

    isListItemElement: (value) => value?.type === BLOCK_TYPES.LIST_ITEM,

    isCrossheadElement: (value): value is CrossheadElement => value?.type === BLOCK_TYPES.CROSSHEAD,

    isFooterElement: (value): value is FooterElement => value?.type === BLOCK_TYPES.FOOTER,

    isParagraphElement: (value): value is ParagraphElement => value?.type === BLOCK_TYPES.PARAGRAPH,

    isTitleElement: (value): value is TitleElement => value?.type === BLOCK_TYPES.TITLE,
    isTitleHeaderElement: (value): value is TitleHeaderElement =>
        value?.type === BLOCK_TYPES.TITLE_HEADER,
    isLeadElement: (value): value is LeadElement => value?.type === BLOCK_TYPES.LEAD,

    isEmptyParagraphElement: (node) => Element.isParagraphElement(node) && Node.string(node) === '',

    isPollElement: (value): value is PollElement => value?.type === BLOCK_TYPES.POLL,

    isPollRelatedElement: (value): value is PollElement | PollQuestionElement | PollAnswerElement =>
        Element.isPollElement(value) ||
        Element.isPollQuestionElement(value) ||
        Element.isPollAnswerElement(value),

    isPollQuestionElement: (value): value is PollQuestionElement =>
        value?.type === BLOCK_TYPES.POLL_QUESTION,

    isPollAnswerElement: (value): value is PollAnswerElement =>
        value?.type === BLOCK_TYPES.POLL_ANSWER,

    isEmptyPollQuestionElement: (value) => {
        if (!Element.isPollQuestionElement(value)) {
            return false;
        }

        const string = Node.string(value);

        return isEmpty(string);
    },

    isEmptyPollAnswerElement: (value) => {
        if (!Element.isPollAnswerElement(value)) {
            return false;
        }

        const string = Node.string(value);

        return isEmpty(string);
    },

    isSnippetElement: (value): value is SnippetElement => value?.type === BLOCK_TYPES.SNIPPET,

    isInterviewSegmentElement: (value): value is InterviewSegmentElement =>
        value?.type === BLOCK_TYPES.INTERVIEW_SEGMENT,

    isInterviewSegmentRelatedElement: (
        value,
    ): value is
        | InterviewSegmentElement
        | InterviewSegmentQuestionElement
        | InterviewSegmentAnswerElement =>
        Element.isInterviewSegmentElement(value) ||
        Element.isInterviewSegmentQuestionElement(value) ||
        Element.isInterviewSegmentAnswerElement(value),

    isInterviewSegmentQuestionElement: (value): value is InterviewSegmentQuestionElement =>
        value?.type === BLOCK_TYPES.INTERVIEW_SEGMENT_QUESTION,

    isInterviewSegmentAnswerElement: (value): value is InterviewSegmentAnswerElement =>
        value?.type === BLOCK_TYPES.INTERVIEW_SEGMENT_ANSWER,

    isEmptyInterviewSegmentElement: (value, { isInlineEdited = false } = {}) => {
        if (!Element.isInterviewSegmentElement(value)) {
            return false;
        }

        if (isInlineEdited) {
            const question = value.children.filter(Element.isInterviewSegmentQuestionElement);
            const answers = value.children.filter(Element.isInterviewSegmentAnswerElement);

            const questionString = isEmpty(question) ? '' : Node.string({ children: question });
            const answersString = isEmpty(answers) ? '' : Node.string({ children: answers });

            return isEmpty(questionString) && isEmpty(answersString);
        }

        return Element.isEmptyParagraphElement(value.data.question[0]);
    },

    isEmptyInterviewSegmentQuestionElement: (value) => {
        if (!Element.isInterviewSegmentQuestionElement(value)) {
            return false;
        }

        const string = Node.string(value);

        return isEmpty(string);
    },

    isEmptyInfoboxTitleElement: (value) => {
        if (!Element.isInfoboxTitleElement(value)) {
            return false;
        }

        const string = Node.string(value);

        return isEmpty(string);
    },
    isEmptyInfoboxContentElement: (value) => {
        if (!Element.isInfoboxContentElement(value)) {
            return false;
        }

        const elements = Node.nodes(value);

        for (const nodeEntry of elements) {
            const [node] = nodeEntry;

            if (Element.isImageElement(node)) {
                return false;
            }
        }
        const string = Node.string(value);

        return isEmpty(string);
    },
    isEmptyInterviewSegmentAnswerElement: (value) => {
        if (!Element.isInterviewSegmentAnswerElement(value)) {
            return false;
        }

        const string = Node.string(value);

        return isEmpty(string);
    },

    isTextElement: (value) => Object.values(TEXT_TYPES).some((type) => type === value?.type),

    isEmptyTextElement: (value) => Element.isTextElement(value) && Node.string(value) === '',

    isImageElement: (value): value is ImageElement => value?.type === BLOCK_TYPES.IMAGE,
    isImageRelatedElement: (
        value,
    ): value is ImageElement | EmbedCaptionElement | EmbedCreditElement =>
        Element.isImageElement(value) ||
        Element.isEmbedCaptionElement(value) ||
        Element.isEmbedCreditElement(value),
    isEmptyImageElement: (value, { isInlineEdited = false } = {}) => {
        if (!Element.isImageElement(value)) {
            return false;
        }

        let caption = value.data.embed?.caption;
        let credit = value.data.embed?.credit;
        let creditString = credit;

        if (isInlineEdited) {
            caption = value.children.filter(Element.isEmbedCaptionElement);
            credit = value.children.filter(Element.isEmbedCreditElement);
            creditString = isEmpty(credit) ? '' : Node.string({ children: credit });
        }

        const captionString = isEmpty(caption) ? '' : Node.string({ children: caption });

        return isEmpty(value.data.src) && isEmpty(captionString) && isEmpty(creditString);
    },
    isEmbedCaptionElement: (value): value is EmbedCaptionElement =>
        value?.type === BLOCK_TYPES.EMBED_CAPTION,
    isEmbedCreditElement: (value): value is EmbedCreditElement =>
        value?.type === BLOCK_TYPES.EMBED_CREDIT,
    isEmptyEmbedCaptionElement: (value) => {
        if (!Element.isEmbedCaptionElement(value)) {
            return false;
        }

        const string = Node.string(value);

        return isEmpty(string);
    },
    isEmptyEmbedCreditElement: (value) => {
        if (!Element.isEmbedCreditElement(value)) {
            return false;
        }

        const string = Node.string(value);

        return isEmpty(string);
    },

    isDynamicTeaserElement: (value): value is DynamicTeaserElement =>
        value?.type === BLOCK_TYPES.DYNAMIC_TEASER,

    isDynamicTeaserRelatedElement: (
        value,
    ): value is DynamicTeaserElement | DynamicTeaserTitleElement =>
        Element.isDynamicTeaserElement(value) || Element.isDynamicTeaserTitleElement(value),

    isEmptyDynamicTeaserElement: (value, { isInlineEdited = false } = {}) => {
        if (!Element.isDynamicTeaserElement(value)) {
            return false;
        }

        let title = value.data.title;

        if (isInlineEdited) {
            title = value.children.filter(Element.isDynamicTeaserTitleElement);
        }

        const titleString = isEmpty(title) ? '' : Node.string({ children: title });

        return (
            isEmpty(value.data.metadataIds) &&
            isEmpty(value.data.image?.url) &&
            isEmpty(titleString)
        );
    },

    isDynamicTeaserTitleElement: (value): value is DynamicTeaserTitleElement =>
        value?.type === BLOCK_TYPES.DYNAMIC_TEASER_TITLE,

    isEmptyDynamicTeaserTitleElement: (value) => {
        if (!Element.isDynamicTeaserTitleElement(value)) {
            return false;
        }

        const string = Node.string(value);

        return isEmpty(string);
    },

    isQuoteElement: (value): value is QuoteElement => value?.type === BLOCK_TYPES.QUOTE,
    isQuoteTextElement: (value): value is QuoteTextElement =>
        value?.type === BLOCK_TYPES.QUOTE_TEXT,
    isQuoteCaptionElement: (value): value is QuoteCaptionElement =>
        value?.type === BLOCK_TYPES.QUOTE_CAPTION,
    isQuoteRelatedElement: (
        value,
    ): value is QuoteElement | QuoteTextElement | QuoteCaptionElement =>
        Element.isQuoteElement(value) ||
        Element.isQuoteTextElement(value) ||
        Element.isQuoteCaptionElement(value),
    isEmptyQuoteElement: (value, { isInlineEdited = false } = {}) => {
        if (!Element.isQuoteElement(value)) {
            return false;
        }

        if (isInlineEdited) {
            const text = value.children.filter(Element.isQuoteTextElement);
            const caption = value.children.filter(Element.isQuoteCaptionElement);

            const textString = isEmpty(text) ? '' : Node.string({ children: text });
            const captionString = isEmpty(text) ? '' : Node.string({ children: caption });

            return isEmpty(textString) && isEmpty(captionString);
        }

        return Element.isEmptyParagraphElement(value.data.quote[0]);
    },

    isEmptyQuoteTextElement: (value) => {
        if (!Element.isQuoteTextElement(value)) {
            return false;
        }

        const string = Node.string(value);

        return isEmpty(string);
    },

    isEmptyQuoteCaptionElement: (value) => {
        if (!Element.isQuoteCaptionElement(value)) {
            return false;
        }

        const string = Node.string(value);

        return isEmpty(string);
    },

    isInfoboxElement: (value): value is InfoboxElement => value?.type === BLOCK_TYPES.INFOBOX,
    isInfoboxTitleElement: (value): value is InfoboxTitleElement =>
        value?.type === BLOCK_TYPES.INFOBOX_TITLE,
    isInfoboxContentElement: (value): value is InfoboxContentElement =>
        value?.type === BLOCK_TYPES.INFOBOX_CONTENT,
    isEmptyInfoboxElement: (value) =>
        Element.isInfoboxElement(value) && Element.isEmptyInfoboxTitleElement(value.children[0]),

    isEmbeddedContentElement: (value): value is EmbeddedContentElement =>
        value?.type === BLOCK_TYPES.EMBEDDED_CONTENT,

    isEmbeddedContentRelatedElement: (
        value,
    ): value is EmbeddedContentElement | EmbedCaptionElement | EmbedCreditElement =>
        Element.isEmbeddedContentElement(value) ||
        Element.isEmbedCaptionElement(value) ||
        Element.isEmbedCreditElement(value),

    isEmptyEmbeddedContentElement: (value, { isInlineEdited = false } = {}) => {
        if (!Element.isEmbeddedContentElement(value)) {
            return false;
        }

        let caption = value.data.embed?.caption;
        let credit = value.data.embed?.credit;
        let creditString = credit;

        if (isInlineEdited) {
            caption = value.children.filter(Element.isEmbedCaptionElement);
            credit = value.children.filter(Element.isEmbedCreditElement);
            creditString = isEmpty(credit) ? '' : Node.string({ children: credit });
        }

        const captionString = isEmpty(caption) ? '' : Node.string({ children: caption });

        return isEmpty(value.data.src) && isEmpty(captionString) && isEmpty(creditString);
    },

    isSummaryListElement: (value): value is SummaryListElement =>
        value?.type === BLOCK_TYPES.SUMMARY_LIST,
    isSummaryListSummaryElement: (value): value is SummaryListSummaryElement =>
        value?.type === BLOCK_TYPES.SUMMARY_LIST_SUMMARY,
    isSummaryListRelatedElement: (value): value is SummaryListElement | SummaryListSummaryElement =>
        Element.isSummaryListElement(value) || Element.isSummaryListSummaryElement(value),

    isEmptySummaryListSummaryElement: (value) => {
        if (!Element.isSummaryListSummaryElement(value)) {
            return false;
        }

        const string = Node.string(value);

        return isEmpty(string);
    },
    isEmptySummaryListElement: (value) =>
        Element.isSummaryListElement(value) &&
        Element.isEmptySummaryListSummaryElement(value.children[0]),

    isPluginWithCharacterCount: (element) => {
        const elements: ElementType[] = [ELEMENT_TYPES.EMBEDDED_INFOBOX, ELEMENT_TYPES.SNIPPET];

        if (Element.isInterviewSegmentElement(element)) {
            // This is a poor check to find out if the element is a void node or not, in order to find
            // out if we are in inline editing mode or not. The proper way would be to use `Editor.isVoid` for this,
            // but since we have not way to access the editor object over here, we cannot do that. This must stay
            // an exception and should not be re-used anywhere else.
            return !Element.isElementList(element.children);
        }

        return elements.includes(element.type);
    },

    isWithoutCharacterCount: (element) => {
        const elements: ElementType[] = [
            ELEMENT_TYPES.IMAGE,
            ELEMENT_TYPES.QUOTE,
            ELEMENT_TYPES.POLL,
            ELEMENT_TYPES.EMBED_CAPTION,
            ELEMENT_TYPES.EMBED_CREDIT,
            ELEMENT_TYPES.EMBEDDED_CONTENT,
            ELEMENT_TYPES.DYNAMIC_TEASER,
            ELEMENT_TYPES.INFOBOX,
            ELEMENT_TYPES.SUMMARY_LIST,
        ];

        return elements.includes(element.type);
    },

    isEmptyElement: (value, options?) =>
        Element.isEmptyTextElement(value) ||
        Element.isEmptyImageElement(value, options) ||
        Element.isEmptyDynamicTeaserElement(value, options) ||
        Element.isEmptyInfoboxElement(value) ||
        Element.isEmptyQuoteElement(value, options) ||
        Element.isEmptyInterviewSegmentElement(value, options) ||
        Element.isEmptyEmbeddedContentElement(value, options) ||
        Element.isEmptySummaryListElement(value),

    isTemplateElement: (value) => Boolean(value?.data?.templateElement),

    isEmptyTemplateElement: (value, options?) =>
        Element.isTemplateElement(value) && Element.isEmptyElement(value, options),
};

import { withTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { PLUGIN_CONFIG_TEMPLATES } from '@@editor/constants';
import setupPlugins from '@@editor/plugins/setup';
import usePostSpellCheck from '@@form/hooks/usePostSpellCheck';

import RichTextEditorBase from './RichTextEditorBase';

const LeadEditor = ({ tenantIds, ...props }) => {
    const pluginConfig = [
        PLUGIN_CONFIG_TEMPLATES.autoReplaceText,
        PLUGIN_NAMES.PARAGRAPH,
        PLUGIN_NAMES.BOLD,
        PLUGIN_NAMES.ITALIC,
        PLUGIN_CONFIG_TEMPLATES.specialCharacters,
        PLUGIN_CONFIG_TEMPLATES.softHyphen,
    ];

    if (props.singleLine) {
        pluginConfig.push(PLUGIN_NAMES.SINGLE_LINE);
    }

    if (props.spellCheck) {
        pluginConfig.push(PLUGIN_NAMES.SPELL_CHECKER);
    }

    const spellCheck = usePostSpellCheck();

    const plugins = useMemo(
        () =>
            setupPlugins(pluginConfig, {
                optionsPerPlugin: {
                    [PLUGIN_NAMES.SPELL_CHECKER]: {
                        spellCheck,
                        tenantIds,
                    },
                },
            }),
        [],
    );

    const stickyToolbarButtons = [
        { name: PLUGIN_NAMES.BOLD },
        { name: PLUGIN_NAMES.ITALIC },
        PLUGIN_CONFIG_TEMPLATES.specialCharacters,
        PLUGIN_CONFIG_TEMPLATES.softHyphen,
    ];

    const conditionalProps = {};

    if (props.singleLine) {
        conditionalProps.minRows = 1;
    }

    return (
        <RichTextEditorBase
            {...conditionalProps}
            {...props}
            plugins={plugins}
            stickyToolbarButtons={stickyToolbarButtons}
            hideFloatingToolbar
        />
    );
};

LeadEditor.propTypes = {
    singleLine: PropTypes.bool,
    spellCheck: PropTypes.bool,
    tenantIds: PropTypes.arrayOf(PropTypes.number),
    t: PropTypes.func,
};

export default withTranslation()(LeadEditor);

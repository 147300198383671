import React from 'react';
import { styled, Stack } from '@mui/material';

import Icon from '@@components/Icon';
import Spacer from '@@components/Spacer';
import { PluginName } from '@@editor/typings/UnityPlugins';

const BadgeWrapper = styled(Stack)(({ theme }) => ({
    color: theme.palette.primary.dark,
}));

const BadgeTitle = styled('span')(({ theme }) => ({
    ...theme.typography.small,
    display: 'inline-block',
    writingMode: 'vertical-rl',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    transform: 'rotate(180deg)',
}));

const BadgeIcon = styled(Icon)({
    transform: 'rotate(-90deg)',
});

type Props = { iconName: string; title: string | PluginName };

const Badge = ({ iconName, title }: Props) => (
    <BadgeWrapper alignItems="center">
        <BadgeTitle>{title}</BadgeTitle>
        <Spacer xs v />
        <BadgeIcon name={iconName} />
    </BadgeWrapper>
);

export default Badge;

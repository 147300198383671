import { UseQueryOptions } from '@tanstack/react-query';

import { HTTP_STATUS_CODES } from '@@constants/http';

export const defaultQueryPlaceholderData = (PAGE_SIZE: number) =>
    ({
        headers: new Headers(),
        status: HTTP_STATUS_CODES.OK,
        body: {
            content: new Array(PAGE_SIZE).fill(null),
            totalElements: PAGE_SIZE,
            totalPages: 0,
            number: 0,
            first: true,
            last: false,
            size: PAGE_SIZE,
        },
    }) satisfies UseQueryOptions['placeholderData'];

export const defaultInfiniteQueryPlaceholderData = (PAGE_SIZE: number) =>
    ({
        pages: [defaultQueryPlaceholderData(PAGE_SIZE)],
        pageParams: [],
    }) satisfies UseQueryOptions['placeholderData'];

import { get } from 'lodash';

import { Node } from '@@editor/helpers';

import { CROSSHEAD_STYLES, CrossheadStyle } from './constants';

export const getCrossheadStyle = (node: Node): CrossheadStyle =>
    // @ts-ignore
    get(node, 'data.style.crossheadType', '_crosshead_default');

export const generateCrossheadData = (crossheadStyle: CrossheadStyle) => ({
    style: crossheadStyle === CROSSHEAD_STYLES.SECTION ? {} : { crossheadType: crossheadStyle },
});

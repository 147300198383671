import { DEFAULT_BLOCK } from '@@editor/constants';
import { Element } from '@@editor/helpers';
import { ListElement, ELEMENT_TYPES } from '@@editor/helpers/Element';
import { isUnityList, UnityText } from '@@editor/typings/UnityElements';

import { CURRENT_VERSION } from './../constants';
import textRules from './textRules';

// currently accepts one paragraph node per list item.
export const createListNode = <T extends ListElement>(
    type,
    listItems: UnityText[] = [],
    next = textRules.deserialize,
) =>
    Element.create<T>({
        ...DEFAULT_BLOCK,
        type,
        children: listItems.map(next).map((li) => ({
            ...li,
            type: ELEMENT_TYPES.LIST_ITEM,
        })),
    } as T);

export const filterList = (node: ListElement) => node.children.filter(Element.isListItemElement);

const listRules = {
    deserialize: (element, next?) => {
        if (!isUnityList(element)) {
            return;
        }

        return createListNode(element.type, element.items, next);
    },

    serialize: (node, next) => {
        if (!Element.isListElement(node)) {
            return;
        }

        const list = filterList(node);

        return {
            version: CURRENT_VERSION,
            type: node.type,
            items: list.map((listItem) =>
                next({
                    ...listItem,
                    type: ELEMENT_TYPES.PARAGRAPH,
                }),
            ),
        };
    },
};

export default listRules;

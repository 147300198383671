import { LANG_CODES } from '@@lib/i18n/constants';
import i18n from '@@lib/i18n/i18n';

export const PREVIEW_TEAM_IMAGE_SIZE = {
    WIDTH: 140,
    HEIGHT: 140,
};

export const LEAGUES_OR_TOURNAMENTS = [
    {
        locale: LANG_CODES.ENGLISH,
        i18nLabel: i18n.t('league.form.nameEnglish'),
    },
    {
        locale: LANG_CODES.GERMAN,
        i18nLabel: i18n.t('league.form.nameGerman'),
    },
    {
        locale: LANG_CODES.FRENCH,
        i18nLabel: i18n.t('league.form.nameFrench'),
    },
];

export const TEAM_PATH_PATTERN = '/teams/:teamType/*';

export const SOCCER_TEAMS = 'soccerTeams';
export const ICE_HOCKEY_TEAMS = 'iceHockeyTeams';
export const TENNIS_PLAYERS = 'tennisPlayers';
export const LEAGUES_AND_TOURNAMENTS = 'leaguesAndTournaments';

export type TeamType =
    | typeof SOCCER_TEAMS
    | typeof ICE_HOCKEY_TEAMS
    | typeof TENNIS_PLAYERS
    | typeof LEAGUES_AND_TOURNAMENTS;

/**
 * * This comment is for translation auto extract
 * t('team.header.select.soccerTeams')
 * t('team.header.select.iceHockeyTeams')
 * t('team.header.select.tennisPlayers')
 * t('team.header.select.leaguesAndTournaments')
 * t('team.route.iceHockeyTeams.create.label')
 * t('team.route.leaguesAndTournaments.create.label')
 * t('team.route.soccerTeams.create.label')
 * t('team.route.tennisPlayers.create.label')
 */
export const TEAM_TYPES = [
    {
        type: `team.header.select.${SOCCER_TEAMS}`,
        sportType: 'soccer',
        paramSort: 'teamName,asc',
        createLabel: `team.route.${SOCCER_TEAMS}.create.label`,
    },
    {
        type: `team.header.select.${ICE_HOCKEY_TEAMS}`,
        sportType: 'iceHockey',
        paramSort: 'teamName,asc',
        createLabel: `team.route.${ICE_HOCKEY_TEAMS}.create.label`,
    },
    {
        type: `team.header.select.${TENNIS_PLAYERS}`,
        sportType: 'tennis',
        paramSort: 'teamName,asc',
        createLabel: `team.route.${TENNIS_PLAYERS}.create.label`,
    },
    {
        type: `team.header.select.${LEAGUES_AND_TOURNAMENTS}`,
        sportType: 'soccer,iceHockey',
        paramSort: 'leagueNames.label,asc',
        createLabel: `team.route.${LEAGUES_AND_TOURNAMENTS}.create.label`,
    },
] as const;

export const TEASERS_KEY = 'teasers';
// TODO: rename to DEFAULT_TENANT_VARIANT_ID
export const DEFAULT_TENANT_ID = 0;
export const DEFAULT_TENANT_ID_LEGACY = '0';
export const FRONT_CATEGORY_NAME = 'Front';

export const MAX_NOTIFICATION_MESSAGE_CHARACTERS = 126;
export const SYNC = {
    STUDIO: 'studio',
    DESKNET: 'desknet',
};

type Resolve<T> = (value: T | PromiseLike<T>) => void;
type Reject = (reason?: any) => void;

class Deferred<T = undefined> {
    readonly promise: Promise<T>;
    resolve: Resolve<T> = () => {};
    reject: Reject = () => {};
    private internalState: 'pending' | 'fulfilled' | 'rejected' = 'pending';

    constructor() {
        this.promise = new Promise<T>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        })
            .then((value) => {
                this.internalState = 'fulfilled';

                return value;
            })
            .catch((reason) => {
                this.internalState = 'rejected';

                throw reason;
            });

        Object.seal(this);
    }

    // Make sure `state` can only be set from within this class
    get state() {
        return this.internalState;
    }
}

export default Deferred;

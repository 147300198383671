import { omit } from 'lodash';

import { GenerateEmbedBlockData } from '@@editor/typings/Embed';
import { MIME_TYPES } from '@@editor/helpers/Element';

export const generateEmbedBlockData: GenerateEmbedBlockData = ({ embed, src, originalSrc }) => ({
    mimetype: MIME_TYPES.EMBEDDED_CONTENT,
    // @ts-ignore
    src,
    originalSrc,
    embed: omit(embed, ['iframe', 'nodeKey']),
});

import { RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        index: true,
        lazy: async () => {
            const { UserListPage } = await import(
                /* webpackChunkName: "userListPage" */ '@@routes/users/UserListPage'
            );

            return { Component: UserListPage };
        },
    },
    {
        path: 'new',
        lazy: async () => {
            const { UserCreatePage } = await import(
                /* webpackChunkName: "userCreatePage" */ '@@routes/users/UserCreatePage'
            );

            return { Component: UserCreatePage };
        },
    },
    {
        path: ':id',
        lazy: async () => {
            const { UserDetailPage } = await import(
                /* webpackChunkName: "userDetailPage" */ '@@routes/users/UserDetailPage'
            );

            return { Component: UserDetailPage };
        },
    },
];

import { mapValues, pickBy, isEqual } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { convertToNumber } from '@@utils/idUtils';
import { resolveOptions } from '@@utils/object';
import usePrevious from '@@hooks/usePrevious';
import PublicationStatus from '@@constants/PublicationStatus';
import ContentStatus from '@@constants/ContentStatus';

import {
    SEARCH_NAMESPACES,
    SearchFields,
    UnitySearch,
    UseUnitySearchOptions,
    getSearchFieldsProps,
} from './types';
import useSearch, { UseSearchOptions } from './useSearch';

const getDefaultSearchFields = (props: getSearchFieldsProps): SearchFields => ({
    q: {
        value: props.urlParams.get('q') || undefined,
        debounceSearch: true,
        mapToUrl: true,
    },
    tenantIds: {
        value: props.values?.tenantIds || props.urlParams.getAll('tenantIds') || null,
        mapToUrl: true,
        // dont persist if prefilled value
        persist: !props.values?.tenantIds,
    },
    userIds: {
        value: props.urlParams.get('userIds') || null,
        mapToUrl: true,
    },
    contentType: {
        value: props.urlParams.get('contentType') || null,
        mapToUrl: true,
    },
    publicationStatus: {
        value: (props.urlParams.getAll('publicationStatus') as PublicationStatus[]) || null,
        mapToUrl: true,
    },
    contentStatus: {
        value: (props.urlParams.getAll('contentStatus') as ContentStatus[]) || null,
        mapToUrl: true,
    },
    leagueIds: {
        value: props.urlParams.getAll('leagueIds') || null,
        mapToUrl: true,
        persist: false,
    },
    categoryIds: {
        value: convertToNumber(props.urlParams.getAll('categoryIds')) || null,
        mapToUrl: true,
    },
    tagIds: {
        value: convertToNumber(props.urlParams.getAll('tagIds')) || null,
        mapToUrl: true,
    },
    type: {
        value: props.urlParams.get('type') || null,
        mapToUrl: true,
    },
});

const getFields = (props: getSearchFieldsProps): UseSearchOptions['fields'] => {
    const resolvedFields = props.getSearchFields
        ? props.getSearchFields(props)
        : getDefaultSearchFields(props);

    return mapValues(pickBy(props.fields), (value, key) => {
        if (typeof value === 'object') {
            return value;
        }

        return resolvedFields[key];
    }) as UseSearchOptions['fields'];
};

/*
useUnitySearch is an abstraction on top of useSearch.
It abstract things like design/layout of the filter components,
which filters to show or hide,
count the currently active and collapsed filters,
include basic pageNumbers prop
*/
const useUnitySearch = (options: UseUnitySearchOptions): UnitySearch => {
    const {
        focusOnMount = true,
        moreFilters = true,
        disableUrlSync,
        minWidth,
        leagueIdsSportType,
        fieldsProps,
        searchNamespace = SEARCH_NAMESPACES.DEFAULT,
    } = options;
    const [urlParams] = useSearchParams();
    const location = useLocation();
    const [pageNumbers, setPageNumbers] = React.useState([1]);

    const searchOptions = {
        disableUrlSync,
        fields: getFields,
        searchNamespace,
    };

    const resolvedOptions = resolveOptions<UseSearchOptions>(searchOptions, {
        ...options,
        urlParams,
    });

    const search = useSearch(resolvedOptions);
    const previousSearch = usePrevious(search);

    const calculatedPageNumbers =
        urlParams.get('page') && !disableUrlSync
            ? [Number(urlParams.get('page')) || 1]
            : pageNumbers;

    const setPage = useCallback((page) => setPageNumbers([Number(page) || 1]), [setPageNumbers]);

    useEffect(() => {
        if (!isEqual(previousSearch?.values, search.values)) {
            setPage(1);
        }
    }, [search.values, previousSearch?.values, setPage]);

    const getPageHref = (page) => {
        const params = new URLSearchParams(location.search);

        params.set('page', page.toString());

        return `?${params.toString()}`;
    };

    const handlePageChange = (e, page) => {
        setPage(page);
    };

    const conditionalProps = {};

    if (!disableUrlSync) {
        Object.assign(conditionalProps, { getPageHref });
    }

    return {
        ...search,
        pageNumbers: calculatedPageNumbers,
        ...conditionalProps,
        handlePageChange,
        unitySearchProps: {
            ...search,
            focusOnMount,
            moreFilters,
            minWidth,
            leagueIdsSportType,
            fieldsProps,
        },
    };
};

export default useUnitySearch;

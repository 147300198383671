import { Theme, createTheme, CSSObject } from '@mui/material';
export const READONLY_BADGE_ATTRIBUTE = 'data-readonly-badge';

export type BadgeProps = {
    placeholderText?: string;
    badgeText?: string;
    showBadge?: boolean;
    readOnly?: boolean;
    [READONLY_BADGE_ATTRIBUTE]?: string;
};

type InputPlaceholderStyleProps = {
    theme: Theme;
    placeholderText?: string;
};

export const getInputPlaceholderStyle = ({
    theme,
    placeholderText,
}: InputPlaceholderStyleProps): CSSObject => ({
    position: 'relative',

    '&::before': {
        position: 'absolute',
        content: `"${placeholderText || ''}"`,
        color: theme.palette.gold.dark,
    },

    '&:hover': {
        '&::before': {
            color: theme.palette.gold.contrastText,
        },
    },
});

type TextBoxStyleProps = {
    theme: Theme;
    placeholderText: string;
};

export const getTextBoxStyle = ({ theme, placeholderText }: TextBoxStyleProps) => ({
    '&::before': {
        content: `"${placeholderText}"`,
        color: theme.palette.gold.dark,
    },
    '&': {
        padding: theme.spacing(4, 0), // Raise importance to overwrite editor-wide padding styles
    },
    p: {
        margin: 0,
    },
});

type TextReadOnlyBadgeStyleProps = {
    theme: Theme;
    badgeText?: string;
    [READONLY_BADGE_ATTRIBUTE]?: string;
};

export const getTextReadOnlyBadgeStyle = (props: TextReadOnlyBadgeStyleProps) => ({
    '&::after': {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: props.theme.spacing(2),
        content: `'${props.badgeText || props[READONLY_BADGE_ATTRIBUTE]}'`,
        color: props.theme.palette.primary.dark,
        fontWeight: 'normal',
        lineHeight: '16px',
        fontStyle: 'italic',
        fontSize: '14px',
    },
});

type TextTemplateStyleProps = {
    theme: Theme;
    $readOnly: boolean | undefined;
};

const getTextTemplateStyle = ({ theme, $readOnly }: TextTemplateStyleProps) => ({
    backgroundColor: theme.palette.gold.light,
    color: theme.palette.gold.dark,
    pointerEvents: 'none',
    display: 'block',
    overflow: 'hidden',
    padding: theme.spacing(2),
    ...($readOnly && { paddingLeft: theme.spacing(3) }),
    width: '100%',
    borderRadius: theme.fixed.editor.elementWrapper.borderRadius,
});

export const getParagraphTemplateStyle = ({
    theme,
    $readOnly,
}: {
    theme: Theme;
    $readOnly?: boolean;
}) => ({
    '&::after': {
        ...getTextTemplateStyle({ theme, $readOnly }),
        ...theme.typography.editorPrimaryMedium,
        content: `'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis at libero non convallis. Sed tempor enim et efficitur egestas. Cras dapibus interdum felis a suscipit. Phasellus lobortis enim a tincidunt feugiat. Vivamus ut interdum neque, eu finibus quam.'`,
    },
});

export const getCrossheadTemplateStyle = ({
    theme,
    $readOnly,
    $isSubsection,
    $crossheadTemplateContent,
}: {
    theme: Theme;
    $isSubsection?: boolean;
    $crossheadTemplateContent: string;
    $readOnly?: boolean;
}) => ({
    '&::after': {
        ...getTextTemplateStyle({ theme, $readOnly }),
        ...theme.typography.editorSecondaryLarge,
        fontWeight: $isSubsection ? '600' : 'bold',
        content: `'${$crossheadTemplateContent}'`,
    },
});

export const getFooterTemplateStyle = ({
    theme,
    $footerTemplateContent,
    $readOnly,
}: {
    theme: Theme;
    $footerTemplateContent: string;
    $readOnly?: boolean;
}) => ({
    '&::after': {
        ...getTextTemplateStyle({ theme, $readOnly }),
        ...theme.typography.editorSubtitleXsmall,
        content: `'${$footerTemplateContent}'`,
    },
});

export const editorPlaceholderTheme = (theme: Theme) =>
    createTheme(theme, {
        fixed: {
            editor: {
                embed: {
                    backgroundColor: theme.palette.gold.light,
                },
            },
        },
    });

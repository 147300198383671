import { isEmpty } from 'lodash';

import { Element } from '@@editor/helpers';

import isState from './isState';

type Options = { isInlineEdited?: boolean };

// a state is empty, if it has no node or only one node which is an empty text.
const isEmptyState = (state?: Element[] | null, options?: Options): boolean => {
    if (!state || isEmpty(state) || isEmpty(state?.[0])) {
        return true;
    } else if (isState(state) && state.length === 1) {
        return Element.isEmptyParagraphElement(state[0]);
    } else if (isState(state) && state.length > 0) {
        return state.every(
            (element) =>
                Element.isTemplateElement(element) || Element.isEmptyElement(element, options),
        );
    }

    return false;
};

export default isEmptyState;

export const getElementPosition = (
    element,
    { relativeTo, relativeToOffsetParent = false } = {},
) => {
    const rect = element.getBoundingClientRect();
    const width = rect.width || element.offsetWidth;
    const height = rect.height || element.offsetHeight;

    // TODO: Find a solution to work with both scrolling and non-scrolling containers
    // let top = rect.top + window.scrollY;
    // let left = rect.left + window.scrollX;
    let top = rect.top;
    let left = rect.left;

    if (relativeToOffsetParent) {
        // eslint-disable-next-line no-param-reassign
        relativeTo = element.offsetParent;
    }

    if (relativeTo instanceof HTMLElement) {
        const relativeToRect = relativeTo.getBoundingClientRect();

        top += relativeTo.scrollTop - relativeToRect.top;
        left += relativeTo.scrollLeft - relativeToRect.left;
    }

    return { top, left, width, height };
};

import React from 'react';
import { FieldValues } from 'react-hook-form';

import BasicRichTextEditor from '@@form/components/RichTextEditor/BasicRichTextEditor';

import RichTextField, { RichTextFieldProps } from './RichTextField';

export const BasicRichTextEditorField = <TFieldValues extends FieldValues = FieldValues>(
    props: RichTextFieldProps<TFieldValues>,
) => <RichTextField {...props} component={BasicRichTextEditor} />;

export default BasicRichTextEditorField;

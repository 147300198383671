import { DialogProps } from '@mui/material';
import { ReactNode } from 'react';

export const ModalCloseEventSource = {
    CANCEL_BUTTON: 'cancelButton',
} as const;

export type ModalCloseEventSource = ValueOf<typeof ModalCloseEventSource>;

export type ModalProps = {
    children?: ReactNode;
    title?: string;
    isOpen?: boolean;
    className?: string;
    isLoading?: boolean;
    onClose: (closeEventSource?: ModalCloseEventSource) => void;
    onDelete?: VoidFunction;
    onConfirm?: VoidFunction;
    cancelButtonText?: string;
    confirmButtonText?: string;
    type?: 'info' | 'confirm' | 'delete';
    reverseButtonOrder?: boolean;
    renderTitle?: () => ReactNode;
} & Omit<DialogProps, 'open' | 'onClose'>;

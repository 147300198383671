import { RouteObject } from 'react-router-dom';
import React from 'react';

import { TenantsParamsProvider } from '@@containers/TenantSpecific/TenantsParamsContext';

export const routes: RouteObject[] = [
    {
        path: '',
        lazy: async () => {
            const { Layout } = await import(
                /* webpackChunkName: "placement" */ '@@routes/placement'
            );

            return {
                element: (
                    <TenantsParamsProvider>
                        <Layout />
                    </TenantsParamsProvider>
                ),
            };
        },
        children: [
            {
                path: ':tenantId/:categoryId/*',
                lazy: async () => {
                    const { CategoryModal } = await import(
                        /* webpackChunkName: "categoryModal" */ '@@routes/placement/components/CategoryModal/CategoryModal'
                    );

                    return { Component: CategoryModal };
                },
            },
        ],
    },
];

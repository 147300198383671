import React from 'react';
import { FieldValues } from 'react-hook-form';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormControl, FormHelperText, FormLabel, styled } from '@mui/material';

import ContentItemList, {
    Props as ContentItemListProps,
} from '@@containers/RelatedContent/ContentItemList';
import useFormField, { UseFormFieldProps } from '@@form/hooks/useFormField';
import { FormFieldError } from '@@form/hooks/useReactHookFormFieldError';

const Wrapper = styled('div')<{ $hasCaption?: boolean }>(({ $hasCaption, theme }) => ({
    position: 'relative',
    marginTop: $hasCaption ? theme.spacing(2) : undefined,
    marginLeft: `-${theme.spacing(4)}`,
    marginRight: `-${theme.spacing(4)}`,
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

type ContentItemListBaseProps = ContentItemListProps & {
    className?: string;
    error?: FormFieldError;
    label?: string;
    caption?: string;
    required?: boolean;
};

const ContentItemListBase = (props: ContentItemListBaseProps) => {
    const { className, error, label, required, ...otherProps } = props;

    const { t } = useTranslation();

    const errorMessage = error?.[0];
    const message = errorMessage?.[0] ?? '';
    const replacements = errorMessage?.[1] ?? {};

    const helperText = errorMessage && t(message, replacements);

    return (
        <FormControl error={Boolean(error)} required={required}>
            {label && <StyledFormLabel>{label}</StyledFormLabel>}
            <Wrapper className={className} $hasCaption={Boolean(otherProps.caption)}>
                <ContentItemList {...otherProps} />
            </Wrapper>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

type ContentItemListFieldProps<TFieldValues extends FieldValues = FieldValues> = Omit<
    ContentItemListBaseProps,
    'onChange' | 'value'
> &
    UseFormFieldProps<TFieldValues>;

const ContentItemListField = <TFieldValues extends FieldValues = FieldValues>(
    props: ContentItemListFieldProps<TFieldValues>,
) => {
    const {
        name,
        validate,
        novalidate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
        ...rest
    } = props;

    const formFieldProps = useFormField<TFieldValues>({
        name,
        novalidate,
        validate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
    });

    return <ContentItemListBase {...omit(formFieldProps, ['ref'])} {...rest} required={required} />;
};

export default ContentItemListField;

import { apiFetcher } from '@@api/fetcher';
import { defaultHeaders } from '@@api/headers';
import { RootState } from '@@scripts/store/store';

export const clientDefaults = (state: RootState) => ({
    baseHeaders: defaultHeaders(state),
    // Because serialization/deserialization is done with Zod transformations
    // inside the client validations, this MUST be set to true.
    // Otherwise the types will not match the data and the data will not be transformed
    validateResponse: true,
    api: apiFetcher(state),
});

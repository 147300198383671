import * as ReactIs from 'react-is';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useRef } from 'react';
import { Backdrop, alpha, styled } from '@mui/material';

import { focusInput } from '@@utils/DOM';
import Spacer from '@@components/Spacer';
import ButtonGroup from '@@form/components/ButtonGroup';
import useModal from '@@containers/Modal/useModal/useModal';
import { InternalFormProps } from '@@containers/ReactHookForm/types';
import useReactHookFormContext from '@@containers/ReactHookForm/useReactHookFormContext';
import Loader from '@@components/Loader';

import Buttons, { ButtonPreset } from './Buttons';
import { ButtonsProvider } from './ButtonsContext';

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    zIndex: theme.fixed.form.loadingOverlayZindex,
    backgroundColor: alpha(theme.palette.common.white, theme.opacityFactors.medium),
    position: 'absolute',
}));

const StyledForm = styled('form')(({ theme }) => ({
    width: '100%',
    [ButtonGroup]: {
        marginTop: theme.spacing(3),
    },
}));

const defaultProps = {
    autoFocus: true,
    focusIndex: 0,
    hasButtonGroup: true,
    hasCancelButton: true,
    onCancel: () => {},
    onDelete: () => {},
    onRestore: () => {},
};

const Form = (props: InternalFormProps) => {
    const { t } = useTranslation();
    const propsWithDefault = { ...defaultProps, ...props };
    const {
        cancelModal: customCancelModal,
        deleteModal: customDeleteModal,
        autoFocus,
        focusIndex,
        disableNoSpacersWarning,
        noSpacers,
        dirty,
        submitting,
        onCancel,
        onRestore,
        onDelete,
        hasActiveCancelButton,
        pristine,
        handleSubmit,
        hasButtonGroup,
        hasCloseButton,
        hasDeleteButton,
        hasCancelButton,
        hasRestoreButton,
        isEditMode,
        alwaysShowCancelButton,
        children,
        renderButtons,
        className,
        formName,
        isLoading,
        renderCancelButton,
        renderDeleteButton,
        renderRestoreButton,
        renderSubmitButton,
    } = propsWithDefault;

    const formContext = useReactHookFormContext();
    const domNode = useRef(null);

    const defaultDeleteModal = useModal({
        type: 'delete',
        title: t('form.modal.deleteTitle'),
        children: <p>{t('form.modal.deleteMessage')}</p>,
    });

    const defaultCancelModal = useModal({
        type: 'confirm',
        title: t('form.modal.cancelTitle'),
        children: <p>{t('form.modal.cancelMessage')}</p>,
    });

    const deleteModal = customDeleteModal || defaultDeleteModal;
    const cancelModal = customCancelModal || defaultCancelModal;

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            if (noSpacers && !disableNoSpacersWarning) {
                console.warn(
                    'Use the `noSpacers` option only if the whole form really does not need spacers. This should not \
                    be used for cases where the automatic spacers function just does not cover all edge cases. \
                    If so, try to improve the automatic spacer logic instead.',
                );
            }
        }

        if (autoFocus) {
            requestAnimationFrame(() => focusInput(domNode.current, focusIndex));
        }
    }, []);

    const handleClickOnResetButton = useCallback(() => {
        if (dirty || submitting) {
            return cancelModal.confirm().then(onCancel);
        }

        return onCancel();
    }, [dirty, submitting]);

    const handleClickOnCloseButton = () => {
        handleClickOnResetButton();
    };

    const handleClickOnRestoreButton = () => {
        if (dirty || submitting) {
            return cancelModal.confirm().then(onRestore);
        }

        return onRestore();
    };

    const handleClickOnDeleteButton = () => deleteModal.delete().then(onDelete);

    const handleClickOnSubmitButton = handleSubmit(props.onSubmit);

    const renderFields = (children) =>
        React.Children.map(children, (child, index) => {
            if (child) {
                if (ReactIs.isFragment(child)) {
                    return renderFields(child.props.children);
                } else if (child.props.hidden !== true && !noSpacers) {
                    return [child, <Spacer md v key={`form-spacer-${index}`} />];
                }

                return child;
            }

            return null;
        });

    const buttonsProps = { ...propsWithDefault, t };

    const renderContents = () => {
        if (typeof children === 'function') {
            return renderFields(
                children({
                    renderButtons: renderButtons
                        ? () => renderButtons(Buttons, buttonsProps, formContext)
                        : () => <ButtonPreset />,
                }),
            );
        }

        return (
            <React.Fragment>
                {renderFields(children)}
                {renderButtons ? (
                    renderButtons(Buttons, buttonsProps, formContext)
                ) : (
                    <ButtonPreset />
                )}
            </React.Fragment>
        );
    };

    return (
        // Context is needed here mainly because of renderButtons functionality
        // as it may have some side effects if the Buttons components initialized inside the Form component:
        // (events may not work correctly on the buttons, buttons are not reachable inside tests, worse performance)
        // so Form component and Buttons share props and methods via context
        <ButtonsProvider
            {...{
                handleClickOnSubmitButton,
                handleClickOnDeleteButton,
                handleClickOnRestoreButton,
                handleClickOnCloseButton,
                handleClickOnResetButton,
                pristine,
                submitting,
                dirty,
                isEditMode,
                hasActiveCancelButton,
                alwaysShowCancelButton,
                hasButtonGroup,
                hasDeleteButton,
                hasRestoreButton,
                hasCancelButton,
                hasCloseButton,
                renderCancelButton,
                renderDeleteButton,
                renderRestoreButton,
                renderSubmitButton,
            }}
        >
            <StyledForm
                name={formName}
                ref={domNode}
                className={className}
                onSubmit={handleClickOnSubmitButton}
            >
                {renderContents()}
            </StyledForm>

            {isLoading && (
                <StyledBackdrop open>
                    <Loader />
                </StyledBackdrop>
            )}
        </ButtonsProvider>
    );
};

export default React.memo(Form);

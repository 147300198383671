import { BLOCK_LOADING_STATES, BLOCK_META_STATES } from '@@editor/constants';
import { Editor } from '@@editor/helpers';
import { isAssetPayload } from '@@utils/assets';

import { createEmbedBlock } from './';

const createEmbedBlockForFile = (file, nodeType, useInlineEditing?: boolean) => {
    let data;

    if (file instanceof File) {
        data = {
            [BLOCK_META_STATES.LOADING]: true,
            [BLOCK_META_STATES.LOADING_STATE]: BLOCK_LOADING_STATES.UPLOAD_FILE,
            mimetype: file.type,
            src: null,
            originalSrc: file,
            embed: {
                url: null,
                elvisId: null,
            },
        };
    } else if (typeof file === 'string' || isAssetPayload(file)) {
        data = {
            [BLOCK_META_STATES.LOADING]: true,
            [BLOCK_META_STATES.LOADING_STATE]: BLOCK_LOADING_STATES.UPLOAD_FILE,
            mimetype: null,
            src: null,
            originalSrc: file,
            embed: {
                url: null,
                elvisId: null,
            },
        };
    } else {
        data = {
            [BLOCK_META_STATES.LOADING]: false,
            [BLOCK_META_STATES.LOADING_STATE]: undefined,
            ...file,
        };
    }

    return createEmbedBlock(data, nodeType, useInlineEditing);
};

const embedFiles = (editor, data, initialOptions, options) => {
    if (Array.isArray(data)) {
        return data.reduce(
            (editor, item) => embedBlock(editor, item, initialOptions, options),
            editor,
        );
    }

    return embedBlock(editor, data, initialOptions, options);
};

const embedBlock = (editor, file, initialOptions, options) => {
    // @ts-ignore Type the file to fix errors
    const block = createEmbedBlockForFile(file, initialOptions.nodeType, editor.useInlineEditing);

    if (!block) {
        return editor;
    }

    Editor.insertElement(editor, block, options);

    return editor;
};

export const createGenerateEmbedFiles =
    (initialOptions = {}) =>
    (editor, data, options = {}) =>
        embedFiles(editor, data, initialOptions, options);

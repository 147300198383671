import { camelCase, omitBy, get } from 'lodash';

import { parseUrl } from '@@utils/URL';
import { MIME_TYPES } from '@@editor/helpers/Element';

export const isVideoCMSEmbedCode = (code) => {
    const { url, params } = parseEmbedCode(code);

    return url && (params.mimetype === MIME_TYPES.VIDEOCMS || params.uv);
};

export const isLivestreamVideoCMSEmbedCode = (code) => {
    const { url, params } = parseEmbedCode(code);

    return url && params.mimetype === MIME_TYPES.VIDEOCMS_LIVESTREAM;
};

export const hasValidVideoCMSVideoId = (code) => {
    const { url, params } = parseEmbedCode(code);

    const videoId = (params.id && params.id.substring(2)) || (params.uv && params.uv.substring(2));

    return url && videoId && /^\d+$/.test(videoId);
};

export const parseEmbedCode = (code) => {
    // el will not be mounted, only serves for parsing. Garbage collected once function terminates
    const el = document.createElement('div');

    el.innerHTML = code;

    const [iframe] = el.getElementsByTagName('iframe');

    if (iframe instanceof HTMLElement) {
        const src = iframe.getAttribute('src').replace(/[“”"]/g, '');
        const url = parseUrl(src);

        if (!url) {
            console.warn('invalid src provided on videocms');

            return { url: null, params: {} };
        }

        ['uv'].forEach((attributeName) => {
            iframe.dataset[camelCase(attributeName)] = iframe.getAttribute(attributeName);
        });

        const dataset = get(iframe, 'dataset');
        const { uv, previewUrl } = dataset;

        // This if statement is here to keep backward compatibility for the old embeds
        // that work with the UV attribute and the preview image in the url
        if (uv && !previewUrl) {
            const params = (url.searchParams.get('params') || '')
                .split('|')
                .reduce((previousValue, param) => {
                    const [key, value] = param.split('@');

                    return Object.assign(previousValue, {
                        [key]: value,
                    });
                }, {});

            iframe.dataset.previewUrl = params.previewPath || null;
        }

        return { url, params: omitBy(iframe.dataset, (value) => value === 'null') };
    }

    return { url: null, params: {} };
};

import React, { ReactNode } from 'react';
import { Transforms } from 'slate';

import Loader from '@@components/Loader';
import { BLOCK_LOADING_STATES, BLOCK_META_STATES } from '@@editor/constants';
import { Editor, Node, ReactEditor } from '@@editor/helpers';
import { EmbedElement } from '@@editor/helpers/Element';
import { UseLoaderHandlers, useLoader } from '@@hooks/useLoader';

const resetLoadingState = (editor: Editor, element: EmbedElement) => {
    if (element.data[BLOCK_META_STATES.LOADING_STATE] === BLOCK_LOADING_STATES.LOAD_FILE) {
        const at = ReactEditor.findPath(editor, element);
        const node = Node.get(editor, at);

        Transforms.setNodes(
            editor,
            {
                data: {
                    ...node.data,
                    [BLOCK_META_STATES.LOADING]: false,
                    [BLOCK_META_STATES.LOADING_STATE]: undefined,
                },
            },
            { at },
        );
    }
};

type Props = {
    editor: Editor;
    element: EmbedElement;
    children: (props: UseLoaderHandlers) => ReactNode;
};

const EmbedComponentLoader = ({ children, editor, element }: Props) => {
    const handleOnLoad = () => {
        resetLoadingState(editor, element);
    };

    const handleOnError = () => {
        resetLoadingState(editor, element);
    };

    const { isLoading, onLoad, onError } = useLoader({
        onLoad: handleOnLoad,
        onError: handleOnError,
    });

    return (
        <Loader isLoading={isLoading} keepChildrenInDom>
            {children({ onLoad, onError })}
        </Loader>
    );
};

export default EmbedComponentLoader;

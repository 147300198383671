import React, { useEffect, useMemo, useState } from 'react';
import {
    Table as MuiTable,
    TableBody,
    TableFooter,
    TableHead,
    TableRow,
    styled,
} from '@mui/material';

import i18n from '@@lib/i18n/i18n';
import { UseSortBy } from '@@api/hooks/useSortBy';

import Column from './Column';
import DraggableRow from './DraggableRow';
import Row from './Row';
import RowLoading from './RowLoading';
import EmptyRow from './EmptyRow';

const StyledTable = styled(MuiTable)(({ theme }) => ({
    'td:first-child, th:first-child': {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2),
    },
    'td:last-child, th:last-child': {
        paddingRight: theme.spacing(4),
    },
}));

const Caption = styled('caption')(({ theme }) => ({
    ...theme.typography.title4,
    captionSide: 'top',
    textAlign: 'left',
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.primary['100']}`,
}));

type Props<T = any> = {
    columns: TableColumn[];
    data: T[];
    getKey?: (column: TableColumn, index: number) => string | number;
    renderEmptyRow?: (columns: TableColumn[]) => React.ReactNode;
    renderRow?: (columns: TableColumn[], item: { data: T; index: number }) => React.ReactNode;
    caption?: string;
    className?: string;
    separatorContainerStyle?: string;
    sortConfig?: UseSortBy;
    withHeader?: boolean;
    onEndReached?: () => void;
};

const useReachedEnd = ({ onEndReached }) => {
    const [tFootRef, setTFootRef] = useState<HTMLTableSectionElement | null>(null);
    const [isIntersecting, setIsIntersecting] = useState(false);
    const observer = useMemo(
        () =>
            new IntersectionObserver(
                (entries) => {
                    // Only when data of the table is loaded, call `onEndReached`
                    if (
                        entries[0].target.previousElementSibling &&
                        entries[0].target.previousElementSibling.clientHeight > 0
                    ) {
                        setIsIntersecting(entries[0].isIntersecting);
                    }
                },
                {
                    threshold: 1,
                },
            ),
        [],
    );

    useEffect(() => {
        if (tFootRef) {
            observer.observe(tFootRef);

            return () => {
                observer.unobserve(tFootRef);
            };
        }
    }, [tFootRef, observer]);

    useEffect(() => {
        if (isIntersecting) {
            onEndReached?.();
        }
    }, [isIntersecting]);

    return { endRef: setTFootRef };
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const Table = <T extends any>({
    columns,
    data,
    caption,
    className,
    sortConfig,
    onEndReached,
    withHeader = true,
    getKey = (column: TableColumn, i: number) => column.fieldName?.toString() || column.type || i,
    renderEmptyRow = (columns: TableColumn[]) => (
        <EmptyRow columns={columns}>{i18n.t('list.noresult')}</EmptyRow>
    ),
    renderRow = (columns, { data: record, index }) => (
        <Row key={index} index={index} columns={columns} record={record} />
    ),
}: Props<T>) => {
    const { endRef } = useReachedEnd({ onEndReached });

    const getColumnProps = (column: TableColumn, sortConfig?: UseSortBy) => {
        if (!sortConfig) {
            return column;
        }

        const { sortBy, fieldName } = column;
        const { name, direction, onChange } = sortConfig;

        const active = name === fieldName;

        return {
            ...column,
            // @ts-ignore fieldName can be missing for example for action column or can be string array
            ...(sortBy ? { onClick: () => onChange(fieldName), direction, active } : null),
        };
    };

    const renderHeader = () => (
        <TableHead>
            <TableRow>
                {columns.map((column, i) => (
                    <Column key={getKey(column, i)} {...getColumnProps(column, sortConfig)} />
                ))}
            </TableRow>
        </TableHead>
    );

    const renderList = () => (
        <>
            <StyledTableBody>
                {data?.map((data, index) => renderRow(columns, { data, index }))}
            </StyledTableBody>

            <TableFooter ref={endRef} />
        </>
    );

    const renderEmptyTable = () => <TableBody>{renderEmptyRow(columns)}</TableBody>;

    return (
        <StyledTable className={className}>
            {caption ? <Caption>{caption}</Caption> : null}

            <colgroup>
                {columns.map((column, i) => (
                    <col
                        key={getKey(column, i)}
                        style={typeof column.width !== 'undefined' ? { width: column.width } : {}}
                    />
                ))}
            </colgroup>

            {withHeader ? renderHeader() : null}

            {data.length > 0 ? renderList() : renderEmptyTable()}
        </StyledTable>
    );
};

Table.Column = Column;
Table.DraggableRow = DraggableRow;
Table.Row = Row;
Table.RowLoading = RowLoading;
Table.EmptyRow = EmptyRow;

export default Table;

import { Transforms } from 'slate';

import { ELEMENT_TYPES } from '@@editor/helpers/Element';
import { Element } from '@@editor/helpers';
import renderElement from '@@editor/plugins/utils/renderElement';
import makeState from '@@editor/utils/makeState';
import {
    normalizeInlineEditableElement,
    preventDeleteBackward,
    preventDeleteForward,
    preventDeleteFragment,
    preventInsertBreak,
} from '@@editor/plugins/utils/inlineEditing';
import deleteForward from '@@editor/plugins/utils/deleteForward';
import deleteBackward from '@@editor/plugins/utils/deleteBackward';
import deleteFragment from '@@editor/plugins/utils/deleteFragment';
import insertBreak from '@@editor/plugins/utils/insertBreak';
import normalizeNode from '@@editor/plugins/utils/normalizeNode';

import { createGenerateLayoutBlock } from '../utils';
import QuoteNode, {
    INLINE_EDITABLE_CHILDREN_TYPES,
    NODE_TYPE,
    TYPE,
} from '../components/QuoteNode';
import QuoteText from '../components/QuoteText';
import QuoteCaption from '../components/QuoteCaption';

const emptyQuote = {
    quote: makeState(),
    caption: makeState(),
};

const generateLayoutBlock = createGenerateLayoutBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
});

const withInlineQuote = (editor, options) => {
    const { isVoid, useInlineEditing } = editor;

    return Object.assign(editor, {
        isVoid: (element: Element) => {
            if (useInlineEditing && Element.isQuoteRelatedElement(element)) {
                return false;
            }

            return element.type === NODE_TYPE ? true : isVoid(element);
        },
        normalizeNode: normalizeNode(editor, [
            [
                normalizeInlineEditableElement,
                {
                    type: ELEMENT_TYPES.QUOTE,
                    allowedChildrenTypes: INLINE_EDITABLE_CHILDREN_TYPES,
                },
            ],
        ]),
        deleteForward: deleteForward(editor, [
            [preventDeleteForward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteBackward: deleteBackward(editor, [
            [preventDeleteBackward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteFragment: deleteFragment(editor, [
            [preventDeleteFragment, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        insertBreak: insertBreak(editor, [
            [preventInsertBreak, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        renderElement: renderElement(
            editor,
            [
                [NODE_TYPE, QuoteNode],
                [ELEMENT_TYPES.QUOTE_TEXT, QuoteText],
                [ELEMENT_TYPES.QUOTE_CAPTION, QuoteCaption],
            ],
            {
                ...options,
            },
        ),
        insertQuote: (at) => {
            generateLayoutBlock(editor, emptyQuote, {
                at,
                replace: false,
            });
            Transforms.select(editor, at);
            Transforms.collapse(editor);
        },
    });
};

export default withInlineQuote;

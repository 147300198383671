import { isString } from 'lodash';

import { INFORMATION_SEPARATOR_ONE } from '@@constants/Unicode';
import useReactHookFormContext from '@@containers/ReactHookForm/useReactHookFormContext';

export type FormFieldError = string[] | undefined;

const useReactHookFormFieldError = (name: string): FormFieldError => {
    const { formState, getFieldState } = useReactHookFormContext();
    // We need to connect `getFieldState` with `formState`, because it needs to trigger a a re-render when an
    // error on a field is raised. Otherwise we would get, for example, a wrong error state on scheduled placing
    // in metadata form for the `requiredOneOf` validation.
    const { error: fieldError } = getFieldState(name, formState);
    const validationError = fieldError?.types?.validate;
    const error = isString(validationError)
        ? validationError.split(INFORMATION_SEPARATOR_ONE).map((error) => JSON.parse(error))
        : undefined;

    return error;
};

export default useReactHookFormFieldError;

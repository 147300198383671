import { styled, Theme } from '@mui/material';
import { MUIStyledCommonProps } from '@mui/system';
import React, { PropsWithChildren, useCallback, useState } from 'react';

import { updateRef } from '@@utils/index';

import { usePageLayout } from './PageLayoutContext';
import { PageLayoutArea } from './types';

const PageLayout = styled('div')({
    width: '100vw',
    maxWidth: '100vw',
    height: '100vh',
    maxHeight: '100vh',
    display: 'grid',
    gridTemplateAreas: `'nav notificationBar notificationBar notificationBar' 'nav head head head' 'nav sidebar main toolbar' 'nav foot foot foot'`,
    gridTemplateRows: 'auto auto 1fr auto',
    gridTemplateColumns: 'auto auto 1fr auto',
    overflow: 'hidden',
});

const PageLayoutElement = styled('div')<{ $gridArea: PageLayoutArea }>((props) => ({
    gridArea: props.$gridArea,
    display: props.$gridArea !== 'main' ? 'flex' : 'block',
    overflow: props.$gridArea === 'main' ? 'auto' : 'visible',
    position: 'relative',

    '&:empty': {
        maxWidth: 0,
        maxHeight: 0,
    },

    // This is needed in order to prevent grid blowout:
    // https://css-tricks.com/preventing-a-grid-blowout/
    // https://stackoverflow.com/a/52787826
    minWidth: 0,
    minHeight: 0,
}));

const createComponent = (area: PageLayoutArea) =>
    React.forwardRef<HTMLElement, PropsWithChildren<MUIStyledCommonProps<Theme>>>(
        ({ children, ...props }, ref) => {
            const [refElement, setRefElement] = useState<HTMLElement | null>(null);
            const refs = usePageLayout();

            const refCallback = useCallback((element) => {
                updateRef([ref, setRefElement, refs[area]], element);
            }, []);

            return (
                <PageLayoutElement {...props} $gridArea={area} ref={refCallback}>
                    {refElement && children}
                </PageLayoutElement>
            );
        },
    );

export const PageLayoutNavigation = createComponent('nav');
export const PageLayoutNotificationBar = createComponent('notificationBar');
export const PageLayoutHeader = createComponent('head');
export const PageLayoutSidebar = createComponent('sidebar');
export const PageLayoutContent = createComponent('main');
export const PageLayoutToolbar = createComponent('toolbar');
export const PageLayoutFooter = createComponent('foot');

PageLayoutNavigation.displayName = 'PageLayoutNavigation';
PageLayoutNotificationBar.displayName = 'PageLayoutNotificationBar';
PageLayoutHeader.displayName = 'PageLayoutHeader';
PageLayoutSidebar.displayName = 'PageLayoutSidebar';
PageLayoutContent.displayName = 'PageLayoutContent';
PageLayoutToolbar.displayName = 'PageLayoutToolbar';
PageLayoutFooter.displayName = 'PageLayoutFooter';

export default PageLayout;

import { Editor } from '@@editor/helpers';
import { createElement } from '@@editor/serialization/UnitySerializer/deserializeNodes';

export const createLayoutBlock = (data, nodeType, useInlineEditing?: boolean) =>
    createElement(nodeType, data, { useInlineEditing });

export const createGenerateLayoutBlock = (initialOptions) => (editor, formData, options) => {
    const block = createLayoutBlock(formData, initialOptions.nodeType, editor.useInlineEditing);

    return Editor.insertElement(editor, block, options);
};

import React from 'react';
import { FieldValues } from 'react-hook-form';

import TextInputField, { TextInputFieldProps } from './TextInput';

export type TextareaFieldProps<TFieldValues extends FieldValues = FieldValues> =
    TextInputFieldProps<TFieldValues>;

const TextareaField = <TFieldValues extends FieldValues = FieldValues>(
    props: TextareaFieldProps<TFieldValues>,
) => {
    const { rows, ...rest } = props;
    const minRows = !rows ? 4 : undefined;

    return <TextInputField multiline minRows={minRows} rows={rows} {...rest} />;
};

export default TextareaField;

import React from 'react';
import { FieldValues } from 'react-hook-form';

import useFormField, { UseFormFieldProps } from '@@form/hooks/useFormField';
import Checkbox, { CheckboxProps } from '@@form/components/Checkbox';

export type CheckboxFieldProps<TFieldValues extends FieldValues = FieldValues> = CheckboxProps &
    UseFormFieldProps<TFieldValues>;

const CheckboxField = <TFieldValues extends FieldValues = FieldValues>(
    props: CheckboxFieldProps<TFieldValues>,
) => {
    const {
        name,
        validate,
        novalidate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
        ...rest
    } = props;

    const { ref, ...formFieldProps } = useFormField<TFieldValues>({
        name,
        novalidate,
        validate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
    });

    const checked = Boolean(formFieldProps.value);

    return (
        <Checkbox
            inputRef={ref}
            {...formFieldProps}
            required={required}
            {...rest}
            checked={checked}
        />
    );
};

export default CheckboxField;

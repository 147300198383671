import { ClientInferResponses } from '@ts-rest/core';

import { AssetPayload, isAssetPayload } from '@@utils/assets';
import { FilerepoRouter, useFilerepoClient } from '@@api/services/filerepo/client';
import { HTTP_STATUS_CODES } from '@@constants/http';
import { OnUploadProgress } from '@@api/fetcher';

export type UploadFileConfig = {
    file: File | string | AssetPayload;
    onUploadProgress?: OnUploadProgress;
};

export type UploadFileResponse = ClientInferResponses<
    FilerepoRouter['files']['postUrl'] | FilerepoRouter['files']['postFile'],
    typeof HTTP_STATUS_CODES.OK
>;

export type UploadFilePromiseResponse = Promise<UploadFileResponse>;

export type UploadFile = (config: UploadFileConfig) => UploadFilePromiseResponse;

const isFile = (data) => data instanceof File;

type UseUploadFile = () => UploadFile;

export const useUploadFile: UseUploadFile = () => {
    const { client: filerepoClient } = useFilerepoClient();

    const { mutateAsync: postAsset } = filerepoClient.files.postUrl.useMutation();
    const { mutateAsync: postFile } = filerepoClient.files.postFile.useMutation();

    return ({ file, onUploadProgress }) => {
        if (isAssetPayload(file)) {
            return postAsset({
                body: { url: file.previewUrl },
                onUploadProgress,
            });
        }

        let formData;

        if (isFile(file)) {
            formData = new FormData();
            formData.append('file', file);

            return postFile({
                body: formData,
                headers: { 'content-type': 'multipart/form-data' },
                onUploadProgress,
            });
        }

        formData = {
            url: file,
        };

        return postAsset({ body: formData, onUploadProgress });
    };
};

export default useUploadFile;

import React from 'react';
import PropTypes from 'prop-types';

const divStyle = {
    position: 'relative',
    height: 0,
    overflow: 'hidden',
    maxWidth: '100%',
    width: '100%',
};

const iframeStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
};

const ratioToPercent = (ratio) => {
    const [w, h] = ratio.split(':').map((num) => Number(num));

    return `${(h / w) * 100}%`;
};

// Usage: <ResponsiveEmbed src='video_url' ratio='4:3' />
const ResponsiveEmbedFrame = ({ ratio, ...props }) => {
    const paddingBottom = ratioToPercent(ratio);
    const style = { ...divStyle, paddingBottom };
    const iframeProps = Object.assign({ frameBorder: 0 }, props, { style: iframeStyle });

    // We use "allow-scripts" for the sandbox to make the basic JS-based websites work.
    // Do not add "allow-same-origin" as it would make the whole sandboxing useless
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#attr-sandbox
    // For some websites like youtube, preview will not work if we have "allow-scripts".
    // omitSandbox will omit the sandbox property all together. This means all restrictions are applied.
    return (
        <div style={style}>
            {props.omitSandbox ? (
                <iframe {...iframeProps} />
            ) : (
                <iframe {...iframeProps} sandbox="allow-scripts" />
            )}
        </div>
    );
};

ResponsiveEmbedFrame.defaultProps = {
    src: null,
    ratio: '16:9',
    omitSandbox: false,
};

ResponsiveEmbedFrame.propTypes = {
    ratio: (props, propName) => {
        if (!/\d+:\d+/.test(props[propName])) {
            return new Error(
                'Invalid ratio supplied to ResponsiveEmbed. Expected a string pattern X:Y',
            );
        }
    },
    src: PropTypes.string,
    omitSandbox: PropTypes.Boolean,
};

export default ResponsiveEmbedFrame;

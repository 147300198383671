import renderElement from '@@editor/plugins/utils/renderElement';
import { MIME_TYPES } from '@@editor/helpers/Element';
import { GenerateEmbedBlockData } from '@@editor/typings/Embed';

import { createGenerateEmbedBlock } from './../utils';
import { NODE_TYPE, TYPE } from './constants';
import EmbeddedInfoboxNode from './components/EmbeddedInfoboxNode';

export const generateEmbedBlockData: GenerateEmbedBlockData = ({ embed, originalSrc }) => ({
    mimetype: MIME_TYPES.EMBEDDED_INFOBOX,
    embed,
    originalSrc,
});

export const generateEmbedBlock = createGenerateEmbedBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
    generateEmbedBlockData,
});

const internalWithEmbedInfobox = (editor, options) =>
    Object.assign(editor, {
        renderElement: renderElement(editor, [[NODE_TYPE, EmbeddedInfoboxNode]], options),
    });

export const withEmbedInfobox = (editor, options) =>
    internalWithEmbedInfobox(editor, {
        ...options,
        nodeType: [NODE_TYPE],
    });

export default withEmbedInfobox;

import { z } from 'zod';

import {
    SectionLayouts as SectionLayoutsConst,
    SectionTypes as SectionTypesConst,
} from '@@routes/placement/constants';
import { AUDIENCE_TYPE } from '@@utils/enums/tenants';
import { LANG_CODES } from '@@lib/i18n/constants';

import { Entity } from '../../utils/schemas/schemas';

export enum SitemapTypesEnum {
    CATEGORY = 'category',
    INTERNAL = 'internal',
    EXTERNAL = 'external',
    GROUP = 'group',
    CLIENT_PAGE = 'clientPage',
}

const SitemapBase = Entity.extend({
    id: z.number().optional(),
    parentId: z.number().nullish(),
    tenantId: z.number(),
    name: z.string(),
    availableFor: z.object({
        app: z.boolean(),
        desktop: z.boolean(),
    }),
});

type SitemapWithChildren = {
    children?: Sitemap[];
};

const SitemapWithChildren: z.ZodType<SitemapWithChildren> = z.object({
    children: z.lazy(() => z.array(Sitemap)).optional(),
});

const SitemapCategory = z.intersection(
    SitemapBase.extend({
        type: z.literal(SitemapTypesEnum.CATEGORY),
        subtitle: z.string().nullish(),
        categoryId: z.number(),
        categoryType: z.string().optional(),
        categoryUrlSlug: z.string(),
        pageStyle: z.string().nullable(),
        htmlMetaTitle: z.string().optional(),
        htmlMetaDescription: z.string().optional(),
        allowRobots: z.boolean(),
    }),
    SitemapWithChildren,
);

export type SitemapCategory = z.infer<typeof SitemapCategory>;

const SitemapInternalLink = z.intersection(
    SitemapBase.extend({
        type: z.literal(SitemapTypesEnum.INTERNAL),
        metadataId: z.number(),
        contentUrlSlug: z.string().nullable(),
        pageStyle: z.string().nullable(),
    }),
    SitemapWithChildren,
);

export type SitemapInternalLink = z.infer<typeof SitemapInternalLink>;

const SitemapExternalLink = z.intersection(
    SitemapBase.extend({
        type: z.literal(SitemapTypesEnum.EXTERNAL),
        contentLink: z.string(),
        newWindow: z.boolean().optional(),
        pageStyle: z.string().nullable(),
    }),
    SitemapWithChildren,
);

export type SitemapExternalLink = z.infer<typeof SitemapExternalLink>;

const SitemapGroup = z.intersection(
    SitemapBase.extend({
        type: z.literal(SitemapTypesEnum.GROUP),
    }),
    SitemapWithChildren,
);

export type SitemapGroup = z.infer<typeof SitemapGroup>;

const SitemapClientPageBase = SitemapBase.extend({
    type: z.literal(SitemapTypesEnum.CLIENT_PAGE),
    clientPageIdentifier: z.string().nullable(),
    clientPagePath: z.string(),
    htmlMetaTitle: z.string(),
    htmlMetaDescription: z.string(),
    allowRobots: z.boolean(),
});

const SitemapClientPage = z.intersection(SitemapClientPageBase, SitemapWithChildren);

export const Sitemap = z.union([
    SitemapCategory,
    SitemapInternalLink,
    SitemapExternalLink,
    SitemapClientPage,
    SitemapGroup,
]);

export const UnitySitemap = Sitemap;

export type Sitemap = z.infer<typeof Sitemap>;
export type UnitySitemap = z.infer<typeof UnitySitemap>;

export const isUnityCategorySitemap = (node: UnitySitemap): node is SitemapCategory =>
    node.type === 'category';

export const isUnityInternalLinkSitemap = (node: UnitySitemap): node is SitemapInternalLink =>
    node.type === 'internal';

const MenuNodeBase = Entity.extend({
    availableFor: z
        .object({
            app: z.boolean(),
            desktop: z.boolean(),
        })
        .optional(),
    highlighted: z.boolean().optional(),
    icon: z
        .object({
            elvisId: z.string().nullish(),
            url: z.string().nullish(),
        })
        .nullish(),
    sitemap: z
        .object({
            id: z.number().optional(),
            name: z.string().optional(),
        })
        .optional(),
});

type MenuNodeBase = z.infer<typeof MenuNodeBase>;

type CompleteMenuNode = MenuNodeBase & {
    children?: CompleteMenuNode[];
};
const MenuNode: z.ZodType<CompleteMenuNode> = MenuNodeBase.extend({
    children: z.lazy(() => z.array(MenuNode).optional()),
});

export type MenuNode = z.infer<typeof MenuNode>;

export const Menu = Entity.extend({
    id: z.number(),
    version: z.number().optional(),
    type: z.string().optional(),
    tenantId: z.number().optional(),
    nodes: z.array(MenuNode).optional(),
});

export type Menu = z.infer<typeof Menu>;

export const EditionTarget = Entity.extend({
    id: z.number().optional(),
    maxEditions: z.number(),
    name: z.string(),
    tenant: z.object({
        audienceType: z.string(),
        categoriesRequested: z.boolean().optional(),
        contentStationTenantName: z.string().nullish(),
        id: z.number(),
        language: z.string(),
        logoUrl: z.string(),
        name: z.string(),
        primaryColor: z.string(),
        streamboatTenantName: z.string(),
    }),
});

export type EditionTarget = z.infer<typeof EditionTarget>;

export const CollectionItem = Entity.extend({
    type: z.literal('brixwireKeyword'),
    categoryIds: z.array(z.number()),
    keyword: z.string(),
    version: z.number(),
    id: z.number(),
    createdBy: z.string(),
    lastUpdatedBy: z.string(),
});

export type CollectionItem = z.infer<typeof CollectionItem>;

const SectionTypes = z.nativeEnum(SectionTypesConst);

export type SectionTypes = z.infer<typeof SectionTypes>;

const SectionLayouts = z.nativeEnum(SectionLayoutsConst);

export type SectionLayouts = z.infer<typeof SectionLayouts>;

const BaseCategorySection = Entity.extend({
    id: z.number(),
    type: SectionTypes,
    tenantId: z.number(),
    selfCategoryId: z.number(),
    title: z.string(),
});

const CategorySectionDefault = BaseCategorySection.extend({
    type: z.literal(SectionTypesConst.DEFAULT),
    pointerCategoryId: z.number(),
    showTitle: z.boolean().optional(),
    icon: z.unknown().nullable(),
    layout: SectionLayouts,
    buttonText: z.string().nullish(),
    teaserCount: z.number(),
    isInfiniteScroll: z.boolean().nullable(),
    adsPositions: z
        .object({
            app: z.array(z.number()).nullable(),
            desktop: z.array(z.number()).nullable(),
            mobileWeb: z.array(z.number()).nullable(),
        })
        .nullish(),
});

export type CategorySectionDefault = z.infer<typeof CategorySectionDefault>;
const NewCategorySectionDefault = CategorySectionDefault.omit({ id: true });

type NewCategorySectionDefault = z.infer<typeof NewCategorySectionDefault>;

const CategorySectionCategory = CategorySectionDefault.omit({ isInfiniteScroll: true }).extend({
    type: z.literal(SectionTypesConst.CATEGORY),
});

export type CategorySectionCategory = z.infer<typeof CategorySectionCategory>;
const NewCategorySectionCategory = CategorySectionCategory.omit({ id: true });

type NewCategorySectionCategory = z.infer<typeof NewCategorySectionCategory>;

const CategorySectionCustom = BaseCategorySection.extend({
    type: z.literal(SectionTypesConst.CUSTOM),
    customTypeIdentifier: z.string(),
});

export type CategorySectionCustom = z.infer<typeof CategorySectionCustom>;

const NewCategorySectionCustom = CategorySectionCustom.omit({ id: true });

type NewCategorySectionCustom = z.infer<typeof NewCategorySectionCustom>;

export const CategorySection = z.union([
    CategorySectionDefault,
    CategorySectionCategory,
    CategorySectionCustom,
]);

export const NewCategorySection = z.union([
    NewCategorySectionDefault,
    NewCategorySectionCategory,
    NewCategorySectionCustom,
]);

export type NewCategorySection = z.infer<typeof NewCategorySection>;

export type CategorySection = z.infer<typeof CategorySection>;
export type UnityCategorySection = z.infer<typeof CategorySection>;
export type NewUnityCategorySection = z.infer<typeof NewCategorySection>;

const CategorySectionDefaults = z.union([
    CategorySectionDefault.partial().required({ type: true }),
    CategorySectionCategory.partial().required({ type: true }),
    CategorySectionCustom.partial().required({ type: true }),
]);

export type CategorySectionDefaults = z.infer<typeof CategorySectionDefaults>;

export const isDefaultCategorySection = (
    section?: CategorySection,
): section is CategorySectionDefault => section?.type === SectionTypesConst.DEFAULT;
export const isNewDefaultCategorySection = (
    section?: NewCategorySection,
): section is NewCategorySectionDefault => section?.type === SectionTypesConst.DEFAULT;

export const isCategoryCategorySection = (
    section?: CategorySection,
): section is CategorySectionCategory => section?.type === SectionTypesConst.CATEGORY;

export const isNewCategoryCategorySection = (
    section?: NewCategorySection,
): section is NewCategorySectionCategory => section?.type === SectionTypesConst.CATEGORY;

export const isCustomCategorySection = (
    section?: CategorySection,
): section is CategorySectionCustom => section?.type === SectionTypesConst.CUSTOM;

export const isNewCustomCategorySection = (
    section?: NewCategorySection,
): section is NewCategorySectionCustom => section?.type === SectionTypesConst.CUSTOM;

export const SectionLayout = z.object({
    name: z.string(),
    tenantIds: z.array(z.number()),
});

export type SectionLayout = z.infer<typeof SectionLayout>;

export const PageStyle = z.object({
    name: z.string(),
    tenantIds: z.array(z.number()),
    types: z.array(z.string()),
});

export type PageStyle = z.infer<typeof PageStyle>;

const CategoryBase = Entity.extend({
    id: z.number(),
    active: z.boolean(),
    expiryDate: z.string().nullable(),
    frontCategory: z.boolean().optional(),
    name: z.string(),
    parentId: z.number().nullable(),
    temporary: z.boolean().optional(),
    type: z.string(),
    syncedWithDesknet: z.boolean(),
});

type CategoryBase = z.infer<typeof CategoryBase>;

type CompleteCategory = CategoryBase & {
    children: CompleteCategory[];
};

export const Category: z.ZodType<CompleteCategory> = CategoryBase.extend({
    children: z.lazy(() => z.array(Category)),
});

export type Category = z.infer<typeof Category>;

type CompleteUnityCategory = CategoryBase & {
    subCategories: CompleteUnityCategory[];
};
export const UnityCategory: z.ZodType<CompleteUnityCategory> = CategoryBase.extend({
    subCategories: z.lazy(() => z.array(UnityCategory)),
});

export type UnityCategory = z.infer<typeof UnityCategory>;

const NewCategoryBase = CategoryBase.omit({ id: true });

type NewCategoryBase = z.infer<typeof NewCategoryBase>;

type NewCompleteCategory = NewCategoryBase & {
    children: NewCompleteCategory[];
};

export const NewCategory: z.ZodType<NewCompleteCategory> = NewCategoryBase.extend({
    children: z.lazy(() => z.array(NewCategory)),
});
export type NewCategory = z.infer<typeof NewCategory>;

type UnityCompleteCategory = NewCategoryBase & {
    subCategories: UnityCompleteCategory[];
};

export const NewUnityCategory: z.ZodType<UnityCompleteCategory> = NewCategoryBase.extend({
    subCategories: z.lazy(() => z.array(NewUnityCategory)),
});

export type NewUnityCategory = z.infer<typeof NewUnityCategory>;

export const Tenant = z.object({
    audienceType: z.nativeEnum(AUDIENCE_TYPE),
    categoriesRequired: z.boolean().optional(),
    contentStationTenantName: z.string().nullable(),
    id: z.number(),
    language: z.union([z.literal('de_CH'), z.literal('fr_CH')]),
    logoUrl: z.string(),
    name: z.string(),
    primaryColor: z.string(),
    streamboatTenantName: z.string(),
});

export type Tenant = z.infer<typeof Tenant>;

export const TenantGroup = z.object({
    id: z.number(),
    name: z.string(),
    logoUrl: z.string(),
    language: z.string(),
    tenantIds: z.array(z.number()),
});

export type TenantGroup = z.infer<typeof TenantGroup>;

export const Locale = z.object({
    locale: z.enum([LANG_CODES.GERMAN, LANG_CODES.FRENCH]),
});

export type Locale = z.infer<typeof Locale>;

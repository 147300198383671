import { Theme, createTheme } from '@mui/material';

const placeholderTheme = (theme: Theme) =>
    createTheme(theme, {
        fixed: {
            fileUpload: {
                dropArea: {
                    backgroundColor: theme.palette.gold.light,
                    backgroundHoverColor: theme.palette.gold.main,
                    iconColor: theme.palette.gold.dark,
                    iconHoverColor: theme.palette.gold.contrastText,
                },
            },
        },
    });

export default placeholderTheme;

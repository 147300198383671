import { useTranslation } from 'react-i18next';
import { ClickAwayListener, Tooltip, styled } from '@mui/material';
import React, { useState } from 'react';

import { ValidationError } from '@@form/utils/validators/image';
import Icon from '@@components/Icon';

export const HORIZONTAL_PADDING = '10px';
// This is the horizontal padding minus border
export const VERTICAL_PADDING = '8px';

export const Wrapper = styled('button')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    position: 'absolute',
    top: `calc(50% - calc(${theme.fixed.icon.medium} / 2))`,
    marginLeft: '-1px',
    marginRight: '1px',
    lineHeight: '0',
    padding: `0 ${HORIZONTAL_PADDING}`,
    border: '0 none',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
}));

const BubbleList = styled('ul')<{ $large?: boolean; $multiple?: boolean }>(
    ({ theme, $large, $multiple }) => ({
        margin: '0',
        padding: theme.spacing(1, 2),
        minWidth: $large ? '198px' : '148px',
        textAlign: 'left',
        listStyleType: $multiple ? 'disc' : 'none',
        paddingLeft: $multiple ? theme.spacing(3) : undefined,
    }),
);

type Props = {
    className?: string;
    error: ValidationError[];
    largeBubble?: boolean;
};

const ValidationIcon = (props: Props) => {
    const { error, className, largeBubble } = props;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setOpen(true);
    };

    const bubbleList = (
        <BubbleList $large={largeBubble} $multiple={error.length > 1}>
            {error.map((innerError, index) => {
                if (Array.isArray(innerError)) {
                    const [errorMessage, replacements] = innerError;

                    return <li key={index}>{t(errorMessage, replacements)}</li>;
                }

                return <li key={index}>{t(innerError)}</li>;
            })}
        </BubbleList>
    );

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Wrapper type="button" className={className}>
                <Tooltip
                    color="error"
                    disableHoverListener
                    open={open}
                    placement="right"
                    title={bubbleList}
                    onClose={handleClose}
                    PopperProps={{
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                },
                            },
                        ],
                    }}
                >
                    <span onClick={handleClick}>
                        <Icon color="error" name="circle-exclamation-sharp" />
                    </span>
                </Tooltip>
            </Wrapper>
        </ClickAwayListener>
    );
};

export default ValidationIcon;

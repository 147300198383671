import React, { Ref } from 'react';
import { useFocused } from 'slate-react';
import { styled } from '@mui/material';

import { Editor, ReactEditor } from '@@editor/helpers';
import { TitleHeaderElement, TitleElement, LeadElement, Element } from '@@editor/helpers/Element';
import { FloatingIcon, getMaxWidth } from '@@editor/toolbars/ElementWrapper/ElementWrapper';
import { getInputPlaceholderStyle } from '@@editor/plugins/serializable/styles';

type WrapperProps = {
    readOnly?: boolean;
    $placeholderText: string;
    $templateElement: boolean;
};

export const Wrapper = styled('div')<WrapperProps>(
    ({ readOnly, $placeholderText, $templateElement, theme }) => ({
        position: 'relative',
        margin: '0 auto',
        width: '100%',
        maxWidth: readOnly
            ? getMaxWidth({ widthType: 'text', theme })
            : theme.fixed.editor.textElement.width,

        [FloatingIcon]: {
            // Heading elements have an extra top padding that we need to account for
            // when displaying the "selection icon", and no wrapper on the sides
            top: `calc(${theme.spacing(1)} + ${theme.spacing(4)})`,
        },

        ...($templateElement &&
            getInputPlaceholderStyle({ theme, placeholderText: $placeholderText })),
    }),
);

type Props = {
    children: React.ReactNode;
    editor: Editor;
    element: TitleElement | TitleHeaderElement | LeadElement;
    forwardedRef?: Ref<HTMLDivElement>;
    placeholderText: string;
};

const HeadingWrapper = (props: Props) => {
    const { children, editor, element, forwardedRef, placeholderText, ...rest } = props;
    const readOnly = ReactEditor.isReadOnly(editor);
    const isSelected = ReactEditor.isElementSelected(editor, element);
    const isFocused = useFocused();

    return (
        <Wrapper
            data-type={element.type}
            readOnly={readOnly}
            ref={forwardedRef}
            $placeholderText={placeholderText}
            $templateElement={
                Element.isTemplateElement(element) || Element.isEmptyTextElement(element)
            }
            {...rest}
        >
            {editor.isArticleEditor && isFocused && isSelected && <FloatingIcon name="pen-clip" />}
            {children}
        </Wrapper>
    );
};

const HeadingWrapperWithRef = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
    <HeadingWrapper {...props} forwardedRef={ref} />
));

export default HeadingWrapperWithRef;

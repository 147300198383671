export const DRAG_DROP = {
    OPERATIONS: 'dragDrop/operations',
};

export const TYPES = {
    CONTENT_ITEM: 'CONTENT_ITEM',
    TABLE_ROW: 'TABLE_ROW',
    LIST_ITEM: 'LIST_ITEM',
    NESTED_LIST_ITEM: 'NESTED_LIST_ITEM',
    EDITOR_ELEMENT: 'EDITOR_ELEMENT',
    ATTACHMENT: 'ATTACHMENT',
    TICKER_EVENT: 'TICKER_EVENT',
} as const;

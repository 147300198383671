import { z } from 'zod';

import { Entity, RichText, UnityRichText } from '../../utils/schemas/schemas';
import { unityVariantsShape, variantsShape } from '../../utils/schemas/utils';
import { UnityEmbeddedMetadata, EmbeddedMetadata, CreationMetadata } from '../metadata/schemas';

const SlideshowVariantBase = z.object({
    title: z.string(),
    titleHeader: z.string(),
    allowAutomaticTeaserUpdate: z.boolean().optional(),
});

const UnitySlideshowVariant = SlideshowVariantBase.extend({
    lead: UnityRichText,
});

const SlideshowVariant = SlideshowVariantBase.extend({
    lead: RichText,
});

const SlideshowBase = Entity.extend({
    id: z.string(),
    metadataId: z.number(),
    slideCount: z.number().optional(),
});

export const UnitySlideshow = SlideshowBase.extend({
    variants: unityVariantsShape(UnitySlideshowVariant),
    metadata: UnityEmbeddedMetadata.optional(),
});

export type UnitySlideshow = z.infer<typeof UnitySlideshow>;

export const Slideshow = SlideshowBase.extend({
    variants: variantsShape(SlideshowVariant),
    metadata: EmbeddedMetadata.optional(),
});

export type Slideshow = z.infer<typeof Slideshow>;

export const NewSlideshow = Slideshow.pick({ variants: true }).extend({
    metadata: CreationMetadata,
});

export type NewSlideshow = z.infer<typeof NewSlideshow>;

export const NewUnitySlideshow = UnitySlideshow.pick({ variants: true }).extend({
    metadata: CreationMetadata,
});
export type NewUnitySlideshow = z.infer<typeof NewUnitySlideshow>;

const SlideshowSlideVariantBase = z.object({
    image: z.any(),
    allowAutomaticTeaserUpdate: z.boolean().optional(),
    visible: z.boolean().optional(),
});

export const UnitySlideshowSlideVariant = SlideshowSlideVariantBase;

const SlideshowSlideVariant = SlideshowSlideVariantBase.extend({
    aspect: z.string().optional(),
});

export type SlideshowSlideVariant = z.infer<typeof SlideshowSlideVariant>;

const SlideshowSlideVariants = variantsShape(SlideshowSlideVariant);

export type SlideshowSlideVariants = z.infer<typeof SlideshowSlideVariants>;

const SlideshowSlideBase = Entity.extend({
    id: z.number(),
    slideshowId: z.string().optional(),
});

export const UnitySlideshowSlide = SlideshowSlideBase.extend({
    variants: unityVariantsShape(UnitySlideshowSlideVariant),
});

export type UnitySlideshowSlide = z.infer<typeof UnitySlideshowSlide>;

export const SlideshowSlide = SlideshowSlideBase.extend({
    variants: SlideshowSlideVariants,
});

export type SlideshowSlide = z.infer<typeof SlideshowSlide>;

export const NewSlideshowSlide = SlideshowSlide.omit({ id: true, slideshowId: true });

export type NewSlideshowSlide = z.infer<typeof NewSlideshowSlide>;

const NewUnitySlideshowSlide = UnitySlideshowSlide.omit({ id: true, slideshowId: true });

export type NewUnitySlideshowSlide = z.infer<typeof NewUnitySlideshowSlide>;

export const SlideshowSlideValidation = z.object({
    id: z.number(),
    errors: z.array(z.literal('emptySlideCredit')),
});

export type SlideshowSlideValidation = z.infer<typeof SlideshowSlideValidation>;

import { parseUrl, toURL } from '@@utils/URL';

// supported url
// https://www.youtube.com/embed/...
export const isYoutubeUrl = (url) => {
    const parsedUrl = toURL(url);

    if (parsedUrl instanceof URL) {
        return /youtube\.com/.test(parsedUrl.hostname);
    }
};

export const isYoutubeEmbedCode = (code) => {
    const url = parseYoutubeEmbedCode(code);

    if (url instanceof URL) {
        return true;
    }
};

export const parseYoutubeEmbedCode = (code) => {
    const el = document.createElement('div');

    el.innerHTML = code;

    const [iframe] = el.getElementsByTagName('iframe');

    if (iframe instanceof HTMLElement) {
        const src = iframe.getAttribute('src').replace(/[“”]/g, '');

        const url = parseUrl(src);

        if (isYoutubeUrl(url)) {
            return url;
        }
    }
};

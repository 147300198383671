import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, styled } from '@mui/material';
import React from 'react';

import SimpleRichTextEditorField from '@@form/fields/RichTextEditor/SimpleRichTextEditor';
import Spacer from '@@components/Spacer';
import makeState from '@@editor/utils/makeState';
import Icon from '@@components/Icon';

const Answer = styled('div')({
    position: 'relative',
});

const RemoveButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: 0,
    top: theme.spacing(4),
    zIndex: theme.zIndex.layer3,
}));

type Props = {
    fields: any;
    name?: string;
    append: (value) => void;
    remove: (index) => void;
};

const AnswersArrayField = ({ fields, name, append, remove }: Props) => {
    const { t } = useTranslation();

    const handleAddAnswer = () => append([makeState()]);

    const handleRemoveAnswer = (index) => remove(index);

    return (
        <>
            {fields.map((field, index) => {
                const { id, ...value } = field;

                // We need to format the value of the field here because react-hook-form
                // somehow transform our array-based value into object-based for fieldArrays
                const formattedValue = map(value);

                return (
                    <Answer key={id}>
                        <SimpleRichTextEditorField
                            name={`${name}.${index}`}
                            defaultValue={formattedValue}
                            label={t('editor.plugin.layout.form.answer')}
                            minRows={2}
                            required
                        />

                        {fields.length > 1 && (
                            <RemoveButton
                                title={t('editor.plugin.layout.form.removeButton')}
                                onClick={() => handleRemoveAnswer(index)}
                            >
                                <Icon name="trash-can" />
                            </RemoveButton>
                        )}

                        <Spacer v md />
                    </Answer>
                );
            })}

            <Spacer v md />

            <Button
                startIcon={<Icon name="plus-sharp" />}
                variant="outlined"
                onClick={handleAddAnswer}
            >
                {t('editor.plugin.layout.form.addButton')}
            </Button>
        </>
    );
};

export default AnswersArrayField;

import {
    AppRouteQuery,
    AppRouter,
    ClientArgs,
    ClientInferRequest,
    Without,
    isAppRoute,
} from '@ts-rest/core';

type GetQueryKeyReturn<Router extends AppRouteQuery> = [
    string,
    Record<string, string | number>,
    ClientInferRequest<Router>['params'],
    ClientInferRequest<Router>['query'],
];

/**
 * Generate a standardized cache key for a query route
 * @param route The query route to generate the key for.
 * @param clientArgs The base arguments used for the client initialization.
 * @param params The parameters for the route. Contains path parameters and query parameters.
 * @returns The query key for the route.
 */
const getQueryKey = <Router extends AppRouteQuery, TClientArgs extends ClientArgs>(
    route: Router,
    clientArgs: TClientArgs,
    { params, query, headers }: Pick<ClientInferRequest<Router>, 'params' | 'query' | 'headers'>,
): GetQueryKeyReturn<Router> => {
    const baseUrl = new URL(clientArgs.baseUrl);
    const path = baseUrl.href + route.path;
    const httpHeaders = {};

    if (clientArgs.baseHeaders && 'x-unity-content-language' in clientArgs.baseHeaders) {
        Object.assign(httpHeaders, {
            'x-unity-content-language': clientArgs.baseHeaders['x-unity-content-language'],
            ...headers,
        });
    }

    return [path, httpHeaders, params, query];
};

type GetQueryKeysReturn<Router extends AppRouter, TClientArgs extends ClientArgs> = Without<
    {
        [TKey in keyof Router]: Router[TKey] extends AppRouter
            ? GetQueryKeysReturn<Router[TKey], TClientArgs>
            : Router[TKey] extends AppRouteQuery
              ? // if the route has both query and params, we need to pass both
                'query' | 'params' extends keyof ClientInferRequest<Router[TKey]>
                  ? (
                        args: Pick<ClientInferRequest<Router[TKey]>, 'query' | 'params'> & {
                            headers?: Partial<ClientInferRequest<Router[TKey]>['headers']>;
                        },
                    ) => ReturnType<typeof getQueryKey<Router[TKey], TClientArgs>>
                  : // if the route has only query, we only need to pass query
                    'query' extends keyof ClientInferRequest<Router[TKey]>
                    ? (
                          args: Pick<ClientInferRequest<Router[TKey]>, 'query'> & {
                              headers?: Partial<ClientInferRequest<Router[TKey]>['headers']>;
                          },
                      ) => ReturnType<typeof getQueryKey<Router[TKey], TClientArgs>>
                    : // if the route has only params, we only need to pass params
                      'params' extends keyof ClientInferRequest<Router[TKey]>
                      ? (
                            args: Pick<ClientInferRequest<Router[TKey]>, 'params'> & {
                                headers?: Partial<ClientInferRequest<Router[TKey]>['headers']>;
                            },
                        ) => ReturnType<typeof getQueryKey<Router[TKey], TClientArgs>>
                      : () => ReturnType<typeof getQueryKey<Router[TKey], TClientArgs>>
              : never;
    },
    never
>;

/**
 * Generates query key functions for all the "query" routes of a client.
 * @param router The router to generate the keys from.
 * @param clientArgs The base arguments used for the client initialization.
 * @returns An object following the same structure as the router, containing
 * only the query routes, with a function for creating the cache key.
 */
export const getQueryKeys = <Router extends AppRouter, TClientArgs extends ClientArgs>(
    router: Router,
    clientArgs: TClientArgs,
): GetQueryKeysReturn<Router, TClientArgs> => {
    // We first recursively map over all the routes and subroutes of the router
    // to generate their respective keys.
    const allKeyMaps = Object.entries(router).map(([routeName, route]) => {
        if (isAppRoute(route)) {
            // We only need to generate a key for queries, not for mutations.
            // If the route is a mutation, we assign null, so we can filter it out later.
            if (route.method === 'GET') {
                return [
                    routeName,
                    (
                        args?: Pick<
                            ClientInferRequest<typeof route>,
                            'params' | 'query' | 'headers'
                        >,
                    ) =>
                        getQueryKey(route, clientArgs, {
                            params: args?.params || {},
                            query: args?.query,
                            headers: args?.headers || {},
                        }),
                ];
            }

            return [routeName, null];
        }

        return [routeName, getQueryKeys(route, clientArgs)];
    });

    // We filter out the null values, as they are mutations.
    const queryKeyMaps = allKeyMaps.filter(([, value]) => value !== null);

    // We recreate an object from the array of key-value pairs
    // so it's easily accessible.
    return Object.fromEntries(queryKeyMaps);
};

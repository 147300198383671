import { RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        index: true,
        lazy: async () => {
            const { TickerList } = await import(
                /* webpackChunkName: "tickerList" */ '@@routes/ticker/TickerList'
            );

            return { Component: TickerList };
        },
    },
    {
        path: 'new',
        lazy: async () => {
            const { TickerSetupPage } = await import(
                /* webpackChunkName: "tickerSetupPage" */ '@@routes/ticker'
            );

            return { Component: TickerSetupPage };
        },
    },
    {
        path: ':id/*',
        lazy: async () => {
            const { TickerDetailPage } = await import(
                /* webpackChunkName: "tickerDetailPage" */ '@@routes/ticker'
            );

            return { Component: TickerDetailPage };
        },
    },
];

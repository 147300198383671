import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

import Icon from '@@components/Icon';
import Spacer from '@@components/Spacer';

import RadioGroupField from './RadioGroup';

const StyledIcon = styled(Icon)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    verticalAlign: 'middle',
}));

type Props = {
    value: string;
    croppingRatio: string;
};

const AspectRatio = ({ value, croppingRatio }: Props) => {
    const showLockIcon = Boolean(value);
    const { t } = useTranslation();
    const cropRatio = t('metaform.teaser.modal.croppingRatio');

    return (
        <RadioGroupField
            name="aspect"
            defaultValue=""
            label={
                <>
                    {cropRatio}: {croppingRatio}
                    {showLockIcon && (
                        <>
                            <Spacer sm h />
                            <Icon name="lock" />
                        </>
                    )}
                </>
            }
            fields={[
                {
                    value: '',
                    label: t('metaform.teaser.modal.noPreset'),
                },
                {
                    value: '2',
                    label: (
                        <>
                            <span>2:1</span>
                            <StyledIcon
                                name="image-landscape-sharp"
                                size="xlarge"
                                color="primary"
                            />
                        </>
                    ),
                },
            ]}
        />
    );
};

export default AspectRatio;

import React from 'react';
import { FieldValues } from 'react-hook-form';

import QuoteEditor from '@@form/components/RichTextEditor/QuoteEditor';

import RichTextField, { RichTextFieldProps } from './RichTextField';

export const QuoteEditorField = <TFieldValues extends FieldValues = FieldValues>(
    props: RichTextFieldProps<TFieldValues>,
) => <RichTextField {...props} component={QuoteEditor} />;

export default QuoteEditorField;

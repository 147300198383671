import { createElement } from '@@editor/serialization/UnitySerializer/deserializeNodes';
import { Editor, Element } from '@@editor/helpers';
import {
    ELEMENT_TYPES,
    ElementType,
    InterviewSegmentElement,
    ParagraphElement,
} from '@@editor/helpers/Element';
import toText from '@@editor/utils/toText';

type ProcessedSegment = {
    question: ParagraphElement | null;
    answers: ParagraphElement[];
};

type processedItem = {
    type: ElementType;
    item: ParagraphElement | ProcessedSegment;
    marked?: boolean;
};

const PREFIX_QUESTION = 'Q: ';
const PREFIX_ANSWER = 'A: ';

export const cleanupItem = (item: ParagraphElement): ParagraphElement => {
    const cleanItemText = toText(item).substring(3);

    return {
        ...item,
        children: [{ text: cleanItemText }],
    };
};

export const processElements = (
    editor: Editor,
    elements: Element[],
): (ParagraphElement | InterviewSegmentElement)[] => {
    let processedSegment: ProcessedSegment = {
        question: null,
        answers: [],
    };

    // These are some helpers to avoid repetition. They are having side effects but are
    // only used locally inside the current function.
    const processedElements: processedItem[] = [];
    const pushProcessedParagraph = (item, marked = false) =>
        processedElements.push({ type: ELEMENT_TYPES.PARAGRAPH, item, marked });
    const pushProcessedInterviewSegment = (item) =>
        processedElements.push({ type: ELEMENT_TYPES.INTERVIEW_SEGMENT, item });
    const pushProcessedInterviewError = (item) => pushProcessedParagraph(item, true);
    const cleanupProcessedSegment = () => {
        if (processedSegment.question) {
            if (processedSegment.answers.length === 0) {
                pushProcessedInterviewError(processedSegment.question);
            } else {
                pushProcessedInterviewSegment(processedSegment);
            }
        }
        processedSegment = {
            question: null,
            answers: [],
        };
    };

    elements.forEach((element) => {
        // We want to process all the text elements except the empty ones
        if (Element.isParagraphElement(element) && !Element.isEmptyParagraphElement(element)) {
            const text = toText(element);

            if (text.startsWith(PREFIX_QUESTION)) {
                // If there is no answers in the processed segment it means either it's the first question
                // or the previous question doesn't have answers and should be marked as interview error
                if (processedSegment.answers.length === 0) {
                    // No question in the processed Segment means it's the first
                    if (!processedSegment.question) {
                        processedSegment.question = element;
                    } else {
                        pushProcessedInterviewError(processedSegment.question);
                    }
                } else {
                    // Else it means we are dealing with a new segment and we can "save" the previous one
                    pushProcessedInterviewSegment(processedSegment);
                }

                // and reset the processed one with the new question
                processedSegment = {
                    question: element,
                    answers: [],
                };
            } else if (text.startsWith(PREFIX_ANSWER)) {
                // We need to be sure a question was asked before getting answers
                if (processedSegment.question) {
                    processedSegment.answers.push(element);
                } else {
                    // Else we need to mark it as interview error, as it needs a question first
                    pushProcessedInterviewError(element);
                }
            } else {
                // If there is a paragraph inserted we want to cleanup the current interview segment.
                // Else, a paragraph added between two answers would jump above the previous question.
                cleanupProcessedSegment();
                pushProcessedParagraph(element);
            }
        }
    });

    // At the end of the loop, we check if there is any open segment and add or mark it
    cleanupProcessedSegment();

    return processedElements.map((element) => {
        if (element.type === ELEMENT_TYPES.INTERVIEW_SEGMENT) {
            const interviewElement = element.item as ProcessedSegment;

            const strippedQuestion = [cleanupItem(interviewElement.question as ParagraphElement)];
            const strippedAnswers = interviewElement.answers.map((answer) => [cleanupItem(answer)]);

            return createElement<InterviewSegmentElement>(
                element.type,
                {
                    question: strippedQuestion,
                    answers: strippedAnswers,
                },
                { useInlineEditing: editor.useInlineEditing },
            );
        }

        return Element.create({
            ...element.item,
            data: {
                displayError: element.marked,
            },
        }) as ParagraphElement;
    });
};

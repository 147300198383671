export const string = (value) => {
    if (value != null) {
        return String(value);
    }
};

export const number = (value) => {
    if (value === '') {
        return null;
    } else if (value != null) {
        return Number(value);
    }
};

export const bool = (value) => {
    if (value != null) {
        return Boolean(value);
    }
};

import { UnityState } from '@@editor/typings/UnityElements';

import { SCHEMA } from './../constants';
import isSerializedState from './isSerializedState';

const wrapSerializedState = (state: any): UnityState => {
    if (state == null || isSerializedState(state)) {
        return state;
    }

    if (Array.isArray(state)) {
        return {
            [SCHEMA.UNITY]: state,
        };
    }

    return {
        [SCHEMA.UNITY]: [state],
    };
};

export default wrapSerializedState;

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import { styled } from '@mui/material';

import { BaseWrapper, StatusText } from './styles';

export const Unit = {
    BYTES: 'BYTES',
    FILES: 'FILES',
} as const;

type Unit = ValueOf<typeof Unit>;

const Wrapper = styled(BaseWrapper)<{ $progress: number }>(({ theme, $progress }) => ({
    background: `linear-gradient(
        to right,
        ${theme.palette.success['100']} ${$progress}%,
        transparent ${$progress}%
    ) no-repeat`,
}));

const Uploading = styled('div')(({ theme }) => ({
    ...theme.typography.title2,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
}));

type Props = {
    className?: string;
    progress: number;
    done: number;
    total: number;
    style?: React.CSSProperties;
    unit?: Unit;
};

const Progress = (props: Props) => {
    const { className, done, total, progress, unit = Unit.FILES, style } = props;
    const { t } = useTranslation();

    // Translation keys for all available units
    // t('fileUploadDropArea.loading.status.bytes')
    // t('fileUploadDropArea.loading.status.files')
    const translatedUnit = t(`fileUploadDropArea.loading.status.${camelCase(unit)}`);

    return (
        <Wrapper $progress={progress} className={className} role="progressbar" style={style}>
            <Uploading>{t('fileUploadDropArea.loading.title')}</Uploading>

            <StatusText>
                {/* Translation key for Trans component
                 * t('fileUploadDropArea.loading.status')
                 */}
                <Trans
                    i18nKey="fileUploadDropArea.loading.status"
                    values={{
                        done: done.toLocaleString(),
                        total: total.toLocaleString(),
                        progress: Math.round(progress),
                        unit: translatedUnit,
                    }}
                />
            </StatusText>
        </Wrapper>
    );
};

export default Progress;

import React from 'react';
import { FieldValues } from 'react-hook-form';

import TextInput, { TextInputProps } from '@@form/components/TextInput';
import useFormField, { UseFormFieldProps } from '@@form/hooks/useFormField';

export type TextInputFieldProps<TFieldValues extends FieldValues = FieldValues> = Omit<
    TextInputProps,
    'onChange'
> &
    UseFormFieldProps<TFieldValues>;

const TextInputField = <TFieldValues extends FieldValues = FieldValues>(
    props: TextInputFieldProps<TFieldValues>,
) => {
    const {
        name,
        validate,
        novalidate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
        ...rest
    } = props;

    const { ref, value, ...formFieldProps } = useFormField<TFieldValues>({
        name,
        novalidate,
        validate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
    });

    const controlledValue = value || '';

    return (
        <TextInput
            inputRef={ref}
            {...formFieldProps}
            value={controlledValue}
            required={required}
            fullWidth
            {...rest}
        />
    );
};

export default TextInputField;

import React from 'react';

import { FacebookElement } from '@@editor/helpers/Element';

import FacebookEmbed from './FacebookEmbed';

type Props = {
    element: FacebookElement;
};

const FacebookPost = ({ element }: Props) => (
    <FacebookEmbed element={element}>
        {({ src, ...rest }) => <div {...rest} className="fb-post" data-href={src} />}
    </FacebookEmbed>
);

export default FacebookPost;

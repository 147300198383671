type Config = {
    [key: string]: (element) => any;
};
class ElementCounter {
    prevElementType = null;
    successiveTypeCounter = 0;
    overallCounter: any = {};
    config: Config = {};

    constructor(config?) {
        this.config = config || { types: (element) => element.type };
    }

    count(element) {
        if (this.prevElementType === element.type) {
            this.successiveTypeCounter++;
        } else {
            this.successiveTypeCounter = 1;
        }

        this.prevElementType = element.type;

        Object.entries(this.config).forEach(([key, value]) => {
            const subKey = value(element);

            if (typeof subKey === 'string') {
                if (!(key in this.overallCounter)) {
                    this.overallCounter[key] = {};
                }

                if (subKey) {
                    if (!(subKey in this.overallCounter[key])) {
                        this.overallCounter[key][subKey] = 0;
                    }

                    this.overallCounter[key][subKey] += 1;
                }
            } else {
                if (!(key in this.overallCounter)) {
                    this.overallCounter[key] = 0;
                }

                if (subKey) {
                    this.overallCounter[key] += 1;
                }
            }
        });

        return {
            overallCount: this.overallCounter,
            successiveTypeCount: this.successiveTypeCounter,
        };
    }
}

export default ElementCounter;

export const ActionTypes = {
    REGISTER: 'REGISTER',
    UNREGISTER: 'UNREGISTER',
} as const;

export type ActionType = ValueOf<typeof ActionTypes>;

type BaseAction<T extends ActionType> = {
    type: T;
    id: Id;
};

export type RegisterAction = BaseAction<typeof ActionTypes.REGISTER> & {
    config: Partial<Config>;
};

export type UnregisterAction = BaseAction<typeof ActionTypes.UNREGISTER>;

export type Action = RegisterAction | UnregisterAction;

export type WhenFunction = (...args: any[]) => Id | void;
export type BeforeConfirmFunction = (result: Id, callback: VoidFunction) => void;

export type State = {
    configs: Config[];
    when: WhenFunction;
    beforeConfirm: BeforeConfirmFunction;
};

export type Config = {
    id: Id;
    when: (...args: any[]) => boolean;
    beforeConfirm: () => void | boolean | undefined;
};

type UnregisterFunction = VoidFunction;
type RegisterFunction = (config: Partial<Config>) => UnregisterFunction;
export type ActionFunctions = {
    register: RegisterFunction;
};

export type LeavePromptContextType = { state: State; actions: ActionFunctions };

/* eslint-disable no-inline-comments, react/jsx-key */
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from 'react-router-dom';
import React from 'react';

import '@@lib/i18n/i18n';

import { useSelector } from '@@store/hooks';
import PrivateRoute from '@@router/components/PrivateRoute';
import { getReducedUISmartSetting } from '@@settings/settingsSlice';
import isolatedRouteConfig from '@@routes/isolated/config';
import config, { NavigationItem } from '@@config';
import ModalProvider from '@@containers/Modal/useModal/ModalProvider';
import { routes as articleRoutes } from '@@routes/articles/routes';
import { routes as placementRoutes } from '@@routes/placement/routes';
import { routes as breakingnewsRoutes } from '@@routes/breakingnews/routes';
import { routes as slideshowRoutes } from '@@routes/slideshow/routes';
import { routes as videoRoutes } from '@@routes/externalContent/videos/routes';
import { routes as embedsRoutes } from '@@routes/externalContent/embeds/routes';
import { routes as linksRoutes } from '@@routes/externalContent/links/routes';
import { routes as tickerRoutes } from '@@routes/ticker/routes';
import { routes as categoriesRoutes } from '@@routes/categories/routes';
import { routes as teamsRoutes } from '@@routes/teams/routes';
import { routes as tagsRoutes } from '@@routes/tags/routes';
import { routes as newslettersRoutes } from '@@routes/newsletters/routes';
import { routes as kickwordsRoutes } from '@@routes/kickwords/routes';
import { routes as sitemapRoutes } from '@@routes/sitemap/routes';
import { routes as collectionsRoutes } from '@@routes/collections/routes';
import { routes as releasenotesRoutes } from '@@routes/releasenotes/routes';
import { routes as maintenancenotesRoutes } from '@@routes/maintenancenotes/routes';
import { routes as previewRoutes } from '@@routes/preview/routes';
import { routes as metadataHistoryRoutes } from '@@routes/metadataHistory/routes';
import { routes as embedRoutes } from '@@routes/embed/routes';
import { routes as userSettingsRoutes } from '@@routes/userSettings/routes';
import { routes as usersRoutes } from '@@routes/users/routes';
import { routes as editionsRoutes } from '@@routes/editions/routes';
import { routes as curatedListRoutes } from '@@routes/curatedList/routes';
import { routes as rtElementsRoutes } from '@@routes/rtElements/routes';
import { routes as homeRoutes } from '@@routes/home/routes';
import { routes as isolatedRoutes } from '@@routes/isolated/routes';
import { routes as editorRoutes } from '@@routes/editor/routes';
import Loader from '@@components/Loader';

import { ErrorPage } from './ErrorPage';
import AppWrapper from './AppWrapper';

export const AppRouter = () => {
    const reducedUI = useSelector(getReducedUISmartSetting);
    const nav = Object.entries(config.navigation).reduce<Record<string, NavigationItem>>(
        (previousValue, [key, value]) => ({
            ...previousValue,
            [key]: {
                ...value,
                path: `${value.uri}/*`,
                forceErrorCode: reducedUI && value.excludeFromReducedUI ? '404' : null,
            },
        }),
        {},
    );

    const subRoutes: RouteObject[] = [
        {
            element: <PrivateRoute />,
            children: homeRoutes,
        },
        {
            path: 'placement',
            element: <PrivateRoute {...nav.placement} />,
            children: placementRoutes,
        },
        {
            path: 'breakingnews',
            element: <PrivateRoute {...nav.breakingnews} />,
            children: breakingnewsRoutes,
        },
        {
            path: 'slideshows',
            element: <PrivateRoute {...nav.slideshows} />,
            children: slideshowRoutes,
        },
        {
            path: 'externalContent/videos',
            element: <PrivateRoute />,
            children: videoRoutes,
        },
        {
            path: 'externalContent/embeds',
            element: <PrivateRoute />,
            children: embedsRoutes,
        },
        {
            path: 'externalContent/links',
            element: <PrivateRoute />,
            children: linksRoutes,
        },
        {
            path: 'articles',
            element: <PrivateRoute {...nav.articles} />,
            children: articleRoutes,
        },
        {
            path: 'tickers',
            element: <PrivateRoute {...nav.tickers} />,
            children: tickerRoutes,
        },
        {
            path: 'categories',
            element: <PrivateRoute {...nav.categories} />,
            children: categoriesRoutes,
        },
        {
            path: 'teams',
            element: <PrivateRoute {...nav.teams} />,
            children: teamsRoutes,
        },
        {
            path: 'tags',
            element: <PrivateRoute {...nav.tags} />,
            children: tagsRoutes,
        },
        {
            path: 'newsletters',
            element: <PrivateRoute {...nav.newsletters} />,
            children: newslettersRoutes,
        },
        {
            path: 'kickwords',
            element: <PrivateRoute {...nav.kickwords} />,
            children: kickwordsRoutes,
        },
        {
            path: 'sitemaps',
            element: <PrivateRoute {...nav.sitemaps} />,
            children: sitemapRoutes,
        },
        {
            path: 'collections',
            element: <PrivateRoute {...nav.collections} />,
            children: collectionsRoutes,
        },
        {
            path: 'releasenotes',
            element: <PrivateRoute {...nav.releasenotes} />,
            children: releasenotesRoutes,
        },
        {
            path: 'maintenancenotes',
            element: <PrivateRoute {...nav.maintenancenotes} />,
            children: maintenancenotesRoutes,
        },
        {
            path: 'preview',
            element: <PrivateRoute />,
            children: previewRoutes,
        },
        {
            path: 'metadata/:metadataId/history',
            element: <PrivateRoute />,
            children: metadataHistoryRoutes,
        },
        {
            path: 'embed',
            element: <PrivateRoute />,
            children: embedRoutes,
        },
        {
            path: 'userSettings',
            element: <PrivateRoute />,
            children: userSettingsRoutes,
        },
        {
            path: 'users',
            element: <PrivateRoute {...nav.users} />,
            children: usersRoutes,
        },
        {
            path: 'editions',
            element: <PrivateRoute {...nav.editions} />,
            children: editionsRoutes,
        },
        {
            path: 'curatedList',
            element: <PrivateRoute {...nav.curatedList} />,
            children: curatedListRoutes,
        },
        {
            path: 'rtelements',
            element: <PrivateRoute {...nav.rtelements} />,
            children: rtElementsRoutes,
        },
        {
            path: 'login',
            lazy: async () => {
                const { Login } = await import(/* webpackChunkName: "login" */ '@@auth/Login');

                return { Component: Login };
            },
        },
        {
            path: '403',
            lazy: async () => {
                const { Error403 } = await import(
                    /* webpackChunkName: "error403" */ '@@containers/App/Error403'
                );

                return { Component: Error403 };
            },
        },
        {
            path: '404',
            lazy: async () => {
                const { Error404 } = await import(
                    /* webpackChunkName: "error404" */ '@@containers/App/Error404'
                );

                return { Component: Error404 };
            },
        },
        {
            path: '*',
            element: <Navigate to="/404" />,
        },
    ];

    if (config.isVisualTest || config.nodeEnv === 'development') {
        const isolatedRoute = {
            path: isolatedRouteConfig.path,
            element: <PrivateRoute />,
            children: isolatedRoutes,
        };

        subRoutes.push(isolatedRoute);
    }

    if (config.env !== 'production') {
        const editorRoute: RouteObject = {
            path: 'editor',
            element: <PrivateRoute />,
            children: editorRoutes,
        };

        subRoutes.push(editorRoute);
    }

    const routes: RouteObject[] = [
        {
            path: '/',
            element: (
                // `ModalProvider` needs to be a child of the router since some modals use hooks which depend on it
                <ModalProvider>
                    <AppWrapper />
                </ModalProvider>
            ),
            errorElement: <ErrorPage />,
            children: subRoutes,
        },
    ];

    const router = createBrowserRouter(routes);

    return <RouterProvider router={router} fallbackElement={<Loader />} />;
};

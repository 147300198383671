import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogContent, DialogTitle, Dialog } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import keycode from 'keycode';

import SubmitButton from '@@components/buttonImplementations/SubmitButton';
import Icon from '@@components/Icon';
import ButtonGroup from '@@form/components/ButtonGroup';

import { ModalCloseEventSource, ModalProps } from './types';

const Modal = ({
    type,
    title,
    children,
    isLoading,
    isOpen = true,
    onClose = noop,
    onDelete = noop,
    onConfirm = noop,
    cancelButtonText,
    confirmButtonText,
    reverseButtonOrder,
    renderTitle,
    ...rest
}: ModalProps) => {
    const { t } = useTranslation();

    const handleWindowKeyDown = (e) => {
        if (type && isOpen && keycode(e.keyCode) === 'enter') {
            e.preventDefault();

            if (type === 'info' || type === 'confirm') {
                onConfirm();
            } else if (type === 'delete') {
                onDelete();
            }
        }
    };

    const renderButtons = (type) => {
        if (type === 'info') {
            return (
                <ButtonGroup>
                    <SubmitButton disabled={isLoading} loading={isLoading} onClick={onConfirm}>
                        {confirmButtonText || t('modal.confirmButton')}
                    </SubmitButton>
                </ButtonGroup>
            );
        } else if (type === 'confirm' || type === 'delete') {
            return (
                <ButtonGroup>
                    {!reverseButtonOrder && (
                        <Button
                            // Currently we can not cancel an ongoing request, so the UI
                            // should not be able to do it too.
                            disabled={isLoading}
                            variant="outlined"
                            onClick={() => onClose(ModalCloseEventSource.CANCEL_BUTTON)}
                            autoFocus
                        >
                            {cancelButtonText || t('modal.cancelButton')}
                        </Button>
                    )}

                    {type === 'delete' ? (
                        <LoadingButton
                            startIcon={<Icon name="trash-can" />}
                            color="error"
                            loading={isLoading}
                            variant="contained"
                            onClick={onDelete}
                        >
                            {confirmButtonText || t('modal.deleteButton')}
                        </LoadingButton>
                    ) : (
                        <SubmitButton loading={isLoading} onClick={onConfirm}>
                            {confirmButtonText || t('modal.confirmButton')}
                        </SubmitButton>
                    )}
                    {reverseButtonOrder && (
                        <Button
                            // Currently we can not cancel an ongoing request, so the UI
                            // should not be able to do it too.
                            disabled={isLoading}
                            variant="outlined"
                            onClick={() => onClose(ModalCloseEventSource.CANCEL_BUTTON)}
                            autoFocus
                        >
                            {cancelButtonText || t('modal.cancelButton')}
                        </Button>
                    )}
                </ButtonGroup>
            );
        }

        return null;
    };

    return (
        <Dialog
            open={isOpen}
            aria-labelledby={title}
            onClose={() => {
                onClose();
            }}
            onKeyDown={handleWindowKeyDown}
            {...rest}
        >
            {renderTitle ? renderTitle() : <DialogTitle variant="title3">{title}</DialogTitle>}
            <DialogContent>{children}</DialogContent>
            {type && <DialogActions>{renderButtons(type)}</DialogActions>}
        </Dialog>
    );
};

export default Modal;

import { parseUrl, toURL } from '@@utils/URL';

const document = window.document;

let fbApiInit = false;

const handleFbCallbacks = () => {
    if (!window._fbCallbacks) {
        window._fbCallbacks = [];
        window._fbCallbacks.push = function (...args) {
            if (fbApiInit) {
                // If the facebook sdk is available already, execute the callbacks right away
                args.forEach((cb) => cb());
            } else {
                // If the facebook sdk is not available yet, wait with the callbacks execution
                Array.prototype.push.apply(this, args);
            }

            return this.length;
        };
    }

    if (!window.fbAsyncInit) {
        // As soon as facebook sdk has been initialized, run all `window._fbCallbacks` in order
        window.fbAsyncInit = () => {
            while (window._fbCallbacks.length) {
                window._fbCallbacks.shift()();
            }

            fbApiInit = true;
        };
    }
};

// https://developers.facebook.com/docs/javascript/quickstart
const insertScript = (locale, { appId, version }) =>
    new Promise((resolve, reject) => {
        let js = document.getElementById('facebook-jssdk');

        if (!js) {
            const firstScript = document.querySelector('script');

            js = document.createElement('script');
            js.id = 'facebook-jssdk';
            js.async = true;
            // In order to avoid race conditions, supply sdk parameters here already instead of
            // using `FB.init`.
            js.src = `https://connect.facebook.net/${locale}/sdk.js#version=${version}&appId=${appId}&xfbml=true&autoLogAppEvents=true"`;
            js.onload = () => resolve(js);
            js.onerror = (err) => reject(new Error(`Failed to load ${err.target.src}`));

            // skipping if in test environment
            if (firstScript) {
                firstScript.parentNode.insertBefore(js, firstScript);
            }
        } else {
            resolve(js);
        }
    });

const insertRootElement = () => {
    // Ensure that there is an element called fb-root according to Facebook requirement
    if (!document.getElementById('fb-root')) {
        const fbRoot = document.createElement('div');

        fbRoot.id = 'fb-root';
        document.body.appendChild(fbRoot);
    }
};

export const importSdk = ({ appId, version, locale = 'en' }) => {
    handleFbCallbacks();
    insertRootElement();

    return insertScript(locale, { appId, version });
};

export const isFacebookUrl = (value) => {
    const url = toURL(value);

    if (url instanceof URL) {
        return /facebook\.com/.test(url.hostname);
    }
};

export const isFacebookEmbedCode = (code) => {
    const url = parseFacebookEmbedCode(code);

    if (url instanceof URL) {
        return true;
    }
};

export const parseFacebookEmbedCode = (code) => {
    const el = document.createElement('div');

    el.innerHTML = code;

    // this currently allows facebook embeds to only work when dragging/pasting
    // the embed code, not the url
    const [iframe] = el.getElementsByTagName('iframe');

    if (iframe instanceof HTMLElement) {
        const url = parseUrl(iframe.getAttribute('src'));

        if (isFacebookUrl(url)) {
            return url;
        }
    }
};

import { RenderLeafProps } from 'slate-react';
import React from 'react';
import { Theme, styled, alpha } from '@mui/material';

import { Editor } from '@@editor/helpers';
import { type SpellAdviceType } from '@@api/services/spellChecker/schemas';

import { isSpellAdviceSelected } from '../utils/spellAdvices';
import { isEditorDistractionFree } from '../utils/data';
import { EditorSpellAdvice } from '../types';

type Props = RenderLeafProps & {
    editor: Editor;
    leaf: RenderLeafProps['leaf'] & {
        spellAdvice: EditorSpellAdvice;
    };
};

const getColor = (palette: Theme['palette'], type: SpellAdviceType) =>
    ({
        SPELLING: palette.error.main,
        GRAMMATICAL: palette.success.main,
        TYPOGRAPHICAL: palette.secondary.main,
        OTHER: palette.warning.main,
    })[type];

const StyledSpellAdviceLeaf = styled('span')<{ $isActive: boolean; $type: SpellAdviceType }>(
    ({ $isActive, $type, theme }) => ({
        textDecoration: 'underline',
        textDecorationColor: getColor(theme.palette, $type),
        textDecorationThickness: theme.borders[1],
        textDecorationStyle: 'wavy',
        textDecorationSkipInk: 'none',
        textUnderlineOffset: '3px',
        backgroundColor: $isActive
            ? alpha(getColor(theme.palette, $type), theme.opacityFactors.low)
            : undefined,
    }),
);

const SpellAdviceLeaf = (props: Props) => {
    const {
        attributes,
        children,
        editor,
        leaf: { spellAdvice },
    } = props;

    const isSelected = isSpellAdviceSelected(editor, spellAdvice);
    const isDistractionFree = isEditorDistractionFree(editor);

    if (isDistractionFree) {
        return (
            <span {...attributes} aria-haspopup="dialog" role="mark">
                {children}
            </span>
        );
    }

    return (
        <StyledSpellAdviceLeaf
            {...attributes}
            $isActive={isSelected}
            $type={spellAdvice.type}
            aria-haspopup="dialog"
            role="mark"
        >
            {children}
        </StyledSpellAdviceLeaf>
    );
};

export default SpellAdviceLeaf;

const renderEditor = (editor, fn, options = {}) => {
    const { renderEditor = (props) => props.children } = editor;

    return (props) => {
        const renderedEditor = fn({ editor, ...props, ...options });

        return renderEditor({ ...props, children: renderedEditor });
    };
};

export default renderEditor;

// https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Recommended_drag_types#dragging_links
const getUrlsFromDataTransfer = (dataTransfer: DataTransfer) => {
    if (dataTransfer.types.includes('text/uri-list')) {
        const urls = dataTransfer
            .getData('text/uri-list')
            .split('\r\n')
            .filter((uri) => !uri.startsWith('#') && !uri.startsWith('file://'));

        if (urls.length) {
            return urls;
        }
    }
};

export default getUrlsFromDataTransfer;

import { noop } from 'lodash';
import { Path, Point, Range } from 'slate';

import crypto from '@@utils/crypto';
import { Operation } from '@@editor/helpers';

import { TextRange } from '../types';
import TextRangeIdIndex from './TextRangeIdIndex';

export type Options<V extends TextRange = TextRange> = {
    onChange?: (map: TextRangeMap<V>) => void;
};

type Action = 'set' | 'delete' | 'clear' | 'transform';

// From the Slate.js docs: "The affinity refers to the direction the Range will go when a user inserts
// content at the edges of the Range. inward means that the Range tends to stay the same size when content
// is inserted at its edges, and outward means that the Range tends to grow when content is inserted at its
// edges."
// This function defines the affinity, according to the action the user is currently performing. We have tweaked
// this, in order to have an optimal UX: When the user is editing text which contains a spell advice, we want to
// be able to show the user as much as possible, and as quick as possible, a meaningful state.
// For example, if a word is marked as a spell advice, and the user adds text at the beginning, the word has been
// changed and the current spell advice cannot be used anymore (we would need to get an updated state from the api).
// But if the user reverts that change, before the next request to the api has been made, we will show the word
// marked as error again immediately. This only works if ranges are transformed correctly while modifying the
// editors content.
export const calculateTransformAffinity = <V extends TextRange>(operation: Operation, value: V) => {
    const { range, text } = value;

    if (operation.type === 'insert_text') {
        const start = Range.start(range);
        const end = Range.end(range);

        if (
            (Point.equals(start, operation) &&
                !operation.text.endsWith(' ') &&
                !text.startsWith(' ')) ||
            (Point.equals(end, operation) && !operation.text.startsWith(' ') && !text.endsWith(' '))
        ) {
            return 'outward';
        }
    }
};

const buildTransformedValue = <V extends TextRange>(value: V, operation: Operation) => {
    const range = Range.transform(value.range, operation, {
        affinity: calculateTransformAffinity(operation, value),
    });

    return range ? { ...value, range } : undefined;
};

// `TextRangeMap` makes it possible to retrieve text ranges more efficiently, by indexing them. If we have,
// for example a text range (a text range could be a spell advice for example) that spans
// from `{ path: [0, 7], offset: 21 }` until `{ path: [1, 14], offset: 7 }`, we will add the id of that
// text range to the index `0` and `1`. Now, if we know, that the document has been changed,
// but we also know there are only changes within the second block (for example when the user is writing text),
// we can easily retrieve all text ranges for only that block (instead of looping over all text ranges available
// for this document). If we know, that within a paragraph we might have 3 text ranges, but in the whole document
// maybe 100, this makes a huge difference!
// Combining this with `Editor.rangeRef` might have been a good thing, but we need to calculate the affinity,
// besides other things, on every transform. Something that Slate.js does not support.
class TextRangeMap<V extends TextRange = TextRange> extends Map<V['id'], V> {
    actions: Action[] = [];
    private idIndex = new TextRangeIdIndex<V['id']>();
    private isTransactionRunning = false;
    private onChange: Required<Options<V>>['onChange'];

    // `lastCommitHash` changes whenever a transaction is commited. Can be used for example
    // within `useEffect`-dependencies (be aware! Component will not rerender if this changes!)
    lastCommitHash = crypto.randomUUID();

    constructor(options: Options<V> = {}) {
        super();

        const { onChange = noop } = options;

        this.onChange = onChange;

        return this;
    }

    // Super fast key-value retrieval functions which use indexed data

    forEachByIndices(indices: number[], callback: (value: V, key: V['id']) => void) {
        this.idIndex.valuesByIndices(indices).forEach((id) => {
            const value = this.get(id);

            if (value) {
                callback(value, id);
            }
        });
    }

    findByRange(range: Range, callback: (value: V, key: V['id']) => boolean) {
        const id = this.idIndex.valuesByRange(range).find((id) => {
            const value = this.get(id);

            return typeof value !== 'undefined' && callback(value, id);
        });

        if (id) {
            return this.get(id);
        }
    }

    findLastByRange(range: Range, callback: (value: V, key: V['id']) => boolean) {
        const id = this.idIndex.valuesByRange(range).findLast((id) => {
            const value = this.get(id);

            return typeof value !== 'undefined' && callback(value, id);
        });

        if (id) {
            return this.get(id);
        }
    }

    // Mutation functions

    set(key: V['id'], value: V) {
        const prevValue = this.get(key);

        if (prevValue) {
            this.idIndex.removeByRange(prevValue.range, prevValue.id);
        }

        this.idIndex.addByRange(value.range, value.id);

        const returnValue = super.set(key, value);

        this.commit('set');

        return returnValue;
    }

    delete(key: V['id']) {
        const prevValue = this.get(key);

        if (prevValue) {
            this.idIndex.removeByRange(prevValue.range, prevValue.id);
        }

        const returnValue = super.delete(key);

        this.commit('delete');

        return returnValue;
    }

    clear() {
        this.idIndex.clear();

        super.clear();

        this.commit('clear');
    }

    // This function contains performance critical code! It is called on every keystroke.
    // Every text range item relates to a certain range within the editor. Whenever the editors
    // contents are changed, those ranges need to change (transform) accordingly. For example,
    // if we have a spell advice on path `[0, 1]`, and the user inserts an image on top, this path
    // needs to be transformed to `[1, 1]`
    transformByIndices(
        indices: number[],
        operation: Operation,
        callback: (newValue: V, oldValue: V, prevValue?: V, nextValue?: V) => V | undefined = (
            newValue,
            oldValue,
        ) => (!Range.equals(oldValue.range, newValue.range) ? newValue : undefined),
    ) {
        if (Operation.isSelectionOperation(operation)) {
            return;
        }

        let ids: V['id'][],
            prevTransformedValue: V | undefined,
            nextTransformedValue: V | undefined;

        if (Path.operationCanTransformPath(operation)) {
            // If we face an operation, which can transform a path, we will transform everything from the first
            // modified index downwards, because we cannot easily find out what were the actual real changes.
            // For example: moving a node from the bottom to the top, does not really change all the blocks
            // in-between, but it will change the paths for every single node in the document tree.

            let startIndex = operation.path[0];

            if (operation.type === 'move_node' && operation.newPath[0] < startIndex) {
                startIndex = operation.newPath[0];
            }

            ids = this.idIndex.valuesByStartIndex(startIndex);
        } else {
            // Otherwise we can easily just transform the changed blocks. Here happens the main performance gain when
            // it comes to indexing. This is the main reason, we've implemented this whole `TextRangeMap` thing
            ids = this.idIndex.valuesByIndices(indices);
        }

        let hasChanges = false;

        // Transform every spell advice affected by the current operation
        this.transaction(() => {
            ids.forEach((id, index) => {
                const value = this.get(id);

                if (value) {
                    // In order to enhance performance, we transform each text range item only once. Since we
                    // need the previous, current and the next text range, we need some logic here. Usually we only need
                    // to calculate the next text range item, since the previous one, anyways has already been
                    // transformed in a previous iteration and the current one we can re-use from iteration right
                    // before (living in `nextTransformedValue`). Only for the first iteration we will have no
                    // `nextTransformedValue`, therefore we need to transofrm both, the current and the next
                    // text range item.
                    const transformedValue =
                        index === 0
                            ? buildTransformedValue(value, operation)
                            : nextTransformedValue;

                    const nextValue = this.get(ids[index + 1]);

                    nextTransformedValue = nextValue && buildTransformedValue(nextValue, operation);

                    if (transformedValue) {
                        const returnValue = callback(
                            transformedValue,
                            value,
                            prevTransformedValue,
                            nextTransformedValue,
                        );

                        if (returnValue) {
                            hasChanges = true;

                            this.set(id, returnValue);

                            prevTransformedValue = returnValue;

                            return;
                        }

                        prevTransformedValue = value;

                        return;
                    }

                    // If, for some reasons, the text range item could not be transformed, it will be dropped

                    hasChanges = true;

                    this.delete(id);

                    prevTransformedValue = undefined;
                }
            });

            this.commit('transform');

            // Only commit this transaction, if there have been updates (performance).
            // Every commit will trigger the change handler of this `TextRangeMap`.
            return hasChanges;
        });
    }

    // Transaction functions
    // --
    // In order to enhance performance, we've introduced transactions. It's very simple: They avoid
    // calling `onChange` on every change. By using the `transaction` function, you can group changes
    // and only call `onChange` if this group of changes have been applied.

    private commit(action: Action | 'transaction') {
        if (action !== 'transaction') {
            this.actions.push(action);
        }

        if (!this.isTransactionRunning) {
            this.lastCommitHash = crypto.randomUUID();
            this.onChange(this);
            this.actions.length = 0;
        }
    }

    transaction(callback: () => void | boolean) {
        // If we are already inside a transaction, let the parent transaction handle the details. Therefore
        // we just execute and return here.
        if (this.isTransactionRunning) {
            callback();

            return;
        }

        this.isTransactionRunning = true;

        const returnValue = callback();

        this.isTransactionRunning = false;

        if (returnValue !== false) {
            this.commit('transaction');
        } else {
            this.actions.length = 0;
        }
    }
}

export default TextRangeMap;

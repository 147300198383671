import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { MetadataRouter, useMetadataClient } from '@@api/services/metadata/client';
import { RequestQuery } from '@@api/types';

const useFetchMetadata = () => {
    const queryClient = useQueryClient();
    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();

    const fetchMetadata = ({
        params,
    }: {
        params: RequestQuery<MetadataRouter['metadata']['getList']>;
    }) =>
        metadataClient.metadata.getList.fetchQuery(
            queryClient,
            metadataKeys.metadata.getList({ query: params }),
            {
                query: params,
            },
        );

    const memoizedFetchMetadata = useMemo(() => fetchMetadata, []);

    return memoizedFetchMetadata;
};

export default useFetchMetadata;

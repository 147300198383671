import React, { useMemo } from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { ThemeProvider, styled } from '@mui/material';

import { ELEMENT_TYPES, Element, ImageElement } from '@@editor/helpers/Element';
import { TYPES } from '@@constants/DragAndDrop';
import { Editor, ReactEditor } from '@@editor/helpers';
import inlineEditedImageTheme from '@@components/FileUploadDropArea/themes/inlineEditedImage';
import placeholderTheme from '@@components/FileUploadDropArea/themes/placeholder';
import FileDropArea from '@@components/FileUploadDropArea/FileDropArea';

import { allowedMimeTypes } from '../constants';
import { createGenerateEmbedFiles } from '../../utils/file';

const StyledFileDropArea = styled(FileDropArea)(({ theme }) => ({
    borderRadius: 0,
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    paddingBottom: theme.spacing(5),
}));

type Props = {
    children: false;
    editor: Editor;
    element: ImageElement;
    nodeType: typeof ELEMENT_TYPES.IMAGE;
};

const ImageDropArea = (props: Props) => {
    const { children, editor, element, nodeType } = props;

    const generateEmbedFiles = useMemo(() => createGenerateEmbedFiles({ nodeType }), []);

    const handleUpload = (images) => {
        const at = ReactEditor.findPath(editor, element);

        generateEmbedFiles(editor, images[0], {
            replace: true,
            at,
        });
    };

    const generateTheme = (theme) =>
        Element.isTemplateElement(element)
            ? placeholderTheme(theme)
            : inlineEditedImageTheme(theme);

    return (
        <ThemeProvider theme={generateTheme}>
            <StyledFileDropArea
                allowedMimeTypes={allowedMimeTypes}
                onDrop={handleUpload}
                onUpload={handleUpload}
                acceptTypes={[
                    NativeTypes.FILE,
                    NativeTypes.URL,
                    NativeTypes.HTML,
                    NativeTypes.TEXT,
                    TYPES.ATTACHMENT,
                ]}
                useChildrenHeight={false}
            >
                {children}
            </StyledFileDropArea>
        </ThemeProvider>
    );
};

export default ImageDropArea;

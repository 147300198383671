import { parseUrl, toURL } from '@@utils/URL';

// supported urls
// https://embed.zattoo.com/CHANNEL
// https://embed-zattoo.com/CHANNEL
// https://zattoo.com/watch/CHANNEL (this only works if the channel is a zattoo partner)
// http://partner.zattoo.com/partner/s1/mini/s1
export const isZattooUrl = (url) => {
    if (url) {
        const parsedUrl = toURL(url);

        if (parsedUrl instanceof URL) {
            if (parsedUrl.protocol !== 'https:') {
                return;
            }

            return (
                /embed-zattoo\.com/.test(parsedUrl.hostname) ||
                /embed.zattoo\.com/.test(parsedUrl.hostname) ||
                /zattoo\.com/.test(parsedUrl.hostname) ||
                /partner\.zattoo\.com/.test(parsedUrl.hostname)
            );
        }
    }
};

export const parseZattooUrl = (zattoOrEmbedChannelUrl) => {
    const url = parseUrl(zattoOrEmbedChannelUrl);

    if (isZattooUrl(url)) {
        return url;
    }
};

import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';

import { useSelector } from '@@store/hooks';
import { getInputPlaceholderStyle } from '@@editor/plugins/serializable/styles';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { getContentLocale } from '@@lib/i18n/i18nSlice';
import { getReducedUISmartSetting } from '@@settings/settingsSlice';
import { useEditorElementsContext } from '@@editor/EditorElementsContext';
import config from '@@config';
import setupPlugins, { ALL_PLUGIN_CONFIG } from '@@editor/plugins/setup';
import useUploadFile from '@@api/hooks/useUploadFile';
import usePostSpellCheck from '@@form/hooks/usePostSpellCheck';
import useFetchMetadata from '@@form/hooks/useFetchMetadata';

import RichTextEditorBase from './RichTextEditorBase';

const RichTextEditor = (props) => {
    const {
        includedPlugins = [],
        pluginConfig = ALL_PLUGIN_CONFIG,
        pluginOptions = {},
        isArticleEditor,
        tenantIds,
        allowAttachments = true,
    } = props;

    let {
        excludedPlugins = [
            PLUGIN_NAMES.INFOBOX,
            PLUGIN_NAMES.POLL,
            PLUGIN_NAMES.DYNAMIC_TEASER,
            PLUGIN_NAMES.COMMENT,
            PLUGIN_NAMES.SPELL_CHECKER,
        ],
    } = props;

    const { actions: elementsContextActions } = useEditorElementsContext();
    const { t } = useTranslation();
    const contentLocale = useSelector(getContentLocale);
    const reducedUI = useSelector(getReducedUISmartSetting);
    const uploadFile = useUploadFile();
    const fetchMetadata = useFetchMetadata();
    const spellCheck = usePostSpellCheck();

    if (reducedUI) {
        excludedPlugins = [...excludedPlugins, ...config.hiddenFloatingToolbarFeatures];
    }

    const plugins = useMemo(
        () =>
            setupPlugins(pluginConfig, {
                included: includedPlugins,
                excluded: excludedPlugins,
                defaultOptions: {
                    contentLocale,
                    reducedUI,
                    t,
                    uploadFile,
                    elementsContextActions: allowAttachments ? elementsContextActions : undefined,
                    tenantIds,
                },
                optionsPerPlugin: {
                    ...pluginOptions,
                    [PLUGIN_NAMES.SPELL_CHECKER]: {
                        ...pluginOptions[PLUGIN_NAMES.SPELL_CHECKER],
                        spellCheck,
                        tenantIds,
                    },
                    [PLUGIN_NAMES.LINK]: {
                        ...pluginOptions[PLUGIN_NAMES.LINK],
                        fetchMetadata,
                    },
                    [PLUGIN_NAMES.INSERT_HTML]: {
                        ...pluginOptions[PLUGIN_NAMES.INSERT_HTML],
                        fetchMetadata,
                    },
                },
            }),
        [],
    );

    return <RichTextEditorBase {...{ ...props, plugins, spellCheck: !isArticleEditor }} />;
};

RichTextEditor.propTypes = {
    activePlugins: PropTypes.array,
    display: PropTypes.string,
    excludedPlugins: PropTypes.array,
    includedPlugins: PropTypes.array,
    useInlineEditing: PropTypes.bool,
    isArticleEditor: PropTypes.bool,
    isArticleHeadingsEditor: PropTypes.bool,
    pluginConfig: PropTypes.array,
    pluginOptions: PropTypes.object,
    reducedUI: PropTypes.bool,
    t: PropTypes.func,
    uploadFile: PropTypes.func,
    elementsContextActions: PropTypes.object,
    readOnly: PropTypes.bool,
    resetOnChangedProp: PropTypes.shape({
        fieldName: PropTypes.string.isRequired,
        parse: PropTypes.func,
    }),
    value: PropTypes.array,
    defaultOptions: PropTypes.object,
    tenantIds: PropTypes.array,
    allowAttachments: PropTypes.bool,
    preserveFloatingToolbarHeight: PropTypes.bool,
    targetTextLength: PropTypes.number,
    showReadOnlyStickyToolbar: PropTypes.bool,
};

export const EmbedContentEditor = styled(RichTextEditor, {
    shouldForwardProp: (prop) => prop !== 'placeholderText',
})(({ $templateElement, placeholderText, theme }) => ({
    ...theme.typography.editorPrimarySmall,
    ...($templateElement && getInputPlaceholderStyle({ theme, placeholderText })),
}));

export default RichTextEditor;

import { useTranslation } from 'react-i18next';
import React from 'react';
import { paperClasses } from '@mui/material';

import { ModalProps } from '@@containers/Modal/types';
import Modal from '@@containers/Modal/ModalWithLeavePrompt';

import SelectionForm, { Props as SelectionFormProps } from './SelectionForm';

export type Props = ModalProps & {
    columns?: SelectionFormProps['columns'];
    disableRestoreFocus?: boolean;
    disableUrlSync?: boolean;
    forceContentTypes?: SelectionFormProps['forceContentTypes'];
    modalLeavePrompt?: SelectionFormProps['modalLeavePrompt'];
    modalTitle?: ModalProps['title'];
    multiple?: SelectionFormProps['multiple'];
    params?: SelectionFormProps['params'];
    searchProps?: SelectionFormProps['searchProps'];
    submitButtonText?: SelectionFormProps['submitButtonText'];
    value?: SelectionFormProps['selectedRelatedContent'];
    width?: string;
    onSubmit: (values: SelectionFormProps['selectedRelatedContent']) => void;
    onSubmitSuccess?: VoidFunction;
    isRowDisabled?: ({ id }: { id: Id }) => boolean;
};

export const RelatedContentModal = ({
    isOpen,
    multiple,
    params,
    value,
    onClose,
    onSubmit,
    onSubmitSuccess,
    disableRestoreFocus,
    disableUrlSync,
    forceContentTypes,
    modalTitle,
    submitButtonText,
    columns,
    width,
    searchProps,
    modalLeavePrompt,
    isRowDisabled,
}: Props) => {
    const { t } = useTranslation();

    const modalWidth = width || '875px';

    return (
        <Modal
            disableRestoreFocus={disableRestoreFocus}
            isOpen={isOpen}
            title={modalTitle || t('metaform.relatedcontent.modal.title')}
            sx={{
                [`.${paperClasses.root}`]: {
                    width: modalWidth,
                    maxWidth: modalWidth,
                },
            }}
            onClose={onClose}
        >
            <SelectionForm
                columns={columns}
                submitButtonText={submitButtonText}
                selectedRelatedContent={value}
                onCancel={onClose}
                onSubmit={onSubmit}
                onSubmitSuccess={onSubmitSuccess}
                multiple={multiple}
                params={params}
                disableUrlSync={disableUrlSync}
                forceContentTypes={forceContentTypes}
                searchProps={searchProps}
                modalLeavePrompt={modalLeavePrompt}
                isRowDisabled={isRowDisabled}
            />
        </Modal>
    );
};

export default RelatedContentModal;

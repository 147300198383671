const crypto = {
    randomUUID(): string {
        // When used in a browser environment
        if (typeof window !== 'undefined' && 'crypto' in window && 'randomUUID' in window.crypto) {
            return window.crypto.randomUUID();
        }

        // When used in a node.js environment
        return require('crypto').randomUUID();
    },
};

export default crypto;

import { RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        path: 'slideshow/:id',
        lazy: async () => {
            const { SlideshowEmbed } = await import(
                /* webpackChunkName: "slideshowEmbed" */ '@@routes/embed/SlideshowEmbed'
            );

            return { Component: SlideshowEmbed };
        },
    },
];

import makeState from '@@editor/utils/makeState';
import isState from '@@editor/utils/isState';
import { Element } from '@@editor/helpers';

import { SCHEMA } from './constants';
import UnitySerializer from './UnitySerializer/UnitySerializer';

// TODO: this make use of the slate state stores in the server as JSON
// TODO: try it with the article format and benchmark both deserializers especially
// in combination with deserialize all richtext.
const deserializeState = (serializedState: any | undefined, options?): Element[] => {
    if (!serializedState) {
        return makeState();
    }

    if (typeof serializedState === 'object') {
        const unitySerializer = new UnitySerializer(options);

        if (UnitySerializer.isSerializedState(serializedState)) {
            // This is the payload from the server
            return makeState(unitySerializer.deserialize(serializedState[SCHEMA.UNITY]));
        } else if (isState(serializedState)) {
            // This is a slate state
            return serializedState as Element[];
        }
    }

    throw new Error('Invalid value for simple richtext field!');
};

export default deserializeState;

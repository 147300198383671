import React, { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadOnly } from 'slate-react';

import { PLUGIN_NAMES, PluginOptions } from '@@editor/typings/UnityPlugins';
import { Editor, Node } from '@@editor/helpers';
import { PATCH_CONTENT_TYPES } from '@@constants/http';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import snackbar from '@@containers/Snackbar';
import { AiToolsRouter, useAiToolsClient } from '@@api/services/aiTools/client';
import { getQueryParams } from '@@api/utils/getQueryParams';

import { getPluginState, setPluginState, getPluginData } from './utils';

const NUM_OF_PROPOSALS = 3;

type EditorWrapperProps = PropsWithChildren<{
    editor: Editor;
    options: PluginOptions;
}>;

const EditorWrapper = ({ children, editor }: EditorWrapperProps) => {
    const state = getPluginState(editor);
    const { t } = useTranslation();
    const selectedProposalId = getPluginData(editor)?.selectedTeaserId;
    const readOnly = useReadOnly();
    const { client: aiToolsClient } = useAiToolsClient();

    const { mutate: postResource } = aiToolsClient.teaserGenerator.post.useMutation();

    const { mutate } = aiToolsClient.resultChoice.patch.useMutation();

    // set initial state
    useEffect(() => {
        setPluginState(editor, 'disabled');
    }, []);

    // fetch when moved to loading state
    useEffect(() => {
        if (state === 'loading') {
            const plainText = editor.children.map((n) => Node.string(n)).join(' ');

            postResource(
                {
                    body: {
                        articleBody: plainText,
                        numOfVariations: NUM_OF_PROPOSALS,
                    },
                },
                {
                    onSuccess: ({ body }) => {
                        if (body.length < NUM_OF_PROPOSALS) {
                            snackbar.info(
                                t('toolbar.teaserGenerator.fewerProposals', {
                                    numOfProposals: NUM_OF_PROPOSALS,
                                }),
                            );
                        }
                        setPluginState(editor, 'suggestion', { proposals: body });
                    },
                    onError: (e) => {
                        setPluginState(editor, 'error', { error: e });
                    },
                },
            );
        }
    }, [state, editor, postResource, t]);

    useEffect(() => {
        if (selectedProposalId) {
            mutate({
                headers: {
                    'content-type': PATCH_CONTENT_TYPES.PATCH,
                },
                query: getQueryParams<AiToolsRouter['resultChoice']['patch']>({
                    id: selectedProposalId,
                }),
                body: { used: true },
            });
        }
    }, [selectedProposalId, mutate]);

    useEffect(() => {
        if (state === 'applied') {
            const proposal = getPluginData(editor)?.proposals?.find(
                (proposal) => proposal.id === selectedProposalId,
            );

            if (!proposal) {
                // applyProposal(editor, proposal);
                setPluginState(editor, 'error');
            }
        }
    }, [state, selectedProposalId, editor]);

    useEffect(() => {
        editor.setEditorContextData({ [PLUGIN_NAMES.TEASER_GENERATOR]: { isReadOnly: readOnly } });
    }, [readOnly]);

    return <>{children}</>;
};

const withTeaserGenerator = (editor: Editor, options: PluginOptions) =>
    Object.assign(editor, {
        renderEditor: renderEditor(editor, ({ children }) => (
            <EditorWrapper editor={editor} options={options}>
                {children}
            </EditorWrapper>
        )),
    });

export default withTeaserGenerator;

import React from 'react';

import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { Editor } from '@@editor/helpers';
import videoCmsEmbedCode from '@@form/utils/validators/videoCmsEmbedCode';
import livestreamVideoCmsEmbedCode from '@@form/utils/validators/livestreamVideoCmsEmbedCode';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import { parseEmbedCode } from '@@utils/socialMedia/videocms';
import { MIME_TYPES, ELEMENT_TYPES, VideocmsElement } from '@@editor/helpers/Element';
import { InsertElementOptions } from '@@editor/helpers/Editor';

import { createEmbedVideoCms, transformParams } from './utils';
import EmbeddedIframe from './../components/EmbeddedIframe';
import EditorWithEmbedModal from './../components/EditorWithEmbedModal';
import EmbedWrapper from './../components/EmbedWrapper';
import VideoCmsPreview from './components/VideoCmsPreview';

const TYPE = PLUGIN_NAMES.VIDEOCMS;

const mimetypeConfigs = {
    // TODO: rename to something like this: x-tamedia?cms/video
    [MIME_TYPES.VIDEOCMS]: {
        Component: VideoCmsPreview,
    },
    [MIME_TYPES.VIDEOCMS_LIVESTREAM]: {
        Component: EmbeddedIframe,
    },
};

type Props = {
    editor: Editor;
    element: VideocmsElement;
};

const VideoCMSNode = (props: Props) => (
    <EmbedWrapper
        {...props}
        type={TYPE}
        mimetypeConfigs={mimetypeConfigs}
        toolbarConfig={{
            infos: {
                iconName: PLUGIN_ICON_NAMES[TYPE],
                title: 'Video',
            },
            actions: ['edit', 'delete'],
            // t('editor.plugin.videoCMS.tooltip')
            tooltips: [{ title: 'editor.plugin.videoCMS.tooltip' }],
        }}
    />
);

export const combinedValidation = (value) => {
    const invalidVideoCMS = videoCmsEmbedCode(value);
    const invalidLivestream = livestreamVideoCmsEmbedCode(value);

    if (invalidVideoCMS && invalidLivestream) {
        return invalidVideoCMS;
    }

    if (!invalidVideoCMS || !invalidLivestream) {
        return;
    }
};

const validate = [combinedValidation];

const validateVideoCmsData = (text) => {
    if (!videoCmsEmbedCode(text)) {
        return text;
    }

    if (!livestreamVideoCmsEmbedCode(text)) {
        return text;
    }
};

const internalWithVideoCms = (editor, options) => {
    const { insertData } = editor;
    const { withCaption, withCredit } = options;

    const embedVideoCms = createEmbedVideoCms(options);

    return Object.assign(editor, {
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    validate={validate}
                    generateEmbedBlock={embedVideoCms}
                    formProps={{
                        withCaption,
                        withCredit,
                        onChangeSrc: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                            const src = e.target.value;
                            const parsedEmbedCode = parseEmbedCode(src);

                            // @ts-ignore This is connected to videocms parser that is not typed, creates conflicts
                            return transformParams(parsedEmbedCode || {});
                        },
                    }}
                    type={TYPE}
                />
            ),
            options,
        ),
        renderElement: renderElement(editor, [[ELEMENT_TYPES.VIDEOCMS, VideoCMSNode]], options),
        insertData: (data: DataTransfer, options: InsertElementOptions) => {
            const text = data.getData('text/plain');

            if (text && validateVideoCmsData(text)) {
                embedVideoCms(editor, text);
            } else {
                insertData(data, options);
            }
        },
    });
};

export const withVideoCms = (editor, options) =>
    internalWithVideoCms(editor, {
        withCaption: true,
        withCredit: true,
        ...options,
        nodeType: ELEMENT_TYPES.VIDEOCMS,
    });

export default withVideoCms;

import { z } from 'zod';

export const ApiErrorV1 = z.object({
    errorCode: z.union([z.string(), z.number()]),
    messages: z.array(z.object({ message: z.string() })),
});
export type ApiErrorV1 = z.infer<typeof ApiErrorV1>;

export const ApiErrorV2 = z.object({
    status: z.number(),
    error: z.string(),
    message: z.string(),
});
export type ApiErrorV2 = z.infer<typeof ApiErrorV2>;

export const RtpApiError = z.object({
    errors: z.array(z.string()),
    exceptionDetails: z.record(z.array(z.string())).optional(),
});
export type RtpApiError = z.infer<typeof RtpApiError>;

export const ApiErrorV3 = z.union([
    z.object({
        status: z.number(),
        errors: z.array(
            z.object({
                code: z.string(),
                title: z.string(),
                message: z.string(),
                fieldNames: z.array(z.string()).optional(),
            }),
        ),
    }),
    RtpApiError,
]);
export type ApiErrorV3 = z.infer<typeof ApiErrorV3>;

export const ApiError = z.union([ApiErrorV1, ApiErrorV2, ApiErrorV3, z.unknown()]);
export type ApiError = z.infer<typeof ApiError>;

// TODO: Fallback after removal of Axios. Find a better solution.
export const ErrNetwork = 'ERR_NETWORK';
export const ErrBadRequest = 'ERR_BAD_REQUEST';
export const ErrCanceled = 'ERR_CANCELED';

import { Pagination as MuiPagination, PaginationItem, PaginationProps } from '@mui/material';
import React from 'react';

import { LinkBehavior } from '@@themes/mui/components';
import Icon from '@@components/Icon';

const previous = () => <Icon name="chevron-left-sharp" />;
const next = () => <Icon name="chevron-right-sharp" />;

type Props = {
    current: number;
    first: number;
    last: number;
    getPageHref?: (page: number) => string;
    onChange?: PaginationProps['onChange'];
};

const Pagination = ({ current, first, last, onChange, getPageHref }: Props) => (
    <MuiPagination
        count={last - first + 1}
        boundaryCount={1}
        siblingCount={2}
        page={Math.min(Math.max(current, first), last)}
        shape="rounded"
        size="large"
        renderItem={(item) => {
            const itemProps = {};
            const href = item.page && getPageHref?.(item.page);

            if (href) {
                Object.assign(itemProps, {
                    component: LinkBehavior,
                    href,
                    matchSearchParams: true,
                });
            }

            return (
                <PaginationItem
                    {...itemProps}
                    {...item}
                    slots={{
                        previous,
                        next,
                    }}
                />
            );
        }}
        onChange={onChange}
    />
);

export default Pagination;

import { useLayoutEffect, useState } from 'react';
import { Range } from 'slate';

import { Editor, ReactEditor } from '@@editor/helpers';

const getBoundingClientRect = (editor: Editor, range: Range | null) => {
    const rect = {
        x: -999,
        y: -999,
    };

    if (range) {
        try {
            const domRange = ReactEditor.toDOMRange(editor, range);

            return domRange.getBoundingClientRect();
        } catch (error) {
            return DOMRect.fromRect(rect);
        }
    }

    return DOMRect.fromRect(rect);
};

export type VirtualElement = {
    getBoundingClientRect: () => DOMRect;
    contextElement?: Element | undefined;
};

const createVirtualElement = (
    editor: Editor,
    range: Range | null,
    editorElement: Element | undefined,
): VirtualElement => ({
    getBoundingClientRect: () => getBoundingClientRect(editor, range),
    contextElement: editorElement,
});

export const useSpellAdvicePopoverPopper = (
    editor: Editor,
    range: Range | null,
    editorElement: Element | undefined,
) => {
    const [virtualReference, setVirtualReference] = useState(() =>
        createVirtualElement(editor, range, editorElement),
    );

    useLayoutEffect(() => {
        setVirtualReference(createVirtualElement(editor, range, editorElement));
    }, [editor, range, editorElement]);

    return virtualReference;
};

export default useSpellAdvicePopoverPopper;

import { get, omit } from 'lodash';
import invariant from 'tiny-invariant';
import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { styled, CSSObject, Stack } from '@mui/material';

import { getToolbarSettings } from '@@editor/utils/getToolbarSettings';
import { Editor, Element } from '@@editor/helpers';
import { PLUGIN_NAMES, PluginName } from '@@editor/typings/UnityPlugins';
import { ToolbarConfig, MimeTypeConfig } from '@@editor/typings/Embed';
import { BLOCK_LOADING_STATES, BLOCK_META_STATES } from '@@editor/constants';
import { EmbedElement, ElementAttributes } from '@@editor/helpers/Element';
import asSlateNode from '@@editor/hoc/asSlateNode';
import useForceUpdate from '@@hooks/useForceUpdate';
import usePrevious from '@@hooks/usePrevious';
import ElementWrapper, { WidthType } from '@@editor/toolbars/ElementWrapper/ElementWrapper';
import Icon from '@@components/Icon';
import Progress, { Unit } from '@@components/FileUploadDropArea/Progress';
import Loader from '@@components/Loader';

import EditableElementFooter from './EditableElementFooter';
import EmbeddedContentFrame from './../embeddedcontent/EmbeddedContentFrame';
import ElementFooter from './ElementFooter';
import EmbedOverlay from './EmbedOverlay';

const StyledIcon = styled(Icon)(({ theme }) => ({
    height: 'auto',
    width: '30%',
    marginTop: theme.spacing(4),
}));

export type Props = {
    type: PluginName;
    attributes: ElementAttributes;
    className: string;
    contentEditable?: boolean;
    component: React.ElementType;
    overlayComponent?: React.ElementType | null;
    placeholderComponent?: React.ElementType;
    editor: Editor;
    element: EmbedElement;
    extendedStyles?: CSSObject;
    readOnly?: boolean;
    toolbarConfig: ToolbarConfig;
    withCaption?: boolean;
    mimetypeConfigs?: MimeTypeConfig;
    widthType?: WidthType;
    children: React.ReactNode;
};

const Placeholder = styled(Stack)(({ theme }) => ({
    width: '100%',
    backgroundColor: theme.palette.gold.light,
    ...theme.typography.title2,
    padding: theme.spacing(6),
    color: theme.palette.gold.dark,
}));

const WrapperStack = styled(Stack)<{ $templateElement?: boolean }>(
    ({ $templateElement, contentEditable, ...props }) => ({
        flexDirection: 'row',
        backgroundColor: 'transparent',
        border: '0 none',
        margin: 0,
        padding: 0,
        minHeight: $templateElement ? '290px' : '160px',
        position: 'relative',
        backgroundSize: 'cover',
        width: '100%',
        userSelect: contentEditable !== true ? 'none' : 'auto',
        [`${props['data-styles']} img`]: {
            objectFit: 'cover',
        },
        '&[data-align="full-width"] img, &[data-align="left"] img, &[data-align="right"] img': {
            maxWidth: '100%',
            height: 'auto',
        },
    }),
);

const OffscreenWrapper = styled('div')({
    position: 'absolute',
    top: '-99999px',
    left: '-99999px',
});

const renderContent = (data, props, Component, minHeight) => {
    const {
        [BLOCK_META_STATES.LOADING]: loading,
        [BLOCK_META_STATES.LOADING_STATE]: loadingState,
        [BLOCK_META_STATES.PROGRESS]: progress,
        templateElement,
    } = data;

    const isLoading = Boolean(loading);
    const isLoadingFile = loadingState === BLOCK_LOADING_STATES.LOAD_FILE;
    const isInProgress = Boolean(progress && !data.src);

    if (templateElement) {
        const PlaceholderComponent = props.placeholderComponent;

        if (PlaceholderComponent) {
            return <PlaceholderComponent {...props} />;
        }

        return (
            <Placeholder alignItems="center" justifyContent="center">
                {props.placeholderText}
                <StyledIcon name={props.placeholderIconName} />
            </Placeholder>
        );
    }

    if (isLoading) {
        if (isInProgress) {
            return (
                <Progress
                    done={progress.value}
                    total={progress.max}
                    progress={(progress.value * 100) / progress.max}
                    unit={Unit.BYTES}
                    // Avoid jumping UI by applying dimensions of previously rendered content
                    style={{ minHeight }}
                />
            );
        }

        return (
            <>
                {/* Avoid jumping UI by applying dimensions of previously rendered content */}
                <div style={{ minHeight }}>
                    <Loader />
                </div>
                {/* In order to start loading the file, we need to render the actual plugin which
                    will insert the image/iframe/etc. that needs to be loaded into the dom */}
                {isLoadingFile &&
                    ReactDOM.createPortal(
                        <OffscreenWrapper>
                            <Component {...props} />
                        </OffscreenWrapper>,
                        document.body,
                    )}
            </>
        );
    }

    return <Component {...props} />;
};

export const EmbedWrapper = (props: Props) => {
    const {
        children,
        element,
        extendedStyles,
        className,
        readOnly,
        type,
        contentEditable,
        component: Component,
        mimetypeConfigs,
        withCaption,
        toolbarConfig,
        editor,
        overlayComponent: Overlay = EmbedOverlay,
        widthType,
    } = props;

    invariant(
        !(contentEditable === true && Editor.isVoid(editor, element)),
        'The content of a void `EmbedWrapper` cannot be editable: ' + JSON.stringify(element),
    );

    const ref = useRef<HTMLDivElement | null>(null);
    const minHeight = useRef(0);

    const data = element.data;
    const align = data.align || 'full-width';

    const isInProgress = Boolean(data[BLOCK_META_STATES.PROGRESS] && !data.src);
    const isLoading = Boolean(data[BLOCK_META_STATES.LOADING]);
    const prevIsLoading = usePrevious(data[BLOCK_META_STATES.LOADING]);
    const coverIframes = editor.getDataIn([PLUGIN_NAMES.DRAG_DROP, 'coverIframes']);

    const mappedComponent = Component || get(mimetypeConfigs, `${data.mimetype}.Component`);

    // currently, BE provides embeddedcontent with only originalSrc set,
    // if an imported embed is not supported in unity OR the mapping is not clear (todo).
    const component = mappedComponent || EmbeddedContentFrame;

    // We need this log for imported embeds from contentstation, which are not yet supported in unity.
    // Either BE is doing the transformation CS -> Unity wrong, or FE lacks of plugin support.
    if (!mappedComponent) {
        console.warn(
            `No component is specified for embed with type '${type}' and mimetype '${data.mimetype}'. Falling back to iframe. This happens in case of unsupported media embeds.`,
        );
    }

    // In order to avoid a jumping layout, we store the size of the content here, for applying it for example
    // to progress components
    useLayoutEffect(() => {
        if (!isInProgress && !isLoading && !prevIsLoading && ref.current) {
            minHeight.current = ref.current.offsetHeight;
        }
    });

    // In order to trigger the above `useLayoutEffect`, to store the `minHeight` of this component, everytime
    // a child changes its size, we force-update this component.
    const forceUpdate = useForceUpdate();
    const resizeObserver = useMemo(() => new ResizeObserver(forceUpdate), [forceUpdate]);
    const handleRef = useCallback((element) => {
        ref.current = element;

        resizeObserver.disconnect();

        if (element) {
            resizeObserver.observe(element);
        }
    }, []);

    const renderElementFooter = () => {
        if (!Editor.isVoid(editor, element)) {
            return (
                <EditableElementFooter {...{ element, withCaption }}>
                    {contentEditable ? null : children}
                </EditableElementFooter>
            );
        }

        return (
            <>
                <ElementFooter {...{ element, withCaption }}>
                    {contentEditable ? null : children}
                </ElementFooter>
            </>
        );
    };

    const renderElementContent = () => {
        // We need to check if the element is an interview segment element.
        // We need to apply the templateElement styles to the wrapper only for non interview and non dynamic teaser templates.
        const isInlineElement =
            Element.isInterviewSegmentElement(element) ||
            Element.isDynamicTeaserElement(element) ||
            Element.isPollElement(element) ||
            Element.isSummaryListElement(element);

        // The overlay should not render any children. The overlay should only be an empty div - of course there might me exceptions.
        // It's the footers or the content components responsability to render the children.
        // Multiple rendering of the children does not make sense and could lead to wrong behaviours.
        const overlayProps = omit(props, 'children');

        return (
            <>
                <WrapperStack
                    data-type={props.type}
                    ref={handleRef}
                    contentEditable={contentEditable && !readOnly}
                    data-align={align || 'center'}
                    data-styles={extendedStyles}
                    justifyContent="center"
                    className={className}
                    $templateElement={Element.isTemplateElement(element) && !isInlineElement}
                >
                    {renderContent(data, props, component, minHeight.current)}
                    {Element.isEmbedElement(element) && coverIframes && !readOnly && Overlay ? (
                        <Overlay {...overlayProps} />
                    ) : null}
                </WrapperStack>

                {renderElementFooter()}
            </>
        );
    };

    const toolbarSettings = getToolbarSettings(editor, element);

    // Only show toolbar for root level elements, never for nested elements.
    if (toolbarSettings?.embedWrapperToolbar.enabled) {
        return (
            // The attributes must be added to the top-level DOM element inside the component https://docs.slatejs.org/concepts/09-rendering
            <ElementWrapper<EmbedElement>
                {...{
                    toolbarConfig,
                    type,
                    editor,
                    element,
                    readOnly,
                    widthType,
                }}
            >
                {renderElementContent()}
            </ElementWrapper>
        );
    }

    return renderElementContent();
};

// We need to set this displayName explicitly because of a bug related
// to the --coverage flag in our jest unit tests.
// Maybe related to this issue: https://github.com/facebook/jest/issues/4021
EmbedWrapper.displayName = 'EmbedWrapper';

export default asSlateNode()(EmbedWrapper);

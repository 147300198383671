import React from 'react';

import { InfoboxElement } from '@@editor/helpers/Element';
import { Editor } from '@@editor/helpers';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { EmbedHeadingEditor } from '@@form/components/RichTextEditor/SimpleRichTextEditor';
import { EmbedContentEditor } from '@@form/components/RichTextEditor/RichTextEditor';

import { PluginsWrapper } from '../../components/PluginsWrapper';

type Props = {
    editor: Editor;
    children: React.ReactNode;
    element: InfoboxElement;
    readOnly?: boolean;
};

const Infobox = ({ element }: Props) => {
    const { title, content } = element.data;

    return (
        <PluginsWrapper>
            <EmbedHeadingEditor value={title} readOnly />
            <EmbedContentEditor
                value={content}
                readOnly
                includedPlugins={[PLUGIN_NAMES.INFOBOX]}
                allowAttachments={false}
            />
        </PluginsWrapper>
    );
};

export default Infobox;

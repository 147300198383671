import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { DropMode } from '@@hooks/useDropItem';
import crypto from '@@utils/crypto';
import Table from '@@components/Table';

const DndTable = (props) => {
    const {
        accept,
        className,
        columns,
        data,
        dragItemProps,
        withHeader,
        onEndReached,
        onClickFieldAction,
        onDrop,
    } = props;
    const { t } = useTranslation();
    const [dragContextId] = useState(() => crypto.randomUUID());

    const renderEmptyRow = useCallback(
        (columns) => (
            <Table.DraggableRow
                {...{ columns, accept, onDrop }}
                as={Table.EmptyRow}
                index={0}
                draggable={false}
                supportedDropModes={[DropMode.REPLACE]}
            >
                {t('list.noresult')}
            </Table.DraggableRow>
        ),
        [accept, t, onDrop],
    );

    const renderRow = useCallback(
        (columns, { data: record, index }) => {
            if (
                record == null ||
                (typeof record.id === 'string' && record.id.startsWith('loading'))
            ) {
                return <Table.RowLoading key={index} columns={columns} />;
            }

            // key = should be unique, otherwise there are problems with slate
            return (
                <Table.DraggableRow
                    {...{
                        columns,
                        record,
                        index,
                        accept,
                        dragItemProps,
                        onDrop,
                        onClickFieldAction,
                    }}
                    key={index}
                    dragContextId={dragContextId}
                />
            );
        },
        [accept, dragContextId, dragItemProps, onDrop, onClickFieldAction],
    );

    return (
        <Table
            {...{
                className,
                data,
                renderEmptyRow,
                renderRow,
                columns,
                withHeader,
                onEndReached,
            }}
        />
    );
};

DndTable.propTypes = {
    columns: PropTypes.array.isRequired,
    accept: PropTypes.array,
    className: PropTypes.string,
    data: PropTypes.array,
    dragItemProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    withHeader: PropTypes.bool,
    onClickFieldAction: PropTypes.func,
    onDrop: PropTypes.func,
    onEndReached: PropTypes.func,
};

export default DndTable;

import React from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { UseCachedValidationProps } from '@@form/hooks/useCachedValidation';
import { FormFieldError } from '@@form/hooks/useReactHookFormFieldError';
import { useTotalCharacterCount } from '@@containers/CharacterCount/CharacterCountContext';

type CountProps = {
    totalCharacterCount: number;
    displayUsedCharacters?: boolean;
    displayRemainingCharacters?: number;
};

const getCount = ({
    totalCharacterCount,
    displayRemainingCharacters,
    displayUsedCharacters,
}: CountProps) => {
    if (!displayUsedCharacters && !displayRemainingCharacters) {
        return undefined;
    }

    if (displayRemainingCharacters) {
        return `${totalCharacterCount}/${displayRemainingCharacters}`;
    }

    return totalCharacterCount > 0 ? totalCharacterCount.toString() : undefined;
};

export type TextInputProps = Omit<TextFieldProps, 'error'> & {
    error?: FormFieldError;
    validate?: UseCachedValidationProps['validate'];
    displayUsedCharacters?: boolean;
    preserveErrorSpace?: boolean;
} & Omit<CountProps, 'totalCharacterCount'>;

const TextInput = (props: TextInputProps) => {
    const {
        error,
        preserveErrorSpace,
        displayUsedCharacters,
        displayRemainingCharacters,
        InputProps,
        ...rest
    } = props;

    const { t } = useTranslation();
    const totalCharacterCount = useTotalCharacterCount(rest.value);

    const errorMessage = error?.[0];
    const message = errorMessage?.[0] ?? '';
    const replacements = errorMessage?.[1] ?? {};

    const helperText =
        (errorMessage && t(message, replacements)) ||
        props.helperText ||
        (preserveErrorSpace && ' ');

    const count = getCount({
        totalCharacterCount,
        displayRemainingCharacters,
        displayUsedCharacters,
    });

    return (
        <TextField
            {...rest}
            error={Boolean(error)}
            helperText={helperText}
            InputProps={{
                ...InputProps,
                ...(count && {
                    endAdornment: <InputAdornment position="end">{count}</InputAdornment>,
                }),
            }}
        />
    );
};

export default TextInput;

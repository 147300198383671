import { initReactI18next } from 'react-i18next';
import i18n, { InitOptions } from 'i18next';

import { LANG_CODES } from './constants';
import locales from './translations';

// the translations
// (tip move them in a JSON file and import them)
const resources: InitOptions['resources'] = {
    [LANG_CODES.ENGLISH]: {
        translation: locales[LANG_CODES.ENGLISH],
    },
    [LANG_CODES.GERMAN]: {
        translation: locales[LANG_CODES.GERMAN],
    },
    [LANG_CODES.FRENCH]: {
        translation: locales[LANG_CODES.FRENCH],
    },
};

const options: InitOptions = {
    debug: false,
    resources: process.env.NODE_ENV !== 'test' ? resources : {},
    lng: LANG_CODES.ENGLISH,

    keySeparator: false,
    nsSeparator: false,

    interpolation: {
        escapeValue: false, // react already safes from xss
    },
    react: {
        useSuspense: false,
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init(options);

export default i18n;

import { find } from 'lodash';
import { Location } from 'slate';
import { Theme } from '@mui/material';

import { Editor, Element } from '@@editor/helpers';
import { PLUGIN_NAMES, PluginName } from '@@editor/typings/UnityPlugins';
import { TabElement as DropdownElement } from '@@components/ElementsDropdown';
import { DEFAULT_BLOCK } from '@@editor/constants';
import { CROSSHEAD_STYLES } from '@@editor/plugins/serializable/text/paragraph/constants';
import { CrossheadElement, ELEMENT_TYPES, ParagraphElement } from '@@editor/helpers/Element';

import { getBaseButtonConfig } from './getBaseButtonConfig';
import { getCrossheadButtonConfigStyle } from './getToolbarConfig';

export const getFloatingButtonConfig = (
    editor: Editor,
    activePlugins: PluginName[],
    theme: Theme,
    at?: Location,
): DropdownElement[] => {
    const isParagraphInActivePlugins = activePlugins.includes(PLUGIN_NAMES.PARAGRAPH);
    const isParagraphPluginAvailable = Boolean(
        find(editor.availablePlugins, { name: PLUGIN_NAMES.PARAGRAPH }),
    );
    const customConfig =
        isParagraphPluginAvailable && isParagraphInActivePlugins
            ? [
                  {
                      label: editor.t(`embed.paragraph.crosshead.default`),
                      title: editor.t(`embed.paragraph.crosshead.default`),
                      onMouseDown: () => {
                          Editor.insertElement(
                              editor,
                              Element.create<ParagraphElement>(DEFAULT_BLOCK),
                              {
                                  at,
                              },
                          );
                      },
                  },
                  {
                      label: editor.t(`embed.paragraph.crosshead.sectionTitle`),
                      title: editor.t(`embed.paragraph.crosshead.sectionTitle`),
                      onMouseDown: () => {
                          Editor.insertElement(
                              editor,
                              Element.create<CrossheadElement>({
                                  type: ELEMENT_TYPES.CROSSHEAD,
                                  data: { style: { crossheadType: CROSSHEAD_STYLES.SECTION } },
                                  children: [{ text: '' }],
                              }),
                              {
                                  at,
                              },
                          );
                      },
                      style: getCrossheadButtonConfigStyle(CROSSHEAD_STYLES.SECTION, theme),
                  },
                  {
                      label: editor.t(`embed.paragraph.crosshead.subsectionTitle`),
                      title: editor.t(`embed.paragraph.crosshead.subsectionTitle`),
                      onMouseDown: () => {
                          Editor.insertElement(
                              editor,
                              Element.create<CrossheadElement>({
                                  type: ELEMENT_TYPES.CROSSHEAD,
                                  data: { style: { crossheadType: CROSSHEAD_STYLES.SUBSECTION } },
                                  children: [{ text: '' }],
                              }),
                              {
                                  at,
                              },
                          );
                      },
                      style: getCrossheadButtonConfigStyle(CROSSHEAD_STYLES.SUBSECTION, theme),
                  },
                  {
                      label: editor.t(`embed.paragraph.crosshead.unorderedListicle`),
                      title: editor.t(`embed.paragraph.crosshead.unorderedListicle`),
                      onMouseDown: () => {
                          Editor.insertElement(
                              editor,
                              Element.create({
                                  type: ELEMENT_TYPES.CROSSHEAD,
                                  data: {
                                      style: { crossheadType: CROSSHEAD_STYLES.UNORDERED_LISTICLE },
                                  },
                                  children: [{ text: '' }],
                              }),
                              {
                                  at,
                              },
                          );
                      },
                      style: getCrossheadButtonConfigStyle(
                          CROSSHEAD_STYLES.UNORDERED_LISTICLE,
                          theme,
                      ),
                  },
                  {
                      label: editor.t(`embed.paragraph.crosshead.orderedListicle`),
                      title: editor.t(`embed.paragraph.crosshead.orderedListicle`),
                      onMouseDown: () => {
                          Editor.insertElement(
                              editor,
                              Element.create({
                                  type: ELEMENT_TYPES.CROSSHEAD,
                                  data: {
                                      style: { crossheadType: CROSSHEAD_STYLES.ORDERED_LISTICLE },
                                  },
                                  children: [{ text: '' }],
                              }),
                              {
                                  at,
                              },
                          );
                      },
                      style: getCrossheadButtonConfigStyle(
                          CROSSHEAD_STYLES.ORDERED_LISTICLE,
                          theme,
                      ),
                  },
                  {
                      label: editor.t(`embed.paragraph.footer`),
                      title: editor.t(`embed.paragraph.footer`),
                      onMouseDown: () => {
                          Editor.insertElement(
                              editor,
                              Element.create({
                                  type: ELEMENT_TYPES.FOOTER,
                                  data: {},
                                  children: [{ text: '' }],
                              }),
                              {
                                  at,
                              },
                          );
                      },
                      style: theme.typography.editorSecondaryXsmall,
                  },
              ]
            : [];

    const contentPlugins: PluginName[] = [
        PLUGIN_NAMES.PARAGRAPH,
        ...(editor.isArticleEditor ? [PLUGIN_NAMES.SUMMARY] : []),
        PLUGIN_NAMES.IMAGE,
        PLUGIN_NAMES.INFOBOX,
        PLUGIN_NAMES.POLL,
        PLUGIN_NAMES.EMBEDDED_COMPONENT,
        editor.isArticleEditor ? PLUGIN_NAMES.INLINE_INTERVIEW : PLUGIN_NAMES.INTERVIEW,
        ...(editor.isArticleEditor ? [PLUGIN_NAMES.IMPORT_INTERVIEW] : []),
        PLUGIN_NAMES.DYNAMIC_TEASER,
        PLUGIN_NAMES.VIDEOCMS,
        PLUGIN_NAMES.SEPARATOR,
        editor.isArticleEditor ? PLUGIN_NAMES.INLINE_QUOTE : PLUGIN_NAMES.QUOTE,
        PLUGIN_NAMES.SLIDESHOW,
        PLUGIN_NAMES.FRONTEND_COMPONENT,
    ];

    const embedsPlugins: PluginName[] = [
        PLUGIN_NAMES.FACEBOOK,
        PLUGIN_NAMES.INSTAGRAM,
        PLUGIN_NAMES.TIKTOK,
        PLUGIN_NAMES.TWITTER,
        PLUGIN_NAMES.YOUTUBE,
        PLUGIN_NAMES.OPTA,
        PLUGIN_NAMES.ZATTOO,
        PLUGIN_NAMES.GOOGLE_MAPS,
        PLUGIN_NAMES.EMBEDDED_CONTENT,
    ];

    const config = [
        {
            name: editor.t('editor.floatingToolbar.tab.content'),
            elements: getBaseButtonConfig(
                editor,
                contentPlugins.filter((plugin) => activePlugins.includes(plugin)),
                { at, customConfig },
            ),
        },
        {
            name: editor.t('editor.floatingToolbar.tab.embeds'),
            elements: getBaseButtonConfig(editor, embedsPlugins, { at }),
        },
    ];

    return config;
};

import { z } from 'zod';

export const ResultChoice = z.object({
    createdAt: z.string().nullable(),
    feedbackEvaluation: z.enum(['THUMBS_UP', 'THUMBS_DOWN']).nullable(),
    id: z.string().nullable(),
    interactionId: z.string().nullable(),
    used: z.boolean(),
});
export type ResultChoice = z.infer<typeof ResultChoice>;

export const TeaserGenerator = ResultChoice.extend({
    title: z.string(),
    titleHeader: z.string(),
    lead: z.string(),
});

export type TeaserGenerator = z.infer<typeof TeaserGenerator>;

export const KeywordSuggestions = ResultChoice.extend({
    keywords: z.array(z.string()),
    articleId: z.string().nullable(),
});

export type KeywordSuggestions = z.infer<typeof KeywordSuggestions>;

export const ImageAltText = ResultChoice.extend({
    altText: z.string(),
    id: z.string(),
});

export type ImageAltText = z.infer<typeof ImageAltText>;

export const ArticleSummary = ResultChoice.extend({
    articleId: z.string().nullable(),
    summary: z.array(z.string()).nullable(),
});

export type ArticleSummary = z.infer<typeof ArticleSummary>;

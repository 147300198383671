import { styled, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@@components/Icon';

const ButtonsWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.layer1,
    display: 'flex',
    gap: `calc(${theme.spacing(1)} / 2)`,
}));

const StyledIconButton = styled(IconButton)(({ $showUI }) => ({
    visibility: $showUI ? 'visible' : 'hidden',
}));

const HoverUIUncollapsed = ({ entity, onClickPreview, onClickEdit, onClickBookmark, showUI }) => {
    const { t } = useTranslation();

    return (
        <ButtonsWrapper>
            <StyledIconButton
                $showUI={showUI}
                size="small"
                title={t('contentitem.previewButton')}
                onClick={onClickPreview}
            >
                <Icon name="eye-regular" size="small" />
            </StyledIconButton>

            <StyledIconButton
                $showUI={showUI}
                size="small"
                title={t('contentitem.editButton')}
                onClick={onClickEdit}
            >
                <Icon name="pen-regular" size="small" />
            </StyledIconButton>

            <StyledIconButton
                color={entity.bookmarkId ? 'secondary' : 'primary'}
                $showUI={Boolean(entity.bookmarkId) || showUI}
                size="small"
                title={t('contentitem.bookmarkButton')}
                onClick={onClickBookmark}
            >
                <Icon name="star" size="small" />
            </StyledIconButton>
        </ButtonsWrapper>
    );
};

HoverUIUncollapsed.propTypes = {
    onClickBookmark: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickPreview: PropTypes.func.isRequired,
    entity: PropTypes.object,
    showUI: PropTypes.bool,
};

export default HoverUIUncollapsed;

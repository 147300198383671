import { useTranslation } from 'react-i18next';
import React from 'react';
import { styled } from '@mui/material';

import { PlaceholderTypes } from '@@routes/placement/constants';
import { type Category } from '@@api/services/tenant/schemas';
import { useTenantClient } from '@@api/services/tenant/client';

const StyledPlaceholder = styled('span')(({ theme }) => ({
    ...theme.typography.subtitle4,
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
}));

type CategoryPlaceholderContainerType = {
    id: Category['id'];
};

const CategoryPlaceholderContainer = ({ id }: CategoryPlaceholderContainerType) => {
    const { client: tenantClient, queryKeys: tenantKeys } = useTenantClient();

    const params = { id };

    const { data } = tenantClient.category.get.useQuery(
        tenantKeys.category.get({ params }),
        { params },
        { enabled: Boolean(id) },
    );

    const category = data?.body;

    return category ? <StyledPlaceholder>{category.name}</StyledPlaceholder> : null;
};

const Main = styled('div')<{ $reducedOpacity?: boolean }>(({ theme, $reducedOpacity }) => ({
    ...theme.typography.subtitle4,
    padding: theme.spacing(2, 0),
    color: theme.palette.primary.dark,
    fontWeight: 'normal',
    ...($reducedOpacity && { opacity: 0.3 }),
}));

type Props = {
    id: Category['id'];
    title: string;
    type: string;
    reducedOpacity?: boolean;
};

const PlaceholderText = ({
    title,
    type = PlaceholderTypes.CATEGORY,
    id,
    reducedOpacity,
}: Props) => {
    const { t } = useTranslation();

    return (
        <Main $reducedOpacity={reducedOpacity}>
            {type === PlaceholderTypes.CATEGORY ? (
                <CategoryPlaceholderContainer id={id} />
            ) : (
                <StyledPlaceholder>{title}</StyledPlaceholder>
            )}
            <span> {t('contentItem.placeholder')}</span>
        </Main>
    );
};

export default PlaceholderText;

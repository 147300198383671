import { flatten } from 'lodash';

import isState from '@@editor/utils/isState';
import { Element, Editor } from '@@editor/helpers';
import {
    ELEMENT_TYPES,
    InfoboxElement,
    InterviewSegmentElement,
    QuoteElement,
    ElementType,
} from '@@editor/helpers/Element';
import toText from '@@editor/utils/toText';
import isEmptyState from '@@editor/utils/isEmptyState';

export const getElementCharacterCount = (element: Element): number =>
    toText(element)
        // We do not want the soft-hyphens to be counted as character
        .replace(/\u00AD/g, '')
        .replace(/\n/g, '').length;

type PluginWithCharacterCount = InfoboxElement | QuoteElement | InterviewSegmentElement;

const getPluginElements = (value: PluginWithCharacterCount): Element[] =>
    Object.values(value.data).reduce((acc, el) => {
        const value = Array.isArray(el) ? flatten(el) : [];

        return [...acc, ...value];
    }, []);

const getElementCharactersCount = (value: Element[]): number =>
    value
        .reduce((acc, element) => {
            if (Element.isWithoutCharacterCount(element)) {
                return acc;
            }

            const elements = Element.isPluginWithCharacterCount(element)
                ? getPluginElements(element as PluginWithCharacterCount)
                : [element];

            return [...acc, ...(elements as Element[])];
        }, [] as Element[])
        .map(getElementCharacterCount)
        .reduce((accumulator, count) => accumulator + count, 0);

export const getCharacterCount = (
    value: string | Element | Element[] | null | undefined,
): number => {
    if (typeof value === 'string') {
        return value.length;
    }

    if (isState(value) && !isEmptyState(value as Element[])) {
        return getElementCharactersCount(value as Element[]);
    }

    if (Element.isElement(value)) {
        return getElementCharactersCount([value as Element]);
    }

    return 0;
};

export const isSingleElementWithoutCharacterCount = (element: Element) => {
    const elementsWithoutCharacterCount: ElementType[] = [
        ELEMENT_TYPES.IMAGE,
        ELEMENT_TYPES.POLL,
        ELEMENT_TYPES.EMBED_CAPTION,
        ELEMENT_TYPES.EMBED_CREDIT,
        ELEMENT_TYPES.EMBEDDED_CONTENT,
    ];

    return elementsWithoutCharacterCount.includes(element.type);
};

// Since there are different rules for element character count and total characters count we handle them separately
// Here we calculate count for a single element, as some of them should have count but should not be included in total
export const getSingleElementCharacterCount = (editor: Editor, element: Element): number => {
    if (!element || !element.children || isSingleElementWithoutCharacterCount(element)) {
        return 0;
    }

    // Separate count for quote caption and quote text
    if (Element.isQuoteElement(element)) {
        const singleElement = Editor.isQuoteTextSelected(editor)
            ? element.children[0]
            : element.children[1];

        return getElementCharacterCount(singleElement);
    }

    const elementWithFilteredChildren = filterElementChildren(element);

    return getElementCharacterCount(elementWithFilteredChildren);
};

// Some elements can have children which should not be included in count (for example image in infobox)
const filterElementChildren = (element: Element) => {
    if (!element || !element.children) {
        return element;
    }
    const newElement = { ...element };

    newElement.children = element.children.filter(
        (child: Element) => !isSingleElementWithoutCharacterCount(child),
    );

    newElement.children = newElement.children.map((child: Element) => filterElementChildren(child));

    return newElement;
};

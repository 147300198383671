import { parseUrl, toURL } from '@@utils/URL';

// supported url
// https://google.com/maps/embed...
export const isGoogleMapsUrl = (url) => {
    const parsedUrl = toURL(url);

    if (parsedUrl instanceof URL) {
        return /google\.com\/maps\/embed/.test(parsedUrl.href);
    }
};

export const isGoogleMapsEmbedCode = (code) => {
    const url = parseGoogleMapsEmbedCode(code);

    if (url instanceof URL) {
        return true;
    }
};

export const parseGoogleMapsEmbedCode = (code) => {
    const el = document.createElement('div');

    el.innerHTML = code;

    const [iframe] = el.getElementsByTagName('iframe');

    if (iframe instanceof HTMLElement) {
        const url = parseUrl(iframe.getAttribute('src'));

        if (isGoogleMapsUrl(url)) {
            return url;
        }
    }
};

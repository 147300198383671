import isDataURI from 'validator/lib/isDataURI';

import { splitDataUri, toURL } from './URL';

export const isSupportedUrl = (value, allowedMimeTypes) => {
    const url = toURL(value);

    if (url instanceof URL) {
        if (isDataURI(url.href)) {
            const { mimeType } = splitDataUri(url.href);

            return allowedMimeTypes
                .map((mimeType) => mimeType.toLowerCase())
                .includes(mimeType.toLowerCase());
        }

        const supportedTypes = allowedMimeTypes.map((type) => type.split('/')[1]).join('|');
        const regex = new RegExp(`[.](${supportedTypes})$`, 'g');

        return regex.test(url.pathname);
    }
};

export const isSupportedFile = (file, allowedMimeTypes) =>
    allowedMimeTypes.includes(file.type.toLowerCase());

export const stripUnsupportedFiles = (files, allowedMimeTypes) =>
    Array.from(files).filter((file) => isSupportedFile(file, allowedMimeTypes));

import { ListItem, ListItemText, styled } from '@mui/material';
import React from 'react';

import { DivWithoutInvalidProps } from '@@components/ElementWithoutInvalidProps';
import listItemBorderStyle from '@@styles/listItemBorderStyle';

import { EmptyListItemProps } from './types';

export const StyledListItem = styled(ListItem)(({ theme }) => ({
    ...listItemBorderStyle({ theme }),
})) as typeof ListItem;

const EmptyListItemText = styled(ListItemText)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const EmptyListItem = React.forwardRef<HTMLDivElement, EmptyListItemProps>((props, ref) => {
    const { children } = props;

    return (
        <StyledListItem {...props} ref={ref} component={DivWithoutInvalidProps}>
            <EmptyListItemText primary={children} primaryTypographyProps={{ variant: 'medium' }} />
        </StyledListItem>
    );
});

export default EmptyListItem;

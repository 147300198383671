import { Editor } from '@@editor/helpers';

const deleteFragment = (
    editor: Editor,
    handlers: [handler: (editor: Editor, ...args: any[]) => boolean | undefined, ...args: any[]][],
) => {
    const { deleteFragment } = editor;

    return () => {
        for (const [handler, ...args] of handlers) {
            if (handler(editor, ...args)) {
                return;
            }
        }

        deleteFragment();
    };
};

export default deleteFragment;

import { RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        index: true,
        lazy: async () => {
            const { SlideshowList } = await import(
                /* webpackChunkName: "slideshowList" */ '@@routes/slideshow/components/SlideshowList'
            );

            return { Component: SlideshowList };
        },
    },
    {
        path: 'new',
        lazy: async () => {
            const { SlideshowSetupPage } = await import(
                /* webpackChunkName: "slideshowSetupPage" */ '@@routes/slideshow'
            );

            return { Component: SlideshowSetupPage };
        },
    },
    {
        path: ':id/*',
        lazy: async () => {
            const { SlideshowDetailPage } = await import(
                /* webpackChunkName: "slideshowDetailPage" */ '@@routes/slideshow'
            );

            return { Component: SlideshowDetailPage };
        },
    },
];

import { Element } from '@@editor/helpers';

/*
 * In this file, we keep the error strings untranslated to be able to use them as error codes.
 * That's why we need to have the translate function as comment on top of each, for the auto-extractor
 */
export default (value) => {
    if (
        value == null ||
        value === '' ||
        value === false ||
        (Array.isArray(value) && value.length === 0) ||
        (Array.isArray(value) && value.every(Element.isEmptyTextElement))
    ) {
        // t('validator.message.required')
        return ['validator.message.required'];
    }
};

// This is the only place in this stack where we use `Node` directly! Use this helper
// in all other places.
/* eslint-disable no-restricted-imports */
import { Node as SlateNode } from 'slate';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface INode {}

export type Node = SlateNode;

export const Node: typeof SlateNode & INode = {
    ...SlateNode,
};

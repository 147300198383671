import { RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        path: '',
        lazy: async () => {
            const { Layout } = await import(
                /* webpackChunkName: "rtElementsLayout" */ '@@routes/rtElements'
            );

            return {
                Component: Layout,
            };
        },
        children: [
            {
                path: ':elementId',
                lazy: async () => {
                    const { ElementForm } = await import(
                        /* webpackChunkName: "rtElementForm" */ '@@routes/rtElements/ElementForm'
                    );

                    return { Component: ElementForm };
                },
            },
        ],
    },
];

import isJSON from 'validator/lib/isJSON';
import isURL from 'validator/lib/isURL';

import type { ImageMimeType } from '@@constants/ImageMimeTypes';

export type AssetsPayload = {
    serverURL: string;
    assets: Asset[];
};

export type AssetPayload = {
    id: string;
    embed: {
        elvisId: string;
    };
    metadata: {
        filename: string;
        folderPath: string;
    };
    previewUrl: string;
};

export type Asset = {
    id: string;
    assetType: string;
    previewUrl: string;
    metadata: {
        filename: string;
        folderPath: string;
    };
};

export const isAsset = (value: any): value is AssetPayload => {
    if (typeof value === 'object' && 'originalUrl' in value) {
        if (typeof value.originalUrl === 'string' && value.originalUrl.includes('dam.ness')) {
            return true;
        }
    }

    return false;
};

export const isAssetPayload = (value: any): value is AssetPayload => {
    if (typeof value === 'object') {
        return (
            'id' in value &&
            'metadata' in value &&
            'previewUrl' in value &&
            'filename' in value.metadata &&
            'folderPath' in value.metadata
        );
    }

    return false;
};

export const isAssetsPayload = (json: string): boolean => {
    if (typeof json === 'string' && isJSON(json)) {
        const data = JSON.parse(json);

        return (
            typeof data.serverURL === 'string' &&
            isURL(data.serverURL) &&
            Array.isArray(data.assets)
        );
    }

    return false;
};

export const isSupportedAsset = (
    { assetType }: Asset,
    allowedMimeTypes: ImageMimeType[],
): boolean =>
    allowedMimeTypes.findIndex(
        (mimeType: ImageMimeType) =>
            mimeType.split('/').slice(-1)[0].toLowerCase() === assetType.toLowerCase(),
    ) >= 0;

export const parseAssetPayload = (json: string): Asset[] | undefined => {
    if (isAssetsPayload(json)) {
        const { assets }: AssetsPayload = JSON.parse(json);

        return assets;
    }
};

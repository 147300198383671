import React from 'react';
import { Transforms } from 'slate';

import getImagesFromDataTransfer, {
    INVALID_FILE_TYPE_ERROR,
} from '@@utils/dataTransfer/getImagesFromDataTransfer';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { parseUrl } from '@@utils/URL';
import getUrlsFromDataTransfer from '@@utils/dataTransfer/getUrlsFromDataTransfer';
import renderElement from '@@editor/plugins/utils/renderElement';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import { ELEMENT_TYPES, Element, ImageElement } from '@@editor/helpers/Element';
import { Editor, Node, Operation, ReactEditor } from '@@editor/helpers';
import { DATA_TRANSFER_TYPE_UNITY_ATTACHMENT } from '@@editor/constants';
import { GlobalOptions, InsertElementOptions } from '@@editor/helpers/Editor';
import {
    normalizeInlineEditableElement,
    preventDeleteBackward,
    preventDeleteForward,
    preventDeleteFragment,
    preventInsertBreak,
    syncInlineEditedEmbedElement,
} from '@@editor/plugins/utils/inlineEditing';
import getElvisIdByImage from '@@editor/selectors/getElvisIdByImage';
import insertBreak from '@@editor/plugins/utils/insertBreak';
import deleteForward from '@@editor/plugins/utils/deleteForward';
import deleteBackward from '@@editor/plugins/utils/deleteBackward';
import normalizeNode from '@@editor/plugins/utils/normalizeNode';
import deleteFragment from '@@editor/plugins/utils/deleteFragment';
import snackbar from '@@containers/Snackbar';

import { createGenerateEmbedFiles } from './../utils/file';
import { generateEmbedBlockData } from './utils';
import { createGenerateEmbedBlock } from './../utils';
import EmbedWrapper from './../components/EmbedWrapper';
import EditorWithEmbedModal from './../components/EditorWithEmbedModal';
import PreviewImage from './components/PreviewImage';
import ImageDropArea from './components/ImageDropArea';
import ImageForm from './ImageForm';
import { allowedMimeTypes, mimetypeConfigs } from './constants';

const TYPE = PLUGIN_NAMES.IMAGE;
const NODE_TYPE = ELEMENT_TYPES.IMAGE;
const INLINE_EDITABLE_CHILDREN_TYPES = [ELEMENT_TYPES.EMBED_CAPTION, ELEMENT_TYPES.EMBED_CREDIT];

const generateEmbedBlock = createGenerateEmbedBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
    generateEmbedBlockData,
    parseEmbedCode: parseUrl,
});

// If we pass children to the drop area, it will behave like:
// - Render the drop area when hovered
// - Render the passed children when NOT hovered
const Content = (props) => (
    <ImageDropArea {...props}>
        <PreviewImage {...props} />
    </ImageDropArea>
);

// If we do NOT pass children to the drop area, it will behave like:
// - Render the drop area when hovered
// - Render the drop area also when NOT hovered
const Placeholder = (props) => <ImageDropArea {...props}>{false}</ImageDropArea>;

type Props = {
    editor: Editor;
    element: ImageElement;
} & GlobalOptions;

const ImageElementComponent = (props: Props) => {
    const { editor, element } = props;
    const { useInlineEditing } = editor;

    return useInlineEditing ? (
        <EmbedWrapper
            {...props}
            type={TYPE}
            toolbarConfig={{
                infos: {
                    iconName: PLUGIN_ICON_NAMES[TYPE],
                    title: 'Image',
                },
                actions: Element.isTemplateElement(element)
                    ? ['delete']
                    : [
                          {
                              type: 'edit',
                              iconName: 'gear',
                              onClick: (e) => {
                                  e.preventDefault();

                                  return requestAnimationFrame(() =>
                                      editor.showEmbedModal(
                                          TYPE,
                                          element,
                                          ReactEditor.findPath(editor, element),
                                      ),
                                  );
                              },
                          },
                          'delete',
                      ],
            }}
            contentEditable={false}
            component={Content}
            // Do not display an overlay component, in order to allow drag and drop actions on the content component
            overlayComponent={null}
            placeholderComponent={Placeholder}
        />
    ) : (
        <EmbedWrapper
            {...props}
            type={TYPE}
            toolbarConfig={{
                infos: {
                    iconName: PLUGIN_ICON_NAMES[TYPE],
                    title: 'Image',
                },
                actions: ['edit', 'delete'],
            }}
            component={PreviewImage}
            // Do not display an overlay component, in order to allow drag and drop actions on the content component
            overlayComponent={null}
            placeholderText={editor.t('editor.plugin.image.placeholderText')}
            placeholderIconName={PLUGIN_ICON_NAMES[TYPE]}
        />
    );
};

const internalWithImage = (editor: Editor, options) => {
    const { apply, insertData, useInlineEditing, onEditorMount } = editor;
    const generateEmbedFiles = createGenerateEmbedFiles(options);
    const elementsContextActions = options.elementsContextActions;

    return Object.assign(editor, {
        apply: (operation: Operation) => {
            if (operation.type === 'set_node') {
                const node = Node.get(editor, operation.path);

                if (Element.isImageElement(node) && !Editor.isVoid(editor, node)) {
                    // We need to sync the inline edited image (children) whenever embed data of an image has been
                    // changed (for example after uploading the image to the BE, the `uploadFileHelper` will update
                    // embed data)
                    syncInlineEditedEmbedElement(
                        editor,
                        node,
                        operation.path,
                        operation.properties.data?.embed,
                        operation.newProperties.data?.embed,
                    );
                }
            }

            if (elementsContextActions) {
                if (operation.type === 'insert_node') {
                    if (Element.isImageElement(operation.node)) {
                        elementsContextActions.addElement?.({
                            editorId: editor.id,
                            element: operation.node,
                        });
                    }
                } else if (operation.type === 'set_node') {
                    const [node] = Editor.node(editor, operation.path);

                    if (
                        Element.isImageElement(node) &&
                        getElvisIdByImage(operation.properties) !==
                            getElvisIdByImage(operation.newProperties)
                    ) {
                        elementsContextActions.removeElement?.({
                            editorId: editor.id,
                            element: node,
                        });

                        elementsContextActions.addElement?.({
                            editorId: editor.id,
                            element: { ...node, ...operation.newProperties },
                        });
                    }
                } else if (operation.type === 'remove_node') {
                    if (Element.isImageElement(operation.node)) {
                        elementsContextActions.removeElement?.({
                            editorId: editor.id,
                            element: operation.node,
                        });
                    }
                }
            }

            apply(operation);
        },
        deleteForward: deleteForward(editor, [
            [preventDeleteForward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteBackward: deleteBackward(editor, [
            [preventDeleteBackward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteFragment: deleteFragment(editor, [
            [preventDeleteFragment, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        insertBreak: insertBreak(editor, [
            [preventInsertBreak, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        normalizeNode: normalizeNode(editor, [
            [
                normalizeInlineEditableElement,
                {
                    type: ELEMENT_TYPES.IMAGE,
                    allowedChildrenTypes: INLINE_EDITABLE_CHILDREN_TYPES,
                },
            ],
        ]),
        onEditorMount: () => {
            if (elementsContextActions) {
                const matches = Editor.elements<ImageElement>(editor, {
                    mode: 'highest',
                    at: [],
                    types: NODE_TYPE,
                });

                const array: ImageElement[] = [];

                for (const match of matches) {
                    const [node] = match;

                    array.push(node);
                }

                elementsContextActions.mount?.({ editorId: editor.id, elements: array });
            }

            onEditorMount();
        },
        onEditorUnmount: () => {
            if (elementsContextActions) {
                elementsContextActions.unMount?.({ editorId: editor.id });
            }
        },
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    formComponent={ImageForm}
                    generateEmbedBlock={generateEmbedBlock}
                    type={TYPE}
                    formProps={{ inlineEdited: useInlineEditing }}
                />
            ),
            options,
        ),
        renderElement: renderElement(editor, [[NODE_TYPE, ImageElementComponent]], {
            ...options,
            generateEmbedFiles,
        }),
        insertData: (data: DataTransfer, options: InsertElementOptions) => {
            const files = data.files;
            const html = data.getData('text/html');
            const text = data.getData('text/plain');
            const urls = getUrlsFromDataTransfer(data);
            const attachment = data.getData(DATA_TRANSFER_TYPE_UNITY_ATTACHMENT);

            const images = getImagesFromDataTransfer(
                { files, html, text, urls, attachment },
                allowedMimeTypes,
                (error) => {
                    // Do not show error for invalid urls, since following plugins in the chain, like
                    // the link plugin, might handle this url if it does not contain an image
                    if (error?.name === INVALID_FILE_TYPE_ERROR) {
                        snackbar.error(
                            <React.Fragment>
                                <strong>{error.name}</strong> {error.message}
                            </React.Fragment>,
                        );
                    }
                },
            );

            if (images.length) {
                generateEmbedFiles(editor, images, options);
            } else {
                // If no image was found, we have to pass the data further down the plugin chain. Maybe another
                // plugin knows what to do with this data
                insertData(data);
            }
        },
        insertImage: (at) => {
            if (useInlineEditing) {
                generateEmbedBlock(
                    editor,
                    {},
                    {
                        at,
                        replace: false,
                    },
                );
                Transforms.select(editor, at);
                Transforms.collapse(editor);
            } else {
                editor.showEmbedModal(TYPE, undefined, at);
            }
        },
    });
};

export const withImage = (editor, options) =>
    internalWithImage(editor, {
        ...options,
        mimetypeConfigs,
        nodeType: NODE_TYPE,
    });

export default withImage;

import { debounce, uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import config from '@@config';
import useDeepCompareMemo from '@@hooks/useDeepCompareMemo';
import { type Tag } from '@@api/services/metadata/schemas';
import Autocomplete, { AutocompleteProps } from '@@form/components/Autocomplete';
import { MetadataRouter, useMetadataClient } from '@@api/services/metadata/client';
import { getQueryParams } from '@@api/utils/getQueryParams';

export type TagsAutocompleteProps = Pick<
    AutocompleteProps<Tag>,
    'placeholder' | 'disabled' | 'required' | 'label' | 'fullWidth' | 'inputRef'
> & {
    value: Tag['id'][];
    onChange: (value: Tag['id'][]) => void;
};

const TagsAutocomplete = (props: TagsAutocompleteProps) => {
    const { value, onChange, ...rest } = props;
    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();

    const { t } = useTranslation();
    const [filter, setFilter] = useState('');
    const hasMinCharacters = filter.length >= 2;

    const memoizedParams = useDeepCompareMemo(
        () =>
            getQueryParams<MetadataRouter['tags']['getAll']>({
                active: true,
                q: filter,
                size: 20,
            }),
        [filter],
    );

    const { data: tagData, isInitialLoading } = metadataClient.tags.getAll.useQuery(
        metadataKeys.tags.getAll({ query: memoizedParams }),
        { query: memoizedParams },
        { enabled: hasMinCharacters, keepPreviousData: true },
    );
    const tags = tagData?.body.content;

    const memoizedTags = React.useMemo(() => tags || [], [tags]);

    const includeIds = value.toString();

    const query = getQueryParams<MetadataRouter['tags']['getAll']>({
        includeIds,
    });

    const { data: selectedTagData, isInitialLoading: isSelectedTagsLoading } =
        metadataClient.tags.getAll.useQuery(
            metadataKeys.tags.getAll({ query }),
            { query },
            { enabled: Boolean(includeIds), keepPreviousData: true },
        );

    const selectedTags = selectedTagData?.body.content;

    const memoizedSelectedTags = React.useMemo(() => selectedTags || [], [selectedTags]);

    const setFilterDebounced = debounce(setFilter, config.searchDebounce);

    const combinedTagsList = uniqBy([...memoizedTags, ...memoizedSelectedTags], 'id');
    const tagsList = hasMinCharacters ? combinedTagsList : memoizedSelectedTags;

    const currentValue = React.useMemo(
        () =>
            value
                .map((item) => memoizedSelectedTags.find((tag) => tag.id === item))
                .filter((x): x is NonNullable<typeof x> => Boolean(x)),
        [value, memoizedSelectedTags],
    );

    const handleChange = (event, value) => {
        setFilter('');

        const adjustedValue = value.map((item) => item.id);

        onChange(adjustedValue);
    };

    const handleInputChange = (event, value: string) => {
        setFilterDebounced(value);
    };

    return (
        <Autocomplete
            multiple
            noOptionsText={
                hasMinCharacters ? t('metaform.tags.nonefound') : t('search.minCharacters')
            }
            loading={isInitialLoading || isSelectedTagsLoading}
            value={currentValue}
            onChange={handleChange}
            onInputChange={handleInputChange}
            options={tagsList}
            getOptionLabel={(option) => option?.name ?? ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            filterOptions={(x) => x}
            filterSelectedOptions
            {...rest}
        />
    );
};

export default TagsAutocomplete;

import { useRef } from 'react';

// Stolen from https://github.com/facebook/react/issues/14490#issuecomment-451924162

type SentinelType = Record<string, any>;

const SENTINEL: SentinelType = {};

const useRefFn = <T>(init): React.MutableRefObject<T> => {
    const ref = useRef<T | SentinelType>(SENTINEL);

    if (ref.current === SENTINEL) {
        ref.current = init();
    }

    return ref as React.MutableRefObject<T>;
};

export default useRefFn;

import { styled } from '@mui/material';

export const PluginsWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '100%',
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.fixed.editor.embed.backgroundColor,
    fontSize: '14px',
}));

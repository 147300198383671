import { Button, styled, Stack } from '@mui/material';
import React from 'react';

import { Editor, Element } from '@@editor/helpers';
import ButtonGroup from '@@form/components/ButtonGroup';

import { QuestionAnswersForm } from '../../components/QuestionAnswersForm';

const StyledQuestionAnswerForm = styled(QuestionAnswersForm)({
    width: '640px',
});

type Props = {
    editor: Editor;
    element: Element;
    onClickImportButton: VoidFunction;
    onCancel: VoidFunction;
    onSubmit: (data: any) => void;
};

const InterviewSegmentForm = (props: Props) => {
    const { editor, element, onClickImportButton, onCancel, onSubmit } = props;

    const isEditMode = Boolean(element);

    const renderButtons = (Buttons, props) => (
        <Stack direction="row" alignItems="baseline">
            {!isEditMode && (
                <Button variant="outlined" onClick={onClickImportButton}>
                    {props.t('editor.plugin.interviewSegment.importInterview')}
                </Button>
            )}

            <ButtonGroup>
                <Buttons.CancelButton />
                <Buttons.SubmitButton>
                    {isEditMode
                        ? props.t('editor.embeddedcontent.modal.submitButton.editMode')
                        : props.t('editor.embeddedcontent.modal.submitButton')}
                </Buttons.SubmitButton>
            </ButtonGroup>
        </Stack>
    );

    return <StyledQuestionAnswerForm {...{ editor, element, onCancel, onSubmit, renderButtons }} />;
};

export default InterviewSegmentForm;

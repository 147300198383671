import React from 'react';
import { styled } from '@mui/material';

import LeadEditor from '@@form/components/RichTextEditor/LeadEditor';
import PreviewImage from '@@containers/PreviewImage';
import { type Image } from '@@api/utils/schemas/schemas';

import { PluginsWrapper } from '../../components/PluginsWrapper';
import TeaserItem from './TeaserItem';
import { MAX_HEIGHT, MAX_WIDTH } from '../../constants';

const StyledLeadEditor = styled(LeadEditor)(({ theme }) => ({
    ...theme.typography.editorSecondaryMedium,
    fontWeight: 'bold',
    padding: theme.spacing(0, 0, 2),
}));

const DynamicTeaser = ({ element }) => {
    const { title, image, metadataIds } = element.data;

    return (
        <PluginsWrapper>
            <StyledLeadEditor value={title} readOnly />

            {image?.url && (
                <PreviewImage
                    src={(image as Image).url}
                    transformations={{
                        ...image,
                        maxWidth: MAX_WIDTH,
                        maxHeight: MAX_HEIGHT,
                    }}
                />
            )}

            {metadataIds?.map((metadataId, index) => (
                <TeaserItem key={index} metadataId={metadataId} />
            ))}
        </PluginsWrapper>
    );
};

export default DynamicTeaser;

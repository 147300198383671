import i18n from '@@lib/i18n/i18n';

/**
 * * This comment is for translation auto extract
 * t('ticker.event.athletics')
 * t('ticker.event.basketball')
 * t('ticker.event.beachVolleyball')
 * t('ticker.event.card')
 * t('ticker.event.crossCountrySkiing')
 * t('ticker.event.curling')
 * t('ticker.event.cycling')
 * t('ticker.event.equestrianism')
 * t('ticker.event.fencing')
 * t('ticker.event.figureSkating')
 * t('ticker.event.formula1')
 * t('ticker.event.freeText')
 * t('ticker.event.game')
 * t('ticker.event.goal')
 * t('ticker.event.golf')
 * t('ticker.event.horseRiding')
 * t('ticker.event.iceHockey')
 * t('ticker.event.info')
 * t('ticker.event.match')
 * t('ticker.event.penalty')
 * t('ticker.event.point')
 * t('ticker.event.set')
 * t('ticker.event.shootOut')
 * t('ticker.event.skiing')
 * t('ticker.event.snowboarding')
 * t('ticker.event.soccer')
 * t('ticker.event.substitution')
 * t('ticker.event.swimming')
 * t('ticker.event.tennis')
 * t('ticker.event.tiebreak')
 * t('ticker.event.timeEvent')
 * t('ticker.event.timeout')
 * t('ticker.event.volleyball')
 * t('ticker.type.iceHockey')
 * t('ticker.type.soccer')
 * t('ticker.type.sportsCompact')
 * t('ticker.type.standard')
 * t('ticker.type.tennis')
 */

export const TICKER_TYPES = {
    STANDARD: 'standard',
    SOCCER: 'soccer',
    ICE_HOCKEY: 'iceHockey',
    TENNIS: 'tennis',
    COMPACT: 'sportsCompact',
} as const;

export const TICKER_EVENTS = {
    SPORTS_COMPACT_FENCING: 'sportsCompact_fencing',
    SPORTS_COMPACT_ICEHOCKEY: 'sportsCompact_iceHockey',
    SPORTS_COMPACT_TENNIS: 'sportsCompact_tennis',
    SPORTS_COMPACT_GOLF: 'sportsCompact_golf',
    SPORTS_COMPACT_HORSERIDING: 'sportsCompact_horseRiding',
    SPORTS_COMPACT_CYCLING: 'sportsCompact_cycling',
    SPORTS_COMPACT_SWIMMING: 'sportsCompact_swimming',
    SPORTS_COMPACT_BASKETBALL: 'sportsCompact_basketball',
    SPORTS_COMPACT_ATHLETICS: 'sportsCompact_athletics',
    SPORTS_COMPACT_FORMULA1: 'sportsCompact_formula1',
    SPORTS_COMPACT_BEACHVOLLEYBALL: 'sportsCompact_beachVolleyball',
    SPORTS_COMPACT_VOLLEYBALL: 'sportsCompact_volleyball',
    SPORTS_COMPACT_SKIING: 'sportsCompact_skiing',
    SPORTS_COMPACT_CROSSCOUNTRYSKIING: 'sportsCompact_crossCountrySkiing',
    SPORTS_COMPACT_SNOWBOARDING: 'sportsCompact_snowboarding',
    SPORTS_COMPACT_FIGURESKATING: 'sportsCompact_figureSkating',
    SPORTS_COMPACT_CURLING: 'sportsCompact_curling',
    SPORTS_COMPACT_SOCCER: 'sportsCompact_soccer',
    SPORTS_COMPACT_OTHER: 'sportsCompact_freeText',
    STANDARD_INFO: 'standard_info',
    SOCCER_INFO: 'soccer_info',
    TENNIS_INFO: 'tennis_info',
    ICE_HOCKEY_INFO: 'iceHockey_info',
    SOCCER_GOAL: 'soccer_goal',
    ICE_HOCKEY_GOAL: 'iceHockey_goal',
    SOCCER_TIME_EVENT: 'soccer_timeEvent',
    ICE_HOCKEY_TIME_EVENT: 'iceHockey_timeEvent',
    ICE_HOCKEY_PENALTY: 'iceHockey_penalty',
    SOCCER_SHOOT_OUT: 'soccer_shootOut',
    ICE_HOCKEY_SHOOT_OUT: 'iceHockey_shootOut',
    ICE_HOCKEY_TIMEOUT: 'iceHockey_timeout',
    SOCCER_CARD: 'soccer_card',
    SOCCER_SUBSTITUTION: 'soccer_substitution',
    TENNIS_POINT: 'tennis_point',
    TENNIS_TIEBREAK: 'tennis_tiebreak',
    TENNIS_GAME: 'tennis_game',
    TENNIS_SET: 'tennis_set',
    TENNIS_MATCH: 'tennis_match',
} as const;

export const COMPACT_EVENTS = [
    TICKER_EVENTS.SPORTS_COMPACT_FENCING,
    TICKER_EVENTS.SPORTS_COMPACT_ICEHOCKEY,
    TICKER_EVENTS.SPORTS_COMPACT_TENNIS,
    TICKER_EVENTS.SPORTS_COMPACT_GOLF,
    TICKER_EVENTS.SPORTS_COMPACT_HORSERIDING,
    TICKER_EVENTS.SPORTS_COMPACT_CYCLING,
    TICKER_EVENTS.SPORTS_COMPACT_SWIMMING,
    TICKER_EVENTS.SPORTS_COMPACT_BASKETBALL,
    TICKER_EVENTS.SPORTS_COMPACT_ATHLETICS,
    TICKER_EVENTS.SPORTS_COMPACT_FORMULA1,
    TICKER_EVENTS.SPORTS_COMPACT_BEACHVOLLEYBALL,
    TICKER_EVENTS.SPORTS_COMPACT_VOLLEYBALL,
    TICKER_EVENTS.SPORTS_COMPACT_SKIING,
    TICKER_EVENTS.SPORTS_COMPACT_CROSSCOUNTRYSKIING,
    TICKER_EVENTS.SPORTS_COMPACT_SNOWBOARDING,
    TICKER_EVENTS.SPORTS_COMPACT_FIGURESKATING,
    TICKER_EVENTS.SPORTS_COMPACT_CURLING,
    TICKER_EVENTS.SPORTS_COMPACT_SOCCER,
    TICKER_EVENTS.SPORTS_COMPACT_OTHER,
] as const;

export const TENNIS_EVENTS = [
    TICKER_EVENTS.TENNIS_POINT,
    TICKER_EVENTS.TENNIS_GAME,
    TICKER_EVENTS.TENNIS_TIEBREAK,
    TICKER_EVENTS.TENNIS_SET,
    TICKER_EVENTS.TENNIS_MATCH,
    TICKER_EVENTS.TENNIS_INFO,
] as const;

export const SOCCER_EVENTS = [
    TICKER_EVENTS.SOCCER_INFO,
    TICKER_EVENTS.SOCCER_TIME_EVENT,
    TICKER_EVENTS.SOCCER_CARD,
    TICKER_EVENTS.SOCCER_SUBSTITUTION,
    TICKER_EVENTS.SOCCER_GOAL,
    TICKER_EVENTS.SOCCER_SHOOT_OUT,
] as const;

export const ICE_HOCKEY_EVENTS = [
    TICKER_EVENTS.ICE_HOCKEY_INFO,
    TICKER_EVENTS.ICE_HOCKEY_TIME_EVENT,
    TICKER_EVENTS.ICE_HOCKEY_PENALTY,
    TICKER_EVENTS.ICE_HOCKEY_TIMEOUT,
    TICKER_EVENTS.ICE_HOCKEY_GOAL,
    TICKER_EVENTS.ICE_HOCKEY_SHOOT_OUT,
] as const;

export const STANDARD_EVENTS = [TICKER_EVENTS.STANDARD_INFO] as const;

export const TENNIS_BREAK_TYPES = {
    BREAK: 'Break',
    MINI_BREAK: 'Mini-Break',
    SETBALL: 'Setball',
    BREAKBALL: 'Breakball',
    MATCHBALL: 'Matchball',
} as const;

export const TENNIS_POINT_STEPS = ['0', '15', '30', '40', 'AD'] as const;

export const TIME_EVENT_LABELS = {
    GAME_START: i18n.t('ticker.event.gameStart'),
    GAME_END: i18n.t('ticker.event.gameEnd'),
    PERIOD_START: i18n.t('ticker.event.periodStart'),
    PERIOD_END: i18n.t('ticker.event.periodEnd'),
    OVERTIME_START: i18n.t('ticker.event.overtimeStart'),
    OVERTIME_END: i18n.t('ticker.event.overtimeEnd'),
    SHOOT_OUT_START: i18n.t('ticker.event.shootOutStart'),
    SHOOT_OUT_END: i18n.t('ticker.event.shootOutEnd'),
    HALFTIME_START: i18n.t('ticker.event.halftimeStart'),
    HALFTIME_END: i18n.t('ticker.event.halftimeEnd'),
} as const;

export const DEFAULT_TENNIS_SCORE = {
    points: ['0', '0'],
    tiebreak: [0, 0],
    sets: [[0, 0]],
    isFinished: false,
};

export const FIELD_NAMES = {
    DATE_TIME: 'data.dateTime',
    DISPLAY_TIME: 'data.displayTime',
    DATE: 'data.date',
    GOALS: 'data.goals',
    GAME_MINUTE: 'data.gameMinute',
    KEY_EVENT: 'keyEvent',
    TIME_EVENT: 'data.timeEvent',
    PENALTY_TIME: 'data.penaltyTime',
    PENALTY_SHOT: 'data.penaltyShot',
    CARD: 'data.card',
    SETS: 'data.sets',
    TENNIS_BREAK: 'data.tennisBreak',
    TIEBREAK: 'data.tiebreak',
    POINTS: 'data.points',
};

export const USER_IMAGE_HEIGHT = 64;
export const USER_IMAGE_WIDTH = USER_IMAGE_HEIGHT;
export const USER_IMAGE_RADIUS = USER_IMAGE_HEIGHT / 2;

export const USER_IMAGE_HEIGHT_SMALL = 32;
export const USER_IMAGE_WIDTH_SMALL = USER_IMAGE_HEIGHT_SMALL;
export const USER_IMAGE_RADIUS_SMALL = USER_IMAGE_HEIGHT_SMALL / 2;

export const MAX_TEXT_LENGTH = 90;
export const MAX_PROLITTERIS_ID_LENGTH = 10;

export const INVALID_PROLITTERIS_ID_MESSAGE = 'validator.message.invalidProlitterisId';
// t('validator.message.invalidProlitterisId');

export const USER_SOURCES = {
    TAMEDIA: 'TAMEDIA',
    MANUAL: 'MANUAL',
    EXTERNAL: 'EXTERNAL',
} as const;

export type UserSourceType = ValueOf<typeof USER_SOURCES>;

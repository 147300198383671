import { FieldValues } from 'react-hook-form';
import React from 'react';

import { number } from '@@form/utils/parsers';

import TextInputField, { TextInputFieldProps } from './TextInput';

const NumberField = <TFieldValues extends FieldValues = FieldValues>(
    props: TextInputFieldProps<TFieldValues>,
) => (
    <TextInputField
        type="number"
        {...props}
        transform={{
            // @ts-expect-error Typing of form fields needs to be improved in order to make this work
            input: String,
            // @ts-expect-error Typing of form fields needs to be improved in order to make this work
            output: (event) => number(event.target.value),
            ...props.transform,
        }}
    />
);

export default NumberField;

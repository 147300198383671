import { MIME_TYPES } from '@@editor/helpers/Element';
import { GenerateEmbedBlockData } from '@@editor/typings/Embed';
import { isOptaEmbedCode } from '@@utils/socialMedia/opta';

export const generateEmbedBlockData: GenerateEmbedBlockData = ({ embed, originalSrc }) => {
    const code = embed.originalSrc || originalSrc;

    if (!isOptaEmbedCode(code)) {
        return;
    }

    return {
        mimetype: MIME_TYPES.OPTA,
        embed,
        originalSrc: code,
    };
};

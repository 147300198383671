import React, { ReactElement } from 'react';
import { styled, Stack } from '@mui/material';

import { ParagraphElement } from '@@editor/helpers/Element';
import Icon from '@@components/Icon';

type Props = {
    children: React.ReactNode;
    element: ParagraphElement;
};

const StyledIcon = styled(Icon)(({ theme }) => ({
    color: theme.palette.error.main,
    minWidth: theme.spacing(3),
}));

const StyledStack = styled(Stack)<{ $displayError?: boolean }>(
    ({ $displayError, theme }) =>
        $displayError && {
            color: theme.palette.primary.light,
            position: 'absolute',
            right: 0,
        },
);

const ErrorWrapper = ({ children, element }: Props): ReactElement => {
    const displayError = element?.data?.displayError;

    return (
        <>
            <StyledStack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                $displayError={displayError}
            >
                <StyledIcon name="circle-exclamation-sharp" />
            </StyledStack>

            {children}
        </>
    );
};

export default ErrorWrapper;

import React from 'react';
import { styled } from '@mui/material';

import { TransformSrc } from '@@editor/typings/Embed';
import { EmbeddedIframeElement } from '@@editor/helpers/Element';
import ResponsiveIFrame from '@@components/ResponsiveIFrame';
import { Editor } from '@@editor/helpers';

import EmbedComponentLoader from './EmbedComponentLoader';

const Wrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.fixed.editor.embed.backgroundColor,
}));

type Props = {
    editor: Editor;
    element: EmbeddedIframeElement;
    omitSandbox?: boolean;
    transformSrc: TransformSrc;
};

const EmbeddedIframe = ({ element, omitSandbox, transformSrc = (src) => src, editor }: Props) => {
    const { src } = element.data;

    return (
        <EmbedComponentLoader editor={editor} element={element}>
            {({ onLoad }) => (
                <Wrapper>
                    {/* @ts-expect-error */}
                    <ResponsiveIFrame
                        src={transformSrc(src as string)}
                        allowFullScreen
                        omitSandbox={omitSandbox}
                        onLoad={onLoad}
                    />
                </Wrapper>
            )}
        </EmbedComponentLoader>
    );
};

export default EmbeddedIframe;

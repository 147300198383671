import { noop } from 'lodash';
import React from 'react';

export type Actions = {
    showCommentPopover: (commentId: string | null, anchorEl: HTMLElement | null) => void;
};

const CommentPopoverActionsContext = React.createContext<Actions>({
    showCommentPopover: noop,
});

export default CommentPopoverActionsContext;

import { useTranslation } from 'react-i18next';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useEffect, useCallback } from 'react';
import moment from 'moment';

import { useSelector } from '@@store/hooks';
import { getNoFormRestorationSmartSetting } from '@@settings/settingsSlice';
import { isExpired } from '@@lib/dateTime/utils';
import config from '@@config';
import snackbar from '@@containers/Snackbar';

import { getReadableNameFromFormKey } from './utils';

const FORM_PERSIST_PREFIX = 'UnityFormPersist';

type Options = {
    persist: boolean;
};

type PersistedForm = {
    values: FieldValues;
    lastChanged: moment.Moment;
};

const defaultPersistOptions = {
    expireAfter: config.persistedFormDataExpireAfter,
};

const getPersistedForm = (storageKey: string) => {
    const value = localStorage.getItem(storageKey);

    return value ? JSON.parse(value) : null;
};

const updatePersistedForm = (storageKey: string, formToPesist: PersistedForm) => {
    localStorage.setItem(storageKey, JSON.stringify(formToPesist));
};

const clearPersistedForm = (storageKey: string) => {
    localStorage.removeItem(storageKey);
};

const useReactHookFormPersist = (
    formName: string,
    form: UseFormReturn,
    options: Options,
): { clearPersistedForm: () => void } => {
    const { persist = false } = options;
    const {
        formState: { isDirty, isSubmitting },
        getValues,
        reset,
    } = form;
    const storageKey = `${FORM_PERSIST_PREFIX}:${formName}`;

    const { t } = useTranslation();
    const noFormRestoration = useSelector(getNoFormRestorationSmartSetting);
    const shouldPersist = persist && !noFormRestoration;

    const updateFormValues = useCallback(
        (values: FieldValues) => {
            reset(values, {
                keepErrors: false,
                keepDefaultValues: true,
                keepValues: false,
                keepDirty: false,
                keepDirtyValues: false,
                keepIsSubmitted: false,
                keepTouched: false,
                keepIsValid: false,
                keepSubmitCount: false,
            });

            snackbar.warning(
                `${t('form.changes.restored')}: ${getReadableNameFromFormKey(formName)}`,
            );
        },
        [formName, reset, t],
    );

    useEffect(() => {
        if (!shouldPersist) {
            return;
        }

        if (noFormRestoration) {
            console.warn('Did not restore because of setting "noFormRestoration"');
        }

        const persistedForm = getPersistedForm(storageKey);

        if (persistedForm) {
            const { values, lastChanged } = persistedForm;

            if (isExpired(lastChanged, defaultPersistOptions.expireAfter)) {
                clearPersistedForm(storageKey);
            } else {
                updateFormValues(values);
            }
        }
    }, [noFormRestoration, shouldPersist, storageKey, updateFormValues]);

    useEffect(
        () => () => {
            clearPersistedForm(storageKey);
        },
        [storageKey],
    );

    const values = getValues();

    useEffect(() => {
        if (shouldPersist && isDirty && !isSubmitting) {
            updatePersistedForm(storageKey, {
                values,
                lastChanged: moment(),
            });
        }
    }, [isDirty, shouldPersist, storageKey, values, isSubmitting]);

    return {
        clearPersistedForm: () => clearPersistedForm(storageKey),
    };
};

export default useReactHookFormPersist;

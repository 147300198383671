import { Location, NodeEntry, Transforms, Path } from 'slate';
import { omit } from 'lodash';

import getUrlsFromDataTransfer from '@@utils/dataTransfer/getUrlsFromDataTransfer';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import { Editor, Element, Node, ReactEditor } from '@@editor/helpers';
import { LinkData, InsertElementOptions } from '@@editor/helpers/Editor';
import {
    LinkElement,
    InternalLinkElement,
    ExternalLinkElement,
    ELEMENT_TYPES,
    ANY_LINK,
} from '@@editor/helpers/Element';
import getIsUrl from '@@form/utils/validators/customUrl';
import { PluginOptions } from '@@editor/typings/UnityPlugins';
import extractMetadataIdFromUrl from '@@utils/extractMetadataIdFromUrl';
import { getQueryParams } from '@@api/utils/getQueryParams';
import { MetadataRouter } from '@@api/services/metadata/client';

import Modal from './Modal';
import RenderedLink from './RenderedLink';
import { LinkPluginOptions } from './types';

const isLinkActive = (editor: Editor): boolean => {
    const inlines = Array.from(
        Editor.inlines(editor, {
            types: ANY_LINK,
        }),
    );

    return inlines.length > 0;
};

const unwrapLink = (editor: Editor): void => {
    Transforms.unwrapNodes(editor, {
        match: (node: Node) => {
            const { type } = node as Element;

            return type === ELEMENT_TYPES.EXTERNAL_LINK || type === ELEMENT_TYPES.INTERNAL_LINK;
        },
    });
};

const wrapLink = (editor: Editor, { text, link, metadataId }: LinkData): void => {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }

    if (typeof text === 'string') {
        Transforms.insertText(editor, text);

        Transforms.move(editor, {
            distance: text.length,
            unit: 'character',
            reverse: true,
            edge: 'anchor',
        });
    }

    const element: LinkElement = metadataId
        ? ({
              type: ELEMENT_TYPES.INTERNAL_LINK,
              data: { metadataId },
              children: [{ text: '' }],
          } as InternalLinkElement)
        : ({
              type: ELEMENT_TYPES.EXTERNAL_LINK,
              data: { href: link },
              children: [{ text: '' }],
          } as ExternalLinkElement);

    Transforms.wrapNodes(editor, element, { split: true });
};

type RemoveLinkOptions = {
    at?: Location;
};

const removeLink = (editor: Editor, options: RemoveLinkOptions = {}): void => {
    const selection = options.at || editor.selection;

    if (selection) {
        Transforms.select(editor, selection);

        unwrapLink(editor);
    }
};

type InsertLinkOptions = RemoveLinkOptions;

const insertLink = (editor: Editor, data: LinkData, options: InsertLinkOptions = {}): void => {
    const selection = options.at || editor.selection;

    if (selection) {
        Transforms.select(editor, selection);

        wrapLink(editor, data);
    }
};

export const withLink = (editor, options: PluginOptions & LinkPluginOptions): Editor => {
    const { insertData, normalizeNode } = editor;

    const { fetchMetadata, shouldNormalizeMarks = true } = options;

    return Object.assign(editor, {
        renderEditor: renderEditor(editor, Modal, options),
        renderElement: renderElement(
            editor,
            [
                [ELEMENT_TYPES.INTERNAL_LINK, RenderedLink],
                [ELEMENT_TYPES.EXTERNAL_LINK, RenderedLink],
            ],
            options,
        ),
        insertData: async (data: DataTransfer, options: InsertElementOptions) => {
            const text = data.getData('text/plain');

            const url = getUrlsFromDataTransfer(data)?.[0] ?? text;

            if (url && !getIsUrl({ allowInsecure: true, allowMailto: true })(url)) {
                const { selection } = editor;
                const selectedText = Editor.string(editor, selection);

                const metadataId = extractMetadataIdFromUrl(url);

                const text = selectedText || url;

                if (metadataId) {
                    const params = getQueryParams<MetadataRouter['metadata']['getList']>({
                        ids: metadataId,
                        size: 1,
                    });

                    await fetchMetadata({ params }).then(({ body }) => {
                        const [metadata] = body.content || [];

                        const data = {
                            link: metadata ? null : url,
                            metadataId: metadata ? metadata.id : null,
                            text,
                        };

                        editor.insertLink(data, { at: selection });
                    });
                } else {
                    const data = {
                        link: url,
                        metadataId: null,
                        text,
                    };

                    editor.insertLink(data, { at: selection });
                }
            } else {
                // If no url was found, we have to pass the data further down the plugin chain. Maybe another
                // plugin knows what to do with this data
                insertData(data, options);
            }
        },
        insertLink: (data: LinkData, options: InsertLinkOptions) =>
            insertLink(editor, data, options),
        removeLink: (options: RemoveLinkOptions) => removeLink(editor, options),
        showLinkModal: () => {
            editor.setData({
                isLinkModalVisible: {
                    selection: editor.selection,
                },
            });
        },
        hideLinkModal: () => {
            editor.setData({
                isLinkModalVisible: false,
            });

            ReactEditor.focus(editor);
        },
        normalizeNode: (nodeEntry: NodeEntry) => {
            if (shouldNormalizeMarks) {
                const [node, nodePath] = nodeEntry;

                // Since native slate functions for checking of node throw an error if there is none
                // And mergeNodes sometimes try to perform on nonexisting node, this is used to prevent error
                const nodeExists = (editor: Editor, path: Path) => {
                    try {
                        Editor.node(editor, path);

                        return true;
                    } catch (error) {
                        return false;
                    }
                };

                // Link element should only have one child, however mergeNodes by slate sometimes removes attributes prop
                // So we mergeNodes while also keeping track of attributes, and apply them at the end
                let attributes = {};

                if (Element.isLinkElement(node)) {
                    for (const [child, path] of Node.children(editor, nodePath)) {
                        attributes = { ...attributes, ...omit(child, ['text']) };

                        if (nodeExists(editor, path)) {
                            Transforms.mergeNodes(editor, { at: path });
                        }
                    }
                    Transforms.setNodes(editor, attributes, { at: [...nodePath, 0] });

                    return;
                }
            }

            normalizeNode(nodeEntry);
        },
    });
};

export default withLink;

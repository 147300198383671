import { CONTAINER_TYPE_MAPPINGS } from '../constants';

export const getInfoboxTitle = (data, t) => {
    const type = data?.style?.containerType;
    const typeTranslationKey = CONTAINER_TYPE_MAPPINGS[type] || 'embed.infobox.default';

    return type
        ? `${t('editor.plugin.infobox.frame.title')} (${t(typeTranslationKey)})`
        : t('editor.plugin.infobox.frame.title');
};

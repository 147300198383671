import React from 'react';

import { FacebookElement } from '@@editor/helpers/Element';

import FacebookEmbed from './FacebookEmbed';

type Props = {
    element: FacebookElement;
};

const FacebookVideo = ({ element }: Props) => (
    // Apple News Format does only need role="fb-video" and URL={src} or as JSON

    <FacebookEmbed element={element}>
        {({ src, width, showText, allowFullscreen, autoplay, ...rest }) => (
            <div
                {...rest}
                className="fb-video"
                data-href={src}
                data-width={width}
                data-allowfullscreen={Boolean(allowFullscreen) || false}
                data-autoplay={Boolean(autoplay) || false}
                data-show-text={Boolean(showText) || false}
            />
        )}
    </FacebookEmbed>
);

export default FacebookVideo;

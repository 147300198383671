import { useTranslation } from 'react-i18next';
import React from 'react';

import Autocomplete from '@@form/components/Autocomplete';
import { TextInputProps } from '@@form/components/TextInput';

type BaseProps = {
    translationNamespace: string;
    optionsList: string[];
    inputRef?: TextInputProps['inputRef'];
    label: TextInputProps['label'];
    className?: string;
};

type Props =
    | (BaseProps & {
          multiple: true;
          value: string[];
          onChange: (value: string[]) => void;
      })
    | (BaseProps & {
          multiple?: false;
          value: string | null;
          onChange: (value: string | null) => void;
      });

const FilterAutocomplete = (props: Props) => {
    const { className, value, onChange, translationNamespace, multiple, optionsList, ...rest } =
        props;
    const { t } = useTranslation();

    const options = optionsList.map((option) => ({
        label: t(`${translationNamespace}.${option}`),
        value: option,
    }));

    const currentValue = multiple
        ? (value || [])
              .map((item) => options.find((option) => (option.value ?? option) === item))
              .filter((x): x is NonNullable<typeof x> => Boolean(x))
        : options.find((option) => option.value === value) ?? null;

    const handleChange = (event, value) => {
        const adjustedValue = Array.isArray(value)
            ? value.map((item) => item.value)
            : value?.value ?? value;

        onChange(adjustedValue);
    };

    return (
        <Autocomplete
            className={className}
            value={currentValue}
            onChange={handleChange}
            options={options}
            multiple={multiple}
            getOptionKey={(option) => option.value}
            getOptionLabel={(option) => option.label}
            {...rest}
        />
    );
};

export default FilterAutocomplete;

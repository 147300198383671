import { RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        index: true,
        lazy: async () => {
            const { EmbedsList } = await import(
                /* webpackChunkName: "embedsList" */ '@@routes/externalContent/embeds/'
            );

            return { Component: EmbedsList };
        },
    },
    {
        path: 'new',
        lazy: async () => {
            const { EmbedsSetupPage } = await import(
                /* webpackChunkName: "embedsSetupPage" */ '@@routes/externalContent/embeds'
            );

            return { Component: EmbedsSetupPage };
        },
    },
    {
        path: ':id/*',
        lazy: async () => {
            const { EmbedsDetailPage } = await import(
                /* webpackChunkName: "embedsDetailPage" */ '@@routes/externalContent/embeds'
            );

            return { Component: EmbedsDetailPage };
        },
    },
];

import { styled } from '@mui/material';

export const BaseWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    padding: theme.spacing(3),
    borderRadius: `${theme.shape.borderRadius}px`,
    borderWidth: theme.borders[1],
    borderStyle: 'solid',
    borderColor: theme.palette.primary['200'],
    backgroundColor: theme.fixed.fileUpload.dropArea.backgroundColor,
    color: theme.palette.primary.dark,
}));

export const StatusText = styled('p')(({ theme }) => ({
    ...theme.typography.small,
}));

import { useTranslation, Trans } from 'react-i18next';
import React from 'react';

import { type Metadata } from '@@api/services/metadata/schemas';
import useModal from '@@containers/Modal/useModal/useModal';
import ContentStatus from '@@constants/ContentStatus';
import { isApiError, isRtpValidationError } from '@@api/errorHandler';
import { type RtpApiError } from '@@api/utils/schemas/errors';

import { isPublishedOrScheduledForIt } from '../utils';

export const willTriggerRtpValidation = (metadata: Metadata) => {
    const { contentStatus } = metadata;

    return contentStatus !== ContentStatus.DRAFT || isPublishedOrScheduledForIt(metadata);
};

/*
 * This comment is for translation auto extract
 * (all possible backend errors)
 t('backend.noMainCategory')
 t('backend.emptySeoTeaserTitleLead')
 t('backend.emptyCategory')
 t('backend.emptyContentName')
 t('backend.emptyEmbedCode')
 t('backend.emptyLead')
 t('backend.emptySlideCredit')
 t('backend.emptyTeaserLead')
 t('backend.emptyTeaserTitle')
 t('backend.emptyMainTenantId')
 t('backend.emptyTenant')
 t('backend.emptyTitle')
 t('backend.emptyUrl')
 t('backend.invalidTargetDateTime')
 t('backend.wrongArticlePublicationStatus')
 t('backend.wrongArticleProofreadStatus')
 t('backend.wrongNumberOfRelatedContent')
 t('backend.onlyOneSeriesAllowed')
 t('backend.invalidTeaserImage')
 t('backend.commentRiskNotSelected')
 t('backend.unpublishedRelatedContent')
 t('backend.mainCategoryNotSyncedWithDesknet')
 t('backend.emptyTargetDateTime')
 t('backend.notSupportedTenantPresent')
 t('backend.invalidTargetTextLength')
*/

type RtpErrorModalContentProps = {
    errorData: RtpApiError;
    metadata: Metadata;
};

const RtpErrorModalContent = ({ errorData, metadata }: RtpErrorModalContentProps) => {
    const { t } = useTranslation();

    let message = t('metaform.validation.modal.description');

    if (willTriggerRtpValidation(metadata)) {
        message = t('metaform.validation.modal.adjust.description');
    }

    return (
        <React.Fragment>
            {message}

            {errorData.errors?.map((error, index) => {
                const detailStrings = errorData.exceptionDetails?.[error]?.map(
                    (string) => `"${string}"`,
                );

                const details = detailStrings?.join(', ');

                return (
                    <p key={index}>
                        -{' '}
                        <Trans
                            i18nKey={`backend.${error}`}
                            values={{ details }}
                            components={{ strong: <strong /> }}
                        />
                    </p>
                );
            })}
        </React.Fragment>
    );
};

export const validateRtp = async (error: unknown, confirmRtp, metadata: Metadata) => {
    if (isApiError(error) && isRtpValidationError(error.body)) {
        const errorData = error.body;

        await confirmRtp({ children: <RtpErrorModalContent {...{ errorData, metadata }} /> });

        return;
    }

    if (error) {
        throw error;
    }
};

const useRtpValidation = () => {
    const { t } = useTranslation();
    const { confirm: confirmRtp } = useModal({
        type: 'info',
        title: t('metaform.validation.modal.title'),
    });

    return (error, metadata: Metadata) => validateRtp(error, confirmRtp, metadata);
};

export default useRtpValidation;

import { noop, omit, get } from 'lodash';
import React, { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { FormControl, FormHelperText, FormLabel, FormLabelProps, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClientInferResponses } from '@ts-rest/core';

import required from '@@form/utils/validators/required';
import ImageEditor from '@@form/components/ImageEditor/ImageEditor';
import ImageUpload, {
    RenderImageProps,
} from '@@form/components/ImageEditor/ImageUpload/ImageUpload';
import {
    DEFAULT_FOCUS_POINT,
    DEFAULT_CROP_MARKS,
    DEFAULT_IMAGE_SIZES,
} from '@@form/components/ImageEditor/constants';
import { SVG_MIME_TYPE } from '@@utils/buildImageTransformationUrl';
import config from '@@config';
import i18n from '@@lib/i18n/i18n';
import FileUploadDropArea, {
    Props as FileUploadDropAreaProps,
} from '@@components/FileUploadDropArea/FileUploadDropArea';
import Image from '@@components/Image';
import useUploadFile from '@@api/hooks/useUploadFile';
import useReactHookFormContext from '@@containers/ReactHookForm/useReactHookFormContext';
import useFormField, { UseFormFieldProps } from '@@form/hooks/useFormField';
import { FormFieldError } from '@@form/hooks/useReactHookFormFieldError';
import { type Image as ImageType } from '@@api/utils/schemas/schemas';
import { FilerepoRouter } from '@@api/services/filerepo/client';
import { HTTP_STATUS_CODES } from '@@constants/http';

const allowedMimeTypes = Object.values(config.allowedImageMimeTypes);

const StyledFileUploadDropArea = styled(FileUploadDropArea)<{ $error: boolean }>(
    ({ $error, theme }) => ({
        borderColor: $error ? theme.palette.error.main : undefined,
        minHeight: `calc(${DEFAULT_IMAGE_SIZES.loaderHeight} - ${theme.spacing(3)})`,
    }),
);

const StyledImage = styled(Image)({
    maxWidth: DEFAULT_IMAGE_SIZES.maxWidth,
    maxHeight: DEFAULT_IMAGE_SIZES.maxHeight,
});

type Props = {
    noDropArea: boolean;
    onMetadataChange: (value: UnknownObject) => void;
    allowImageEdit?: boolean;
    className?: string;
    error?: FormFieldError;
    label?: FormLabelProps['children'];
    // only provide the basic image tools: upload, crop, focuspoint, reset
    // but hide all additional fields: caption, credit, ...
    imageOnly?: boolean;
    renderButtons?: (buttons: unknown) => React.ReactNode;
    renderImage?: (args: RenderImageProps) => React.ReactNode;
    renderLoader?: () => React.ReactNode;
    showPlaceholderImage?: boolean;
    style?: React.StyleHTMLAttributes<HTMLDivElement>;
    showAspectRatio?: boolean;
    hasButtonGroup?: boolean;
    value: ImageType;
    onChange: (
        value:
            | (ImageType & { description?: string | null; uploadSuccess?: boolean })
            | { errors: unknown },
    ) => void;
    name: string;
    placeholder?: string;
    required?: boolean;
    aspectRatio?: string;
};

export const ImageUploadField = (props: Props) => {
    const {
        onChange,
        value,
        name,
        onMetadataChange = noop,
        className,
        error,
        noDropArea = false,
        renderButtons,
        hasButtonGroup,
        renderLoader,
        renderImage,
        showPlaceholderImage = true,
        placeholder,
        allowImageEdit = true,
        showAspectRatio,
        label,
        required,
    } = props;
    const uploadFile = useUploadFile();
    const [croppingRatio, setCroppingRatio] = useState('0');

    const { t } = useTranslation();

    const errorMessage = error?.[0];
    const message = errorMessage?.[0] ?? '';
    const replacements = errorMessage?.[1] ?? {};

    const helperText = errorMessage && t(message, replacements);

    const { trigger, watch } = useReactHookFormContext();
    const aspectRatio = watch('aspect') || props.aspectRatio;
    const handleOnUploadSuccess = (
        payload: ClientInferResponses<
            FilerepoRouter['files']['postFile'] | FilerepoRouter['files']['postUrl']
        >,
    ) => {
        if (payload.status === HTTP_STATUS_CODES.OK) {
            onChange({
                // We want the previous value, but not potential old error data
                ...omit(value, 'errors'),
                url: payload.body._links.data.href,
                elvisId: payload.body.id,
                focusPoint: DEFAULT_FOCUS_POINT,
                cropMarks: DEFAULT_CROP_MARKS,
                // Only file repo is calling it mediaType, we use mimetype
                mimetype: payload.body.mediaType,
                description: payload.body.metadata.name,
                uploadSuccess: true,
            });

            trigger(name);

            onMetadataChange(payload.body.metadata);
        }
    };

    const handleOnUploadFailure = (errors) => {
        onChange({ errors });

        trigger(name, { force: true });
    };

    const handleOnChange = (image) =>
        onChange({
            ...value,
            ...omit(image, ['src']),
        });

    const handleOnDeleteImage = () => {
        onChange({
            ...value,
            url: null,
            elvisId: null,
            focusPoint: DEFAULT_FOCUS_POINT,
            cropMarks: DEFAULT_CROP_MARKS,
        });
    };

    const upload: FileUploadDropAreaProps['uploadFile'] = (file) =>
        uploadFile({
            file,
        });

    const renderImageUpload = () => (
        <ImageUpload
            // TODO: build cropped url
            src={(value && value.url) || ''}
            allowedMimeTypes={allowedMimeTypes}
            uploadImage={uploadFile}
            onChange={onChange}
            onUploadSuccess={handleOnUploadSuccess}
            onUploadFailure={handleOnUploadFailure}
            onDeleteImage={handleOnDeleteImage}
            // This needs to be a inline function to properly update the focus point
            renderImage={(renderProps: RenderImageProps) => {
                if (renderImage) {
                    return renderImage({ ...renderProps, value });
                }

                if (!value || !value.url) {
                    if (showPlaceholderImage) {
                        return <Image placeholder={placeholder} alt="" />;
                    }

                    return null;
                }

                const { handleOnLoad } = renderProps;

                if (value.mimetype === SVG_MIME_TYPE || !allowImageEdit) {
                    return <StyledImage src={value.url} onLoad={handleOnLoad} alt="" />;
                }

                return (
                    <ImageEditor
                        {...props}
                        value={{
                            ...value,
                            src: value.url,
                        }}
                        onChange={handleOnChange}
                        onLoad={handleOnLoad}
                        aspectRatio={aspectRatio}
                        setCroppingRatio={setCroppingRatio}
                    />
                );
            }}
            renderButtons={renderButtons}
            hasButtonGroup={hasButtonGroup}
            renderLoader={renderLoader}
            className={className}
            showAspectRatio={showAspectRatio}
            aspectRatio={aspectRatio}
            croppingRatio={croppingRatio}
        />
    );

    return (
        <FormControl fullWidth error={Boolean(error)} required={required}>
            {label && <FormLabel>{label}</FormLabel>}

            {noDropArea ? (
                renderImageUpload()
            ) : (
                <StyledFileUploadDropArea
                    $error={Boolean(error)}
                    className={className}
                    allowedMimeTypes={allowedMimeTypes}
                    uploadFile={upload}
                    onUploadSuccess={handleOnUploadSuccess}
                    onUploadFailure={handleOnUploadFailure}
                >
                    {value && value.url && renderImageUpload()}
                </StyledFileUploadDropArea>
            )}

            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

const validateRequired = (path) => (value) => {
    const specificValue = get(value, path);

    if (required(specificValue)) {
        return [i18n.t('validator.message.required')];
    }
};

type ImageUploadFormFieldProps<TFieldValues extends FieldValues = FieldValues> = Props &
    Omit<UseFormFieldProps<TFieldValues>, 'validate'>;

const ImageUploadFormField = <TFieldValues extends FieldValues = FieldValues>(
    props: ImageUploadFormFieldProps<TFieldValues>,
) => {
    const validate = props.required ? [validateRequired('url')] : [];

    const {
        name,
        novalidate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
        ...rest
    } = props;

    const formFieldProps = useFormField<TFieldValues>({
        name,
        novalidate,
        validate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
    });

    return <ImageUploadField {...omit(formFieldProps, ['ref'])} {...rest} required={required} />;
};

export default ImageUploadFormField;

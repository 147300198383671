import React, { CSSProperties, PropsWithChildren } from 'react';
import { styled } from '@mui/material';

import { Editor } from '@@editor/helpers';
import { getTextBoxStyle } from '@@editor/plugins/serializable/styles';
import { Element, ElementAttributes, LeadElement } from '@@editor/helpers/Element';

import HeadingWrapper from './HeadingWrapper';

type LeadWrapperProps = {
    readOnly?: boolean;
    placeholderText: string;
};

const LeadWrapper = styled(HeadingWrapper)<LeadWrapperProps>(
    ({ theme, placeholderText, readOnly }) => ({
        ...getTextBoxStyle({ theme, placeholderText }),
        ...(readOnly ? { paddingTop: '0px' } : {}),
        ...theme.typography.editorSubtitleMedium,
    }),
);

type Props = PropsWithChildren<{
    attributes?: ElementAttributes;
    editor: Editor;
    element: LeadElement;
    style?: CSSProperties;
}>;

const Lead = React.forwardRef<HTMLElement, Props>(
    ({ attributes, children, editor, element, style }, ref) => {
        const isEmpty = Element.isEmptyTextElement(element);

        const placeholderText = isEmpty
            ? editor.t('editor.plugin.paragraph.lead.placeholderText')
            : '';

        return (
            <LeadWrapper {...{ ref, style, ...attributes, editor, element, placeholderText }}>
                {children}
            </LeadWrapper>
        );
    },
);

export default Lead;

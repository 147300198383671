import { z } from 'zod';

import { COMPACT_EVENTS, TENNIS_BREAK_TYPES, TICKER_EVENTS } from '@@routes/ticker/constants';

import { Entity, RichText, UnityRichText } from '../../../utils/schemas/schemas';
import { unityVariantsShape, variantsShape } from '../../../utils/schemas/utils';

const TickerEventVariantBase = z.object({
    title: z.string().nullable(),
    visible: z.boolean().optional(),
    citation: z.string().nullable(),
});

const TickerEventVariant = TickerEventVariantBase.extend({
    content: RichText,
});

const UnityTickerEventVariant = TickerEventVariantBase.extend({
    content: UnityRichText,
});

export const TickerEventBase = Entity.extend({
    id: z.string().uuid(),
    variants: variantsShape(TickerEventVariant),
    pinned: z.boolean().optional(),
});

const NewTickerEventBase = TickerEventBase.omit({ id: true, pinned: true });

const UnityTickerEventBase = Entity.extend({
    id: z.string().uuid(),
    variants: unityVariantsShape(UnityTickerEventVariant),
    pinned: z.boolean().optional(),
});

const NewUnityTickerEventBase = UnityTickerEventBase.omit({ id: true, pinned: true });

const BaseCardEvent = z.object({
    type: z.literal(TICKER_EVENTS.SOCCER_CARD),
    keyEvent: z.boolean().optional(),
    data: z.object({
        card: z.union([z.literal('YELLOW_CARD'), z.literal('RED_CARD')]),
        gameMinute: z.number().optional(),
    }),
});

const CardEvent = TickerEventBase.merge(BaseCardEvent);

export type CardEvent = z.infer<typeof CardEvent>;

const NewCardEvent = NewTickerEventBase.merge(BaseCardEvent);

const UnityCardEvent = UnityTickerEventBase.merge(BaseCardEvent);

const NewUnityCardEvent = NewUnityTickerEventBase.merge(BaseCardEvent);

const BaseGoalEvent = z.object({
    keyEvent: z.boolean().optional(),
    data: z.object({
        goals: z.array(z.number()),
        gameMinute: z.number(),
    }),
});

const SoccerGoalEventBase = BaseGoalEvent.extend({
    type: z.literal(TICKER_EVENTS.SOCCER_GOAL),
});

const SoccerGoalEvent = SoccerGoalEventBase.extend(TickerEventBase.shape);

export type SoccerGoalEvent = z.infer<typeof SoccerGoalEvent>;

const NewSoccerGoalEvent = SoccerGoalEventBase.extend(NewTickerEventBase.shape);
const UnitySoccerGoalEvent = SoccerGoalEventBase.extend(UnityTickerEventBase.shape);
const NewUnitySoccerGoalEvent = SoccerGoalEventBase.extend(NewUnityTickerEventBase.shape);

const IceHockeyGoalEventBase = BaseGoalEvent.extend({
    type: z.literal(TICKER_EVENTS.ICE_HOCKEY_GOAL),
});

const IceHockeyGoalEvent = IceHockeyGoalEventBase.extend(TickerEventBase.shape);

export type IceHockeyGoalEvent = z.infer<typeof IceHockeyGoalEvent>;

const NewIceHockeyGoalEvent = IceHockeyGoalEventBase.extend(NewTickerEventBase.shape);
const UnityIceHockeyGoalEvent = IceHockeyGoalEventBase.extend(UnityTickerEventBase.shape);
const NewUnityIceHockeyGoalEvent = IceHockeyGoalEventBase.extend(NewUnityTickerEventBase.shape);

const BaseInfoEvent = z.object({
    keyEvent: z.boolean().optional(),
    data: z
        .object({
            dateTime: z.string().nullish(),
            displayTime: z.boolean().nullish(),
        })
        .nullable(),
});

const StandardInfoEventBase = BaseInfoEvent.extend({
    type: z.literal(TICKER_EVENTS.STANDARD_INFO),
});

const StandardInfoTickerEvent = StandardInfoEventBase.extend(TickerEventBase.shape);

export type StandardInfoTickerEvent = z.infer<typeof StandardInfoTickerEvent>;

const NewStandardInfoTickerEvent = StandardInfoEventBase.extend(NewTickerEventBase.shape);
const UnityStandardInfoEvent = StandardInfoEventBase.extend(UnityTickerEventBase.shape);
const NewUnityStandardInfoEvent = StandardInfoEventBase.extend(NewUnityTickerEventBase.shape);

const BaseSportInfoEvent = z.object({
    keyEvent: z.boolean().optional(),
    data: z
        .object({
            gameMinute: z.number().nullish(),
            dateTime: z.string().nullish(),
            displayTime: z.boolean().nullish(),
        })
        .nullable(),
});

const SoccerInfoEventBase = BaseSportInfoEvent.extend({
    type: z.literal(TICKER_EVENTS.SOCCER_INFO),
});

const SoccerInfoEvent = SoccerInfoEventBase.extend(TickerEventBase.shape);

export type SoccerInfoEvent = z.infer<typeof SoccerInfoEvent>;

const NewSoccerInfoEvent = SoccerInfoEventBase.extend(NewTickerEventBase.shape);
const UnitySoccerInfoEvent = SoccerInfoEventBase.extend(UnityTickerEventBase.shape);
const NewUnitySoccerInfoEvent = SoccerInfoEventBase.extend(NewUnityTickerEventBase.shape);

const IceHockeyInfoEventBase = BaseSportInfoEvent.extend({
    type: z.literal(TICKER_EVENTS.ICE_HOCKEY_INFO),
});

const IceHockeyInfoEvent = IceHockeyInfoEventBase.extend(TickerEventBase.shape);

export type IceHockeyInfoEvent = z.infer<typeof IceHockeyInfoEvent>;

const NewIceHockeyInfoEvent = IceHockeyInfoEventBase.extend(NewTickerEventBase.shape);
const UnityIceHockeyInfoEvent = IceHockeyInfoEventBase.extend(UnityTickerEventBase.shape);
const NewUnityIceHockeyInfoEvent = IceHockeyInfoEventBase.extend(NewUnityTickerEventBase.shape);

const TennisInfoEventBase = BaseSportInfoEvent.extend({
    type: z.literal(TICKER_EVENTS.TENNIS_INFO),
});

const TennisInfoEvent = TennisInfoEventBase.extend(TickerEventBase.shape);

export type TennisInfoEvent = z.infer<typeof TennisInfoEvent>;

const NewTennisInfoEvent = TennisInfoEventBase.extend(NewTickerEventBase.shape);
const UnityTennisInfoEvent = TennisInfoEventBase.extend(UnityTickerEventBase.shape);
const NewUnityTennisInfoEvent = TennisInfoEventBase.extend(NewUnityTickerEventBase.shape);

const BaseShootOutEvent = z.object({
    keyEvent: z.boolean().optional(),
    data: z
        .object({
            penaltyShot: z.array(z.union([z.number(), z.null()])).nullable(),
        })
        .strict(),
});

const SoccerShootOutEventBase = BaseShootOutEvent.extend({
    type: z.literal(TICKER_EVENTS.SOCCER_SHOOT_OUT),
});

const SoccerShootOutEvent = SoccerShootOutEventBase.extend(TickerEventBase.shape);

export type SoccerShootOutEvent = z.infer<typeof SoccerShootOutEvent>;

const NewSoccerShootOutEvent = SoccerShootOutEventBase.extend(NewTickerEventBase.shape);
const UnitySoccerShootOutEvent = SoccerShootOutEventBase.extend(UnityTickerEventBase.shape);
const NewUnitySoccerShootOutEvent = SoccerShootOutEventBase.extend(NewUnityTickerEventBase.shape);

const IceHockeyShootOutEventBase = BaseShootOutEvent.extend({
    type: z.literal(TICKER_EVENTS.ICE_HOCKEY_SHOOT_OUT),
});

const IceHockeyShootOutEvent = IceHockeyShootOutEventBase.extend(TickerEventBase.shape);

export type IceHockeyShootOutEvent = z.infer<typeof IceHockeyShootOutEvent>;

const NewIceHockeyShootOutEvent = IceHockeyShootOutEventBase.extend(NewTickerEventBase.shape);
const UnityIceHockeyShootOutEvent = IceHockeyShootOutEventBase.extend(UnityTickerEventBase.shape);
const NewUnityIceHockeyShootOutEvent = IceHockeyShootOutEventBase.extend(
    NewUnityTickerEventBase.shape,
);

const BaseSubstitutionEvent = z.object({
    type: z.literal(TICKER_EVENTS.SOCCER_SUBSTITUTION),
    keyEvent: z.boolean().optional(),
    data: z
        .object({
            gameMinute: z.number().optional(),
        })
        .strict(),
});

const SubstitutionEvent = TickerEventBase.merge(BaseSubstitutionEvent);

export type SubstitutionEvent = z.infer<typeof SubstitutionEvent>;

const NewSubstitutionEvent = NewTickerEventBase.merge(BaseSubstitutionEvent);

const UnitySubstitutionEvent = UnityTickerEventBase.merge(BaseSubstitutionEvent);

const NewUnitySubstitutionEvent = NewUnityTickerEventBase.merge(BaseSubstitutionEvent);

const SoccerTimeEventEventBase = z.object({
    type: z.literal(TICKER_EVENTS.SOCCER_TIME_EVENT),
    keyEvent: z.boolean().optional(),
    data: z
        .object({
            timeEvent: z.union([
                z.literal('GAME_START'),
                z.literal('GAME_END'),
                z.literal('HALFTIME_START'),
                z.literal('HALFTIME_END'),
                z.literal('OVERTIME_START'),
                z.literal('OVERTIME_END'),
                z.literal('SHOOT_OUT_START'),
                z.literal('SHOOT_OUT_END'),
            ]),
        })
        .strict(),
});

const SoccerTimeEventEvent = SoccerTimeEventEventBase.extend(TickerEventBase.shape);

export type SoccerTimeEventEvent = z.infer<typeof SoccerTimeEventEvent>;

const NewSoccerTimeEventEvent = SoccerTimeEventEventBase.extend(NewTickerEventBase.shape);
const UnitySoccerTimeEventEvent = SoccerTimeEventEventBase.extend(UnityTickerEventBase.shape);
const NewUnitySoccerTimeEventEvent = SoccerTimeEventEventBase.extend(NewUnityTickerEventBase.shape);

const BasePenaltyEvent = z.object({
    type: z.literal(TICKER_EVENTS.ICE_HOCKEY_PENALTY),
    keyEvent: z.boolean().optional(),
    data: z
        .object({
            penaltyTime: z.string(),
            gameMinute: z.number().optional(),
        })
        .strict(),
});

const PenaltyEvent = TickerEventBase.merge(BasePenaltyEvent);

export type PenaltyEvent = z.infer<typeof PenaltyEvent>;

const NewPenaltyEvent = NewTickerEventBase.merge(BasePenaltyEvent);

const UnityPenaltyEvent = UnityTickerEventBase.merge(BasePenaltyEvent);

const NewUnityPenaltyEvent = NewUnityTickerEventBase.merge(BasePenaltyEvent);

const BaseIceHockeyTimeEventEvent = z.object({
    type: z.literal(TICKER_EVENTS.ICE_HOCKEY_TIME_EVENT),
    keyEvent: z.boolean().optional(),
    data: z
        .object({
            timeEvent: z.union([
                z.literal('GAME_START'),
                z.literal('GAME_END'),
                z.literal('PERIOD_START'),
                z.literal('PERIOD_END'),
                z.literal('OVERTIME_START'),
                z.literal('OVERTIME_END'),
                z.literal('SHOOT_OUT_START'),
                z.literal('SHOOT_OUT_END'),
            ]),
        })
        .strict(),
});

const IceHockeyTimeEventEvent = TickerEventBase.merge(BaseIceHockeyTimeEventEvent);

export type IceHockeyTimeEventEvent = z.infer<typeof IceHockeyTimeEventEvent>;

const NewIceHockeyTimeEventEvent = NewTickerEventBase.merge(BaseIceHockeyTimeEventEvent);

const UnityIceHockeyTimeEventEvent = UnityTickerEventBase.merge(BaseIceHockeyTimeEventEvent);

const NewUnityIceHockeyTimeEventEvent = NewUnityTickerEventBase.merge(BaseIceHockeyTimeEventEvent);

const BaseTimeoutEvent = z.object({
    type: z.literal(TICKER_EVENTS.ICE_HOCKEY_TIMEOUT),
    keyEvent: z.boolean().optional(),
    data: z
        .object({
            gameMinute: z.number(),
        })
        .strict(),
});

const TimeoutEvent = TickerEventBase.merge(BaseTimeoutEvent);

export type TimeoutEvent = z.infer<typeof TimeoutEvent>;

const NewTimeoutEvent = NewTickerEventBase.merge(BaseTimeoutEvent);

const UnityTimeoutEvent = UnityTickerEventBase.merge(BaseTimeoutEvent);

const NewUnityTimeoutEvent = NewUnityTickerEventBase.merge(BaseTimeoutEvent);

const SoccerTickerEvent = z.discriminatedUnion('type', [
    CardEvent,
    SoccerGoalEvent,
    SoccerInfoEvent,
    SoccerShootOutEvent,
    SubstitutionEvent,
    SoccerTimeEventEvent,
]);

export type SoccerTickerEvent = z.infer<typeof SoccerTickerEvent>;

const NewSoccerTickerEvent = z.discriminatedUnion('type', [
    NewCardEvent,
    NewSoccerGoalEvent,
    NewSoccerInfoEvent,
    NewSoccerShootOutEvent,
    NewSubstitutionEvent,
    NewSoccerTimeEventEvent,
]);

const UnitySoccerTickerEvent = z.discriminatedUnion('type', [
    UnityCardEvent,
    UnitySoccerGoalEvent,
    UnitySoccerInfoEvent,
    UnitySoccerShootOutEvent,
    UnitySubstitutionEvent,
    UnitySoccerTimeEventEvent,
]);

const NewUnitySoccerTickerEvent = z.discriminatedUnion('type', [
    NewUnityCardEvent,
    NewUnitySoccerGoalEvent,
    NewUnitySoccerInfoEvent,
    NewUnitySoccerShootOutEvent,
    NewUnitySubstitutionEvent,
    NewUnitySoccerTimeEventEvent,
]);

const IceHockeyTickerEvent = z.discriminatedUnion('type', [
    IceHockeyGoalEvent,
    IceHockeyInfoEvent,
    PenaltyEvent,
    IceHockeyShootOutEvent,
    IceHockeyTimeEventEvent,
    TimeoutEvent,
]);

export type IceHockeyTickerEvent = z.infer<typeof IceHockeyTickerEvent>;

const NewIceHockeyTickerEvent = z.discriminatedUnion('type', [
    NewIceHockeyGoalEvent,
    NewIceHockeyInfoEvent,
    NewPenaltyEvent,
    NewIceHockeyShootOutEvent,
    NewIceHockeyTimeEventEvent,
    NewTimeoutEvent,
]);

const UnityIceHockeyTickerEvent = z.discriminatedUnion('type', [
    UnityIceHockeyGoalEvent,
    UnityIceHockeyInfoEvent,
    UnityPenaltyEvent,
    UnityIceHockeyShootOutEvent,
    UnityIceHockeyTimeEventEvent,
    UnityTimeoutEvent,
]);

const NewUnityIceHockeyTickerEvent = z.discriminatedUnion('type', [
    NewUnityIceHockeyGoalEvent,
    NewUnityIceHockeyInfoEvent,
    NewUnityPenaltyEvent,
    NewUnityIceHockeyShootOutEvent,
    NewUnityIceHockeyTimeEventEvent,
    NewUnityTimeoutEvent,
]);

const BaseGameEvent = z.object({
    type: z.literal(TICKER_EVENTS.TENNIS_GAME),
    keyEvent: z.boolean().optional(),
    data: z
        .object({
            sets: z.array(z.tuple([z.number(), z.number()]).nullish()),
            tennisBreak: z.nativeEnum(TENNIS_BREAK_TYPES).optional(),
        })
        .strict(),
});

const GameEvent = TickerEventBase.merge(BaseGameEvent);

export type GameEvent = z.infer<typeof GameEvent>;

const NewGameEvent = NewTickerEventBase.merge(BaseGameEvent);

const UnityGameEvent = UnityTickerEventBase.merge(BaseGameEvent);

const NewUnityGameEvent = NewUnityTickerEventBase.merge(BaseGameEvent);

const BaseTennisMatchEvent = z.object({
    type: z.literal(TICKER_EVENTS.TENNIS_MATCH),
    keyEvent: z.boolean().optional(),
    data: z
        .object({
            sets: z.array(z.tuple([z.number(), z.number()]).nullish()),
        })
        .strict(),
});

const MatchEvent = TickerEventBase.merge(BaseTennisMatchEvent);

export type MatchEvent = z.infer<typeof MatchEvent>;

const NewMatchEvent = NewTickerEventBase.merge(BaseTennisMatchEvent);

const UnityMatchEvent = UnityTickerEventBase.merge(BaseTennisMatchEvent);

const NewUnityMatchEvent = NewUnityTickerEventBase.merge(BaseTennisMatchEvent);

const BasePointEvent = z.object({
    type: z.literal(TICKER_EVENTS.TENNIS_POINT),
    keyEvent: z.boolean().optional(),
    data: z
        .object({
            points: z.array(
                z.union([
                    z.literal('0'),
                    z.literal('15'),
                    z.literal('30'),
                    z.literal('40'),
                    z.literal('AD'),
                ]),
            ),
            tennisBreak: z
                .union([
                    z.literal(TENNIS_BREAK_TYPES.SETBALL),
                    z.literal(TENNIS_BREAK_TYPES.BREAKBALL),
                    z.literal(TENNIS_BREAK_TYPES.MATCHBALL),
                ])
                .optional(),
        })
        .strict(),
});

const PointEvent = TickerEventBase.merge(BasePointEvent);

export type PointEvent = z.infer<typeof PointEvent>;

const NewPointEvent = NewTickerEventBase.merge(BasePointEvent);

const UnityPointEvent = UnityTickerEventBase.merge(BasePointEvent);

const NewUnityPointEvent = NewUnityTickerEventBase.merge(BasePointEvent);

const BaseSetEvent = z.object({
    type: z.literal(TICKER_EVENTS.TENNIS_SET),
    keyEvent: z.boolean().optional(),
    data: z
        .object({
            sets: z.array(z.union([z.null().optional(), z.tuple([z.number(), z.number()])])),
        })
        .strict(),
});

const SetEvent = TickerEventBase.merge(BaseSetEvent);

export type SetEvent = z.infer<typeof SetEvent>;

const NewSetEvent = NewTickerEventBase.merge(BaseSetEvent);

const UnitySetEvent = UnityTickerEventBase.merge(BaseSetEvent);

const NewUnitySetEvent = NewUnityTickerEventBase.merge(BaseSetEvent);

const BaseTiebreakEvent = z.object({
    type: z.literal(TICKER_EVENTS.TENNIS_TIEBREAK),
    keyEvent: z.boolean().optional(),
    data: z
        .object({
            tiebreak: z.tuple([z.number(), z.number()]),
            tennisBreak: z
                .union([
                    z.literal(TENNIS_BREAK_TYPES.SETBALL),
                    z.literal(TENNIS_BREAK_TYPES.MATCHBALL),
                    z.literal(TENNIS_BREAK_TYPES.MINI_BREAK),
                ])
                .optional(),
        })
        .strict(),
});

const TiebreakEvent = TickerEventBase.merge(BaseTiebreakEvent);

export type TiebreakEvent = z.infer<typeof TiebreakEvent>;

const NewTiebreakEvent = NewTickerEventBase.merge(BaseTiebreakEvent);

const UnityTiebreakEvent = UnityTickerEventBase.merge(BaseTiebreakEvent);

const NewUnityTiebreakEvent = NewUnityTickerEventBase.merge(BaseTiebreakEvent);

const TennisTickerEvent = z.discriminatedUnion('type', [
    GameEvent,
    TennisInfoEvent,
    MatchEvent,
    PointEvent,
    SetEvent,
    TiebreakEvent,
]);

export type TennisTickerEvent = z.infer<typeof TennisTickerEvent>;

const NewTennisTickerEVent = z.discriminatedUnion('type', [
    NewGameEvent,
    NewTennisInfoEvent,
    NewMatchEvent,
    NewPointEvent,
    NewSetEvent,
    NewTiebreakEvent,
]);

const UnityTennisTickerEvent = z.discriminatedUnion('type', [
    UnityGameEvent,
    UnityTennisInfoEvent,
    UnityMatchEvent,
    UnityPointEvent,
    UnitySetEvent,
    UnityTiebreakEvent,
]);

const NewUnityTennisTickerEvent = z.discriminatedUnion('type', [
    NewUnityGameEvent,
    NewUnityTennisInfoEvent,
    NewUnityMatchEvent,
    NewUnityPointEvent,
    NewUnitySetEvent,
    NewUnityTiebreakEvent,
]);

const StandardTickerEvent = StandardInfoTickerEvent;

export type StandardTickerEvent = z.infer<typeof StandardTickerEvent>;

const NewStandardTickerEvent = NewStandardInfoTickerEvent;

const UnityStandardTickerEvent = UnityStandardInfoEvent;

const NewUnityStandardTickerEvent = NewUnityStandardInfoEvent;

const BaseCompactEvent = z.object({
    type: z.enum(COMPACT_EVENTS),
    keyEvent: z.boolean().optional(),
    data: z
        .object({
            date: z.string().optional(),
        })
        .strict(),
});

const CompactEvent = TickerEventBase.merge(BaseCompactEvent);

export type CompactEvent = z.infer<typeof CompactEvent>;

const NewCompactEvent = NewTickerEventBase.merge(BaseCompactEvent);

const UnityCompactEvent = UnityTickerEventBase.merge(BaseCompactEvent);

const NewUnityCompactEvent = NewUnityTickerEventBase.merge(BaseCompactEvent);

export const TickerEvent = z.union([
    ...SoccerTickerEvent.options,
    ...IceHockeyTickerEvent.options,
    ...TennisTickerEvent.options,
    CompactEvent,
    StandardTickerEvent,
]);

export type TickerEvent = z.infer<typeof TickerEvent>;

export const NewTickerEvent = z.union([
    ...NewSoccerTickerEvent.options,
    ...NewIceHockeyTickerEvent.options,
    ...NewTennisTickerEVent.options,
    NewCompactEvent,
    NewStandardTickerEvent,
]);

export type NewTickerEvent = z.infer<typeof NewTickerEvent>;

export const UnityTickerEvent = z.union([
    ...UnitySoccerTickerEvent.options,
    ...UnityIceHockeyTickerEvent.options,
    ...UnityTennisTickerEvent.options,
    UnityCompactEvent,
    UnityStandardTickerEvent,
]);

export type UnityTickerEvent = z.infer<typeof UnityTickerEvent>;

export const NewUnityTickerEvent = z.union([
    ...NewUnitySoccerTickerEvent.options,
    ...NewUnityIceHockeyTickerEvent.options,
    ...NewUnityTennisTickerEvent.options,
    NewUnityCompactEvent,
    NewUnityStandardTickerEvent,
]);

export type NewUnityTickerEvent = z.infer<typeof NewUnityTickerEvent>;

const TickerEventReorderOperation = z.literal('move');

type TickerEventReorderOperation = z.infer<typeof TickerEventReorderOperation>;

export const UnityTickerEventReorder = z.array(
    z.object({
        op: TickerEventReorderOperation,
        path: z.string(),
        from: z.string(),
    }),
);

export type UnityTickerEventReorder = z.infer<typeof UnityTickerEventReorder>;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Loader from '@@components/Loader';
import AutofitIframe from '@@components/AutofitIframe/AutofitIframe';

import BrokenPreview from './BrokenPreview';

const EmbedPreview = ({ src, isLoading = Boolean(src), isValid = true }) => {
    const [loading, setLoading] = useState(isLoading);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    const handleOnLoad = () => setLoading(false);

    if (!src) {
        return null;
    }

    return (
        <>
            {loading && <Loader />}
            {isValid && <AutofitIframe src={src} onLoad={handleOnLoad} />}
            {!isValid && !loading && <BrokenPreview />}
        </>
    );
};

EmbedPreview.propTypes = {
    isLoading: PropTypes.bool,
    isValid: PropTypes.bool,
    src: PropTypes.string,
};

export default EmbedPreview;

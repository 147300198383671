import {
    FormControlLabel,
    FormControlLabelProps,
    Switch as MuiSwitch,
    SwitchProps,
    Tooltip,
} from '@mui/material';
import React from 'react';

type BaseProps = SwitchProps & {
    tooltip?: string | boolean;
};

const BaseSwitch = (props: BaseProps) => {
    const { tooltip, ...switchProps } = props;

    return tooltip ? (
        <Tooltip title={tooltip}>
            {/* Needs to be wrapped in span element for tooltip to work */}
            <span tabIndex={0}>
                <MuiSwitch {...switchProps} />
            </span>
        </Tooltip>
    ) : (
        <MuiSwitch {...switchProps} />
    );
};

export type Props = BaseProps & {
    label?: FormControlLabelProps['label'];
    labelPlacement?: FormControlLabelProps['labelPlacement'];
};

const Switch = (props: Props) => {
    const { label, labelPlacement, checked, ...switchProps } = props;

    if (label) {
        return (
            <FormControlLabel
                control={<BaseSwitch {...switchProps} />}
                checked={checked}
                label={label}
                labelPlacement={labelPlacement}
            />
        );
    }

    return <BaseSwitch {...switchProps} checked={checked} />;
};

export default Switch;

const config = {
    selector: 'Isolated__ScreenshotArea',
    path: '/isolated/:scenarioName?/:preset?',
};

Object.assign(config, {
    getPath: (scenarioName, { preset, queryParams } = {}) => {
        let path = config.path;

        path = path.replace(':scenarioName?', scenarioName);
        path = preset ? path.replace(':preset?', preset) : path.replace('/:preset?', '');
        path +=
            (queryParams &&
                '?' + Object.entries(queryParams).map(([key, value]) => `${key}=${value}`)) ||
            '';

        return path;
    },
});

export default config;

import deserialize, { updateLinkOrigin } from '@@editor/serialization/Html/GoogleDocs';
import { Editor, Element, Node } from '@@editor/helpers';
import { InsertElementOptions } from '@@editor/helpers/Editor';
import { PluginOptions } from '@@editor/typings/UnityPlugins';
import { ELEMENT_TYPES, ElementType } from '@@editor/helpers/Element';

import { LinkPluginOptions } from '../text/link/types';

// Google docs will have an id in elements starting with, docs-internal-guid.
const isGoogleDoc = (html: string) => html.includes('id="docs-internal-guid');

export const hasElementType = (fragment: Node, targetType: ElementType) => {
    if (Array.isArray(fragment)) {
        for (const child of fragment) {
            if (hasElementType(child, targetType)) {
                return true;
            }
        }
    } else if (fragment.type === targetType) {
        return true;
    } else if (fragment.children) {
        for (const child of fragment.children) {
            if (hasElementType(child, targetType)) {
                return true;
            }
        }
    }

    return false;
};

const withInsertHtml = (editor, options: PluginOptions & LinkPluginOptions) => {
    const { insertData } = editor;
    const { fetchMetadata } = options;

    return Object.assign(editor, {
        insertData: async (data: DataTransfer, options: InsertElementOptions) => {
            const html = data.getData('text/html');

            // currently we support only Googledocs as an external source.
            if (html && isGoogleDoc(html)) {
                const parsed = new DOMParser().parseFromString(html, 'text/html');
                const fragment = deserialize(parsed.body);

                // This block handles asynchronous operations specifically for links, checking their origins.
                // Initially, these links are treated as external. Subsequently, if any links exist, they are updated accordingly.
                if (hasElementType(fragment, ELEMENT_TYPES.EXTERNAL_LINK)) {
                    const processLinks = async (item: Element) => {
                        const newItem = await updateLinkOrigin(item, fetchMetadata);

                        if (item.children) {
                            newItem.children = await Promise.all(item.children.map(processLinks));
                        }

                        return newItem;
                    };

                    const fragmentWithProcessedLinks = await Promise.all(
                        fragment.map(processLinks),
                    );

                    Editor.insertFragment(editor, fragmentWithProcessedLinks);

                    return;
                }

                Editor.insertFragment(editor, fragment);

                return;
            }

            insertData(data, options);
        },
    });
};

export default withInsertHtml;

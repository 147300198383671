import { overEvery } from 'lodash';

import { BAD_REQUEST } from '@@api/constants/errorCodes';
import { convertToNumber } from '@@utils/idUtils';
import { getTenantSpecificTeaserVariant } from '@@containers/Teaser/utils';
import { DEFAULT_TENANT_ID } from '@@containers/metadata/constants';
import { DEFAULT_TEASER_ID } from '@@containers/Teaser/constants';

import { PlacementItemTypes } from './constants';

const SEPARATOR = '_';

export const serializeColumnId = (tenantId, categoryId) => `${tenantId}${SEPARATOR}${categoryId}`;

export const deserializeColumnId = (id) => convertToNumber(id.split(SEPARATOR));

export const getTenantIdFromColumnId = (id) => deserializeColumnId(id)[0];
export const getCategoryIdFromColumnId = (id) => deserializeColumnId(id)[1];

export const getOpenColumnCategoriesByTenantId = (tenantId, columns) =>
    columns
        .map((columnId) => deserializeColumnId(columnId))
        .filter(([tenantId_]) => tenantId_ === tenantId)
        .map(([, categoryId]) => categoryId);

export const PLACEHOLDER_ID = `0${SEPARATOR}0`;

export const isEditMode = (columnId) => columnId !== PLACEHOLDER_ID;

export const getPlacementItemId = (item, index) => {
    if (item.placementItemType === PlacementItemTypes.PLACEHOLDER) {
        if (item.placeholderType === 'category') {
            return `${item.placeholderTenantId}_${item.placeholderCategoryId}_${item.placeholderIndexPosition}`;
        }

        // We can actually have duplicate placeholder identifiers, that's why we need to add the index as well here!
        // Duplicate ids will break the UI (since they are used as a `key` on the list item component)!
        return `${item.placeholderTenantId}_${item.placeholderIdentifier}_${item.placeholderIndexPosition}_i${index}`;
    }

    return item.metadataId;
};

export const isSameNotNull = (property) => (source, target) =>
    source[property] !== null && source[property] === target[property];

export const isSameProperties = (properties) => overEvery(properties.map(isSameNotNull));
export const isSameColumn = isSameProperties(['categoryId', 'tenantId']);
export const isSamePlacementItemType = isSameNotNull('placementItemType');
export const isSamePlaceholderType = isSameNotNull('placeholderType');
export const isSameMetadataId = isSameNotNull('metadataId');
export const isSamePlaceholderCategory = isSameProperties([
    'placeholderType',
    'placeholderTenantId',
    'placeholderCategoryId',
    'placeholderIndexPosition',
]);
export const isSamePlaceholderCustom = isSameProperties([
    'placeholderType',
    'placeholderTenantId',
    'placeholderTitle',
    'placeholderIdentifier',
    'placeholderIndexPosition',
]);

const placeholderComparators = {
    category: isSamePlaceholderCategory,
    custom: isSamePlaceholderCustom,
};

const isSamePlaceholder = (a, b) => placeholderComparators[a.placeholderType](a, b);

const placementItemComparators = {
    [PlacementItemTypes.DIRECT_PLACEMENT]: isSameMetadataId,
    [PlacementItemTypes.PLACEHOLDER]: isSamePlaceholder,
};

export const isEqualPlacementItem = (a, b) => {
    const comparePlacementItem = placementItemComparators[a.placementItemType];

    return isSamePlacementItemType(a, b) && comparePlacementItem(a, b);
};

export const isPlacementTenantError = (body) =>
    'status' in body &&
    'message' in body &&
    body.status === BAD_REQUEST &&
    body.message &&
    body.message.includes('are not assigned to tenant');

export const getBookmarkedMetadataList = (metadataList, bookmarkList) =>
    metadataList.map((metadata) => {
        if (!metadata) {
            return null;
        }

        const bookmark = bookmarkList.find((bookmark) => bookmark?.metadataId === metadata?.id);

        return {
            ...metadata,
            bookmarkId: bookmark?.id || null,
        };
    });

export const getMetadataListForBookmarks = (bookmarkList, metadataList) =>
    bookmarkList.map((bookmark) => {
        const metadata = metadataList.find((metadata) => metadata?.id === bookmark?.metadataId);

        if (!metadata) {
            return metadata;
        }

        return {
            ...metadata,
            bookmarkId: bookmark?.id || null,
        };
    });

export const mapPlacementItemProps = (props) => {
    const entity = getTenantSpecificTeaserVariant(
        props.entity,
        DEFAULT_TENANT_ID,
        DEFAULT_TEASER_ID,
    );

    const dragSourceItemProps = {
        ...(props.dragSourceItemProps ? props.dragSourceItemProps : {}),
        metadataId: entity.id,
        tenantIds: entity.tenantIds || [],
    };

    return { entity, dragSourceItemProps };
};

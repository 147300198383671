import { RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        index: true,
        lazy: async () => {
            const { MetadataHistoryRoute } = await import(
                /* webpackChunkName: "metadataHistory" */ '@@routes/metadataHistory'
            );

            return { Component: MetadataHistoryRoute };
        },
    },
];

import { pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { styled } from '@mui/material';

import 'react-image-crop/dist/ReactCrop.css';
import { type CropMarks } from '@@api/utils/schemas/schemas';

import {
    validateCropMarks,
    validateAspectRatio,
    getImageRatio,
    getAspectRatioNumber,
} from './utils';
import { DEFAULT_CROP_MARKS, PERCENT_100 } from '../constants';

export const DEFAULT_IMAGE_SIZE = 280;
const DEFAULT_CROP_MARKS_PROPS = Object.keys(DEFAULT_CROP_MARKS);

const HALF = 50;
const THIRD = 33;

type ImageSize = Pick<CropMarks, 'width' | 'height'>;

const calculateImageSize = (image: ImageSize) =>
    image.width > image.height
        ? {
              width: DEFAULT_IMAGE_SIZE,
              height: Math.round((image.height / image.width) * DEFAULT_IMAGE_SIZE),
          }
        : {
              width: Math.round((image.width / image.height) * DEFAULT_IMAGE_SIZE),
              height: DEFAULT_IMAGE_SIZE,
          };

type Props = {
    className?: string;
    src: string;
    value: CropMarks;
    onChange: (crop: CropMarks) => void;
    onImageLoaded: (image: HTMLImageElement) => void;
    aspectRatio: string;
    setCroppingRatio: (ratio: string) => void;
    onComplete: (crop: CropMarks, percentageCrop: CropMarks) => void;
    onImageError: VoidFunction;
};

const ImageCrop = ({
    className,
    onChange = () => {},
    onImageLoaded = () => {},
    aspectRatio,
    value = { x: 0, y: 0, width: PERCENT_100, height: PERCENT_100 },
    src,
    onImageError,
    onComplete,
    setCroppingRatio,
}: Props) => {
    const [minWidth, setMinWidth] = useState<number>(20);
    const [minHeight, setMinHeight] = useState<number>(20);
    const [imageSize, setImageSize] = useState<ImageSize | undefined>(undefined);
    const [crop, setCrop] = useState<Crop>();

    const handleOnChange = (crop: CropMarks, percentCrop: CropMarks) => {
        const areValidCropMarks =
            validateCropMarks(percentCrop, { minWidth, minHeight }) &&
            validateAspectRatio(crop, Number(aspectRatio));

        if (areValidCropMarks) {
            onChange(pick(percentCrop, DEFAULT_CROP_MARKS_PROPS) as CropMarks);
        }
    };

    const handleOnImageLoaded = (image: HTMLImageElement) => {
        const { width, height } = image;

        setMinWidth(width > height ? THIRD : HALF);
        setMinHeight(height > width ? THIRD : HALF);
        setImageSize({
            width,
            height,
        });

        onImageLoaded(image);
    };

    useEffect(() => {
        // set crop only after the image is loaded
        if (imageSize) {
            setCrop({ ...value, unit: '%' });
        }
    }, [value, imageSize]);

    return (
        <ReactCrop
            className={`${className} ReactCrop--no-animate`}
            crop={crop}
            onChange={handleOnChange}
            minWidth={minWidth}
            minHeight={minHeight}
            keepSelection
            ruleOfThirds
            aspect={getAspectRatioNumber(aspectRatio)}
            onComplete={(crop, percentCrop) => {
                setCroppingRatio(getImageRatio(crop));
                onComplete(crop, percentCrop);
            }}
        >
            <img
                src={src}
                onLoad={(e) => handleOnImageLoaded(e.currentTarget)}
                onError={onImageError}
                style={{
                    ...(imageSize ? calculateImageSize(imageSize) : {}),
                }}
            />
        </ReactCrop>
    );
};

export default styled(ImageCrop)({
    userSelect: 'none',
    verticalAlign: 'top',
});

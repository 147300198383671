import { Transforms, NodeEntry } from 'slate';

import { Editor, Element, Node } from '@@editor/helpers';
import ElementCounter from '@@editor/plugins/utils/ElementCounter';

export const withSingleEmbed = (editor: Editor) => {
    const { normalizeNode } = editor;

    return Object.assign(editor, {
        normalizeNode: (entry: NodeEntry<Node>): void => {
            const [node, path] = entry;

            if (Editor.isEditor(node)) {
                const elementCounter = new ElementCounter({
                    embeds: Element.isEmbedElement,
                    paragraphs: Element.isParagraphElement,
                });

                // First we count all the elements within the editor
                for (const [child] of Node.children(editor, path)) {
                    elementCounter.count(child);
                }

                // Then we manage them based on the total count
                for (const [child, childPath] of Node.children(editor, path)) {
                    const overallCount = elementCounter.overallCounter;

                    if (Element.isParagraphElement(child)) {
                        // Do not allow more than 1 paragraph in a row
                        // do not allow paragraph when there is an embed already
                        if (overallCount.embeds === 1 || overallCount.paragraphs > 1) {
                            Transforms.removeNodes(editor, {
                                at: childPath,
                            });

                            return;
                        }

                        const text = Node.string(child);

                        // Remove any text in any paragraph
                        if (text.length > 0) {
                            Transforms.delete(editor, {
                                at: Editor.range(editor, childPath),
                                unit: 'block',
                            });

                            return;
                        }
                    }

                    if (Element.isEmbedElement(child)) {
                        // Do not allow more than 1 embed element

                        if (overallCount.embeds > 1) {
                            Transforms.removeNodes(editor, {
                                at: childPath,
                            });

                            return;
                        }
                    }
                }
            }

            // Fall back to the original `normalizeNode` to enforce other constraints.
            normalizeNode(entry);
        },
    });
};

export default withSingleEmbed;

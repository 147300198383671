import { camelCase } from 'lodash';
import { TFunction } from 'i18next';
import React from 'react';

import { Editor } from '@@editor/helpers';
import Modal from '@@containers/Modal/ModalWithLeavePrompt';
import ButtonGroup from '@@form/components/ButtonGroup';
import { UseReactHookFormProps } from '@@containers/ReactHookForm/types';

import SocialEmbedForm from './SocialEmbedForm';

type Props = {
    children: React.ReactNode;
    editor: Editor;
    formComponent: any;
    formProps: any;
    generateEmbedBlock: (editor, formData, options) => void;
    t: TFunction;
    type: string;
    validate: Validator[];
    value: any;
};

const EditorWithEmbedModal = (props: Props) => {
    const {
        editor,
        children,
        formComponent: FormComponent,
        formProps: passedFormProps,
        generateEmbedBlock,
        type,
        validate,
    } = props;
    const { t } = editor;

    const { isModalOpen, element, at } = editor.isEmbedModalVisible(type) || {
        isModalOpen: false,
        element: null,
        at: null,
    };

    const isEditMode = Boolean(element);

    const handleSubmit: UseReactHookFormProps['onSubmit'] = (formData) => {
        generateEmbedBlock(editor, formData, {
            at,
            replace: isEditMode,
        });

        editor.hideEmbedModal(type);
    };

    const handleCancel = () => {
        editor.hideEmbedModal(type);
    };

    const renderButtons = (Buttons) => (
        <ButtonGroup>
            <Buttons.CancelButton />

            <Buttons.SubmitButton>
                {isEditMode
                    ? editor.t('editor.embeddedcontent.modal.submitButton.editMode')
                    : editor.t('editor.embeddedcontent.modal.submitButton')}
            </Buttons.SubmitButton>
        </ButtonGroup>
    );

    const formProps = {
        type,
        element,
        validate,
        t,
        onCancel: handleCancel,
        onSubmit: handleSubmit,
        editor,
        ...passedFormProps,
    };

    /**
     * * This comment is for translation auto extract
     * t('editor.edit.embed.embeddedComponent')
     * t('editor.edit.embed.embeddedcontent')
     * t('editor.edit.embed.image')
     * t('editor.edit.embed.quote')
     * t('editor.edit.embed.infobox')
     * t('editor.edit.embed.interviewSegment')
     * t('editor.edit.embed.dynamicTeaser')
     * t('editor.edit.embed.poll')
     * t('editor.edit.embed.slideshow')
     * t('editor.edit.embed.facebook')
     * t('editor.edit.embed.instagram')
     * t('editor.edit.embed.zattoo')
     * t('editor.edit.embed.twitter')
     * t('editor.edit.embed.tiktok')
     * t('editor.edit.embed.opta')
     * t('editor.edit.embed.frontendComponent')
     * t('editor.edit.embed.googlemaps')
     * t('editor.edit.embed.videocms')
     * t('editor.edit.embed.youtube')
     * t('editor.insert.embed.embeddedcontent')
     * t('editor.insert.embed.image')
     * t('editor.insert.embed.quote')
     * t('editor.insert.embed.summary')
     * t('editor.insert.embed.slideshow')
     * t('editor.insert.embed.infobox')
     * t('editor.insert.embed.embeddedComponent')
     * t('editor.insert.embed.interviewSegment')
     * t('editor.insert.embed.dynamicTeaser')
     * t('editor.insert.embed.poll')
     * t('editor.insert.embed.videocms')
     */
    const modalTitle = editor.t(
        `editor.${isEditMode ? 'edit' : 'insert'}.embed.${camelCase(type)}`,
    );

    return (
        <>
            <Modal
                disableRestoreFocus
                isOpen={isModalOpen}
                title={modalTitle}
                onClose={handleCancel}
            >
                <FormComponent {...formProps} renderButtons={renderButtons} />
            </Modal>
            {children}
        </>
    );
};

EditorWithEmbedModal.defaultProps = {
    formComponent: SocialEmbedForm,
};

export default EditorWithEmbedModal;

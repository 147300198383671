import { camelCase, upperFirst } from 'lodash';

import ContentStatus from '@@constants/ContentStatus';

export const getColorName = (status: ContentStatus) => `contentStatus.${camelCase(status)}`;

export const getI18nKey = (status: ContentStatus) => `contentStatus.${status}`;

export const getIconName = (status: ContentStatus) =>
    `contentStatus${upperFirst(camelCase(status))}`;

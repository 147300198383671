import React from 'react';

import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import { ReactEditor, Element, Editor } from '@@editor/helpers';
import { InterviewSegmentElement } from '@@editor/helpers/Element';

import InterviewSegmentNode, {
    NODE_TYPE,
    TYPE,
} from '../../layout/components/InterviewSegmentNode';
import { createGenerateLayoutBlock } from '../utils';
import InterviewModal from './components/InterviewModal';

const generateLayoutBlock = createGenerateLayoutBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
});

export const withImportInterview = (editor: Editor, options) => {
    const { isVoid, useInlineEditing } = editor;

    return Object.assign(editor, {
        isVoid: (element: Element) => {
            if (useInlineEditing && Element.isInterviewSegmentRelatedElement(element)) {
                return false;
            }

            return element.type === NODE_TYPE ? true : isVoid(element);
        },
        renderElement: renderElement(editor, [[NODE_TYPE, InterviewSegmentNode]], options),
        renderEditor: renderEditor(
            editor,
            (props) => (
                <InterviewModal
                    {...props}
                    formProps={{ editor, formName: 'LayoutInterviewSegment' }}
                    generateEmbedBlock={generateLayoutBlock}
                />
            ),
            options,
        ),
        showImportInterviewModal: (
            element?: InterviewSegmentElement,
            isEditMode = Boolean(element),
            at = editor.selection && editor.selection.focus,
        ) => {
            editor.setData({
                isImportInterviewModalVisible: {
                    element,
                    at,
                    isEditMode,
                },
            });
        },
        hideImportInterviewModal: () => {
            editor.setData({
                isImportInterviewModalVisible: false,
            });

            requestAnimationFrame(() => {
                ReactEditor.focus(editor);
            });
        },
        isImportInterviewModalVisible: () => editor.getDataIn(['isImportInterviewModalVisible']),
    });
};

export default withImportInterview;

import { styled, Button } from '@mui/material';
import React, { ReactNode, useRef } from 'react';

const HiddenInput = styled('input')({
    display: 'none',
});

export type UploadButtonProps = {
    onUpload: (e: Event, a: Array<File>) => void;
    accept?: string;
    children?: ReactNode;
    className?: string;
    disabled?: boolean;
    domNode?: React.RefObject<HTMLDivElement>;
    handleChangeOfFileInput?: VoidFunction;
    handleMouseDownOnUploadButton?: VoidFunction;
    multiple?: boolean;
    title?: string;
};

const UploadButton = ({
    accept,
    disabled,
    children,
    multiple = true,
    title,
    className,
    onUpload,
}: UploadButtonProps) => {
    const domNode = useRef<HTMLDivElement>(null);

    const handleChangeOfFileInput = (e) => {
        const fileInput = e.target;

        onUpload(e, Array.from(fileInput.files));
        fileInput.value = '';
    };
    const handleMouseDownOnUploadButton = (e) => {
        e.preventDefault();

        const fileInput: HTMLInputElement | undefined | null =
            domNode.current?.querySelector('input[type="file"]');

        fileInput?.click();
    };

    return (
        <div ref={domNode} className={className}>
            <Button
                title={title}
                disabled={disabled}
                variant="outlined"
                onMouseDown={handleMouseDownOnUploadButton}
            >
                {children}
            </Button>

            <HiddenInput
                type="file"
                multiple={multiple}
                accept={accept}
                onChange={handleChangeOfFileInput}
            />
        </div>
    );
};

export default UploadButton;

import { useDrag } from 'react-dnd';

import { TYPES } from '@@constants/DragAndDrop';
import { Element } from '@@editor/helpers';

import { DragItem } from './types';

type Options = {
    blockRef: React.RefObject<HTMLDivElement>;
    editorId: string;
    element: Element;
};

export const useDragBlock = ({ blockRef, editorId, element }: Options) =>
    useDrag(
        () => ({
            type: TYPES.EDITOR_ELEMENT,
            item(): DragItem {
                return { blockRef, editorId, element };
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [],
    );

import React from 'react';
import { FieldValues } from 'react-hook-form';

import InfoboxEditor from '@@form/components/RichTextEditor/InfoboxEditor';
import useUploadFile from '@@api/hooks/useUploadFile';

import RichTextField, { RichTextFieldProps } from './RichTextField';

export const InfoboxEditorField = <TFieldValues extends FieldValues = FieldValues>(
    props: RichTextFieldProps<TFieldValues>,
) => {
    const uploadFile = useUploadFile();

    const { inputProps, ...rest } = props;

    return (
        <RichTextField
            {...rest}
            component={InfoboxEditor}
            inputProps={{
                ...inputProps,
                uploadFile,
            }}
        />
    );
};

export default InfoboxEditorField;

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';

import ContentTeaserImage from './ContentTeaserImage';
import ContentDetails from './ContentDetails';

const Main = styled('div')({
    display: 'flex',
    flexFlow: 'row',
});

const ContentItemContent = (props) => (
    <Main>
        <ContentTeaserImage {...props} />
        <ContentDetails {...props} />
    </Main>
);

ContentItemContent.propTypes = {
    collapsed: PropTypes.bool,
    datePrefix: PropTypes.string,
    entity: PropTypes.object,
    getDate: PropTypes.func,
    isPlaceholder: PropTypes.bool,
    placementOptions: PropTypes.object,
    position: PropTypes.number,
    reducedOpacity: PropTypes.bool,
    renderImage: PropTypes.func,
    onClick: PropTypes.func,
};

export default ContentItemContent;

import { castArray, cloneDeep, get, has, set } from 'lodash';

// TODO: Using `any` in order to stay backwards compatible. Instead we should type any occurence
// of `transformValues` correctly
const transformValues = <TIn extends object | undefined, TOut extends object = any>(
    object: TIn,
    config,
): TOut => {
    const clonedObject = cloneDeep(object);

    if (clonedObject) {
        config.forEach(([paths, fn]) => {
            castArray(paths).forEach((path) => {
                if (has(clonedObject, path)) {
                    const value = get(clonedObject, path);
                    const transformedValue: ReturnType<typeof fn> = fn(value);

                    set(clonedObject, path, transformedValue);
                }
            });
        });
    }

    return clonedObject as unknown as TOut;
};

export default transformValues;

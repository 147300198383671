export const getRel = (target?: string) => {
    if (target === '_blank') {
        return 'noopener noreferrer';
    }

    return '';
};

const getRelList = (target: string) => getRel(target).split(' ').join(',');

export const openInNewTab = (url, withRel = true) => {
    const win = window.open(url, '_blank', withRel ? getRelList('_blank') : '');

    if (win) {
        win.focus();
    }
};

import React from 'react';

export const filterChildren = (filter) => (children: React.ReactNode) =>
    React.Children.toArray(children).filter(filter);

const getDisplayName = (Component) => {
    if (typeof Component === 'string') {
        return Component;
    }

    if (!Component) {
        return undefined;
    }

    return Component.displayName || Component.name || 'Component';
};

export const wrapDisplayName = (BaseComponent, hocName) =>
    `${hocName}(${getDisplayName(BaseComponent)})`;

import { pick } from 'lodash';
import { MenuItemProps, ListItemIcon, MenuItem, Tooltip, Typography } from '@mui/material';
import React from 'react';

import Icon from '@@components/Icon';

type Props<RootComponent extends React.ElementType> = MenuItemProps<
    RootComponent,
    { component?: RootComponent }
> & {
    iconName?: string;
    tooltip?: string | boolean;
};

const ContextMenuItem = <RootComponent extends React.ElementType>({
    children,
    color,
    iconName,
    tooltip,
    ...props
}: Props<RootComponent>) => (
    <Tooltip title={tooltip} placement="left">
        <div>
            <MenuItem
                {...pick(props, ['component', 'disabled', 'href', 'rel', 'target', 'onClick'])}
            >
                <Typography
                    color={color || 'primary.main'}
                    variant="button"
                    display="inline-flex"
                    alignItems="center"
                >
                    {iconName && (
                        <ListItemIcon sx={{ color: 'inherit' }}>
                            <Icon name={iconName} />
                        </ListItemIcon>
                    )}

                    {children}
                </Typography>
            </MenuItem>
        </div>
    </Tooltip>
);

export default ContextMenuItem;

import { NodeEntry, Path, Point, Range, Text } from 'slate';

import crypto from '@@utils/crypto';
import { Editor, Operation } from '@@editor/helpers';
import {
    type SpellCheckSpellAdvices,
    type SpellCheckTexts,
} from '@@api/services/spellChecker/schemas';

import { isSpellAdviceWhitelisted } from './whitelist';
import { EditorSpellAdvice, EditorSpellAdvices } from '../types';
import TextRangeMap, { Options } from './TextRangeMap';

type SpellAdviceRange = Range & { spellAdvice: EditorSpellAdvice };

const EDITOR_TO_SPELL_ADVICES: WeakMap<Editor, EditorSpellAdvices> = new WeakMap();

export const initializeSpellAdvices = (editor: Editor, options?: Options<EditorSpellAdvice>) => {
    const spellAdvices = new TextRangeMap<EditorSpellAdvice>(options);

    EDITOR_TO_SPELL_ADVICES.set(editor, spellAdvices);
};

export const getSpellAdvices = (editor: Editor) => {
    const spellAdvices = EDITOR_TO_SPELL_ADVICES.get(editor);

    if (!spellAdvices) {
        throw new Error('`spellAdvices` needs to be initialized before it can be used!');
    }

    return spellAdvices;
};

export const isSpellAdviceSelected = (editor: Editor, spellAdvice: EditorSpellAdvice) =>
    editor.selection != null &&
    // A spell advice should only be selected, if the selection is within the boundaries of a spell
    // advice. Therefore we have to check for `anchor` and `focus` separately.
    Range.includes(spellAdvice.range, editor.selection.anchor) &&
    Range.includes(spellAdvice.range, editor.selection.focus);

export const removeSpellAdvicesByIndices = (editor: Editor, indices: number[]) => {
    const spellAdvices = getSpellAdvices(editor);

    spellAdvices.transaction(() => {
        spellAdvices.forEachByIndices(indices, (spellAdvice, spellAdviceId) => {
            spellAdvices.delete(spellAdviceId);
        });
    });
};

export const clearSpellAdvices = (editor: Editor) => {
    const spellAdvices = getSpellAdvices(editor);

    spellAdvices.clear();
};

export const importSpellAdvices = (
    editor: Editor,
    newSpellAdvices: SpellCheckSpellAdvices,
    texts: SpellCheckTexts,
) => {
    const indices = Object.keys(texts).map((index) => index.split(',').map(Number)[0]);
    const spellAdvices = getSpellAdvices(editor);

    spellAdvices.transaction(() => {
        // Remove those spell advices, we've requested new ones for
        removeSpellAdvicesByIndices(editor, indices);

        Object.entries(newSpellAdvices).forEach(([index, spellAdvicesForIndex]) => {
            const path: Path = index.split(',').map(Number);
            const start = Editor.start(editor, path);

            spellAdvicesForIndex.forEach((newSpellAdvice) => {
                const { offset, length } = newSpellAdvice;

                // prolexis can return multiple spellcheck errors for the same source,
                // so we merge proposals of those
                let existingSpellAdvice;

                spellAdvices.forEachByIndices([Number(index)], (spellAdvice) => {
                    if (spellAdvice.offset === offset && spellAdvice.length === length) {
                        existingSpellAdvice = spellAdvice;
                    }
                });

                if (existingSpellAdvice) {
                    spellAdvices.set(existingSpellAdvice.id, {
                        ...existingSpellAdvice,
                        proposals: [...existingSpellAdvice.proposals, ...newSpellAdvice.proposals],
                    });

                    return;
                }

                const anchor =
                    offset > 0
                        ? Editor.after(editor, start, {
                              unit: 'character',
                              distance: offset,
                          })
                        : start;

                const focus = Editor.after(editor, start, {
                    unit: 'character',
                    distance: offset + length,
                });

                if (anchor && focus) {
                    const initialRange: Range = { anchor, focus };

                    const spellAdvice: EditorSpellAdvice = {
                        ...newSpellAdvice,
                        // Initial range will always stay the same and will never be transformed
                        initialRange,
                        // Range, on the other hand, will be transformed after every editor operation
                        range: initialRange,
                        text: texts[index].substring(offset, offset + length),
                        id: crypto.randomUUID(),
                        isActive: true,
                    };

                    spellAdvices.set(spellAdvice.id, {
                        ...spellAdvice,
                        isActive: !isSpellAdviceWhitelisted(editor, spellAdvice),
                    });
                }
            });
        });
    });
};

export const decorateSpellAdvices = (editor: Editor, nodeEntry: NodeEntry) => {
    const [node, path] = nodeEntry;
    const spellAdvices = getSpellAdvices(editor);
    const ranges: SpellAdviceRange[] = [];

    // We do not need to process the root node (editor). We also do not need to iterate over text
    // nodes (performance), since their parents anyways already matched, if they contain any
    // spell check results and slate is passing decorations from parent nodes to its children.
    if (path.length > 0 && !Text.isText(node)) {
        spellAdvices.forEachByIndices([path[0]], (spellAdvice) => {
            const { range, isActive } = spellAdvice;

            // Do less expensive checks first (performance)
            if (isActive && Range.includes(range, path)) {
                ranges.push({
                    spellAdvice,
                    ...range,
                });
            }
        });
    }

    return ranges;
};

// Use this function to calculate the new `isActive`-state of spell advices when transforming
// them. Use the `isActive` flag of spell advices in all other places. Do not re-run this
// function again (performance)!
const isSpellAdviceActive = (
    editor: Editor,
    spellAdvice: EditorSpellAdvice,
    prevSpellAdvice?: EditorSpellAdvice,
    nextSpellAdvice?: EditorSpellAdvice,
) => {
    // This function contains performance critical code! It is called on every keystroke.
    // This is why we compute criteria, which is less expensive to compute, first.
    const { initialRange, range } = spellAdvice;
    const hasNotSameIndex = range.anchor.path[0] !== range.focus.path[0];

    // If a spell advice has been split into two different blocks, we do not want to show it
    // active anymore, since it is not the same text anymore, as it was initially checked against
    if (hasNotSameIndex) {
        return false;
    }

    // If a spell advice was whitelisted, we do not want to show it active anymore
    const isWhitelisted = isSpellAdviceWhitelisted(editor, spellAdvice);

    if (isWhitelisted) {
        return false;
    }

    const prevInitialRangeEnd = prevSpellAdvice && Range.end(prevSpellAdvice.initialRange);
    const initialRangeStart = Range.start(initialRange);
    const initialRangeEnd = Range.end(initialRange);
    const nextInitialRangeStart = nextSpellAdvice && Range.start(nextSpellAdvice.initialRange);

    const wasPrevInitiallyAdjacent =
        prevInitialRangeEnd && Point.equals(prevInitialRangeEnd, initialRangeStart);

    const wasNextInitiallyAdjacent =
        nextInitialRangeStart && Point.equals(nextInitialRangeStart, initialRangeEnd);

    const prevRangeEnd = prevSpellAdvice && Range.end(prevSpellAdvice.range);
    const rangeStart = Range.start(range);
    const rangeEnd = Range.end(range);
    const nextRangeStart = nextSpellAdvice && Range.start(nextSpellAdvice.range);

    const isPrevAdjacent = prevRangeEnd && Point.equals(prevRangeEnd, rangeStart);
    const isNextAdjacent = nextRangeStart && Point.equals(nextRangeStart, rangeEnd);

    const isWronglyAdjacent =
        (isPrevAdjacent &&
            !wasPrevInitiallyAdjacent &&
            !prevSpellAdvice.text.endsWith(' ') &&
            !spellAdvice.text.startsWith(' ')) ||
        (isNextAdjacent &&
            !wasNextInitiallyAdjacent &&
            !spellAdvice.text.endsWith(' ') &&
            !nextSpellAdvice.text.startsWith(' '));

    // Two different spell advices can never be adjacent(*), which means: One word can only have one spell
    // advice at a time. If we have two, it means the user, for example, removed a white space between two
    // spell advices. In this scenario it would not be correct to show two spell advices for one word, therefore
    // we'll set both to inactive (and wait for the server to give us the correct spell advice for that word).
    // (*) There are two exceptions:
    // 1. If a spell advice ends with a whitespace, the next spell advice can be adjacent.
    // 2. If a spell advice was initally adjacent to another spell advice, it will be allowed again later on.
    if (isWronglyAdjacent) {
        return false;
    }

    const nextText = Editor.string(editor, range);
    const hasNotSameText = spellAdvice.text !== nextText;

    // If the text of a marked spell advice has changed in the editor, we do not want to show it
    // active anymore, since it is not the same text anymore, as it was initially checked against
    if (hasNotSameText) {
        return false;
    }

    return true;
};

// This function contains performance critical code! It is called on every keystroke.
// Every spell advice relates to a certain range within the editor. Whenever the editors
// contents are changed, those ranges need to change (transform) accordingly. For example,
// if we have a spell advice on path `[0, 1]`, and the user inserts an image on top, this path
// needs to be transformed to `[1, 1]`
export const transformSpellAdvices = (
    editor: Editor,
    operation: Operation,
    dirtyIndices: number[],
) => {
    const spellAdvices = getSpellAdvices(editor);

    const handleTransform = (newSpellAdvice, oldSpellAdvice, prevSpellAdvice, nextSpellAdvice) => {
        const { range, isActive } = newSpellAdvice;
        const nextIsActive = isSpellAdviceActive(
            editor,
            newSpellAdvice,
            prevSpellAdvice,
            nextSpellAdvice,
        );

        // Do less expensive checks first (performance)
        if (isActive !== nextIsActive || !Range.equals(oldSpellAdvice.range, range)) {
            return {
                ...newSpellAdvice,
                isActive: nextIsActive,
            };
        }

        // If we do not return anything from this function, this spell advice will be dropped
    };

    spellAdvices.transformByIndices(dirtyIndices, operation, handleTransform);
};

import { styled } from '@mui/material';

const ButtonGroup = styled('div', {
    shouldForwardProp: (prop: string) => !['disableMargin'].includes(prop),
})<{ disableMargin?: boolean }>(({ disableMargin, theme }) => ({
    width: '100%',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(2),
    '&&&': {
        margin: disableMargin ? 0 : null,
    },
}));

export default ButtonGroup;

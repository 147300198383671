import { useTranslation } from 'react-i18next';
import React from 'react';
import { FieldValues } from 'react-hook-form';

import { getSrcFromIframeString } from '@@utils/DOM';
import { isSecureUrl } from '@@utils/URL';
import {
    singleIFrameOrURLValidator,
    isSecureSingleIframe,
} from '@@form/utils/validators/singleIFrameOrURLValidator';

import TextareaField, { TextareaFieldProps } from './Textarea';

type Value = string;

export const parseValueForUrl = (value: Value) => {
    if (isSecureUrl(value)) {
        return value;
    }

    if (isSecureSingleIframe(value)) {
        return getSrcFromIframeString(value);
    }

    return null;
};

const UrlIframe = <TFieldValues extends FieldValues = FieldValues>(
    props: TextareaFieldProps<TFieldValues>,
) => {
    const { t } = useTranslation();

    return (
        <TextareaField
            placeholder={t('form.fields.urlIframe.placeholder')}
            {...props}
            validate={[singleIFrameOrURLValidator]}
        />
    );
};

export default UrlIframe;

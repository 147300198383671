import { ToggleButton, ToggleButtonProps, styled } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import FloatingBox, { Props as FloatingBoxProps } from '../FloatingBox';

const StyledFloatingBox = styled(FloatingBox)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    borderRadius: `${theme.shape.borderRadius}px`,
}));

type Props = PropsWithChildren<
    ToggleButtonProps & {
        FloatingBoxProps?: FloatingBoxProps;
    }
>;

const FloatingToggleButton = ({ children, FloatingBoxProps, ...props }: Props) => (
    <StyledFloatingBox {...FloatingBoxProps}>
        <ToggleButton {...props}>{children}</ToggleButton>
    </StyledFloatingBox>
);

export default FloatingToggleButton;

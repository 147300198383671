import { IconButton, styled } from '@mui/material';
import React from 'react';

import { useDispatch, useSelector } from '@@store/hooks';
import { getSetting, getSmartSetting, setSetting } from '@@settings/settingsSlice';
import { resolveApiEnv } from '@@scripts/resolveEnv';
import Icon from '@@components/Icon';

import { MODE_BIG } from '../constants';

export const Wrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    position: 'fixed',
    right: 0,
    bottom: 0,
    color: theme.palette.common.white,
    margin: theme.spacing(4),
    padding: `0 ${theme.spacing(1)}`,
    background: theme.palette.warning.main,
    borderRadius: `${theme.shape.borderRadius}px`,
    border: `${theme.borders[2]} solid ${theme.palette.warning.main}`,
    boxShadow: theme.shadows[1],
    zIndex: theme.zIndex.layer3,

    '> ul': {
        display: 'flex',
        alignItems: 'center',
        listStyleType: 'none',
        margin: 0,
        padding: 0,

        '> li': {
            padding: theme.spacing(2),

            [`> ${Icon}`]: {
                '& + *': {
                    marginLeft: theme.spacing(1),
                },

                marginBottom: `-${3}px`,
            },
        },
    },
}));

type Props = {
    healthyMicroserviceCount: number;
    unhealthyMicroserviceCount: number;
    onClickCloseButton: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const SmallToolbar = ({
    healthyMicroserviceCount,
    unhealthyMicroserviceCount,
    onClickCloseButton,
}: Props) => {
    const dispatch = useDispatch();

    const smartReducedUI = useSelector((state) => getSmartSetting(state, { key: 'reducedUI' }));
    const smartUserRole = useSelector((state) => getSmartSetting(state, { key: 'userRole' }));
    const userRole = useSelector((state) => getSetting(state, { key: 'userRole' }));

    const handleClickOnSmallWrapper = () =>
        dispatch(setSetting({ path: 'devToolbar', value: MODE_BIG }));

    const totalMicroserviceCount = healthyMicroserviceCount + unhealthyMicroserviceCount;

    const unhealthyShare = (unhealthyMicroserviceCount * 100) / totalMicroserviceCount;

    const FIRST_THIRD = 0;
    const SECOND_THIRD = 33;
    const THIRD_THIRD = 66;

    const apiHealthIconName =
        (unhealthyShare > THIRD_THIRD && 'face-sad-cry') ||
        (unhealthyShare > SECOND_THIRD && 'face-sad-tear') ||
        (unhealthyShare > FIRST_THIRD && 'face-frown-slight') ||
        'face-smile';

    const apiHealtTitle = `API health (${healthyMicroserviceCount} up, ${unhealthyMicroserviceCount} down)`;

    return (
        <Wrapper onClick={handleClickOnSmallWrapper}>
            <ul>
                <li title="API">
                    <Icon name="plug" />

                    <span>{resolveApiEnv()}</span>
                </li>

                <li title={smartReducedUI ? 'UI is reduced' : 'UI is not reduced'}>
                    <Icon name={smartReducedUI ? 'eye-slash' : 'eye'} />
                </li>

                <li title="User role">
                    <Icon name="users" />

                    <span>{userRole || smartUserRole}</span>
                </li>

                <li title={apiHealtTitle}>
                    <Icon name={apiHealthIconName} />
                </li>

                <li title="Close">
                    <IconButton size="small" onClick={onClickCloseButton}>
                        <Icon name="xmark" />
                    </IconButton>
                </li>
            </ul>
        </Wrapper>
    );
};

export default SmallToolbar;

/* eslint-disable complexity */
import { find, camelCase } from 'lodash';
import { Theme } from '@mui/material';

import {
    PLUGIN_NAMES,
    PLUGIN_ICON_NAMES,
    Plugin,
    PluginList,
    PluginName,
} from '@@editor/typings/UnityPlugins';
import { Editor } from '@@editor/helpers';
import { ELEMENT_TYPES, CrossheadStyle, ANY_LINK } from '@@editor/helpers/Element';
import {
    CROSSHEAD_TRANSLATION_KEYS,
    CROSSHEAD_STYLES,
} from '@@editor/plugins/serializable/text/paragraph/constants';
import setupPlugins from '@@editor/plugins/setup';

import { ToolbarButtonConfig } from '../typings';

export const WRAP_TEXT_PLUGIN_ICON_NAMES = {
    'single-guillemets': 'chevron-left-chevron-right-sharp',
    guillemets: 'chevrons-left-chevrons-right-sharp',
};

export const INSERT_TEXT_PLUGIN_ICON_NAMES = {
    'quotation-dash': 'dash-sharp',
    subscript2: 'subscript-2-sharp-regular',
    superscript2: 'superscript-2-sharp-regular',
    superscript3: 'superscript-3-sharp-regular',
    'soft-hyphen': PLUGIN_ICON_NAMES[PLUGIN_NAMES.SOFT_HYPHEN],
};

type CrossheadButtonConfig = {
    buttons: ToolbarButtonConfig[];
    activeButton: ToolbarButtonConfig;
};

const checkPluginAvailability = (plugin, availablePlugins) =>
    Boolean(
        find(
            availablePlugins,
            (o) => o.name === plugin.name || checkPluginAvailability(plugin, o.options?.plugins),
        ),
    );

export const getCrossheadButtonConfigStyle = (crossheadStyle: CrossheadStyle, theme: Theme) => {
    /* eslint-disable camelcase */
    const styles = {
        _crosshead_subsection: {
            paddingLeft: theme.spacing(4),
        },
        _crosshead_listicle: {
            '&:before': {
                content: '"1. \u00A0"',
            },
        },
        _crosshead_listicle_unordered: {
            '&:before': {
                content: '"• \u00A0"',
            },
        },
    };
    /* eslint-disable camelcase */

    return {
        ...theme.typography.editorSecondarySmall,
        ...(styles[crossheadStyle] || {}),
        fontWeight: crossheadStyle === '_crosshead_subsection' ? '600' : 'bold',
    };
};

const getCrossheadButtonDefaultConfig = (
    editor: Editor,
    crossheadStyle: CrossheadStyle,
    theme: Theme,
): Partial<ToolbarButtonConfig> => ({
    title: editor.t(CROSSHEAD_TRANSLATION_KEYS[crossheadStyle]),
    label: editor.t(CROSSHEAD_TRANSLATION_KEYS[crossheadStyle]),
    onMouseDown: () => editor.toggleCrosshead(crossheadStyle),
    style: getCrossheadButtonConfigStyle(crossheadStyle, theme),
});

const getCrossheadButtonConfig = (editor: Editor, theme: Theme): CrossheadButtonConfig => {
    const buttons: ToolbarButtonConfig[] = [
        {
            id: 'crosshead-paragraph',
            title: editor.t('embed.paragraph.crosshead.default'),
            label: editor.t('embed.paragraph.crosshead.default'),
            active: editor.isParagraphActive(),
            onMouseDown: editor.toParagraph,
        },
        {
            ...getCrossheadButtonDefaultConfig(editor, CROSSHEAD_STYLES.SECTION, theme),
            id: 'crosshead-section-title',
            active: editor.isSectionActive(),
        },
        {
            ...getCrossheadButtonDefaultConfig(editor, CROSSHEAD_STYLES.SUBSECTION, theme),
            id: 'crosshead-subsection-title',
            active: editor.isSubsectionActive(),
        },
        {
            ...getCrossheadButtonDefaultConfig(editor, CROSSHEAD_STYLES.UNORDERED_LISTICLE, theme),
            id: 'crosshead-listicle-unordered',
            active: editor.isUnorderedListicleActive(),
        },
        {
            ...getCrossheadButtonDefaultConfig(editor, CROSSHEAD_STYLES.ORDERED_LISTICLE, theme),
            id: 'crosshead-listicle',
            active: editor.isOrderedListicleActive(),
        },

        {
            id: 'footer',
            title: editor.t('embed.paragraph.footer'),
            label: editor.t('embed.paragraph.footer'),
            active: editor.isFooterActive(),
            onMouseDown: editor.toFooter,
            style: theme.typography.editorSecondaryXsmall,
        },
    ];

    const activeButton: ToolbarButtonConfig =
        buttons.find((action: ToolbarButtonConfig) => action.active) || buttons[0];

    return { buttons, activeButton };
};

const getToggleConfig = (editor: Editor, plugin: Plugin) => ({
    id: plugin.name,
    iconName: PLUGIN_ICON_NAMES[plugin.name],
    // t('editor.apply.subscript')
    // t('editor.apply.superscript')
    // t('editor.apply.bold')
    // t('editor.apply.italic')
    // t('editor.apply.underlined')
    // t('editor.apply.code')
    title: editor.t(`editor.apply.${plugin.name}`),
    active: Editor.isMarkActive(editor, plugin.name),
    invalid:
        Editor.isVoidNodeOnlySelected(editor) ||
        ((Editor.isEmbedCaptionSelected(editor) ||
            Editor.isPollQuestionSelected(editor) ||
            Editor.isPollAnswerSelected(editor)) &&
            (
                [
                    PLUGIN_NAMES.UNDERLINED,
                    PLUGIN_NAMES.SUBSCRIPT,
                    PLUGIN_NAMES.SUPERSCRIPT,
                ] as PluginName[]
            ).includes(plugin.name)) ||
        ((Editor.isInterviewSegmentAnswerSelected(editor) ||
            Editor.isInterviewSegmentQuestionSelected(editor)) &&
            ([PLUGIN_NAMES.UNDERLINED] as PluginName[]).includes(plugin.name)) ||
        ((Editor.isDynamicTeaserTitleSelected(editor) ||
            Editor.isInfoboxTitleSelected(editor) ||
            Editor.isCrossheadSelected(editor)) &&
            (
                [PLUGIN_NAMES.BOLD, PLUGIN_NAMES.ITALIC, PLUGIN_NAMES.UNDERLINED] as PluginName[]
            ).includes(plugin.name)),
    onMouseDown: () => editor.toggleMark(plugin.name),
});

export const getToolbarConfig = (
    editor: Editor,
    plugins: PluginList[],
    theme: Theme,
): ToolbarButtonConfig[] => {
    const formattedPlugins = setupPlugins(plugins);

    return formattedPlugins.reduce<ToolbarButtonConfig[]>((previousValue, plugin) => {
        const isPluginAvailable = checkPluginAvailability(plugin, editor.availablePlugins);

        if (isPluginAvailable) {
            const isVoidNodeOnlySelected: boolean = Editor.isVoidNodeOnlySelected(editor);

            switch (plugin.name) {
                case PLUGIN_NAMES.PARAGRAPH: {
                    const [match] = Editor.blocks(editor, {
                        match: (node) =>
                            ![
                                ELEMENT_TYPES.PARAGRAPH,
                                ELEMENT_TYPES.CROSSHEAD,
                                ELEMENT_TYPES.FOOTER,
                                // @ts-ignore
                            ].includes(node.type),
                    });

                    const containsInvalidBlocks = Boolean(match);
                    const { buttons: actions, activeButton } = getCrossheadButtonConfig(
                        editor,
                        theme,
                    );

                    return [
                        ...previousValue,
                        {
                            id: plugin.name,
                            title: editor.t(`editor.apply.paragraph`),
                            label: activeButton.title,
                            invalid: containsInvalidBlocks,
                            actions,
                            columns: 2,
                        },
                        { id: 'spacer' },
                    ];
                }

                case PLUGIN_NAMES.BOLD:
                case PLUGIN_NAMES.ITALIC:
                case PLUGIN_NAMES.UNDERLINED:
                case PLUGIN_NAMES.SUBSCRIPT:
                case PLUGIN_NAMES.SUPERSCRIPT:
                case PLUGIN_NAMES.CODE:
                    return [...previousValue, getToggleConfig(editor, plugin)];

                case PLUGIN_NAMES.LINK: {
                    const links = Array.from(
                        Editor.inlines(editor, {
                            types: ANY_LINK,
                        }),
                    );

                    return [
                        ...previousValue,
                        {
                            id: 'link',
                            iconName: PLUGIN_ICON_NAMES[plugin.name][0],
                            title: editor.t(`editor.insert.link`),
                            active: Editor.isInlineActive(editor, ANY_LINK),
                            // False if there is more than one link inside the selection
                            invalid:
                                isVoidNodeOnlySelected ||
                                Editor.isQuoteTextSelected(editor) ||
                                Editor.isQuoteCaptionSelected(editor) ||
                                Editor.isInfoboxTitleSelected(editor) ||
                                Editor.isDynamicTeaserTitleSelected(editor),
                            hidden: links.length > 1,
                            onMouseDown: () => {
                                editor.showLinkModal();
                            },
                        },
                        {
                            id: 'unlink',
                            iconName: PLUGIN_ICON_NAMES[plugin.name][1],
                            title: editor.t('editor.remove.link'),
                            invalid:
                                isVoidNodeOnlySelected ||
                                Editor.isQuoteTextSelected(editor) ||
                                Editor.isQuoteCaptionSelected(editor) ||
                                Editor.isInfoboxTitleSelected(editor) ||
                                Editor.isDynamicTeaserTitleSelected(editor),
                            hidden: !Editor.isInlineActive(editor, ANY_LINK),
                            onMouseDown: editor.removeLink,
                        },
                    ];
                }

                case PLUGIN_NAMES.LIST:
                    return [
                        ...previousValue,
                        {
                            id: 'unordered-list',
                            iconName: PLUGIN_ICON_NAMES[plugin.name][0],
                            title: editor.t(`editor.insert.bulletedList`),
                            active: editor.isUnorderedListActive?.(),
                            invalid:
                                isVoidNodeOnlySelected ||
                                Editor.isEmbedCaptionSelected(editor) ||
                                Editor.isPollQuestionSelected(editor) ||
                                Editor.isPollAnswerSelected(editor) ||
                                Editor.isInterviewSegmentQuestionSelected(editor) ||
                                Editor.isInterviewSegmentAnswerSelected(editor) ||
                                Editor.isQuoteTextSelected(editor) ||
                                Editor.isQuoteCaptionSelected(editor) ||
                                Editor.isInfoboxTitleSelected(editor) ||
                                Editor.isDynamicTeaserTitleSelected(editor) ||
                                Editor.isSummaryListSummarySelected(editor),

                            onMouseDown: editor.toggleUnorderedList,
                        },
                        {
                            id: 'ordered-list',
                            iconName: PLUGIN_ICON_NAMES[plugin.name][1],
                            title: editor.t(`editor.insert.numberedList`),
                            active: editor.isOrderedListActive?.(),
                            invalid:
                                isVoidNodeOnlySelected ||
                                Editor.isEmbedCaptionSelected(editor) ||
                                Editor.isPollQuestionSelected(editor) ||
                                Editor.isPollAnswerSelected(editor) ||
                                Editor.isInterviewSegmentQuestionSelected(editor) ||
                                Editor.isInterviewSegmentAnswerSelected(editor) ||
                                Editor.isQuoteTextSelected(editor) ||
                                Editor.isQuoteCaptionSelected(editor) ||
                                Editor.isInfoboxTitleSelected(editor) ||
                                Editor.isDynamicTeaserTitleSelected(editor) ||
                                Editor.isSummaryListSummarySelected(editor),
                            onMouseDown: editor.toggleOrderedList,
                        },
                    ];

                case PLUGIN_NAMES.SPECIAL_CHARACTERS:
                    return [
                        ...previousValue,
                        { id: 'spacer' },
                        {
                            id: 'special-characters',
                            iconName: PLUGIN_ICON_NAMES[plugin.name],
                            title: editor.t(`editor.insert.specialCharacters`),
                            invalid: isVoidNodeOnlySelected,
                            actions: getToolbarConfig(editor, plugin.options.plugins, theme),
                            columnWidth: 50,
                            justifyButtons: 'center',
                        },
                        { id: 'spacer' },
                    ];

                case PLUGIN_NAMES.WRAP_TEXT:
                    return [
                        ...previousValue,
                        {
                            id: plugin.options.id as string,
                            iconName: WRAP_TEXT_PLUGIN_ICON_NAMES[plugin.options.id as string],
                            // t('editor.wrap.singleGuillemets');
                            // t('editor.wrap.guillemets');
                            title: editor.t(`editor.wrap.${camelCase(plugin.options.id)}`),
                            invalid: isVoidNodeOnlySelected,
                            onMouseDown: () => editor.wrapText(plugin.options.text as string),
                        },
                    ];

                case PLUGIN_NAMES.INSERT_TEXT:
                    return [
                        ...previousValue,
                        {
                            id: plugin.options.id as string,
                            iconName: INSERT_TEXT_PLUGIN_ICON_NAMES[plugin.options.id as string],
                            // t('editor.insert.quotationDash')
                            // t('editor.insert.softHyphen')
                            // t('editor.insert.subscript2')
                            // t('editor.insert.superscript2')
                            // t('editor.insert.superscript3')
                            title: editor.t(`editor.insert.${camelCase(plugin.options.id)}`),
                            invalid:
                                isVoidNodeOnlySelected ||
                                Editor.isQuoteTextSelected(editor) ||
                                Editor.isQuoteCaptionSelected(editor),
                            onMouseDown: () => editor.insertText(plugin.options.text as string),
                        },
                    ];

                default:
                    return previousValue;
            }
        }

        return previousValue;
    }, []);
};

import React from 'react';

import { InstagramElement } from '@@editor/helpers/Element';
import { Editor } from '@@editor/helpers';

type Props = {
    editor: Editor;
    element: InstagramElement;
};

const InstagramPost = ({ element }: Props) => {
    const { originalSrc } = element.data;

    // Inserting the embed code directly will display a placeholder provided by Instagram, because
    // we are not authenticated. This is enough because the actual post can be seen in the preview,
    // and by clicking on the placeholder.

    // The originalSrc is validated before being used, so we know it's an instagram embed code
    // eslint-disable-next-line react/no-danger
    return originalSrc ? <div dangerouslySetInnerHTML={{ __html: originalSrc }} /> : null;
};

export default InstagramPost;

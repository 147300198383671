import { LANG_CODES } from '@@lib/i18n/constants';

const UNIT_SECOND_ENGLISH = 's';
const UNIT_MINUTE_ENGLISH = 'm';
const UNIT_HOUR_ENGLISH = 'h';
const UNIT_DAY_ENGLISH = 'd';
const UNIT_WEEK_ENGLISH = 'w';
const UNIT_MONTH_ENGLISH = 'mo';
const UNIT_YEAR_ENGLISH = 'y';

const UNIT_SECOND_GERMAN = 's';
const UNIT_MINUTE_GERMAN = 'm';
const UNIT_HOUR_GERMAN = 'st';
const UNIT_DAY_GERMAN = 't';
const UNIT_WEEK_GERMAN = 'w';
const UNIT_MONTH_GERMAN = 'mo';
const UNIT_YEAR_GERMAN = 'j';

const UNIT_SECOND_FRENCH = 's';
const UNIT_MINUTE_FRENCH = 'm';
const UNIT_HOUR_FRENCH = 'h';
const UNIT_DAY_FRENCH = 'd';
const UNIT_WEEK_FRENCH = 's';
const UNIT_MONTH_FRENCH = 'mo';
const UNIT_YEAR_FRENCH = 'a';

const PREFIX_FUTURE_ENGLISH = 'in';
const PREFIX_FUTURE_GERMAN = 'in';
const PREFIX_FUTURE_FRENCH = 'dans';

const PREFIX_PAST_ENGLISH = '';
const PREFIX_PAST_GERMAN = '';
const PREFIX_PAST_FRENCH = '';

// currently unused, needed for follow-up ticket
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FORMAT_RELATIVE_SHORTER = {
    ENGLISH: {
        future: `${PREFIX_FUTURE_ENGLISH} %s`,
        past: `%s ${PREFIX_PAST_ENGLISH}`,
        s: `%d${UNIT_SECOND_ENGLISH}`,
        ss: `%d${UNIT_SECOND_ENGLISH}`,
        m: `%d${UNIT_MINUTE_ENGLISH}`,
        mm: `%d${UNIT_MINUTE_ENGLISH}`,
        h: `%d${UNIT_HOUR_ENGLISH}`,
        hh: `%d${UNIT_HOUR_ENGLISH}`,
        d: `%d${UNIT_DAY_ENGLISH}`,
        dd: `%d${UNIT_DAY_ENGLISH}`,
        w: `%d${UNIT_WEEK_ENGLISH}`,
        ww: `%d${UNIT_WEEK_ENGLISH}`,
        M: `%d${UNIT_MONTH_ENGLISH}`,
        MM: `%d${UNIT_MONTH_ENGLISH}`,
        y: `%d${UNIT_YEAR_ENGLISH}`,
        yy: `%d${UNIT_YEAR_ENGLISH}`,
    },
    GERMAN: {
        future: `${PREFIX_FUTURE_GERMAN} %s`,
        past: `%s ${PREFIX_PAST_GERMAN}`,
        s: `%d${UNIT_SECOND_GERMAN}`,
        ss: `%d${UNIT_SECOND_GERMAN}`,
        m: `%d${UNIT_MINUTE_GERMAN}`,
        mm: `%d${UNIT_MINUTE_GERMAN}`,
        h: `%d${UNIT_HOUR_GERMAN}`,
        hh: `%d${UNIT_HOUR_GERMAN}`,
        d: `%d${UNIT_DAY_GERMAN}`,
        dd: `%d${UNIT_DAY_GERMAN}`,
        w: `%d${UNIT_WEEK_GERMAN}`,
        ww: `%d${UNIT_WEEK_GERMAN}`,
        M: `%d${UNIT_MONTH_GERMAN}`,
        MM: `%d${UNIT_MONTH_GERMAN}`,
        y: `%d${UNIT_YEAR_GERMAN}`,
        yy: `%d${UNIT_YEAR_GERMAN}`,
    },
    FRENCH: {
        future: `${PREFIX_FUTURE_FRENCH} %s`,
        past: `%s ${PREFIX_PAST_FRENCH}`,
        s: `%d${UNIT_SECOND_FRENCH}`,
        ss: `%d${UNIT_SECOND_FRENCH}`,
        m: `%d${UNIT_MINUTE_FRENCH}`,
        mm: `%d${UNIT_MINUTE_FRENCH}`,
        h: `%d${UNIT_HOUR_FRENCH}`,
        hh: `%d${UNIT_HOUR_FRENCH}`,
        d: `%d${UNIT_DAY_FRENCH}`,
        dd: `%d${UNIT_DAY_FRENCH}`,
        w: `%d${UNIT_WEEK_FRENCH}`,
        ww: `%d${UNIT_WEEK_FRENCH}`,
        M: `%d${UNIT_MONTH_FRENCH}`,
        MM: `%d${UNIT_MONTH_FRENCH}`,
        y: `%d${UNIT_YEAR_FRENCH}`,
        yy: `%d${UNIT_YEAR_FRENCH}`,
    },
};

const CONFIG = {
    [LANG_CODES.FRENCH.toLowerCase()]: {
        calendar: {
            sameDay: '[Aujourd’hui,] LT',
            nextDay: '[Demain,] LT',
            nextWeek: 'dddd[,] LT',
            lastDay: '[Hier,] LT',
            lastWeek: 'dddd[,] LT',
            sameElse: 'll',
        },
    },
    [LANG_CODES.ENGLISH.toLowerCase()]: {
        calendar: {
            sameDay: '[Today,] LT',
            nextDay: '[Tomorrow,] LT',
            nextWeek: 'dddd[,] LT',
            lastDay: '[Yesterday,] LT',
            lastWeek: 'dddd[,] LT',
            sameElse: 'll',
        },
    },
    [LANG_CODES.GERMAN.toLowerCase()]: {
        calendar: {
            sameDay: '[heute,] LT [Uhr]',
            sameElse: 'll',
            nextDay: '[morgen,] LT [Uhr]',
            nextWeek: 'dddd[,] LT [Uhr]',
            lastDay: '[gestern,] LT [Uhr]',
            lastWeek: 'dddd[,] LT [Uhr]',
        },
    },
};

export default CONFIG;

import React, { CSSProperties, PropsWithChildren, Ref } from 'react';
import { Range } from 'slate';
import { find } from 'lodash';
import { useTranslation } from 'react-i18next';
import { styled, Theme } from '@mui/material';

import { Editor, Element, ReactEditor } from '@@editor/helpers';
import { ElementAttributes, ELEMENT_TYPES, TextElement } from '@@editor/helpers/Element';
import ElementWrapper from '@@editor/toolbars/ElementWrapper/ElementWrapper';
import useTenants from '@@api/hooks/resources/useTenants';
import { useTenantVariant } from '@@containers/TenantSpecific/TenantVariantContext';
import { type Tenant } from '@@api/services/tenant/schemas';
import Draggable from '@@editor/plugins/dnd/Draggable';
import { getToolbarSettings } from '@@editor/utils/getToolbarSettings';

import Ol from '../list/components/Ol';
import Ul from '../list/components/Ul';
import Crosshead from '../paragraph/components/Crosshead';
import Paragraph from '../paragraph/components/Paragraph';
import Footer from '../paragraph/components/Footer';
import Title from '../paragraph/components/Title';
import TitleHeader from '../paragraph/components/TitleHeader';
import Lead from '../paragraph/components/Lead';
import {
    getCrossheadTemplateStyle,
    getParagraphTemplateStyle,
    getFooterTemplateStyle,
} from '../../styles';
import { getCrossheadStyle } from '../paragraph/utils';
import { CROSSHEAD_TRANSLATION_KEYS } from '../paragraph/constants';

type Props = {
    attributes: ElementAttributes;
    editor: Editor;
    element: TextElement;
    children: React.ReactNode;
};

const getTemplateStyle = ({
    theme,
    $isSubsection,
    $type,
    $readOnly,
    $crossheadTemplateContent,
    $footerTemplateContent,
}: {
    theme: Theme;
    $isSubsection?: boolean;
    $type: string;
    $readOnly?: boolean;
    $crossheadTemplateContent: string;
    $footerTemplateContent: string;
}) => ({
    color: theme.palette.gold.dark,
    borderColor: theme.palette.primary.light,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    ...($type === ELEMENT_TYPES.FOOTER &&
        getFooterTemplateStyle({ theme, $footerTemplateContent, $readOnly })),
    ...($type === ELEMENT_TYPES.CROSSHEAD &&
        getCrossheadTemplateStyle({ theme, $readOnly, $isSubsection, $crossheadTemplateContent })),
    ...(!$readOnly &&
        $type === ELEMENT_TYPES.PARAGRAPH &&
        getParagraphTemplateStyle({
            theme,
            $readOnly,
        })),
});

const TextWrapper = styled('div')<{
    $templateElement?: boolean;
    $emptyTextElement?: boolean;
    $isArticleEditor?: boolean;
    $type: string;
    $isFocused: boolean;
    $readOnly: boolean;
    $crossheadTemplateContent: string;
    $footerTemplateContent: string;
    $isSubsection?: boolean;
}>(
    ({
        $isArticleEditor,
        $type,
        $isFocused,
        $readOnly,
        theme,
        $emptyTextElement,
        $templateElement,
        $isSubsection,
        $crossheadTemplateContent,
        $footerTemplateContent,
    }) => ({
        ...(!$isArticleEditor && { padding: theme.spacing(2) }),
        ...(!($isFocused && !$readOnly) &&
            ($templateElement || $emptyTextElement) &&
            getTemplateStyle({
                theme,
                $isSubsection,
                $type,
                $readOnly,
                $crossheadTemplateContent,
                $footerTemplateContent,
            })),
        wordBreak: 'break-word',
    }),
);

type RenderContentProps = PropsWithChildren<{
    ref?: Ref<HTMLElement>;
    style?: CSSProperties;
    attributes?: ElementAttributes;
    primaryColor?: string;
}>;

const renderContent = (
    editor: Editor,
    element: TextElement,
    { ref, style, attributes, children, primaryColor }: RenderContentProps,
) => {
    if (Element.isParagraphElement(element)) {
        return (
            <Paragraph
                {...{ ref, style, attributes, editor, element }}
                showBadge={Boolean(editor.isArticleEditor) && !Element.isTemplateElement(element)}
            >
                {children}
                <span contentEditable={false} />
            </Paragraph>
        );
    }

    if (Element.isOrderedListElement(element)) {
        return (
            <Ol {...{ ref, style, attributes, editor, element }}>
                {children}
                <span contentEditable={false} />
            </Ol>
        );
    }

    if (Element.isUnorderedListElement(element)) {
        return (
            <Ul {...{ ref, style, attributes, editor, element }}>
                {children}
                <span contentEditable={false} />
            </Ul>
        );
    }

    if (Element.isCrossheadElement(element)) {
        return (
            <Crosshead {...{ ref, style, attributes, editor, element }}>
                {children}
                {/* The `<span contentEditorable={false} />` lives inside the `Crosshead` component */}
            </Crosshead>
        );
    }

    if (Element.isFooterElement(element)) {
        return (
            <Footer {...{ ref, style, attributes, editor, element }}>
                {children}
                <span contentEditable={false} />
            </Footer>
        );
    }

    if (Element.isTitleElement(element)) {
        return (
            <Title {...{ ref, style, attributes, editor, element }}>
                {children}
                <span contentEditable={false} />
            </Title>
        );
    }

    if (Element.isTitleHeaderElement(element)) {
        return (
            <TitleHeader {...{ ref, style, attributes, editor, element, primaryColor }}>
                {children}
                <span contentEditable={false} />
            </TitleHeader>
        );
    }

    if (Element.isLeadElement(element)) {
        return (
            <Lead {...{ ref, style, attributes, editor, element }}>
                {children}
                <span contentEditable={false} />
            </Lead>
        );
    }

    return children;
};

const TextBlock = (props: Props) => {
    const { attributes, children, editor, element } = props;
    const { isArticleEditor, isArticleHeadingsEditor, selection, withDnd } = editor;

    const { data: tenants } = useTenants();
    const { selectedVariantId } = useTenantVariant();
    const selectedTenant = find(tenants, { id: selectedVariantId }) as Tenant | undefined;
    const primaryColor = selectedTenant?.primaryColor;
    const elementPath = ReactEditor.findPath(editor, element);
    const readOnly = ReactEditor.isReadOnly(editor);
    const { t } = useTranslation();

    const isCollapsedAndFocused = Boolean(
        selection &&
            Range.isCollapsed(selection) &&
            Range.includes(Editor.range(editor, elementPath), selection.focus),
    );

    if (!isArticleEditor || isArticleHeadingsEditor) {
        if (!readOnly && withDnd) {
            return (
                <Draggable attributes={attributes} element={element}>
                    {({ ref, style, attributes }) =>
                        renderContent(editor, element, {
                            ref,
                            style,
                            attributes,
                            children,
                            primaryColor,
                        })
                    }
                </Draggable>
            );
        }

        return renderContent(editor, element, { attributes, children, primaryColor });
    }

    const crossheadTemplateContent = t(`${CROSSHEAD_TRANSLATION_KEYS[getCrossheadStyle(element)]}`);
    const footerTemplateContent = t('embed.paragraph.footer');

    const toolbarSettings = getToolbarSettings(editor, element);

    // Only show toolbar for root level elements, never for nested elements.
    if (!toolbarSettings?.embedWrapperToolbar.enabled) {
        return renderContent(editor, element, { children, primaryColor });
    }

    return (
        <ElementWrapper<TextElement>
            {...{ attributes, children, editor, element, readOnly }}
            widthType="text"
            toolbarConfig={{ actions: ['delete'] }}
        >
            <TextWrapper
                $templateElement={Element.isTemplateElement(element)}
                $emptyTextElement={Element.isEmptyTextElement(element)}
                $type={element.type}
                $footerTemplateContent={footerTemplateContent}
                $crossheadTemplateContent={crossheadTemplateContent}
                $isArticleEditor={isArticleEditor}
                $isFocused={isCollapsedAndFocused}
                $readOnly={readOnly}
            >
                {renderContent(editor, element, { children, primaryColor })}
            </TextWrapper>

            {/* This is needed to limit a blocks selection to itself. For example when triple clicking a paragraph,
                in chrome, the selection would reach until the beginning of the next paragraph. When pressing
                any character, with this selection set, would lead to weird results. In firefox, for example,
                this is not a problem, the selection would end at the very end of the same paragraph. */}
            <span contentEditable={false} />
        </ElementWrapper>
    );
};

export default TextBlock;

import React from 'react';
import { FieldValues } from 'react-hook-form';

import SimpleRichTextEditor from '@@form/components/RichTextEditor/SimpleRichTextEditor';

import RichTextField, { RichTextFieldProps } from './RichTextField';

export const SimpleRichTextEditorField = <TFieldValues extends FieldValues = FieldValues>(
    props: RichTextFieldProps<TFieldValues>,
) => <RichTextField {...props} component={SimpleRichTextEditor} />;

export default SimpleRichTextEditorField;

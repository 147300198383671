import { forEach, isEqual, isPlainObject, identity, isEmpty, isObjectLike } from 'lodash';
import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';

import { mergeWithPath } from '@@utils/collection';
import { Element } from '@@editor/helpers';

type DirtyFields = FieldNamesMarkedBoolean<FieldValues>;

export const getDirtyFieldNames = (
    dirtyFields: DirtyFields,
    currentPath: string[] = [],
    paths: string[] = [],
) => {
    forEach(dirtyFields, (value, key) => {
        if (value === true) {
            paths.push(currentPath.concat(key).join('.'));
        } else {
            getDirtyFieldNames(value, currentPath.concat(key), paths);
        }
    });

    return paths;
};

const arrayComparator = (a, b) => {
    if (isPlainObject(a) && isPlainObject(b)) {
        if (typeof a.variantId !== 'undefined' && typeof b.variantId !== 'undefined') {
            return a.variantId === b.variantId;
        }

        if (typeof a.id !== 'undefined' && typeof b.id !== 'undefined') {
            return a.id === b.id;
        }

        if (typeof a.identifier !== 'undefined' && typeof b.identifier !== 'undefined') {
            return a.identifier === b.identifier;
        }
    }

    return isEqual(a, b);
};

type MergeArrayOptions = {
    comparator?: (left: unknown, right: unknown) => boolean;
    customizer?: (item: unknown, oldItem: unknown, newIndex: number, oldIndex: number) => unknown;
    // For each item that does exist on the left side, but not on the right (which means it was
    // potenatially deleted on the right side), this function will be called
    shouldKeepLeftItemWhenNotPresentOnRightSide?: (index: number) => boolean;
};

// Since modified values have priority over current values we need to pass them as first argument
const mergeArrayWith = (left: unknown[], right: unknown[], options: MergeArrayOptions = {}) => {
    const {
        comparator = isEqual,
        customizer = identity,
        shouldKeepLeftItemWhenNotPresentOnRightSide = () => true,
    } = options;
    const result = [...right];

    let insertPosition = 0;

    left.forEach((leftItem, index) => {
        const leftInResultIndex = result.findIndex(
            (rightItem, i) => i >= insertPosition && comparator(leftItem, rightItem),
        );
        const isLeftFoundInResult = leftInResultIndex >= 0;

        if (!isLeftFoundInResult) {
            if (shouldKeepLeftItemWhenNotPresentOnRightSide(index)) {
                result.splice(
                    insertPosition,
                    0,
                    customizer(leftItem, leftItem, insertPosition, index),
                );

                insertPosition++;
            }
        } else {
            result.splice(
                insertPosition,
                0,
                customizer(result[leftInResultIndex], leftItem, insertPosition, index),
            );
            result.splice(leftInResultIndex + 1, 1);

            insertPosition++;
        }
    });

    return result;
};

type ContainsFieldNameOptions = {
    matchParents?: boolean;
    matchChildren?: boolean;
};

export const containsFieldName = (
    fieldNames: string[],
    fieldName: string,
    options: ContainsFieldNameOptions = {},
) => {
    const { matchParents = false, matchChildren = false } = options;

    return fieldNames.some(
        (currentFieldName) =>
            (matchParents && fieldName.startsWith(currentFieldName + '.')) ||
            (matchChildren && currentFieldName.startsWith(fieldName + '.')) ||
            fieldName === currentFieldName,
    );
};

const isRteFieldValue = (value: unknown) => Array.isArray(value) && Element.isElementList(value);

const isTypeofFieldValueOrNull = (value): value is FieldValues | null =>
    isObjectLike(value) || value === null;

export const mergeFormFieldValues = (
    formValues: FieldValues | null,
    newFormValues: FieldValues | null,
    dirtyFields: FieldNamesMarkedBoolean<FieldValues>,
    registeredFields: string[],
    registeredFieldArrays: string[],
    atoms: RegExp[] = [],
    parentPath = '',
    // eslint-disable-next-line max-params
): FieldValues => {
    const dirtyFieldNames = getDirtyFieldNames(dirtyFields);

    const isDirty = (fieldName) =>
        containsFieldName(dirtyFieldNames, fieldName, { matchParents: true, matchChildren: true });
    const isDirtyUpwards = (fieldName) =>
        containsFieldName(dirtyFieldNames, fieldName, { matchParents: true });
    const isField = (name: string) => containsFieldName(registeredFields, name);
    const isFieldArray = (name: string) => containsFieldName(registeredFieldArrays, name);

    return mergeWithPath(
        {},
        formValues,
        newFormValues,
        // eslint-disable-next-line max-params
        (objValue, srcValue, key, object, source, stack, path) => {
            if (isEmpty(object)) {
                return;
            }

            const fullPath = parentPath ? `${parentPath}.${path}` : path;
            const isArray = Array.isArray(objValue) && Array.isArray(srcValue);
            const isRteArray = isRteFieldValue(objValue) && isRteFieldValue(srcValue);
            const isNonRteArray = isArray && !isRteArray;
            const isAtomic = atoms.some((atom) => atom.test(fullPath));

            if (isAtomic) {
                if (isDirty(fullPath)) {
                    return objValue;
                }

                return srcValue;
            } else if (isNonRteArray) {
                const mergedArray = mergeArrayWith(objValue, srcValue, {
                    comparator: arrayComparator,
                    customizer: (item, oldItem, newIndex, oldIndex) => {
                        const currentPath = `${fullPath}.${oldIndex}`;

                        if (isTypeofFieldValueOrNull(oldItem) && isTypeofFieldValueOrNull(item)) {
                            return mergeFormFieldValues(
                                oldItem,
                                item,
                                dirtyFields,
                                registeredFields,
                                registeredFieldArrays,
                                atoms,
                                currentPath,
                            );
                        }

                        return item;
                    },
                    // For each item that does exist on the left side, but not on the right (which means it was
                    // potenatially deleted on the right side), this function will be called
                    shouldKeepLeftItemWhenNotPresentOnRightSide: (index: number) => {
                        if (isFieldArray(fullPath)) {
                            return isDirty(`${fullPath}.${index}`);
                        }

                        return true;
                    },
                });

                return mergedArray;
            } else if (isField(fullPath) || isRteArray) {
                if (isDirty(fullPath)) {
                    return objValue;
                }

                return srcValue;
            } else if (isDirtyUpwards(fullPath)) {
                return objValue;
            }
        },
    );
};

export const getReadableNameFromFormKey = (formName: string) => {
    if (formName.indexOf('::')) {
        return formName.split('::')[0];
    }

    return formName;
};

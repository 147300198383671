import update from 'immutability-helper';
import invariant from 'tiny-invariant';

export const addItem = (array, index, item) => [
    ...array.slice(0, index),
    item,
    ...array.slice(index),
];

// removes all null, undefined and empty object entries
export const cleanArray = (arr, cleanArraysAndObjects = true) =>
    arr.filter((item) => {
        if (item === null || typeof item === 'undefined') {
            return false;
        }
        if (item === '') {
            return false;
        }
        if (cleanArraysAndObjects && typeof item === 'object' && Object.keys(item).length <= 0) {
            return false;
        }

        return true;
    });

export const moveItem = (array, fromIndex, toIndex) => {
    invariant(fromIndex >= 0, 'Selected item index exceeds array bounds.');

    if (toIndex < 0) {
        // eslint-disable-next-line no-param-reassign
        toIndex = 0;
    }

    const nextArray = update(array, {
        $splice: [
            [fromIndex, 1],
            [toIndex, 0, array[fromIndex]],
        ],
    });

    return nextArray;
};

export const removeIndex = (array, index) => {
    if (typeof index !== 'number' || index > array.length - 1 || index < 0) {
        return array;
    }

    return [...array.slice(0, index), ...array.slice(index + 1)];
};

export const replaceIndex = (array, index, item) => [
    ...array.slice(0, index),
    item,
    ...array.slice(index + 1),
];

export const initialize = (len, value) => new Array(len).fill(value);

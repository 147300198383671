import { pick } from 'lodash';

import { isYoutubeUrl } from '@@utils/socialMedia/youtube';
import { MIME_TYPES } from '@@editor/helpers/Element';
import { GenerateEmbedBlockData } from '@@editor/typings/Embed';

export const generateEmbedBlockData: GenerateEmbedBlockData = ({ embed, src, originalSrc }) => {
    if (!isYoutubeUrl(src)) {
        return;
    }

    return {
        mimetype: MIME_TYPES.YOUTUBE,
        src: (src as URL).href,
        originalSrc,
        embed: pick(embed, ['caption', 'captionTitle', 'credit']),
    };
};

const generateYoutubeEmbedUri = (youtubeId) => `https://www.youtube.com/embed/${youtubeId}`;

const getYoutubeVideoIdFromSrc = (src) => {
    const matched = src && src.match(/v=([^&]+)/);

    return matched && matched[1];
};

// transforms any youtube url into a valid youtube embed url
export const transformYoutubeSrc = (src) => {
    if (src.indexOf('/embed') > -1) {
        return src;
    }

    return generateYoutubeEmbedUri(getYoutubeVideoIdFromSrc(src));
};

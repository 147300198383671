import { withTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';

import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { PLUGIN_CONFIG_TEMPLATES } from '@@editor/constants';
import setupPlugins from '@@editor/plugins/setup';
import usePostSpellCheck from '@@form/hooks/usePostSpellCheck';
import { getInputPlaceholderStyle } from '@@editor/plugins/serializable/styles';

import RichTextEditorBase from './RichTextEditorBase';

const BaseSimpleRichTextEditor = ({ tenantIds, ...props }) => {
    const { multiline } = props;

    const pluginConfig = [
        PLUGIN_CONFIG_TEMPLATES.autoReplaceText,
        PLUGIN_NAMES.PARAGRAPH,
        PLUGIN_CONFIG_TEMPLATES.specialCharacters,
        PLUGIN_CONFIG_TEMPLATES.softHyphen,
    ];

    if (!multiline) {
        pluginConfig.push(PLUGIN_NAMES.SINGLE_LINE);
    }

    if (props.spellCheck) {
        pluginConfig.push(PLUGIN_NAMES.SPELL_CHECKER);
    }

    const stickyToolbarButtons = [
        PLUGIN_CONFIG_TEMPLATES.specialCharacters,
        PLUGIN_CONFIG_TEMPLATES.softHyphen,
    ];

    const spellCheck = usePostSpellCheck();

    const plugins = useMemo(
        () =>
            setupPlugins(pluginConfig, {
                optionsPerPlugin: {
                    [PLUGIN_NAMES.SPELL_CHECKER]: {
                        spellCheck,
                        tenantIds,
                    },
                },
            }),
        [],
    );

    const conditionalProps = {};

    if (!multiline) {
        conditionalProps.minRows = 1;
    }

    return (
        <RichTextEditorBase
            {...conditionalProps}
            {...props}
            plugins={plugins}
            stickyToolbarButtons={stickyToolbarButtons}
            hideFloatingToolbar
        />
    );
};

BaseSimpleRichTextEditor.propTypes = {
    multiline: PropTypes.bool,
    spellCheck: PropTypes.bool,
    tenantIds: PropTypes.arrayOf(PropTypes.number),
    t: PropTypes.func,
};

const SimpleRichTextEditor = withTranslation()(BaseSimpleRichTextEditor);

// Because of how plugins and editors are imported and loaded
// we need to initialize all these styled editors here, to avoid the
// "Cannot access before initialization" error

export const EmbedHeadingEditor = styled(SimpleRichTextEditor, {
    shouldForwardProp: (prop) => !['placeholderText'].includes(prop),
})(({ $templateElement, placeholderText, theme }) => ({
    ...theme.typography.editorSecondaryMedium,
    fontWeight: 'bold',
    padding: `0 0 ${theme.spacing(2)}`,
    ...($templateElement && getInputPlaceholderStyle({ theme, placeholderText })),
}));

export default SimpleRichTextEditor;

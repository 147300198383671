import { Trans } from 'react-i18next';
import { Tooltip, Link, styled, Stack } from '@mui/material';
import React from 'react';

import Spacer from '@@components/Spacer';

import Icon, { IconSize } from './Icon';

const StyledLink = styled(Link)({
    color: 'inherit',
    textDecoration: 'underline',
});

const StyledIcon = styled(Icon)({
    pointerEvents: 'all',
});

export type Props = {
    title: string;
    children?: React.ReactNode;
    iconSize?: IconSize;
    iconColor?: string;
    hideSpacer?: boolean;
    iconName?: string;
};

const InfoTooltip = (props: Props) => {
    const { title, iconSize, iconColor, children, hideSpacer, iconName } = props;

    return (
        <Stack direction="row" alignItems="center">
            {children}

            {!hideSpacer && <Spacer xs h />}

            <Tooltip
                title={
                    <Trans
                        i18nKey={title}
                        components={{
                            linkComponent: <StyledLink target="_blank" />,
                            linkIcon: <Icon name="arrow-up-right-from-square" size="small" />,
                            spacer: <Spacer md v />,
                        }}
                    />
                }
            >
                <StyledIcon
                    name={iconName ?? 'circle-info'}
                    color={iconColor ?? 'primary.light'}
                    size={iconSize ?? 'small'}
                />
            </Tooltip>
        </Stack>
    );
};

export default InfoTooltip;

import { useSpellCheckerClient } from '@@api/services/spellChecker/client';

const usePostSpellCheck = () => {
    const { client: spellCheckerClient } = useSpellCheckerClient();

    const { mutateAsync: spellCheck } = spellCheckerClient.spellCheck.post.useMutation();

    return spellCheck;
};

export default usePostSpellCheck;

import { useTranslation } from 'react-i18next';
import { withReact } from 'slate-react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { useEffect, useMemo, useState } from 'react';

import { useInitEditorDataContext } from '@@editor/plugins/data/EditorDataContext/EditorDataContext';
import { withPlugins } from '@@editor/plugins/setup';
import { Editor } from '@@editor/helpers';
import useForceUpdate from '@@hooks/useForceUpdate';
import makeState from '@@editor/utils/makeState';

import { UseUnityEditorProps } from '../typings/UnityEditor';
import withDefaults, { withFallbacks } from '../plugins/defaults/plugin';
import withData from '../plugins/data/plugin';

const createUnityEditor = () => withFallbacks(withHistory(createEditor()));
const createUnityReactEditor = () => withFallbacks(withHistory(withReact(createEditor())));

const createUseUnityEditor = (isReact?: boolean) => {
    const factoryFn = isReact ? createUnityReactEditor : createUnityEditor;

    return (props: UseUnityEditorProps) => {
        const { editorId, plugins = [] } = props;
        const { editorContextData, setEditorContextData } = useInitEditorDataContext(editorId);
        const { t } = useTranslation();
        const forceUpdate = useForceUpdate();

        const basicEditor = useMemo(() => {
            const editor = factoryFn();

            editor.setEditorContextData = setEditorContextData;

            return editor;
        }, [editorId]);

        basicEditor.id = editorId;
        basicEditor.t = t;
        basicEditor.debug = props.debug;
        basicEditor.disabled = props.disabled;
        // Do not use `forceUpdate` casually! Make sure you know what you're doing! This should only be used for
        // extreme rare edge cases and could lead to significant performance issues!
        basicEditor.forceUpdate = forceUpdate;
        basicEditor.availablePlugins = plugins;
        basicEditor.withDnd = props.withDnd;
        basicEditor.isArticleEditor = props.isArticleEditor;
        basicEditor.isArticleHeadingsEditor = props.isArticleHeadingsEditor;
        basicEditor.hideFloatingToolbar = props.hideFloatingToolbar;
        basicEditor.preserveFloatingToolbarHeight = props.preserveFloatingToolbarHeight;
        basicEditor.defaultFloatingToolbarPlugins = props.defaultFloatingToolbarPlugins;
        basicEditor.useInlineEditing = props.useInlineEditing;
        basicEditor.stickyToolbarButtons = props.stickyToolbarButtons;

        const editor = useMemo(
            // Order is crucial!!! Defaults must be applied first, because they must be called last!
            () => withPlugins(withData(withDefaults(basicEditor)), plugins),
            [basicEditor, plugins],
        );

        useEffect(() => {
            // Keep the editors internal data storage in sync with `editorContextData`
            if (editorContextData) {
                editor.setData(editorContextData);
            }
        }, [editor, editorContextData]);

        useEffect(() => {
            editor.onEditorMount();

            return () => {
                editor.onEditorUnmount();
            };
        }, [editor]);

        const [defaultValue] = useState(makeState);
        // We always need at least one paragraph with at least one text node as child for slate to
        // work correctly
        const nextInitialValue = props.value.length === 0 ? defaultValue : props.value;

        const initialValue = useMemo(() => {
            editor.children = nextInitialValue;

            Editor.normalize(editor, { force: true });

            return editor.children;

            // We want to run this whenever a new editor is created
        }, [basicEditor]);

        return { editor, initialValue, nextInitialValue };
    };
};

export const useUnityEditor = createUseUnityEditor(false);
export const useUnityReactEditor = createUseUnityEditor(true);

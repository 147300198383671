import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { MS, MINUTE, HOUR, DAY, TARGET_DATE_TIME } from './constants';
import { DATETIME } from './formats';
import { formatDate, formatPastDate, isCurrentWeek, formatTargetDateTime } from './utils';

export const DateTime = (props) => {
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        if (props.live) {
            tick();
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
                setTimeoutId(null);
            }
        };
    }, []);

    useEffect(() => {
        if (!props.live && timeoutId) {
            clearTimeout(timeoutId);
        }
    }, [props.live, props.date]);

    const parseDate = (date) => moment(date).valueOf();

    const tick = () => {
        const now = moment.now();
        const seconds = Math.round(Math.abs(now - parseDate(props.date)) / MS);

        let timer;

        if (seconds < MINUTE) {
            timer = MS;
        } else if (seconds < HOUR) {
            timer = MS * MINUTE;
        } else if (seconds < DAY) {
            timer = MS * HOUR;
        }

        if (timer) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setTimeoutId(setTimeout(tick, timer));
        }
    };

    const { date, format, type, fullDatePrefix, titlePrefix } = props;

    if (!moment(date).isValid()) {
        console.warn('Invalid date provided');

        return <span>{moment(date).format()}</span>;
    }

    let parsed;

    switch (type) {
        case 'past': {
            parsed = formatPastDate(date, props.relative);
            break;
        }

        case TARGET_DATE_TIME: {
            parsed = formatTargetDateTime(date);
            break;
        }

        default: {
            parsed = formatDate(date, format);
        }
    }

    const timeTitle = formatDate(date, DATETIME);

    const titleDate = titlePrefix ? titlePrefix.concat(' ', timeTitle) : timeTitle;

    return (
        <span className={props.className}>
            {fullDatePrefix && !isCurrentWeek(date) && type === 'past' && (
                <span>{fullDatePrefix}&nbsp;</span>
            )}
            <time dateTime={date} title={titleDate}>
                {parsed}
            </time>
        </span>
    );
};

DateTime.propTypes = {
    format: PropTypes.string.isRequired,
    live: PropTypes.bool.isRequired,
    className: PropTypes.string,
    date: PropTypes.string,
    fullDatePrefix: PropTypes.string,
    relative: PropTypes.bool,
    titlePrefix: PropTypes.string,
    type: PropTypes.string,
};

DateTime.defaultProps = {
    format: DATETIME,
    live: true,
};

export default DateTime;

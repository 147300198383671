import { omit, pick } from 'lodash';

import crypto from '@@utils/crypto';
import {
    type Category,
    type CategorySection,
    type NewCategorySection,
    type Menu,
    type MenuNode,
    type Sitemap,
    type UnitySitemap,
    type UnityCategory,
    type UnityCategorySection,
    isCategoryCategorySection,
    isDefaultCategorySection,
    NewCategory,
    NewUnityCategory,
    NewUnityCategorySection,
    isNewDefaultCategorySection,
    isNewCategoryCategorySection,
} from '@@api/services/tenant/schemas';

export const deserializeSitemap = (entity: UnitySitemap): Sitemap => ({
    ...entity,
    children: entity.children?.map(deserializeSitemap),
});

export const serializeSitemap = (entity: Sitemap): UnitySitemap => ({
    ...entity,
    children: entity.children?.map(serializeSitemap),
});

// To handle toggling (expand/collapse) of nested list items, we need a unique id per item. Since
// BE is not delivering us one for menu nodes, we need to generate them ourselves and remove them
// again before sending the data back to BE.

const idify = (nodes: MenuNode[] = [], parentId: string | null = null) =>
    nodes.map((node) => {
        const id = crypto.randomUUID();

        return {
            ...node,
            id,
            parentId,
            children: idify(node.children, id),
        };
    });

const unidify = (nodes) =>
    nodes.map((node) => ({ ...omit(node, ['id', 'parentId']), children: unidify(node.children) }));

export const deserializeMenu = (entity: Menu): Menu => ({ ...entity, nodes: idify(entity.nodes) });
export const serializeMenu = (entity: Menu): Menu => ({ ...entity, nodes: unidify(entity.nodes) });

export const serializeCategorySection = (entity: CategorySection): UnityCategorySection => {
    if (isDefaultCategorySection(entity) || isCategoryCategorySection(entity)) {
        return {
            ...entity,
            icon: entity.icon ? pick(entity.icon, ['elvisId', 'url']) : null,
        };
    }

    return omit(entity, 'icon');
};

export const serializeNewCategorySection = (
    entity: NewCategorySection,
): NewUnityCategorySection => {
    if (isNewDefaultCategorySection(entity) || isNewCategoryCategorySection(entity)) {
        return {
            ...entity,
            icon: entity.icon ? pick(entity.icon, ['elvisId', 'url']) : null,
        };
    }

    return omit(entity, 'icon');
};

export const deserializeCategory = ({ subCategories, ...rest }: UnityCategory): Category => ({
    ...rest,
    // Our nested list logic cannot handle custom key names like `subCategories`. It assumes, nested items are always
    // stored underneath a key called `children`. It would have been way too time consuming, to make our
    // nested list logic being able to handle custom key names (also because of TS).
    children: subCategories.map(deserializeCategory),
});

export const serializeCategory = ({
    children,
    ...rest
}: Category | NewCategory): UnityCategory | NewUnityCategory => ({
    ...rest,
    subCategories: children.map(serializeCategory),
});

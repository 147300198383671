import { z } from 'zod';

import { ContentStatus, ContentType, PublicationStatus } from './schemas';

const QueryParams = z.object({
    sort: z.string().optional(),
    q: z.string().optional(),
    tenantIds: z.union([z.string(), z.array(z.string())]).optional(),
    userIds: z.string().optional(),
    contentStatus: ContentStatus.optional(),
    publicationStatus: PublicationStatus.optional(),
    tagIds: z.array(z.string()).optional(),
    categoryIds: z.union([z.string(), z.array(z.string())]).optional(),
    contentType: ContentType.optional(),
    useSearch: z.boolean().optional(),
});

export type QueryParams = z.infer<typeof QueryParams>;

export const SearchParams = z.object({
    q: z.string().optional(),
    tenantIds: z.coerce.string().optional(),
    userIds: z.string().nullish(),
    contentStatus: z.string().optional(),
    publicationStatus: z.string().optional(),
    tagIds: z.coerce.string().optional(),
    categoryIds: z.coerce.string().optional(),
    contentType: z.string().optional(),
});

export type SearchParams = z.infer<typeof SearchParams>;

export const PaginationParams = z.object({
    page: z.coerce.number().optional(),
    size: z.coerce.number().optional(),
    sort: z.string().nullish(),
});

export type PaginationParams = z.infer<typeof PaginationParams>;

/* eslint-disable camelcase */

import { CrossheadElement } from '@@editor/helpers/Element';
import { Editor } from '@@editor/helpers';

export const EDITOR_TO_ORDERED_LISTICLES = new WeakMap<Editor, Set<CrossheadElement>>();

interface ICrossheadTranslationKeys {
    _crosshead_default: string;
    _crosshead_subsection: string;
    _crosshead_listicle: string;
    _crosshead_listicle_unordered: string;
}

export type CrossheadStyle = keyof ICrossheadTranslationKeys;

/**
 * * This comment is for translation auto extract
 * t('embed.paragraph.crosshead.default')
 * t('embed.paragraph.crosshead.sectionTitle')
 * t('embed.paragraph.crosshead.subsectionTitle')
 * t('embed.paragraph.crosshead.orderedListicle')
 * t('embed.paragraph.crosshead.unorderedListicle')
 */
export const CROSSHEAD_TRANSLATION_KEYS: ICrossheadTranslationKeys = {
    _crosshead_default: 'embed.paragraph.crosshead.sectionTitle',
    _crosshead_subsection: 'embed.paragraph.crosshead.subsectionTitle',
    _crosshead_listicle: 'embed.paragraph.crosshead.orderedListicle',
    _crosshead_listicle_unordered: 'embed.paragraph.crosshead.unorderedListicle',
};

export const CROSSHEAD_STYLES: { [key: string]: CrossheadStyle } = {
    SECTION: '_crosshead_default',
    SUBSECTION: '_crosshead_subsection',
    ORDERED_LISTICLE: '_crosshead_listicle',
    UNORDERED_LISTICLE: '_crosshead_listicle_unordered',
} as const;

import { useTranslation } from 'react-i18next';
import React from 'react';
import { styled } from '@mui/material';

import { getInputPlaceholderStyle } from '@@editor/plugins/serializable/styles';
import { Editor, Element, Node, ReactEditor } from '@@editor/helpers';
import { ElementAttributes, EmbedElement } from '@@editor/helpers/Element';

type StyledInterviewSegmentAnswerElementProps = {
    $templateElement: boolean;
    $placeholderText: string;
};

export const StyledInterviewSegmentAnswerElement = styled(
    'div',
)<StyledInterviewSegmentAnswerElementProps>(({ $templateElement, $placeholderText, theme }) => ({
    ...theme.typography.editorPrimaryMedium,
    ...($templateElement && getInputPlaceholderStyle({ theme, placeholderText: $placeholderText })),
}));

type Props = {
    attributes: ElementAttributes;
    children: React.ReactNode;
    editor: Editor;
    element: EmbedElement;
};

const InterviewSegmentAnswerElement = (props: Props) => {
    const { attributes, children, editor, element } = props;
    const { t } = useTranslation();
    const path = ReactEditor.findPath(editor, element);
    const parentNode = Node.parent(editor, path);

    return (
        <>
            <StyledInterviewSegmentAnswerElement
                {...attributes}
                $templateElement={
                    Element.isTemplateElement(parentNode) ||
                    Element.isEmptyInterviewSegmentAnswerElement(element)
                }
                $placeholderText={t('editor.plugin.layout.form.answer')}
            >
                {children}
            </StyledInterviewSegmentAnswerElement>
        </>
    );
};

export default InterviewSegmentAnswerElement;

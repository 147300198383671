import { debounce, pick } from 'lodash';
import { useFocused, useReadOnly } from 'slate-react';
import { NodeEntry, Range, Transforms } from 'slate';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from '@@store/hooks';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderLeaf from '@@editor/plugins/utils/renderLeaf';
import { Editor, Operation, ReactEditor } from '@@editor/helpers';
import { getContentLocaleSetting } from '@@settings/settingsSlice';
import snackbar from '@@containers/Snackbar';
import { SpellCheckerRouter, useSpellCheckerClient } from '@@api/services/spellChecker/client';
import { getQueryParams } from '@@api/utils/getQueryParams';

import { EditorSpellAdvices, EditorSpellAdvice, PluginOptions } from './types';
import { DEBOUNCE_DELAY } from './constants';
import { debouncedSpellCheck } from './utils/request';
import {
    getPluginData,
    setEditorContextPluginData,
    setPluginData,
    isEditorDistractionFree,
} from './utils/data';
import {
    addDirtyIndex,
    clearDirtyIndices,
    getDirtyIndices,
    transformDirtyIndices,
} from './utils/dirtyIndices';
import {
    clearSpellAdvices,
    decorateSpellAdvices,
    getSpellAdvices,
    initializeSpellAdvices,
    transformSpellAdvices,
} from './utils/spellAdvices';
import {
    addSpellAdviceToWhitelist,
    applySpellAdviceWhitelist,
    initializeWhitelist,
    transformSpellAdviceWhitelist,
} from './utils/whitelist';
import useSelectedSpellAdvice from './hooks/useSelectedSpellAdvice';
import useSpellAdvicePopoverPopper from './hooks/useSpellAdvicePopoverPopper';
import SpellAdviceLeaf from './components/SpellAdviceLeaf';
import SpellAdvicePopover from './components/SpellAdvicePopover';

const isSpellCheckerEnabled = (editor: Editor): boolean =>
    Boolean(getPluginData(editor, 'isEnabled')) && !ReactEditor.isReadOnly(editor);

type EditorWrapperProps = PropsWithChildren<{
    editor: Editor;
    options: PluginOptions;
}>;

const EditorWrapper = ({ children, editor, options }: EditorWrapperProps) => {
    const [editorElement, setEditorElement] = useState<Element>();
    const { t } = useTranslation();
    const isEnabled = isSpellCheckerEnabled(editor);
    const isDistractionFree = isEditorDistractionFree(editor);
    const isFocused = useFocused();
    const isReadOnly = useReadOnly();
    const spellAdvices = getSpellAdvices(editor);
    const contentLocale = useSelector(getContentLocaleSetting);
    const { client: spellCheckerClient } = useSpellCheckerClient();
    const {
        selectedSpellAdvice,
        selectedRange,
        reset: resetSelectedSpellAdvice,
    } = useSelectedSpellAdvice(editor, spellAdvices, {
        enabled: isEnabled && isFocused,
    });

    const { mutate: proposeToDictionary } = spellCheckerClient.proposal.post.useMutation();

    const virtualReference = useSpellAdvicePopoverPopper(editor, selectedRange, editorElement);

    useEffect(() => {
        setEditorElement(ReactEditor.toDOMNode(editor, editor));
    }, []);

    useEffect(() => {
        if (isEnabled) {
            debouncedSpellCheck(editor, options);
        } else {
            clearSpellAdvices(editor);
        }
    }, [isEnabled]);

    useEffect(() => {
        setEditorContextPluginData(editor, { isReadOnly });
    }, [isReadOnly]);

    const query = getQueryParams<SpellCheckerRouter['proposal']['post']>({
        tenantIds: options.tenantIds,
    });

    const handleOnSuggest = (spellAdvice: EditorSpellAdvice) =>
        options.tenantIds &&
        proposeToDictionary(
            {
                query,
                body: {
                    text: spellAdvice.text,
                },
            },
            {
                onSuccess: () => {
                    snackbar.success(t('editor.plugin.spellChecker.suggestionSubmitted'));
                },
            },
        );

    return (
        <>
            {children}

            {virtualReference && !isDistractionFree && selectedSpellAdvice && (
                <SpellAdvicePopover
                    virtualReference={virtualReference}
                    // Make sure popover is re-mounted everytime it renders a different spell advice
                    key={selectedSpellAdvice.id}
                    spellAdvice={selectedSpellAdvice}
                    onPropose={(spellAdvice, proposal) => {
                        const node = { text: proposal, ...Editor.marks(editor) };

                        Editor.withoutNormalizing(editor, () => {
                            Transforms.insertNodes(editor, node, {
                                at: spellAdvice.range,
                                select: true,
                            });

                            spellAdvices.forEach((innerSpellAdvice) => {
                                if (
                                    innerSpellAdvice.id !== spellAdvice.id &&
                                    innerSpellAdvice.text === spellAdvice.text
                                ) {
                                    Transforms.insertNodes(editor, node, {
                                        at: innerSpellAdvice.range,
                                        select: false,
                                    });
                                }
                            });
                        });

                        resetSelectedSpellAdvice();
                    }}
                    onIgnore={(spellAdvice) => {
                        addSpellAdviceToWhitelist(editor, spellAdvices, spellAdvice);

                        resetSelectedSpellAdvice();
                    }}
                    onClose={resetSelectedSpellAdvice}
                    onSuggest={handleOnSuggest}
                    enableSuggestions={contentLocale === 'de-CH'}
                />
            )}
        </>
    );
};

export const withSpellChecker = (editor: Editor, options: PluginOptions) => {
    const { apply, decorate, normalizeNode, onChangeValue } = editor;

    const debouncedUpdateSpellAdvicesCount = debounce(
        (editor: Editor, spellAdvices: EditorSpellAdvices) => {
            let spellAdvicesCount = 0;

            spellAdvices.forEach(({ isActive }) => {
                if (isActive) {
                    spellAdvicesCount++;
                }
            });

            setEditorContextPluginData(editor, {
                spellAdvicesCount,
            });
        },
        options.debounceDelay,
    );

    initializeSpellAdvices(editor, {
        onChange: (spellAdvices) => {
            debouncedUpdateSpellAdvicesCount(editor, spellAdvices);

            // We only want to debounce "transform"-actions, since those could be called on every keystroke.
            // If we would debounce all the other actions as well, this could lead to a laggy UI
            if (!spellAdvices.actions.includes('transform')) {
                debouncedUpdateSpellAdvicesCount.flush();
            }
        },
    });

    initializeWhitelist(editor);

    setPluginData(editor, pick(options, ['isEnabled', 'isDistractionFree']));

    return Object.assign(editor, {
        renderEditor: renderEditor(editor, ({ children }) => (
            <EditorWrapper editor={editor} options={options}>
                {children}
            </EditorWrapper>
        )),
        renderLeaf: renderLeaf(editor, [['spellAdvice', SpellAdviceLeaf]]),
        apply: (operation: Operation) => {
            // Order of function calls within this function is important! `apply` might apply changes to the editors
            // document, which then will be used within `applySpellAdviceWhitelist` and `transformSpellAdvices`.
            // And `applySpellAdviceWhitelist`, on the other hand, will modify whitelist data, which
            // `transformSpellAdviceWhitelist` and `transformSpellAdvices` is using and is relying on.

            apply(operation);

            if (isSpellCheckerEnabled(editor)) {
                // We cannot use `dirtyIndices` generated by `normalizeNode` here, because `apply`
                // is executed before `normalizeNode`
                const currentDirtyIndices = editor.getDirtyPaths(operation).reduce(
                    (result, dirtyPath) =>
                        // We need to take action for changes related to whitelist as well over here,
                        // otherwise the spell advice would still be displayed after adding it to the whitelist.
                        // That's why we cannot use `Operation.isSetNodeWhitelistOperation` here, not like we do
                        // in `normalizeNode`
                        dirtyPath.length === 1 ? result.concat(dirtyPath) : result,
                    [],
                );

                applySpellAdviceWhitelist(editor, operation);
                transformDirtyIndices(editor, operation);
                transformSpellAdviceWhitelist(editor, operation, currentDirtyIndices);
                transformSpellAdvices(editor, operation, currentDirtyIndices);
            }
        },
        decorate: (nodeEntry: NodeEntry): Range[] => {
            const ranges = isSpellCheckerEnabled(editor)
                ? decorateSpellAdvices(editor, nodeEntry)
                : [];

            return [...ranges, ...decorate(nodeEntry)];
        },
        normalizeNode: (nodeEntry) => {
            const [, path] = nodeEntry;

            if (isSpellCheckerEnabled(editor)) {
                const lastOperation = editor.operations[editor.operations.length - 1];

                // Changes related to whitelist, do not need a refetch, therefore do not mark
                // this index as dirty, if this is a `SetNodeWhitelistOperation`.
                if (path.length === 1 && !Operation.isSetNodeWhitelistOperation(lastOperation)) {
                    addDirtyIndex(editor, path[0]);
                }
            }

            normalizeNode(nodeEntry);
        },
        onChangeValue: () => {
            if (isSpellCheckerEnabled(editor)) {
                const isError = getPluginData(editor, 'isError');

                if (isError) {
                    // Spell-check the whole document if it is currently in error state, not only parts of it
                    debouncedSpellCheck(editor, options);
                } else {
                    // We cannot re-use `dirtyIndices` from `apply` here, since those could change along the way.
                    // `apply` is always only executed for one single operation, `onChangeValue` is executed, once,
                    // for one to n operations!
                    // Also we need to collect and transform `dirtyIndices` until `debouncedSpellCheck` has succeeded,
                    // otherwise the spell advices, for those canceled `debouncedSpellCheck` function calls, will be
                    // lost
                    const dirtyIndices = getDirtyIndices(editor);

                    // Only spell-check the elements, that were changed since last execution of `onChangeValue` (by
                    // passing `dirtyIndices` to `debouncedSpellCheck`)
                    debouncedSpellCheck(editor, options, dirtyIndices).then((wasCanceled) => {
                        if (!wasCanceled) {
                            clearDirtyIndices(editor);
                        }
                    });
                }
            }

            onChangeValue();
        },
    });
};

const withDebounceDelay =
    (wrappedFunction: typeof withSpellChecker) => (editor: Editor, options: PluginOptions) =>
        wrappedFunction(editor, {
            ...options,
            debounceDelay: options.debounceDelay ?? DEBOUNCE_DELAY,
        });

export default withDebounceDelay(withSpellChecker);

import React from 'react';
import { FieldValues } from 'react-hook-form';

import CaptionEditor from '@@form/components/RichTextEditor/CaptionEditor';

import RichTextField, { RichTextFieldProps } from './RichTextField';

export const CaptionEditorField = <TFieldValues extends FieldValues = FieldValues>(
    props: RichTextFieldProps<TFieldValues>,
) => <RichTextField {...props} component={CaptionEditor} />;

export default CaptionEditorField;

import { parseUrl } from '@@utils/URL';

const document = window.document;

export const isInstagramUrl = (url) => /^http(?:s)?:\/\/(?:www\.)?instagram\.com\/.+/.test(url);

export const isInstagramEmbedCode = (code) => {
    const url = parseInstagramEmbedCode(code);

    if (url instanceof URL) {
        return true;
    }
};

export const parseInstagramEmbedCode = (code) => {
    const el = document.createElement('div');

    el.innerHTML = code;

    const [blockquote] = el.getElementsByTagName('blockquote');

    if (blockquote instanceof HTMLElement) {
        if (blockquote.classList.contains('instagram-media')) {
            const links = Array.from(blockquote.getElementsByTagName('a'));
            const postLink = links.find((link) => isInstagramUrl(link.getAttribute('href')));

            if (postLink) {
                const url = parseUrl(postLink.getAttribute('href'));

                if (url) {
                    return url;
                }
            }
        }
    }
};

import { pickBy } from 'lodash';

import { MARKS } from '@@editor/constants';

const marks = Object.values(MARKS);

const hasActiveMark = (elementOrNode = {}) =>
    Object.entries(elementOrNode).some(
        ([key, value]) => marks.includes(key) && Boolean(value) === true,
    );

const pickMarks = (value, key) => !marks.includes(key) || Boolean(value) === true;

const markRules = {
    deserialize: (element) => {
        if (hasActiveMark(element)) {
            return pickBy(element, pickMarks);
        }
    },
    serialize: (node) => {
        if (hasActiveMark(node)) {
            return pickBy(node, pickMarks);
        }
    },
};

export default markRules;

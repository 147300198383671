import React from 'react';
import { FieldValues } from 'react-hook-form';

import useFormField, { UseFormFieldProps } from '@@form/hooks/useFormField';
import RadioGroup, { RadioGroupProps } from '@@form/components/RadioGroup';

export type RadioGroupFieldProps<TFieldValues extends FieldValues = FieldValues> = RadioGroupProps &
    UseFormFieldProps<TFieldValues>;

const RadioGroupField = <TFieldValues extends FieldValues = FieldValues>(
    props: RadioGroupFieldProps<TFieldValues>,
) => {
    const {
        name,
        validate,
        novalidate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
        ...rest
    } = props;

    const { ref, ...formFieldProps } = useFormField<TFieldValues>({
        name,
        novalidate,
        validate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
    });

    return <RadioGroup inputRef={ref} {...formFieldProps} required={required} {...rest} />;
};

export default RadioGroupField;

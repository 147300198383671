import { useRtElementsClient } from '@@api/services/rtelements/client';

import { getEmbeddedComponentId } from './utils';

const useEmbeddedComponentAsNode = (data) => {
    const id = getEmbeddedComponentId(data);
    const { client: rtElementsClient, queryKeys: rtElementsKeys } = useRtElementsClient();

    const params = { id };
    const { data: rtElementData } = rtElementsClient.rtElements.get.useQuery(
        rtElementsKeys.rtElements.get({ params }),
        { params },
        { enabled: Boolean(id) },
    );

    const rtElement = rtElementData?.body;

    return rtElement?.element[0];
};

export default useEmbeddedComponentAsNode;

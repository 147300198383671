import React from 'react';

import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { parseTwitterEmbedCode, importSdk } from '@@utils/socialMedia/twitter';
import twitterEmbedCode from '@@form/utils/validators/twitterEmbedCode';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import { ELEMENT_TYPES, MIME_TYPES } from '@@editor/helpers/Element';
import { InsertElementOptions } from '@@editor/helpers/Editor';

import { generateEmbedBlockData } from './utils';
import { createGenerateEmbedBlock } from './../utils';
import Tweet from './components/Tweet';
import EditorWithEmbedModal from './../components/EditorWithEmbedModal';
import EmbedWrapper from './../components/EmbedWrapper';

importSdk();

const TYPE = PLUGIN_NAMES.TWITTER;
const NODE_TYPE = ELEMENT_TYPES.TWITTER;

const mimetypeConfigs = {
    [MIME_TYPES.TWITTER]: {
        Component: Tweet,
    },
};

const extendedStyles = {
    [`&[data-type='${TYPE}'] .twitter-tweet`]: {
        margin: '0 auto !important',
    },
};

const TwitterNode = (props) => (
    <EmbedWrapper
        {...props}
        type={TYPE}
        mimetypeConfigs={mimetypeConfigs}
        extendedStyles={extendedStyles}
        toolbarConfig={{
            infos: {
                iconName: PLUGIN_ICON_NAMES[TYPE],
                title: 'Twitter',
            },
            actions: ['edit', 'delete'],
        }}
    />
);

const generateEmbedBlock = createGenerateEmbedBlock({
    contentType: 'html',
    type: TYPE,
    nodeType: NODE_TYPE,
    generateEmbedBlockData,
    parseEmbedCode: parseTwitterEmbedCode,
});

const validate = [twitterEmbedCode];

const internalWithTwitter = (editor, options) => {
    const { insertData } = editor;

    return Object.assign(editor, {
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    validate={validate}
                    generateEmbedBlock={generateEmbedBlock}
                    type={TYPE}
                />
            ),
            options,
        ),
        renderElement: renderElement(editor, [[NODE_TYPE, TwitterNode]], options),
        insertData: (data: DataTransfer, options: InsertElementOptions) => {
            const text = data.getData('text/plain');

            if (text && !twitterEmbedCode(text)) {
                generateEmbedBlock(editor, text);
            } else {
                insertData(data, options);
            }
        },
    });
};

export const withTwitter = (editor, options) =>
    internalWithTwitter(editor, {
        ...options,
        nodeType: NODE_TYPE,
    });

export default withTwitter;

import { AssetPayload, isAssetPayload } from '@@utils/assets';

export type ValidationError = string | [string, Record<string, string | number>];
export type ErrorsPayload = { errors?: ValidationError[] };

export const validateImageFile = (file: File | string | AssetPayload): Promise<ValidationError[]> =>
    new Promise((resolve) => {
        const errors: ValidationError[] = [];

        // If the upload is done with a URL from Assets, we do not have access
        // to the file and therefore cannot validate it.
        // We consider it valid to keep current behaviour
        if (typeof file === 'string' || isAssetPayload(file)) {
            resolve(errors);
        } else {
            const reader = new FileReader();

            reader.onload = function () {
                // file is loaded
                const img = new Image();

                // This is only necessary for test, to be able to trigger
                // a load event on the image. This is automatic in a browser
                if (process.env.NODE_ENV === 'test') {
                    document.body.appendChild(img);
                }

                img.onload = function () {
                    resolve(errors);
                };

                img.onerror = function () {
                    resolve(errors);
                };

                img.src = reader.result as string; // is the data URL because called with readAsDataURL
            };

            reader.readAsDataURL(file);
        }
    });

import { every, get, omit } from 'lodash';

import { type Metadata } from '@@api/services/metadata/schemas';
import { DEFAULT_TENANT_ID } from '@@containers/metadata/constants';
import { getTeasersFromMetadata } from '@@containers/metadata/utils';
import {
    getDefaultVariant,
    getVariantById,
    getVariantIndexById,
} from '@@containers/TenantSpecific/arrayUtils';
import { type Tenant } from '@@api/services/tenant/schemas';

import { DEFAULT_TEASER_ID, SEO_TEASER_ID, VARIANTS_KEY } from './constants';
import { AnyTeaser, DefaultTeaser, Teaser, TeaserId, Teasers, TeaserVariants } from './types';

export const getDefaultTeaserVariant = (teasers?: Metadata['teasers']) =>
    teasers && getDefaultVariant(teasers)[VARIANTS_KEY][DEFAULT_TEASER_ID];

const getTeaserVariantsFromTeasers = (
    teaser: Metadata['teasers'],
    tenantVariantId: Tenant['id'],
): Teasers => getVariantById(teaser, tenantVariantId);

const getTeaserVariantFromTeaser = <T>(teaser: Teasers, variantId: TeaserId): T =>
    get(teaser, `${VARIANTS_KEY}.${variantId}`);

const getTeaserFromMetadataTeaser = <T>(
    teaser: Metadata['teasers'],
    tenantVariantId: Tenant['id'],
    teaserVariantId: TeaserId,
): T =>
    getTeaserVariantFromTeaser<T>(
        getTeaserVariantsFromTeasers(teaser, tenantVariantId),
        teaserVariantId,
    );

export const getTenantSpecificTeaserVariant = <T extends Teaser>(
    entity: Metadata,
    tenantVariantId: Tenant['id'],
    teaserVariantId: TeaserId,
): Metadata & T => ({
    ...entity,
    ...getTeaserFromMetadataTeaser<T>(
        getTeasersFromMetadata(entity),
        tenantVariantId,
        teaserVariantId,
    ),
});

export const getDefaultTenantDefaultTeaser = (entity: Metadata): Metadata & DefaultTeaser =>
    getTenantSpecificTeaserVariant<DefaultTeaser>(entity, DEFAULT_TENANT_ID, DEFAULT_TEASER_ID);

export type DefaultSeoTeaserDiff = {
    tenantId: typeof DEFAULT_TENANT_ID;
    oldTitle: AnyTeaser['title'];
    newTitle: AnyTeaser['title'];
};

export const getDefaultSeoTeaserDiff = (
    prevTeasers: Metadata['teasers'],
    nextTeasers: Metadata['teasers'],
): DefaultSeoTeaserDiff | null => {
    const prevTeaserVariantPath =
        getVariantIndexById(prevTeasers, DEFAULT_TENANT_ID) + `.${VARIANTS_KEY}`;

    const nextTeaserVariantPath =
        getVariantIndexById(nextTeasers, DEFAULT_TENANT_ID) + `.${VARIANTS_KEY}`;

    const prevTeaserVariantTitlePath = `${prevTeaserVariantPath}.seo.title`;
    const nextTeaserVariantTitlePath = `${nextTeaserVariantPath}.seo.title`;

    const prevTitle = get(prevTeasers, prevTeaserVariantTitlePath) as unknown as AnyTeaser['title'];
    const nextTitle = get(nextTeasers, nextTeaserVariantTitlePath) as unknown as AnyTeaser['title'];

    if (prevTitle !== nextTitle) {
        return {
            tenantId: DEFAULT_TENANT_ID,
            oldTitle: prevTitle,
            newTitle: nextTitle,
        };
    }

    return null;
};

export const getSeoTeaserTitle = (titleHeader, title) =>
    [titleHeader, title].filter((str) => Boolean(str)).join(': ');

export const getAdoptDefaultValue = (variants) => {
    const seoTeaser = variants[SEO_TEASER_ID];
    const defaultTeaser = variants[DEFAULT_TEASER_ID];

    const seoTeaserTitleFromDefault = getSeoTeaserTitle(
        defaultTeaser.titleHeader,
        defaultTeaser.title,
    );

    // seoTeaser can be undefined in older articles, that were not migrated
    return seoTeaserTitleFromDefault === seoTeaser?.title && defaultTeaser.lead === seoTeaser?.lead;
};

export const isSocialMediaEqualToDefault = ({ variants }: Teasers) => {
    const socialMediaVariants: Partial<TeaserVariants> = omit(variants, [
        DEFAULT_TEASER_ID,
        SEO_TEASER_ID,
    ]);
    const socialMediaIsEqualToDefault: boolean = every(
        socialMediaVariants,
        omit(variants[DEFAULT_TEASER_ID], 'useEmbedUrl'),
    );

    return socialMediaIsEqualToDefault;
};

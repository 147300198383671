import { isSecureUrl } from '@@utils/URL';
import { getDomNodesFromString, getSrcFromIframeString } from '@@utils/DOM';

export const isSecureSingleIframe = (value: string) => {
    const iframes = getDomNodesFromString<'iframe'>(value, 'iframe');
    const iframeSrc = getSrcFromIframeString(value);
    const isSecureSrc = isSecureUrl(iframeSrc);

    return (
        iframes.length === 1 &&
        isSecureSrc &&
        value.startsWith('<iframe') &&
        value.endsWith('</iframe>')
    );
};

export const singleIFrameOrURLValidator = (value: string) => {
    if (!isSecureUrl(value) && !isSecureSingleIframe(value)) {
        // t('validator.message.oneIframeOrUrl');
        return ['validator.message.oneIframeOrUrl'];
    }
};

import type { ImageMimeType } from '@@constants/ImageMimeTypes';
import config from '@@config';

import PreviewImage from './components/PreviewImage';

export const mimetypeConfigs = Object.values(config.allowedImageMimeTypes).reduce(
    (result, mimeType: ImageMimeType) => ({
        ...result,
        [mimeType]: {
            Component: PreviewImage,
        },
    }),
    {},
);

export const allowedMimeTypes = Object.keys(mimetypeConfigs) as ImageMimeType[];

import { atob, btoa } from 'isomorphic-base64';

const encode = (object) => {
    const content = JSON.stringify(object);
    const encoded = btoa(encodeURIComponent(content));

    return encoded;
};

const decode = (base64) => {
    const content = decodeURIComponent(atob(base64));
    const decoded = JSON.parse(content);

    return decoded;
};

export const serializeNode = (node) => encode(node);
export const deserializeNode = (node) => decode(node);

export default {
    serializeNode,
    deserializeNode,
};

import { z } from 'zod';

import { Entity, RichText, UnityRichText } from '../../utils/schemas/schemas';

export const UnityReleaseNote = Entity.extend({
    id: z.number().optional(),
    body: UnityRichText,
});

export type UnityReleaseNote = z.infer<typeof UnityReleaseNote>;

export const ReleaseNote = Entity.extend({
    id: z.number().optional(),
    body: RichText,
});

export type ReleaseNote = z.infer<typeof ReleaseNote>;

export const ReleaseNoteConfig = z.object({
    visible: z.boolean(),
});

export type ReleaseNoteConfig = z.infer<typeof ReleaseNoteConfig>;

const MaintenanceNoteBase = Entity.extend({
    id: z.number().optional(),
    state: z.string().optional(),
    notification: z.string().nullable(),
});

export const UnityMaintenanceNote = MaintenanceNoteBase.extend({
    note: UnityRichText,
});

export type UnityMaintenanceNote = z.infer<typeof UnityMaintenanceNote>;

export const MaintenanceNote = MaintenanceNoteBase.extend({
    note: RichText,
});

export type MaintenanceNote = z.infer<typeof MaintenanceNote>;

export const Quote = Entity.extend({
    id: z.number().optional(),
    author: z.string(),
    message: z.string(),
});

export type Quote = z.infer<typeof Quote>;

import { styled, Stack } from '@mui/material';

import { DropMode } from '@@hooks/useDropItem';
import Icon from '@@components/Icon';
import PreviewImage from '@@containers/PreviewImage';

export const FIXED_ITEM_HEIGHT = 113;
export const FIXED_ITEM_HEIGHT_PLACEHOLDER = 105;
export const FIXED_ITEM_HEIGHT_COLLAPSED = 89;
export const PREVIEW_IMAGE_WIDTH = 100;

export const isReplaceDropMode = (dropMode) => dropMode === DropMode.REPLACE;

const getItemHeight = ({ fixedHeight, collapsed, isPlaceholder }) => {
    if (!fixedHeight) {
        return 'auto';
    }

    if (isPlaceholder) {
        return `${FIXED_ITEM_HEIGHT_PLACEHOLDER}px`;
    }

    return collapsed ? `${FIXED_ITEM_HEIGHT_COLLAPSED}px` : `${FIXED_ITEM_HEIGHT}px`;
};

export const Wrapper = styled('div', {
    shouldForwardProp: (prop) =>
        !['isPlaceholder', 'canDrag', 'fixedHeight', 'collapsed'].includes(prop),
})(({ theme, isPlaceholder, canDrag, fixedHeight, collapsed }) => ({
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    height: getItemHeight({ fixedHeight, collapsed, isPlaceholder }),
    margin: 0,
    padding: theme.spacing(3, 4),
    paddingTop: isPlaceholder && 0,
    cursor: canDrag ? 'move' : 'auto',
}));

export const LoadingWrapper = styled(Wrapper, {
    shouldForwardProp: (prop) => prop !== 'collapsed',
})(({ collapsed }) => ({
    minHeight: `${collapsed ? FIXED_ITEM_HEIGHT_COLLAPSED : FIXED_ITEM_HEIGHT}px`,
}));

export const Position = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    position: 'absolute',
    left: `-${theme.spacing(4)}`,
    width: theme.spacing(4),
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.typography.subtitle4,
    color: theme.palette.primary.light,
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
}));

export const InfoBar = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.text.secondary,
    ...theme.typography.small,
}));

export const Title = styled('span', {
    shouldForwardProp: (prop) => !['small', 'isEmptyPlaceholder', 'reducedOpacity'].includes(prop),
})(({ theme, small, isEmptyPlaceholder, reducedOpacity }) => ({
    ...(small ? theme.typography.subtitle4 : theme.typography.subtitle3),
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
    position: 'relative',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: isEmptyPlaceholder ? theme.palette.primary['500'] : theme.palette.text.primary,
    ...(isEmptyPlaceholder && { fontStyle: 'italic' }),
    ...(reducedOpacity && { opacity: 0.3 }),
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

export const StyledPreviewImage = styled(PreviewImage)({
    width: `${PREVIEW_IMAGE_WIDTH}px`,
    height: '56px',
    objectFit: 'cover',
});

import React, { useReducer } from 'react';

import ModalContext from './ModalContext';
import { applyAction, createActionFunctions } from './reducer';
import { Action, State } from './types';

const initialState: State = {
    modals: [],
};

type Reducer = (prevState: State, action: Action) => State;

type Props = { children: React.ReactElement };
const ModalProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer<Reducer>(applyAction, initialState);
    const actions = createActionFunctions(dispatch);

    return (
        <ModalContext.Provider value={{ state, actions }}>
            {state.modals.map((modal) => {
                const ModalComponent = modal.component;

                return (
                    <ModalComponent
                        key={modal.id}
                        {...modal.props}
                        onClose={() => {
                            actions.hide(modal.id);

                            modal.props.onClose?.();
                        }}
                    />
                );
            })}

            {children}
        </ModalContext.Provider>
    );
};

export default ModalProvider;

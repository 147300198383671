import React from 'react';

import { useSelector } from '@@store/hooks';
import { getFullLocale } from '@@lib/i18n/i18nSlice';
import { convertToNumber } from '@@utils/idUtils';
import { TextInputProps } from '@@form/components/TextInput';
import Autocomplete from '@@form/components/Autocomplete';
import { LivetickerRouter, useLivetickerClient } from '@@api/services/liveticker/client';
import { getQueryParams } from '@@api/utils/getQueryParams';

const PAGE_SIZE = 1000;

type BaseProps = {
    placeholder?: string;
    sportType: 'soccer' | 'iceHockey' | 'tennis' | 'soccer,iceHockey';
    inputRef?: TextInputProps['inputRef'];
    label: TextInputProps['label'];
    required?: TextInputProps['required'];
    noOptionsText?: React.ReactNode;
};
export type LeaguesAutocompleteProps =
    | (BaseProps & {
          multiple: true;
          value: number[];
          onChange: (value: number[]) => void;
      })
    | (BaseProps & {
          multiple?: false;
          value: number | null;
          onChange: (value: number | null) => void;
      });

const LeaguesAutocomplete = (props: LeaguesAutocompleteProps) => {
    const { sportType, value, onChange, multiple, ...rest } = props;
    const { client: livetickerClient, queryKeys: livetickerKeys } = useLivetickerClient();

    const uiLocale = useSelector(getFullLocale);

    const query = getQueryParams<LivetickerRouter['leagues']['getAll']>({
        sportTypes: sportType,
        size: PAGE_SIZE,
    });
    const { data: leagueData, isInitialLoading } = livetickerClient.leagues.getAll.useQuery(
        livetickerKeys.leagues.getAll({ query }),
        { query },
        { enabled: Boolean(sportType) },
    );
    const data = leagueData?.body.content;

    // TODO: Change when https://jira.tamedia.ch/browse/CD2-3681 is solved!
    const optionList =
        data?.map((league) => {
            const localeLeague =
                league.names.find(
                    (name) => name.locale === uiLocale,
                    // If the name for the current locale is not found, we use the first available one.
                    // This should happen only in very rare cases with old leagues, as now all locales
                    // are required
                ) || league.names[0];

            return { ...league, label: localeLeague?.label };
        }) || [];

    const currentValue = multiple
        ? (value || [])
              .map((item) =>
                  optionList.find((option) => (option.id ?? option) === convertToNumber(item)),
              )
              .filter((x): x is NonNullable<typeof x> => Boolean(x))
        : optionList.find((option) => option.id === value) ?? null;

    const handleChange = (event, value) => {
        const adjustedValue = Array.isArray(value)
            ? value.map((item) => item.id)
            : value?.id ?? value;

        onChange(adjustedValue);
    };

    return (
        <Autocomplete
            loading={isInitialLoading}
            onChange={handleChange}
            value={currentValue}
            options={optionList}
            multiple={multiple}
            getOptionKey={(option) => option.id!}
            getOptionLabel={(option) => option.label}
            {...rest}
        />
    );
};

export default LeaguesAutocomplete;

import { z } from 'zod';

import { Entity, Image } from '../../../utils/schemas/schemas';

const TeamBase = Entity.extend({
    id: z.number(),
    leagueIds: z.array(z.number()),
    legacyId: z.string().nullish(),
    name: z.string(),
    sportType: z.string(),
});

export const Team = TeamBase.extend({
    image: z
        .object({
            elvisId: z.string().nullish(),
            url: z.string().nullable(),
        })
        .optional(),
});

export type Team = z.infer<typeof Team>;

export const UnityTeam = TeamBase.extend({
    logoId: z.string().nullish(),
    logoURL: z.string().nullable(),
});

export type UnityTeam = z.infer<typeof UnityTeam>;

export const NewTeam = Team.omit({ id: true });
export type NewTeam = z.infer<typeof NewTeam>;

export const NewUnityTeam = UnityTeam.omit({ id: true }).partial({ logoURL: true });
export type NewUnityTeam = z.infer<typeof NewUnityTeam>;

const LeagueName = z.object({
    label: z.string(),
    locale: z.string(),
});

export type LeagueName = z.infer<typeof LeagueName>;

export const League = Entity.extend({
    id: z.number().optional(),
    names: z.array(LeagueName),
    sportType: z.enum(['soccer', 'iceHockey', 'tennis']).optional(),
});

export type League = z.infer<typeof League>;

export const TeamType = z.object({
    id: z.number(),
    name: z.string(),
    image: Image,
});
export type TeamType = z.infer<typeof TeamType>;

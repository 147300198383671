import React from 'react';

import {
    isRegisterAction,
    isUnregisterAction,
    isShowAction,
    isHideAction,
    isUpdatePropsAction,
    Action,
    ActionFunctions,
    ActionType,
    HideAction,
    Modal,
    RegisterAction,
    ShowAction,
    State,
    UpdatePropsAction,
    UnregisterAction,
} from './types';

const extendModalProps = (modals: Modal[], id: Id, props: AnyObject) =>
    modals.reduce<Modal[]>((result, modal) => {
        if (modal.id === id) {
            return result.concat({ ...modal, props: { ...modal.props, ...props } });
        }

        return result.concat(modal);
    }, []);

export const applyAction = (state: State, action: Action) => {
    if (isRegisterAction(action)) {
        const { id, component, props } = action;

        const modal: Modal = {
            id,
            component,
            props: {
                isOpen: false,
                ...props,
            },
        };

        return {
            ...state,
            modals: state.modals.concat(modal),
        };
    } else if (isUnregisterAction(action)) {
        const { id } = action;

        return {
            ...state,
            modals: state.modals.filter((modal) => modal.id !== id),
        };
    } else if (isShowAction(action)) {
        const { id } = action;

        return {
            ...state,
            modals: extendModalProps(state.modals, id, { isOpen: true }),
        };
    } else if (isHideAction(action)) {
        const { id } = action;

        return {
            ...state,
            modals: extendModalProps(state.modals, id, { isOpen: false }),
        };
    } else if (isUpdatePropsAction(action)) {
        const { id, props } = action;

        return {
            ...state,
            modals: extendModalProps(state.modals, id, props),
        };
    }

    return state;
};

export const createActionFunctions = (dispatch: React.Dispatch<Action>): ActionFunctions => ({
    register: (id, props, component) => {
        const registerAction: RegisterAction = {
            type: ActionType.REGISTER,
            id,
            component,
            props,
        };

        dispatch(registerAction);

        return id;
    },
    unregister: (id) => {
        const unregisterAction: UnregisterAction = { type: ActionType.UNREGISTER, id };

        dispatch(unregisterAction);
    },
    show: (id, props) => {
        if (props) {
            const updatePropsAction: UpdatePropsAction = {
                type: ActionType.UPDATE_PROPS,
                id,
                props,
            };

            dispatch(updatePropsAction);
        }

        const showAction: ShowAction = { type: ActionType.SHOW, id };

        dispatch(showAction);
    },
    hide: (id) => {
        const hideAction: HideAction = { type: ActionType.HIDE, id };

        dispatch(hideAction);
    },
    updateProps: (id, props) => {
        const updatePropsAction: UpdatePropsAction = {
            type: ActionType.UPDATE_PROPS,
            id,
            props,
        };

        dispatch(updatePropsAction);
    },
});

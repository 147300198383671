import { find } from 'lodash';
import { DropTargetMonitor, XYCoord } from 'react-dnd';

import { PLUGIN_NAMES, Plugin } from '@@editor/typings/UnityPlugins';
import { DragItem, DropDirection, DropDirections } from '@@editor/plugins/dnd/types';

export const checkForDnd = (plugins: Plugin[]) =>
    Boolean(find(plugins, { name: PLUGIN_NAMES.DRAG_DROP }));

export const getHoverDirection = (
    dragItem: DragItem,
    monitor: DropTargetMonitor<DragItem>,
    blockRef: React.RefObject<HTMLElement>,
): DropDirection => {
    if (
        !blockRef.current ||
        // Don't replace items with themselves
        blockRef.current === dragItem.blockRef?.current
    ) {
        return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = blockRef.current?.getBoundingClientRect();

    // Get vertical middle
    const halfHeight = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    if (!clientOffset) {
        return;
    }

    // Get pixels to the top
    const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (hoverClientY < halfHeight) {
        return DropDirections.TOP;
    }

    // Dragging upwards
    if (hoverClientY >= halfHeight) {
        return DropDirections.BOTTOM;
    }
};

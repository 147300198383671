import React from 'react';
import { FieldValues } from 'react-hook-form';

import RichTextString from '@@form/components/RichTextEditor/RichTextString';

import RichTextField, { RichTextFieldProps } from './RichTextField';

export const RichTextStringField = <TFieldValues extends FieldValues = FieldValues>(
    props: RichTextFieldProps<TFieldValues>,
) => <RichTextField {...props} component={RichTextString} />;

export default RichTextStringField;

/* eslint-disable no-magic-numbers */
const BELOW_ALL = -1;

const zIndexes = {
    BELOW_ALL,
    // base layers
    LAYER1: 10,
    LAYER2: 20,
    // intermediate layers
    EDITOR_STICKY_TOOLBAR: 30,
    HOVERED_STICKY_TOOLBAR: 32,
    EDITOR_COMMENTS: 36,
    // top layers
    LAYER3: 100,
    FORM_LOADING_OVERLAY: 200,
};

export default zIndexes;

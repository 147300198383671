import { useEffect, useRef, useState } from 'react';

import MultiFileUploadHelper from '@@utils/MultiFileUploadHelper';

const useMultiFileUploadHelper = (options = {}) => {
    const { waitAfterComplete = 0, onProgress = () => {}, onComplete = () => {} } = options;
    const [isLoading, setLoading] = useState(false);
    const [totalFiles, setTotalFiles] = useState(0);
    const [filesPending, setFilesPending] = useState(0);
    const [progress, setProgress] = useState(0);
    const helperRef = useRef();

    useEffect(() => {
        helperRef.current = new MultiFileUploadHelper({
            ...options,
            onProgress: (e) => {
                const { filesPending, progress } = e;

                setFilesPending(filesPending);
                setProgress(progress);

                onProgress(e);
            },
            onComplete: (results) => {
                setFilesPending(0);
                setProgress(100);

                setTimeout(() => {
                    onComplete(results);

                    setLoading(false);
                    setTotalFiles(0);
                    setFilesPending(0);
                    setProgress(0);
                }, waitAfterComplete);
            },
        });

        return () => {
            helperRef.current = null;
        };
    });

    const upload = (files) => {
        if (files.length <= 0) {
            return;
        }

        setLoading(true);
        setTotalFiles(files.length);
        setFilesPending(files.length);

        helperRef.current.upload(files);
    };

    return [{ isLoading, totalFiles, filesPending, progress }, upload];
};

export default useMultiFileUploadHelper;

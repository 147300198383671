import { noop } from 'lodash';
import { NodeEntry, Transforms } from 'slate';

import { ORIGIN_EVENT } from '@@editor/constants';
import { Editor, Node } from '@@editor/helpers';
import {
    Element,
    ELEMENT_TYPES,
    LeadElement,
    TitleElement,
    TitleHeaderElement,
} from '@@editor/helpers/Element';

import { HEADING_ELEMENT_IDS } from './constants';

// This plugin is made for the HeadingsEditor component, to make the article headings a fixed layout
// by preventing to add, split or remove elements.
// Since title, titleHeader and lead are always defined, we do not need to handle
// their re-creation (normalizeNode)
export const withHeadingsLayout = (editor: Editor) => {
    const { deleteBackward, deleteForward, normalizeNode, onEditorMount, setFragmentData } = editor;

    return Object.assign(editor, {
        // Inserting a new line in any of the elements is not allowed. It will also prevent
        // split of existing elements into multiple ones
        insertBreak: noop,
        // When at the end of an element, we do not allow to deleteForward to avoid merging/replacement
        // of existing elements
        deleteForward: (unit) =>
            Editor.isSelectionAtEndOfElement(editor) ? undefined : deleteForward(unit),
        // When at the beginning of an element, we do not allow to deleteBackward to avoid merging/replacement
        // of existing elements
        deleteBackward: (unit) =>
            Editor.isSelectionAtStartOfElement(editor) ? undefined : deleteBackward(unit),
        setFragmentData: (data: DataTransfer, originEvent: ValueOf<typeof ORIGIN_EVENT>) => {
            setFragmentData(data, originEvent);

            if (originEvent === 'copy' || originEvent === 'cut') {
                data.clearData('text/html');
                data.clearData('application/x-slate-fragment');
            }
        },
        // Since we want only plain text to be copied, without any formatting,
        // we want to use `insertTextData` instead of `insertData`
        insertData: (data: DataTransfer) => {
            // We replace all linebreaks with a space, to keep everything as one element
            const text = data.getData('text/plain');

            if (text) {
                const flattenText = text.replaceAll(/(?:\r\n|\r|\n)/g, ' ');

                Editor.insertText(editor, flattenText);
            }
        },
        onEditorMount: () => {
            const children = Node.children(editor, []);

            for (const [child] of children) {
                HEADING_ELEMENT_IDS.set(child.type, child.id);
            }

            onEditorMount();
        },
        normalizeNode: (entry: NodeEntry) => {
            const [, path] = entry;

            if (path.length === 0) {
                if (editor.children.length <= 1 && Editor.string(editor, [0, 0]) === '') {
                    const titleHeader: TitleHeaderElement = {
                        type: ELEMENT_TYPES.TITLE_HEADER,
                        id: HEADING_ELEMENT_IDS.get(ELEMENT_TYPES.TITLE_HEADER),
                        children: [{ text: '' }],
                    };

                    Transforms.insertNodes(editor, titleHeader, {
                        at: [0],
                        select: true,
                    });
                }

                if (editor.children.length < 2) {
                    const title: TitleElement = {
                        type: ELEMENT_TYPES.TITLE,
                        id: HEADING_ELEMENT_IDS.get(ELEMENT_TYPES.TITLE),
                        children: [{ text: '' }],
                    };

                    Transforms.insertNodes(editor, title, { at: [1] });
                }

                if (editor.children.length < 3) {
                    const lead: LeadElement = {
                        type: ELEMENT_TYPES.LEAD,
                        id: HEADING_ELEMENT_IDS.get(ELEMENT_TYPES.LEAD),
                        children: [{ text: '' }],
                    };

                    Transforms.insertNodes(editor, lead, { at: [2] });
                }

                if (editor.children.length > 3) {
                    Transforms.removeNodes(editor, { at: [3] });
                }

                // We want to make sure that the first three elements are always the same
                // We also want these elements to keep their original ID, event if they are recreated
                // otherwise, the id will be modified, and will affect revisions and the compare tool output
                if (!Element.isTitleHeaderElement(editor.children[0]) || !editor.children[0].id) {
                    Transforms.setNodes(
                        editor,
                        {
                            type: ELEMENT_TYPES.TITLE_HEADER,
                            id: HEADING_ELEMENT_IDS.get(ELEMENT_TYPES.TITLE_HEADER),
                        },
                        { at: [0] },
                    );
                }

                if (!Element.isTitleElement(editor.children[1]) || !editor.children[1].id) {
                    Transforms.setNodes(
                        editor,
                        {
                            type: ELEMENT_TYPES.TITLE,
                            id: HEADING_ELEMENT_IDS.get(ELEMENT_TYPES.TITLE),
                        },
                        { at: [1] },
                    );
                }

                if (!Element.isLeadElement(editor.children[2]) || !editor.children[2].id) {
                    Transforms.setNodes(
                        editor,
                        {
                            type: ELEMENT_TYPES.LEAD,
                            id: HEADING_ELEMENT_IDS.get(ELEMENT_TYPES.LEAD),
                        },
                        { at: [2] },
                    );
                }
            }

            normalizeNode(entry);
        },
    });
};

export default withHeadingsLayout;

import React from 'react';
import { styled, Stack } from '@mui/material';

import { renderButtons } from '../utils/renderButtons';
import { ToolbarButtonConfig } from '../typings';

export const Wrapper = styled(Stack)<{ disabled?: boolean }>(({ theme }) => ({
    flexDirection: 'row',
    position: 'sticky',
    borderTopLeftRadius: theme.borders[1],
    borderTopRightRadius: theme.borders[1],
    cursor: 'auto',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.common.white,
    zIndex: theme.fixed.editor.stickyToolbar.zIndex,
    top: 0,
    margin: '1px 0 4px -12px',
    alignItems: 'center',
}));

export const ButtonsWrapper = styled(Stack)({
    flexGrow: 1,
    maxWidth: '100%',
    flexWrap: 'wrap',
    flexDirection: 'row',
});

type Props = {
    buttonsConfig: ToolbarButtonConfig[];
    disabled?: boolean;
};

const Toolbar = ({ buttonsConfig, disabled }: Props) => (
    <Wrapper contentEditable={false} disabled={disabled}>
        <ButtonsWrapper>{renderButtons(buttonsConfig, { disabled })}</ButtonsWrapper>
    </Wrapper>
);

export default Toolbar;

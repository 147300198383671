import React, { CSSProperties, PropsWithChildren } from 'react';
import { styled } from '@mui/material';

import { Editor } from '@@editor/helpers';
import { TitleHeaderElement, ElementAttributes, Element } from '@@editor/helpers/Element';
import InfoTooltip from '@@components/InfoTooltip';
import { useArticleConflictContext } from '@@routes/articles/ArticleConflictContext';
import { getTextBoxStyle } from '@@editor/plugins/serializable/styles';

import HeadingWrapper from './HeadingWrapper';

type TitleHeaderWrapperProps = {
    tenantColor?: string;
    placeholderText?: string;
};

const TitleHeaderWrapper = styled(HeadingWrapper)<TitleHeaderWrapperProps>((props) => ({
    ...props.theme.typography.editorSubtitleSmall,
    fontWeight: 'bold',
    color: props.tenantColor,
    ...getTextBoxStyle(props),
}));

const TooltipContent = styled('span')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: theme.spacing(1),
}));

type Props = PropsWithChildren<{
    attributes?: ElementAttributes;
    editor: Editor;
    element: TitleHeaderElement;
    primaryColor?: string;
    style?: CSSProperties;
}>;

const TitleHeader = React.forwardRef<HTMLElement, Props>(
    ({ attributes, children, editor, element, primaryColor, style }, ref) => {
        const isEmpty = Element.isEmptyTextElement(element);
        const { isInConflict } = useArticleConflictContext();

        const placeholderText = isEmpty
            ? editor.t('editor.plugin.paragraph.titleHeader.placeholderText')
            : '';

        return (
            <TitleHeaderWrapper
                {...{ ref, style, ...attributes, editor, element, placeholderText }}
                tenantColor={primaryColor}
            >
                {children}
                {!isInConflict && (
                    <TooltipContent>
                        {/* t('editor.headings.tooltip') */}
                        <InfoTooltip title="editor.headings.tooltip" hideSpacer />
                    </TooltipContent>
                )}
            </TitleHeaderWrapper>
        );
    },
);

export default TitleHeader;

import { isEmpty, omit } from 'lodash';
import React, { useCallback, useContext, useEffect, useReducer } from 'react';

import crypto from '@@utils/crypto';
import { getCharacterCount } from '@@utils/charactersCount';
import { Element } from '@@editor/helpers';

const ACTION_TYPES = {
    ADD: 'ADD',
    REMOVE: 'REMOVE',
} as const;

export type ACTION_TYPE = ValueOf<typeof ACTION_TYPES>;

type AddAction = {
    type: typeof ACTION_TYPES.ADD;
    id: Id;
    count: number;
};

type RemoveAction = {
    type: typeof ACTION_TYPES.REMOVE;
    id: Id;
};

type Add = ({ id, count }: { id: Id; count: number }) => VoidFunction;

type State = Record<string, number>;

type CharacterCountContextType = {
    state: State;
    add: Add;
};

const CharacterCountContext = React.createContext<CharacterCountContextType | null>(null);

const countReducer = (state: State, action: AddAction | RemoveAction) => {
    switch (action.type) {
        case ACTION_TYPES.ADD: {
            const { id, count } = action;

            return { ...state, [id]: count };
        }
        case ACTION_TYPES.REMOVE: {
            return omit(state, action.id);
        }
        default: {
            return state;
        }
    }
};

export const CharacterCountProvider = ({ children }) => {
    const [state, dispatch] = useReducer(countReducer, {});

    const add = useCallback((payload) => {
        dispatch({
            type: ACTION_TYPES.ADD,
            ...payload,
        });

        return () =>
            dispatch({
                type: ACTION_TYPES.REMOVE,
                ...payload,
            });
    }, []);

    const value = { state, add };

    return (
        <CharacterCountContext.Provider value={value}>{children}</CharacterCountContext.Provider>
    );
};

export const useCharacterCount = (element?: Element | Element[]): number => {
    const context = useContext(CharacterCountContext);

    const characterCount = element ? getCharacterCount(element) : 0;
    const add = context?.add;

    useEffect(() => {
        if (add) {
            const id = crypto.randomUUID();

            return add({ id, count: characterCount });
        }
    }, [characterCount, add]);

    return characterCount;
};

export const useTotalCharacterCount = (value) => {
    const context = useContext(CharacterCountContext);

    if (context && !isEmpty(context.state)) {
        return Object.values(context.state).reduce((accumulator, count) => accumulator + count, 0);
    }

    return getCharacterCount(value);
};

import { parseUrl, toURL } from '@@utils/URL';

const document = window.document;

// https://dev.twitter.com/web/javascript/loading
const insertScript = () =>
    new Promise((resolve, reject) => {
        window.twttr = (() => {
            const twttr = window.twttr || {};
            let js = document.getElementById('twitter-wjs');

            if (!js) {
                const firstScript = document.querySelector('script');

                js = document.createElement('script');
                js.id = 'twitter-wjs';
                js.async = true;
                js.src = '//platform.twitter.com/widgets.js';
                js.onload = () => resolve(js);
                js.onerror = (err) => reject(new Error(`Failed to load ${err.target.src}`));

                // skipping if in test environment
                if (firstScript) {
                    firstScript.parentNode.insertBefore(js, firstScript);
                }

                // eslint-disable-next-line no-underscore-dangle, no-param-reassign
                twttr._e = [];
                twttr.ready = (f) => {
                    twttr._e.push(f);
                };
            } else {
                resolve(js);
            }

            return twttr;
        })();
    });

const isTweetUrl = (value) =>
    /^http(?:s)?:\/\/(?:www\.)?twitter\.com\/[^<]*\/status\/.+/.test(value);

export const importSdk = () => insertScript();

export const isTwitterUrl = (value) => {
    const url = toURL(value);

    if (url instanceof URL) {
        return /twitter\.com/.test(url.hostname);
    }
};

export const isTwitterEmbedCode = (code) => {
    const { url } = parseTwitterEmbedCode(code);

    if (url instanceof URL) {
        return true;
    }
};

// https://help.twitter.com/de/managing-your-account/twitter-username-rules#
export const isValidTwitteUsername = (username) => /^[a-zA-Z0-9_]{1,15}$/.test(username);

export const parseTwitterEmbedCode = (code) => {
    const el = document.createElement('div');

    el.innerHTML = code;

    const [blockquote] = el.getElementsByTagName('blockquote');

    if (blockquote instanceof HTMLElement) {
        if (blockquote.className.match(/twitter-[tweet|video]/)) {
            const links = Array.from(blockquote.getElementsByTagName('a'));

            const tweetLink = links.find((link) => isTweetUrl(link.getAttribute('href')));

            if (tweetLink) {
                const tweetUrl = parseUrl(tweetLink.getAttribute('href'));

                if (tweetUrl) {
                    return {
                        url: tweetUrl,
                        dataset: blockquote.dataset,
                    };
                }
            }
        }
    }

    return {};
};

import { RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        index: true,
        lazy: async () => {
            const { LinkList } = await import(
                /* webpackChunkName: "linkList" */ '@@routes/externalContent/links/'
            );

            return { Component: LinkList };
        },
    },
    {
        path: 'new',
        lazy: async () => {
            const { LinkSetupPage } = await import(
                /* webpackChunkName: "linkSetupPage" */ '@@routes/externalContent/links'
            );

            return { Component: LinkSetupPage };
        },
    },
    {
        path: ':id/*',
        lazy: async () => {
            const { LinkDetailPage } = await import(
                /* webpackChunkName: "linkDetailPage" */ '@@routes/externalContent/links'
            );

            return { Component: LinkDetailPage };
        },
    },
];

import { useTranslation } from 'react-i18next';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { styled } from '@mui/material';

import DateTime from '@@lib/dateTime/DateTime';
import { EMPTY_PLACEHOLDER_ID } from '@@containers/ContentItem/constants';
import { ContentWrapper, InfoBar, StyledIcon, Title } from '@@containers/ContentItem/styles';
import Spacer from '@@components/Spacer';
import config from '@@config';
import { PlacementOptions } from '@@routes/placement/types';
import { type Metadata } from '@@api/services/metadata/schemas';
import { Teaser } from '@@containers/Teaser/types';

const defaultGetDate = (entity) => entity.updatedAt || entity.createdAt;

export const TitleHeader = styled('span')(({ theme }) => ({
    ...theme.typography.small,
    color: theme.palette.text.secondary,
    whiteSpace: 'pre-wrap',
    position: 'relative',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
}));

type Props = {
    entity: Metadata & Teaser;
    collapsed?: boolean;
    datePrefix?: string;
    isPlaceholder?: boolean;
    placementOptions?: PlacementOptions;
    reducedOpacity?: boolean;
    getDate?: ((entity: Metadata & Teaser) => string) | undefined;
    className?: string;
};

const ContentDetails = ({
    entity,
    collapsed,
    isPlaceholder,
    reducedOpacity,
    placementOptions,
    getDate = defaultGetDate,
    datePrefix,
    className,
}: Props) => {
    const { t } = useTranslation();

    // @ts-ignore Metadata should not have it as optional
    const iconName = config.contentTypeToIconName[entity.resourceName];

    const isEmptyPlaceholder = isPlaceholder && entity.id === EMPTY_PLACEHOLDER_ID;

    const renderDate = () => {
        if (isEmptyPlaceholder) {
            return null;
        }

        if (placementOptions) {
            if (placementOptions.deletedDate) {
                return <span>{t('placementtool.contentItem.deleted')}</span>;
            }

            if (placementOptions.unpublishedDate) {
                return <span>{t('placementtool.contentItem.unpublished')}</span>;
            }

            return placementOptions.publishedSince ? (
                <DateTime date={placementOptions.publishedSince} type="past" relative />
            ) : (
                <span>{t('placementtool.contentItem.notPublishedYet')}</span>
            );
        }

        return (
            <>
                <span>{datePrefix || t('contentitem.datePrefix.default')}&nbsp;</span>
                <DateTime
                    date={getDate(entity)}
                    type="past"
                    titlePrefix={t('contentitem.publishedTimeago')}
                    fullDatePrefix={!collapsed ? t('contentitem.fullDatePrefix.default') : ''}
                />
            </>
        );
    };

    return (
        <ContentWrapper className={className}>
            <TitleHeader>{entity.titleHeader}</TitleHeader>

            <Title {...{ small: collapsed, isEmptyPlaceholder, reducedOpacity }}>
                <LinesEllipsis text={entity.title || ''} maxLine="2" component="span" />
            </Title>

            {collapsed ? <Spacer xs v /> : <Spacer sm v />}

            <InfoBar>
                {iconName && <StyledIcon name={iconName} />}
                {renderDate()}
            </InfoBar>
        </ContentWrapper>
    );
};

export default ContentDetails;

import { isEqual } from 'lodash';
import { useRef } from 'react';

export type DeepCompareDependency<T = any> = { value: T; type: 'shallow' | 'deep' };

// Do not use this hook directly in the code. Use `useDeepCompareMemo` and/or `useMemoWithOptions`
// instead. This hook is only meant to be used internally for `useDeepCompareMemo` and/or
// `useMemoWithOptions`. The interface of `useDeepCompareMemo` and `useMemoWithOptions` is more
// similar to react's `useMemo` which is a good thing! :)

const useDeepCompareMemoize = <T>({ value, type = 'shallow' }: DeepCompareDependency<T>): T => {
    const shallow = type === 'shallow';
    const ref = useRef(value);
    // it can be done by using useMemo as well
    // but useRef is rather cleaner and easier

    if (shallow || !isEqual(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
};

export default useDeepCompareMemoize;

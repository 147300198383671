const IMAGE_MIME_TYPES = {
    JPEG: 'image/jpeg',
    JPG: 'image/jpg',
    PNG: 'image/png',
    GIF: 'image/gif',
    WEBP: 'image/webp',
    SVG: 'image/svg+xml',
} as const;

export type ImageMimeType = ValueOf<typeof IMAGE_MIME_TYPES>;

export default IMAGE_MIME_TYPES;

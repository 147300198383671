import { MutationCache, QueryCache, QueryClient } from '@tanstack/query-core';
import { ZodError } from 'zod';

import handleApiError, { isApiError } from '@@api/errorHandler';
import { HTTP_STATUS_CODES } from '@@constants/http';
import { store } from '@@store/store';
import { clearAuthentication } from '@@auth/authSlice';

import { FetcherValidationError } from './FetcherValidationError';

export const etagCache = new Map();

const queryCache = new QueryCache({
    onError: handleApiError,
});

const mutationCache = new MutationCache({
    onError: handleApiError,
});

const queryClient = new QueryClient({
    queryCache,
    mutationCache,
    defaultOptions: {
        queries: {
            retry: (failureCount, error: any) => {
                // We want Zod errors to fail immediately
                // because no amount of retries will fix them.
                if (
                    error &&
                    (error instanceof ZodError || error instanceof FetcherValidationError)
                ) {
                    return false;
                }

                return failureCount < 3;
            },
            onError: (error: unknown) => {
                if (isApiError(error) && error.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
                    store.dispatch(clearAuthentication());
                }
            },
        },
    },
});

export default queryClient;

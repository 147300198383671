// unidirectional rule, does not work in both directions
const errorRules = {
    deserialize: (attributes) => {
        // eslint-disable-next-line no-console
        console.log(
            '[UnitySerializer:deserialize] this should not happen. Missing rule for:',
            attributes,
        );
    },

    serialize: (node) => {
        if (node.type) {
            // eslint-disable-next-line no-console
            console.log(
                '[UnitySerializer:deserialize] this should not happen. Missing rule for:',
                node,
            );
        }
    },
};

export default errorRules;

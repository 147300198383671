import { Location, NodeEntry, Path, Range, Span, Text, Transforms } from 'slate';
import scrollIntoView from 'scroll-into-view-if-needed';

import { isElementNode } from '@@utils/DOM';
import { Editor, Node as SlateNode, ReactEditor } from '@@editor/helpers';
import { type Comment } from '@@api/services/content/schemas/comment';

import {
    COMMENT_THREAD_ID_ATTRIBUTE_NAME,
    EDITOR_TO_UNSUPPORTED_ELEMENT_TYPES,
    MARK,
    NEW_COMMENT_ID,
} from './constants';
import type { CommentNodeInfo } from './types';

type AddCommentOptions = {
    select?: boolean;
    shouldScrollIntoView?: boolean;
};

export const addComment = (
    editor: Editor,
    id: Comment['id'],
    options: AddCommentOptions = {},
): void => {
    const { select = true, shouldScrollIntoView = false } = options;

    Transforms.setNodes(
        editor,
        { [MARK]: id },
        {
            match: (node, path) => canAddCommentOnNode(editor, node, path),
            split: true,
            voids: true,
        },
    );

    if (select) {
        selectComment(editor, id, { shouldScrollIntoView });
    }
};

export const isCloseToCommentThreadElement = (node: Node | null, id?: Comment['id']) => {
    if (node && isElementNode(node)) {
        return (
            (id != null
                ? node.closest(`[${COMMENT_THREAD_ID_ATTRIBUTE_NAME}="${id}"]`)
                : node.closest(`[${COMMENT_THREAD_ID_ATTRIBUTE_NAME}]`)) != null
        );
    }

    return false;
};

export const isCommentActive = (editor: Editor, id?: Comment['id']): boolean => {
    const [firstMatch] = commentNodes(editor, { id });

    // A comment is considered active if it touches the boundaries of the current selection AND either the
    // editor is focused OR the related comment thread component (within the comment panel/list) is focused
    return (
        Boolean(firstMatch) &&
        (ReactEditor.isFocused(editor) || isCloseToCommentThreadElement(document.activeElement, id))
    );
};

export const isCommentNode = (node: SlateNode, id?: Comment['id']): boolean => {
    if (typeof id !== 'undefined') {
        return node[MARK] === id;
    }

    return MARK in node;
};

export const removeComment = (editor: Editor, id: Comment['id']): void => {
    // Since several nodes can have the same commentId, we have to traverse the whole document
    Transforms.unsetNodes(editor, MARK, { at: [], match: (node) => isCommentNode(node, id) });
};

type SelectCommentOptions = {
    shouldScrollIntoView?: boolean;
};

export const selectComment = (
    editor: Editor,
    id: Comment['id'],
    options: SelectCommentOptions = {},
): void => {
    const { shouldScrollIntoView = false } = options;

    const commentRange = getCommentRange(editor, id);

    if (commentRange) {
        Transforms.select(editor, commentRange);

        if (shouldScrollIntoView) {
            const anchorNode = SlateNode.get(editor, commentRange.anchor.path);
            const domNode = ReactEditor.toDOMNode(editor, anchorNode);

            scrollIntoView(domNode, {
                behavior: 'smooth',
                block: 'center',
                scrollMode: 'if-needed',
            });
        }
    }
};

export const canAddCommentOnNode = (editor: Editor, node: SlateNode, path: Path): boolean => {
    const { selection } = editor;
    const isNonEmptyTextNode = Text.isText(node) && node.text.length > 0;
    const unsupportedElementTypes = EDITOR_TO_UNSUPPORTED_ELEMENT_TYPES.get(editor) ?? [];

    return (
        isNonEmptyTextNode &&
        !isCommentNode(node) &&
        !unsupportedElementTypes.includes(SlateNode.parent(editor, path).type) &&
        selection !== null &&
        Range.includes(selection, path)
    );
};

export const isInjectingNewCommentAllowed = (editor: Editor): boolean => {
    const { selection } = editor;
    const isReadOnly = ReactEditor.isReadOnly(editor);
    const hasActiveComment = isCommentActive(editor);

    return (
        !isReadOnly &&
        selection !== null &&
        Range.isExpanded(selection) &&
        !hasActiveComment &&
        !Editor.isVoidNodeOnlySelected(editor)
    );
};

type CommentNodesOptions = {
    at?: Location | Span;
    id?: Comment['id'];
    injectNewComment?: boolean;
};

export const commentNodes = function* <T extends SlateNode>(
    editor: Editor,
    options: CommentNodesOptions = {},
): Generator<NodeEntry<T>, void, undefined> {
    const { at, id, injectNewComment = false } = options;

    yield* Editor.nodes(editor, {
        at,
        match: (node, path) =>
            isCommentNode(node, id) ||
            (injectNewComment && canAddCommentOnNode(editor, node, path)),
    });
};

export const getCommentRange = (editor: Editor, id: Comment['id']): Range | undefined => {
    const finalMatches: NodeEntry[] = [];

    let hadFirstMatch = false;

    // Match everything after first comment node
    const matches = Editor.nodes(editor, {
        at: [],
        match: (node) => {
            if (hadFirstMatch) {
                return true;
            } else if (isCommentNode(node, id)) {
                hadFirstMatch = true;

                return true;
            }

            return false;
        },
    });

    // Iterate over matches until we have no comment node with `id` and no empty text node
    for (const match of matches) {
        const [node] = match;

        if (isCommentNode(node, id)) {
            finalMatches.push(match);
        } else if (Text.isText(node) && node.text.length > 0) {
            break;
        }
    }

    if (finalMatches.length > 0) {
        const firstMatch = finalMatches.slice(0, 1)[0];
        const lastMatch = finalMatches.slice(-1)[0];

        const range = {
            anchor: Editor.start(editor, firstMatch[1]),
            focus: Editor.end(editor, lastMatch[1]),
        };

        return range;
    }
};

export const getCommentNodesInfo = (editor: Editor, options = {}): CommentNodeInfo[] => {
    const { selection } = editor;
    const comments: CommentNodeInfo[] = [];
    const injectNewComment = isInjectingNewCommentAllowed(editor);
    const matches = commentNodes(editor, { ...options, injectNewComment });

    let wasNewCommentInjected = false;

    for (const match of matches) {
        const [node] = match as NodeEntry;
        const id = node[MARK] || NEW_COMMENT_ID.get(editor);
        const active = id === NEW_COMMENT_ID.get(editor) || isCommentActive(editor, id);

        if (id === NEW_COMMENT_ID.get(editor)) {
            // Only inject a new `commentId` once, otherweise several creation forms would be displayed. This
            // could happen if the user has selected more than one node (for example when the selection includes
            // normal text but also a link)
            if (wasNewCommentInjected || !selection) {
                continue;
            }

            wasNewCommentInjected = true;
        }

        comments.push({ id, active, node, editor });
    }

    return comments;
};

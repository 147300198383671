import React from 'react';
import { FieldValues } from 'react-hook-form';

import Switch, { Props as SwitchProps } from '@@form/components/Switch';
import useFormField, { UseFormFieldProps } from '@@form/hooks/useFormField';

export type SwitchFieldProps<TFieldValues extends FieldValues = FieldValues> = SwitchProps &
    UseFormFieldProps<TFieldValues>;

const SwitchField = <TFieldValues extends FieldValues = FieldValues>(
    props: SwitchFieldProps<TFieldValues>,
) => {
    const {
        name,
        validate,
        novalidate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
        ...rest
    } = props;

    const { ref, ...formFieldProps } = useFormField<TFieldValues>({
        name,
        novalidate,
        validate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
    });

    const checked = Boolean(formFieldProps.value);

    return (
        <Switch
            inputRef={ref}
            {...formFieldProps}
            required={required}
            {...rest}
            checked={checked}
        />
    );
};

SwitchField.displayName = 'SwitchField';

export default SwitchField;

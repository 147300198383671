import React from 'react';

import { FacebookElement } from '@@editor/helpers/Element';

import FacebookEmbed from './FacebookEmbed';

type Props = {
    element: FacebookElement;
};

const FacebookComment = ({ element }: Props) => (
    <FacebookEmbed element={element}>
        {({ src, includeParent, ...rest }) => (
            <div
                {...rest}
                className="fb-comment-embed"
                data-include-parent={includeParent || false}
                data-href={src}
            />
        )}
    </FacebookEmbed>
);

export default FacebookComment;

import React from 'react';

import { ELEMENT_TYPES, MIME_TYPES } from '@@editor/helpers/Element';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { parseFacebookEmbedCode, importSdk } from '@@utils/socialMedia/facebook';
import facebookEmbedCode from '@@form/utils/validators/facebookEmbedCode';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import config from '@@config';
import { InsertElementOptions } from '@@editor/helpers/Editor';

import { generateEmbedBlockData } from './utils';
import { createGenerateEmbedBlock } from './../utils';
import Page from './components/Page';
import Video from './components/Video';
import Post from './components/Post';
import Comment from './components/Comment';
import EditorWithEmbedModal from './../components/EditorWithEmbedModal';
import EmbedWrapper from './../components/EmbedWrapper';

importSdk({
    appId: config.facebook.appId,
    version: config.facebook.version,
    locale: config.defaultUiLocale.split('-').shift(),
});

const TYPE = PLUGIN_NAMES.FACEBOOK;
const NODE_TYPE = ELEMENT_TYPES.FACEBOOK;

const mimetypeConfigs = {
    [MIME_TYPES.FACEBOOK_POST]: {
        Component: Post,
    },
    [MIME_TYPES.FACEBOOK_COMMENT]: {
        Component: Comment,
    },
    [MIME_TYPES.FACEBOOK_PAGE]: {
        Component: Page,
    },
    [MIME_TYPES.FACEBOOK_VIDEO]: {
        Component: Video,
    },
};

// TODO: evaluate if this is a good approach
const extendedStyles = {
    [`&[data-type='${TYPE}']`]: {
        '.fb-page': {
            minWidth: '180px',
            maxWidth: '750px',
            minHeight: '70px',
        },
        '.fb-post': {
            minWidth: '350px',
            maxWidth: '750px',
        },
        '.fb-video': {
            minWidth: '220px',
        },
        '.fb-comment-embed': {
            minWidth: '220px',
        },
    },
};

const FacebookNode = (props) => (
    <EmbedWrapper
        {...props}
        type={TYPE}
        mimetypeConfigs={mimetypeConfigs}
        extendedStyles={extendedStyles}
        toolbarConfig={{
            infos: {
                iconName: PLUGIN_ICON_NAMES[TYPE],
                title: 'Facebook',
            },
            actions: ['edit', 'delete'],
        }}
    />
);

const generateEmbedBlock = createGenerateEmbedBlock({
    contentType: 'html',
    type: TYPE,
    nodeType: ELEMENT_TYPES.FACEBOOK,
    generateEmbedBlockData,
    parseEmbedCode: parseFacebookEmbedCode,
});

const validate = [facebookEmbedCode];

const internalWithFacebook = (editor, options) => {
    const { insertData } = editor;

    return Object.assign(editor, {
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    validate={validate}
                    generateEmbedBlock={generateEmbedBlock}
                    type={TYPE}
                />
            ),
            options,
        ),
        renderElement: renderElement(editor, [[NODE_TYPE, FacebookNode]], options),
        insertData: (data: DataTransfer, options: InsertElementOptions) => {
            // insertdata is getting called with undefined data.
            // This will guard against that.
            if (data) {
                const text = data.getData('text/plain');

                if (text && !facebookEmbedCode(text)) {
                    generateEmbedBlock(editor, text);
                } else {
                    insertData(data, options);
                }
            }
        },
    });
};

export const withFacebook = (editor, options) =>
    internalWithFacebook(editor, {
        ...options,
        nodeType: NODE_TYPE,
    });

export default withFacebook;

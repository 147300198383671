import { Transforms } from 'slate';

import renderElement from '@@editor/plugins/utils/renderElement';
import { Element, Editor } from '@@editor/helpers';
import makeState from '@@editor/utils/makeState';
import { ELEMENT_TYPES } from '@@editor/helpers/Element';
import {
    normalizeInlineEditableElement,
    preventDeleteBackward,
    preventDeleteForward,
    preventDeleteFragment,
    preventInsertBreak,
} from '@@editor/plugins/utils/inlineEditing';
import deleteForward from '@@editor/plugins/utils/deleteForward';
import deleteBackward from '@@editor/plugins/utils/deleteBackward';
import deleteFragment from '@@editor/plugins/utils/deleteFragment';
import insertBreak from '@@editor/plugins/utils/insertBreak';
import normalizeNode from '@@editor/plugins/utils/normalizeNode';

import InterviewSegmentNode, {
    INLINE_EDITABLE_CHILDREN_TYPES,
    NODE_TYPE,
    TYPE,
} from '../../layout/components/InterviewSegmentNode';
import { createGenerateLayoutBlock } from '../utils';
import InterviewSegmentQuestionElement from '../components/InterviewSegmentQuestionElement';
import InterviewSegmentAnswerElement from '../components/InterviewSegmentAnswerElement';

const emptyInterviewData = {
    question: makeState(),
    answers: [makeState()],
};

const generateLayoutBlock = createGenerateLayoutBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
});

export const withInlineInterview = (editor: Editor, options) => {
    const { isVoid, useInlineEditing } = editor;

    return Object.assign(editor, {
        isVoid: (element: Element) => {
            if (useInlineEditing && Element.isInterviewSegmentRelatedElement(element)) {
                return false;
            }

            return element.type === NODE_TYPE ? true : isVoid(element);
        },
        deleteForward: deleteForward(editor, [
            [preventDeleteForward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteBackward: deleteBackward(editor, [
            [preventDeleteBackward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteFragment: deleteFragment(editor, [
            [preventDeleteFragment, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        insertBreak: insertBreak(editor, [
            [preventInsertBreak, { types: [ELEMENT_TYPES.INTERVIEW_SEGMENT_QUESTION] }],
        ]),
        normalizeNode: normalizeNode(editor, [
            [
                normalizeInlineEditableElement,
                {
                    type: ELEMENT_TYPES.INTERVIEW_SEGMENT,
                    allowedChildrenTypes: INLINE_EDITABLE_CHILDREN_TYPES,
                },
            ],
        ]),
        renderElement: renderElement(
            editor,
            [
                [NODE_TYPE, InterviewSegmentNode],
                [ELEMENT_TYPES.INTERVIEW_SEGMENT_QUESTION, InterviewSegmentQuestionElement],
                [ELEMENT_TYPES.INTERVIEW_SEGMENT_ANSWER, InterviewSegmentAnswerElement],
            ],
            options,
        ),
        insertInlineInterview: (at = editor.selection?.focus) => {
            generateLayoutBlock(editor, emptyInterviewData, {
                at,
                replace: false,
            });
            if (at) {
                Transforms.select(editor, at);
                Transforms.collapse(editor);
            }
        },
    });
};

export default withInlineInterview;

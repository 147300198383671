import { useTranslation } from 'react-i18next';
import React from 'react';
import { InputProps } from '@mui/material';

import { Editor } from '@@editor/helpers';
import Spacer from '@@components/Spacer';
import CaptionEditorField from '@@form/fields/RichTextEditor/CaptionEditor';
import RichTextStringField from '@@form/fields/RichTextEditor/RichTextString';
import IframeFields from '@@containers/IframeFields';

type Props = {
    groupName?: string;
    validateIframeField?: Validator[];
    onIframeBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
    onIframeChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
    editor?: Editor;
    InputProps?: InputProps;
};

const EmbeddedContentFields = ({
    groupName,
    validateIframeField,
    onIframeBlur,
    onIframeChange,
    editor,
    InputProps = {},
}: Props) => {
    const { t } = useTranslation();

    const makeName = (name) => (groupName ? `${groupName}.${name}` : name);

    return (
        <>
            <IframeFields
                InputProps={InputProps}
                validateIframeField={validateIframeField}
                onIframeBlur={onIframeBlur}
                onIframeChange={onIframeChange}
                names={{
                    url: makeName('embed.iframe'),
                    fallBackHeight: makeName('embed.fallBackHeight'),
                }}
            />

            <Spacer v md />
            <RichTextStringField
                name={makeName('embed.captionTitle')}
                label={t('embed.captionTitle')}
                displayUsedCharacters
            />

            <Spacer v md />

            {!editor?.useInlineEditing && (
                <>
                    <CaptionEditorField
                        name={makeName('embed.caption')}
                        label={t('embed.caption')}
                        displayUsedCharacters
                    />

                    <Spacer v md />

                    <RichTextStringField
                        name={makeName('embed.credit')}
                        label={t('embed.credit')}
                        displayUsedCharacters
                    />
                </>
            )}
        </>
    );
};

export default EmbeddedContentFields;

import React, { useRef, useEffect } from 'react';

import { useSelector } from '@@store/hooks';
import { getLocale } from '@@lib/i18n/i18nSlice';
import { toURL } from '@@utils/URL';
import { TwitterElement } from '@@editor/helpers/Element';
import { Editor } from '@@editor/helpers';

import EmbedComponentLoader from '../../components/EmbedComponentLoader';

// Extract only the numeric id of a twitter status
// e.g. '923556861168836609' from
// https://twitter.com/wordstionary/status/923556861168836609?ref_src=twsrc%5Etfw
export const extractTweetId = (url?: string | URL) =>
    toURL(url)?.pathname.match(/\/status\/(\d+)/)?.[1];

type Props = {
    editor: Editor;
    element: TwitterElement;
    onLoad: VoidFunction;
};

// general tweet embed - https://dev.twitter.com/web/embedded-tweets
// oEmbed with CMS - https://dev.twitter.com/web/embedded-tweets/cms#macro
const TwitterTweet = ({ element, onLoad }: Props) => {
    const domNode = useRef<HTMLDivElement | null>(null);

    const locale = useSelector(getLocale);

    useEffect(() => {
        const { src, payload } = element.data;
        const tweetId = extractTweetId(src);

        const options = {
            theme: 'light',
            cards: true,
            align: 'center',
            ...payload,
            // our locale has higher priority then the twitter locale pasted in?
            lang: locale,
        };

        if (tweetId) {
            // For widget options see: https://dev.twitter.com/web/embedded-tweets/parameters
            // @ts-ignore Not sure how to type the Window object
            window.twttr.ready(({ widgets }) => {
                // Component might be unmounted by the time twitter sdk has been instantiated, so
                // check if reference to domNode is still available first
                if (domNode.current) {
                    domNode.current.innerHTML = '';
                    widgets
                        .createTweet(tweetId, domNode.current, options)
                        .then(() => {
                            onLoad();
                        })
                        .catch(() => {
                            onLoad();
                        });
                }
            });
        }
    }, [element]);

    return <div ref={domNode} />;
};

export default ({ editor, element }) => (
    <EmbedComponentLoader element={element} editor={editor}>
        {({ onLoad }) => <TwitterTweet editor={editor} element={element} onLoad={onLoad} />}
    </EmbedComponentLoader>
);

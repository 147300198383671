import moment from 'moment';

const formats = [moment.ISO_8601];

export const isDate = (value) => moment(value, formats, true).isValid();

export const isToday = (value) => moment().isSame(value, 'day');

export const isCurrentWeek = (value) => moment().isSame(value, 'week');

const isCurrentYear = (value) => moment().isSame(value, 'year');

export const formatDate = (value, format) => {
    if (format) {
        return moment(value).format(format);
    }

    // Backend expects a UTC date, so we use `toISOString`
    return moment(value).toISOString();
};

export const formatPastDate = (value, relative) =>
    isToday(value) || relative ? moment(value).fromNow() : moment(value).calendar();

export const getDiffAsMinutes = (firstValue, secondValue) =>
    Math.floor(moment.duration(moment(secondValue).diff(moment(firstValue))).asMinutes());

export const formatTargetDateTime = (value) => {
    if (isToday(value)) {
        return moment(value).format('HH:mm');
    }
    if (isCurrentYear(value)) {
        return moment(value).format('D MMM HH:mm');
    }

    return moment(value).format('D MMM YYYY HH:mm');
};

export const isExpired = (date, expireAfter) =>
    moment() > moment(date).add(expireAfter, 'milliseconds');

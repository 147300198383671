import invariant from 'tiny-invariant';

import { openInNewTab } from '@@utils/browser';

export const isExternalContent = (resourceName) =>
    ['embeds', 'links', 'videos'].includes(resourceName);

export default (contentEntity, navigate, newTab = false) => {
    const { resourceName, resourceId } = contentEntity;

    invariant(resourceName && resourceId, 'Invalid entity format.');

    let pathname = `/${resourceName}/${resourceId}`;

    if (isExternalContent(resourceName)) {
        pathname = `/externalContent${pathname}`;
    }

    if (newTab) {
        openInNewTab(`${window.location.origin}${pathname}`);
    } else {
        navigate({ pathname });
    }
};

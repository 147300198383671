import { useContext } from 'react';

import MetadataContext from '@@containers/metadata/MetadataContext';
import TenantVariantContext from '@@containers/TenantSpecific/TenantVariantContext';

type Props = {
    name: string;
    dependsOn: UnknownObject;
};

const stringifyDependents = (dependents: Props['dependsOn']) =>
    Object.keys(dependents)
        .filter((key) => dependents[key] !== null)
        .map((key) => `${key}:${dependents[key]}`)
        .join('|');

const useGeneratedFormName = ({ name, dependsOn }: Props) => {
    const metadataContext = useContext(MetadataContext);
    const tenantVariantContext = useContext(TenantVariantContext);

    return `${name}::${stringifyDependents({
        metadataId: metadataContext.metadataId,
        selectedTenantVariantId: tenantVariantContext.selectedVariantId,
        ...dependsOn,
    })}`;
};

export default useGeneratedFormName;

import { isEqual } from 'lodash';
import React from 'react';

import PlainSerializer from '@@editor/serialization/Plain';
import useDebouncedValue from '@@hooks/useDebouncedValue';

import SimpleRichTextEditor from './SimpleRichTextEditor';

type Props = {
    value: string;
    onChange?: (value: string) => void;
    onBlur?: (e: React.FocusEvent) => void;
    multiline?: boolean;
};

const RichTextString = (props: Props) => {
    const { value, onChange, onBlur, ...editorProps } = props;

    const debouncedValueProps = useDebouncedValue({
        value,
        onChange,
        onBlur,
        shouldCallOnChange: (parsedValue) => Boolean(onChange) && !isEqual(value, parsedValue),
        deserialize: PlainSerializer.deserialize,
        serialize: PlainSerializer.serialize,
    });

    return <SimpleRichTextEditor {...editorProps} {...debouncedValueProps} />;
};

export default RichTextString;

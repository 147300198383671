import { RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        path: ':id?',
        lazy: async () => {
            const { Layout } = await import(
                /* webpackChunkName: "releasenotes" */ '@@routes/releasenotes'
            );

            return { Component: Layout };
        },
    },
];

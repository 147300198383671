import { get } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@@store/store';

import { ROLES, UserRole } from './constants';
import checkAuthentication from './utils/checkAuthentication';
import parseJWT from './utils/parseJWT';

const MS = 1000;

export type AuthState = {
    tokenType: string | null;
    accessToken: string | null;
    jti: string | null;
    userId: string | null;
    userRole: string | null;
    expiresIn: number;
    loggedInTime: number;
};

const initialState: AuthState = {
    tokenType: null,
    accessToken: null,
    jti: null,
    userId: null,
    userRole: null,
    expiresIn: 0,
    loggedInTime: 0,
};

export const chooseRole = (role?: UserRole) => {
    if (role && Object.values(ROLES).includes(role)) {
        return role;
    }

    if (role) {
        console.warn('invalid user role supplied', role);
    }

    return ROLES.EDITOR;
};

/* We disable the camelcase rule because these are defined outside of our app */
/* eslint-disable camelcase */
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        processAuthenticationPayload(state, action) {
            // TODO:
            // next line comes from location after the redirect, it also contains jti
            // it's kinda weird as everything is passes using the url,.. the token alone
            // carries all information.
            const { access_token, token_type, expires_in } = action.payload;

            // token data
            const { unity_user_id, jti, authorities } = parseJWT(access_token);

            return {
                ...initialState,
                tokenType: token_type,
                accessToken: access_token,
                jti,
                // We do not add any other user properties (e.g. user_name, first_name, ...) here,
                // since we prefer one source of truth. So we fetch the user entity for that.
                userId: unity_user_id,
                userRole: chooseRole(get(authorities, '0')),
                // Work with milliseconds everywhere!!!!
                expiresIn: Number(expires_in) * MS,
                loggedInTime: Date.now(),
            };
        },
        clearAuthentication() {
            return { ...initialState };
        },
    },
});
/* eslint-enable camelcase */

const getExpiresIn = (state: RootState) => state.auth && state.auth.expiresIn;
const getLoggedInTime = (state: RootState) => state.auth && state.auth.loggedInTime;

export const getAuthToken = (state: RootState) =>
    state.auth && state.auth.accessToken && state.auth.tokenType
        ? state.auth.tokenType + ' ' + state.auth.accessToken
        : null;

export const getAuth = (state: RootState) => state.auth;
export const getUserRole = (state: RootState) => state.auth && state.auth.userRole;
export const getUserId = (state: RootState) => state.auth && state.auth.userId;
export const getAccessToken = (state: RootState) => state.auth && state.auth.accessToken;

export const selectIsAuthenticated = (state: RootState) =>
    checkAuthentication(getLoggedInTime(state), getExpiresIn(state));

export const { processAuthenticationPayload, clearAuthentication } = authSlice.actions;
export default authSlice.reducer;

import { isGoogleMapsUrl } from '@@utils/socialMedia/googlemaps';
import { MIME_TYPES } from '@@editor/helpers/Element';
import { GenerateEmbedBlockData } from '@@editor/typings/Embed';

export const generateEmbedBlockData: GenerateEmbedBlockData = ({ src: url, originalSrc }) => {
    if (!isGoogleMapsUrl(url)) {
        return;
    }

    return {
        mimetype: MIME_TYPES.GOOGLE_MAPS,
        src: (url as URL).href,
        originalSrc,
    };
};

import { noop } from 'lodash';
import React, {
    useRef,
    Ref,
    useState,
    useCallback,
    useMemo,
    CSSProperties,
    ReactNode,
} from 'react';
import { styled } from '@mui/material';

import useMergedRef from '@@hooks/useMergedRef';
import { SLATE_ATTRIBUTE_SELECTORS } from '@@editor/constants';
import { Element, ElementAttributes } from '@@editor/helpers/Element';
import Icon from '@@components/Icon';

import { useDndBlock } from './useDndBlock';
import { DropDirections } from './types';
import DropLine from './DropLine';

const DragHandle = styled('div')({
    pointerEvents: 'auto',
    display: 'flex',
    alignItems: 'center',
});

DragHandle.displayName = 'DragHandle';

const DragIcon = styled(Icon)(({ theme }) => ({
    color: theme.palette.primary.dark,
    '&:hover': {
        cursor: 'move',
    },
}));

const DraggableContext = React.createContext<Ref<HTMLDivElement>>(React.createRef());

const useDraggableContext = () => {
    const context = React.useContext(DraggableContext);

    if (!context) {
        throw new Error(`Compound components cannot be rendered outside the Draggable component`);
    }

    return context;
};

type ChildrenProps = {
    ref: Ref<HTMLElement>;
    style: CSSProperties;
    attributes?: ElementAttributes;
};

type Props = {
    attributes?: ElementAttributes;
    children: ({ ref, style, attributes }: ChildrenProps) => ReactNode;
    element: Element;
};

const Draggable = (props: Props) => {
    const { attributes, children, element } = props;
    const { ref: attributeRef = noop, ...rest } = attributes || {};
    const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
    const rootRef = useRef<HTMLDivElement>(null);
    const dragWrapperRef = useRef(null);
    const multiRootRef = useMergedRef(setReferenceElement, rootRef, attributeRef);

    const { dragRef, isDragging } = useDndBlock({
        blockRef: rootRef,
        element,
    });

    const multiDragRef = useMergedRef(dragRef, dragWrapperRef);

    const style: CSSProperties = useMemo(
        () => ({ position: 'relative', opacity: isDragging ? '0.5' : '1' }),
        [isDragging],
    );

    const refCallback = useCallback(
        (domNode) => {
            // The root ref must be the actual slate element in order to work currectly together with the drop line.
            // Sometimes this will be the current domNode, but in other cases this can be a parent of the current
            // domNode. Therefore we need to use the `closest` function, to find the actual element
            multiRootRef(domNode ? domNode.closest(SLATE_ATTRIBUTE_SELECTORS.BLOCKS) : domNode);
        },
        [multiRootRef],
    );

    return (
        <>
            <DropLine for={{ element: referenceElement, direction: DropDirections.TOP }} />

            <DraggableContext.Provider value={multiDragRef}>
                {children({ ref: refCallback, style, attributes: rest })}
            </DraggableContext.Provider>

            <DropLine for={{ element: referenceElement, direction: DropDirections.BOTTOM }} />
        </>
    );
};

const Handle = () => {
    const multiDragRef = useDraggableContext();

    return (
        <DragHandle ref={multiDragRef}>
            <DragIcon name="grip-dots-vertical" size="large" />
        </DragHandle>
    );
};

Draggable.Handle = Handle;

export default Draggable;

import React, { useMemo } from 'react';

import { PLUGIN_NAMES, PluginConfig, PluginName } from '@@editor/typings/UnityPlugins';
import { PLUGIN_CONFIG_TEMPLATES } from '@@editor/constants';
import setupPlugins from '@@editor/plugins/setup';
import { ParagraphElement } from '@@editor/helpers/Element';

import RichTextEditorBase from './RichTextEditorBase';

type Props = {
    singleLine?: boolean;
    value: ParagraphElement[];
    readOnly?: boolean;
};

const QuoteEditor = (props: Props) => {
    const plugins = useMemo(() => {
        const pluginConfig: (PluginConfig | PluginName)[] = [
            PLUGIN_CONFIG_TEMPLATES.autoReplaceText,
            PLUGIN_NAMES.PARAGRAPH,
            PLUGIN_NAMES.BOLD,
            PLUGIN_NAMES.ITALIC,
            PLUGIN_NAMES.UNDERLINED,
            PLUGIN_NAMES.SUBSCRIPT,
            PLUGIN_NAMES.SUPERSCRIPT,
            PLUGIN_CONFIG_TEMPLATES.specialCharacters,
        ];

        if (props.singleLine) {
            pluginConfig.push(PLUGIN_NAMES.SINGLE_LINE);
        }

        return setupPlugins(pluginConfig);
    }, [props.singleLine]);

    const stickyToolbarButtons = [
        { name: PLUGIN_NAMES.BOLD },
        { name: PLUGIN_NAMES.ITALIC },
        { name: PLUGIN_NAMES.UNDERLINED },
        { name: PLUGIN_NAMES.SUBSCRIPT },
        { name: PLUGIN_NAMES.SUPERSCRIPT },
        PLUGIN_CONFIG_TEMPLATES.specialCharacters,
    ];

    const conditionalProps: { minRows?: number } = {};

    if (props.singleLine) {
        conditionalProps.minRows = 1;
    }

    return (
        <RichTextEditorBase
            {...conditionalProps}
            {...props}
            plugins={plugins}
            stickyToolbarButtons={stickyToolbarButtons}
            hideFloatingToolbar
        />
    );
};

export default QuoteEditor;

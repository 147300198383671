import { omit } from 'lodash';

import config from '@@config';

export const getImageExtensionRegex = (type) => new RegExp(`.${type}`, 'gi');

export const generateEmbedBlockData = ({ embed, src, originalSrc }) => {
    const supportedTypes = Object.keys(config.allowedImageMimeTypes);
    const basicBlock = {
        src: src?.href || src,
        originalSrc,
        // mimetype will be part of the root object (see further below)
        // and it is not (yet) allowed to be included in contentAttributes
        embed: omit(embed, 'mimetype'),
    };

    // uploaded image
    const isFileRepoSrc = src?.pathname?.indexOf('/filerepo/files/') >= 0;

    if (isFileRepoSrc) {
        return {
            mimetype: embed.mimetype || config.allowedImageMimeTypes.PNG,
            ...basicBlock,
        };
    }

    // pasted url
    const typeMatch = supportedTypes.find((type) =>
        getImageExtensionRegex(type).test(src?.pathname),
    );

    if (typeMatch) {
        return {
            mimetype: config.allowedImageMimeTypes[typeMatch.toUpperCase()],
            ...basicBlock,
        };
    }

    return basicBlock;
};

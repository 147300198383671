import React from 'react';

import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { Editor } from '@@editor/helpers';
import { parseYoutubeEmbedCode } from '@@utils/socialMedia/youtube';
import youtubeEmbedCode from '@@form/utils/validators/youtubeEmbedCode';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import { ELEMENT_TYPES, MIME_TYPES, YoutubeElement } from '@@editor/helpers/Element';
import { InsertElementOptions } from '@@editor/helpers/Editor';

import { createGenerateEmbedBlock } from './../utils';
import { generateEmbedBlockData } from './utils';
import EmbeddedIframe from './../components/EmbeddedIframe';
import EditorWithEmbedModal from './../components/EditorWithEmbedModal';
import EmbedWrapper from './../components/EmbedWrapper';

const TYPE = PLUGIN_NAMES.YOUTUBE;
const NODE_TYPE = ELEMENT_TYPES.YOUTUBE;
const EmbeddedIframeWrapper = (props) => <EmbeddedIframe {...props} omitSandbox />;

const mimetypeConfigs = {
    [MIME_TYPES.YOUTUBE]: {
        Component: EmbeddedIframeWrapper,
    },
};

type Props = {
    editor: Editor;
    element: YoutubeElement;
};

const YoutubeNode = (props: Props) => (
    <EmbedWrapper
        {...props}
        type={TYPE}
        mimetypeConfigs={mimetypeConfigs}
        toolbarConfig={{
            infos: {
                iconName: PLUGIN_ICON_NAMES[TYPE],
                title: 'YouTube',
            },
            actions: ['edit', 'delete'],
        }}
    />
);

const generateEmbedBlock = createGenerateEmbedBlock({
    contentType: 'html',
    type: TYPE,
    nodeType: NODE_TYPE,
    generateEmbedBlockData,
    parseEmbedCode: parseYoutubeEmbedCode,
});

const validate = [youtubeEmbedCode];

const internalWithYoutube = (editor, options) => {
    const { insertData } = editor;

    return Object.assign(editor, {
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    validate={validate}
                    generateEmbedBlock={generateEmbedBlock}
                    type={TYPE}
                    formProps={{
                        withCaption: true,
                        withCredit: true,
                    }}
                />
            ),
            options,
        ),
        renderElement: renderElement(editor, [[NODE_TYPE, YoutubeNode]], options),
        insertData: (data: DataTransfer, options: InsertElementOptions) => {
            const text = data.getData('text/plain');

            if (text && !youtubeEmbedCode(text)) {
                generateEmbedBlock(editor, text);
            } else {
                insertData(data, options);
            }
        },
    });
};

export const withYoutube = (editor, options) =>
    internalWithYoutube(editor, {
        ...options,
        nodeType: NODE_TYPE,
    });

export default withYoutube;

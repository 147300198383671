import transformValues from '@@editor/serialization/transformValues';
import { UnityState } from '@@editor/typings/UnityElements';
import unwrapSerializedState from '@@editor/serialization/UnitySerializer/unwrapSerializedState';
import UnitySerializer from '@@editor/serialization/UnitySerializer/UnitySerializer';
import { Compare, type UnityCompare } from '@@api/services/content/schemas/article';

import {
    MoveEnd,
    MoveStart,
    MoveOperation,
    CompareElement,
    ActionOperation,
    IdentityOperation,
    CompareElementOrNull,
    CompareOperationEnum,
    UnityCompareOperation,
    RemoveOperation,
} from './types';

export const extractContentPart = (content: CompareElementOrNull[], index: number) =>
    content?.[index] ? [content[index]] : null;

export const extractElement = (element: CompareElement): CompareElementOrNull[] => [element];

export const isIdentity = (payload: UnityCompareOperation): payload is IdentityOperation =>
    payload.operation === CompareOperationEnum.IDENTITY;

export const isActionOperation = (mod: UnityCompareOperation): mod is ActionOperation =>
    mod.operation === CompareOperationEnum.ADD || mod.operation === CompareOperationEnum.UPDATE;

export const isRemoveOperation = (mod: UnityCompareOperation): mod is RemoveOperation =>
    mod.operation === CompareOperationEnum.REMOVE;

export const isMoveEnd = (payload: UnityCompareOperation): payload is MoveEnd =>
    payload.operation === CompareOperationEnum.MOVE_END;

export const isMoveStart = (payload: UnityCompareOperation): payload is MoveStart =>
    payload.operation === CompareOperationEnum.MOVE_START;

export const isMove = (payload: UnityCompareOperation): payload is MoveOperation =>
    isMoveEnd(payload) || isMoveStart(payload);

type Options = {
    canReuseThreads?: boolean;
};

/*
 * Creates threads that are used by arrows to connect moved blocks
 *
 * Thread visual representation:
 *           -16  -8   0   8   16
 *             |   |   |   |   |
 *             |   |   |   |   |
 *  ---------  |   |   |   |   |  ---------
 * | block  |  |   |   |   |   | |  block |
 * ----------  |   |   |   |   |  ---------
 */
/**
 * If no threads are busy: pick thread 0
 * If thread is busy, pick next one - check the order in thread map
 * If all threads are busy, check if you can free any and use that one
 * If all else fails, use thread 0 (really an edge case)
 */
export const createOffsetThreads = (options: Options = {}) => {
    const { canReuseThreads = true } = options;

    /** this is will set pixel values for distances between threads, add more threads if needed */
    const threadOffsetMap = {
        0: 0,
        1: 12,
        2: -12,
        3: 24,
        4: -24,
        5: 6,
        6: -6,
        7: 18,
        8: -18,
        9: 30,
        10: -30,
        11: 0,
        12: 12,
        13: -12,
        14: 24,
        15: -24,
        16: 6,
        17: -6,
        18: 18,
        19: -18,
        20: 30,
        21: -30,
    };

    const threads = Object.keys(threadOffsetMap).map((_, index) => ({
        end: 0,
        start: 0,
        available: true,
        offset: threadOffsetMap[index],
    }));

    return {
        getOffset: ({ start, end }: { start: number; end: number }) => {
            let offset = 0;

            for (let i = 0; i < threads.length; i++) {
                const reusableThread =
                    canReuseThreads &&
                    threads.find(
                        (thread) =>
                            start > thread.end &&
                            end > thread.start &&
                            start > thread.start &&
                            end > thread.end,
                    );

                if (reusableThread) {
                    reusableThread.start = start;
                    reusableThread.end = end;
                    offset = reusableThread.offset;
                    break;
                }

                if (threads[i].available) {
                    threads[i] = {
                        ...threads[i],
                        start,
                        end,
                        available: false,
                    };
                    offset = threads[i].offset;
                    break;
                }
            }

            return offset;
        },
    };
};

export const deserializeCompareType = (payload: UnityCompare): Compare => {
    const unitySerializer = new UnitySerializer({ useInlineEditing: true });

    return transformValues(payload, [
        [
            'originArticle.content',
            (contentWrapped: UnityState) =>
                unwrapSerializedState(contentWrapped).map((content) =>
                    content ? unitySerializer.deserializeNode(content) : null,
                ),
        ],
        [
            'derivativeArticle.contentModifications',
            (modifications) =>
                modifications.map((modification) => {
                    if ('element' in modification) {
                        return {
                            ...modification,
                            element: unitySerializer.deserializeNode(modification.element),
                        };
                    }

                    return modification;
                }),
        ],
    ]);
};

import { useContext } from 'react';

import { TenantsParamsContext } from '@@containers/TenantSpecific/TenantsParamsContext';
import { AUDIENCE_TYPE } from '@@utils/enums/tenants';
import { type Tenant } from '@@api/services/tenant/schemas';
import { TenantRouter, useTenantClient } from '@@api/services/tenant/client';
import { RequestQuery } from '@@api/types';
import { CONTENT_LANGUAGE_HEADER } from '@@api/constants/headers';
import ContentLocaleContext from '@@containers/contentLocale/ContentLocaleContext';
import { useSelector } from '@@scripts/store/hooks';
import { getContentLocaleSetting } from '@@scripts/settings/settingsSlice';

type Props = {
    params?: RequestQuery<TenantRouter['tenant']['getAll']>;
    isFullList?: boolean;
};

export const useTenants = ({ params, isFullList }: Props = {}) => {
    const tenantParams = useContext(TenantsParamsContext);
    const contentLocaleContext = useContext(ContentLocaleContext);
    const contentLocaleSetting = useSelector(getContentLocaleSetting);
    const { client: tenantClient, queryKeys: tenantKeys } = useTenantClient();

    const query = isFullList ? params : { ...tenantParams, ...params };
    const headers = {
        [CONTENT_LANGUAGE_HEADER]:
            query?.contentLocale || contentLocaleContext || contentLocaleSetting,
    };

    const restQuery = tenantClient.tenant.getAll.useQuery(
        tenantKeys.tenant.getAll({ query: query || {}, headers }),
        { query, headers },
        { staleTime: Infinity },
    );

    const queryInfo = {
        ...restQuery,
        data: restQuery.data?.body,
    };

    const { data: tenants } = queryInfo;

    const ids: Tenant['id'][] | undefined = tenants?.map((tenant) => tenant.id);

    const isFreeMedia = (id) =>
        tenants?.find((tenant) => tenant.id === id)?.audienceType === AUDIENCE_TYPE.FREE;

    return { ...queryInfo, ids, isFreeMedia };
};

export default useTenants;

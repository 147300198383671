import { Range, Transforms } from 'slate';

import { DEFAULT_BLOCK } from '@@editor/constants';
import { Editor, Element, Node } from '@@editor/helpers';
import { ELEMENT_TYPES, ElementType, ListElement } from '@@editor/helpers/Element';

let nodeTypeBeforeMutation: ElementType = ELEMENT_TYPES.PARAGRAPH;

export const isWrappedByList = (editor: Editor, options?): boolean =>
    Editor.isElementActive(
        editor,
        [ELEMENT_TYPES.ORDERED_LIST, ELEMENT_TYPES.UNORDERED_LIST],
        options,
    );

export const isSelectionFollowedByList = (editor: Editor): boolean => {
    const next = Editor.next(editor);

    if (next) {
        const [, nextPath] = next;

        return isWrappedByList(editor, { at: nextPath });
    }

    return false;
};

export const isEmptyListItemSelected = (editor: Editor): boolean => {
    const focusBlock = Editor.focusBlock(editor);

    if (
        focusBlock != null &&
        Element.isListItemElement(focusBlock[0]) &&
        editor.selection &&
        Range.isCollapsed(editor.selection)
    ) {
        const focusBlockText = Node.string(focusBlock[0]);

        return focusBlockText.length === 0;
    }

    return false;
};

const createToggleList =
    (type: typeof ELEMENT_TYPES.UNORDERED_LIST | typeof ELEMENT_TYPES.ORDERED_LIST) =>
    (editor: Editor): void => {
        const [closesListEntry] = Editor.elements<Node>(editor, {
            types: [ELEMENT_TYPES.UNORDERED_LIST, ELEMENT_TYPES.ORDERED_LIST],
            mode: 'lowest',
        });

        if (closesListEntry) {
            const [node, path] = closesListEntry;

            if (node.type !== type) {
                Transforms.setNodes(
                    editor,
                    {
                        type,
                    },
                    { at: path },
                );

                return;
            }
        }

        Editor.withoutNormalizing(editor, () => {
            const containsListItem = Editor.isElementActive(editor, ELEMENT_TYPES.LIST_ITEM);
            const isWrappedByList = Editor.isElementActive(editor, type);

            Transforms.unwrapNodes(editor, {
                match: Element.isListElement,
                split: true,
            });

            if (!containsListItem) {
                const focusBlock = Editor.focusBlock(editor);

                nodeTypeBeforeMutation = focusBlock
                    ? (focusBlock[0] as Element).type
                    : ELEMENT_TYPES.PARAGRAPH;

                Transforms.setNodes(editor, { type: ELEMENT_TYPES.LIST_ITEM });
                Transforms.wrapNodes<ListElement>(editor, { type, data: {}, children: [] });
            } else {
                if (isWrappedByList) {
                    Transforms.setNodes(editor, { type: nodeTypeBeforeMutation });
                } else {
                    Transforms.wrapNodes<ListElement>(editor, { type, data: {}, children: [] });
                }
            }
        });
    };

export const toggleUnorderedList = createToggleList(ELEMENT_TYPES.UNORDERED_LIST);
export const toggleOrderedList = createToggleList(ELEMENT_TYPES.ORDERED_LIST);

export const removeList = (editor: Editor): void =>
    Editor.withoutNormalizing(editor, () => {
        Transforms.setNodes(editor, { type: DEFAULT_BLOCK.type });
        Transforms.unwrapNodes(editor, {
            match: Element.isListElement,
            split: true,
        });
    });

import { omit } from 'lodash';

import { PollElement } from '@@editor/helpers/Element';

export const removePollIds = (data: PollElement['data']) => ({
    ...data,
    question: [
        {
            ...data.question[0],
            data: { ...omit(data.question[0].data, ['pollItemId']) },
        },
    ],
    answers: data.answers.map((answer) => [
        { ...answer[0], data: { ...omit(answer[0].data, ['pollItemId']) } },
    ]),
});

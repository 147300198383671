import { Text } from 'slate';

import { Element } from '@@editor/helpers';
import makeState from '@@editor/utils/makeState';

const string = (node) => {
    if (Text.isText(node)) {
        return node.text;
    }

    return node.children.map(string).join(Element.isBlockList(node.children) ? '\n' : '');
};

export const serialize = (state) => {
    if (Array.isArray(state)) {
        return string({ children: state });
    }

    throw new Error(`Plain.serialize: Could not serialize data ${state}`);
};

export default {
    deserialize: makeState,
    serialize,
};

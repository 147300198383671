import { DEFAULT_TENANT_ID } from '@@containers/metadata/constants';
import deserializeState from '@@editor/serialization/deserializeState';
import serializeState from '@@editor/serialization/serializeState';
import transformValues from '@@editor/serialization/transformValues';
import { NewUnityUser, NewUser, type UnityUser, type User } from '@@api/services/auth/schemas';

import { deserializeVariants, serializeVariants } from '../../utils/transformers';

type FullNameAndInitials = { initials?: string; fullName?: string };

export const getFullName = (user) => `${user?.firstName || ''} ${user?.lastName || ''}`.trim();

export const getFullNameAndInitials = (user: UnityUser): FullNameAndInitials => {
    if (user.firstNameFromAD === null || user.lastNameFromAD === null) {
        return {};
    }

    const initials = `${user.firstNameFromAD.charAt(0)}${user.lastNameFromAD.charAt(0)}`;

    let firstName = '';
    let lastName = '';

    if (user.serviceUser) {
        firstName = user.firstNameFromAD;
    } else {
        firstName = user.variants[DEFAULT_TENANT_ID].firstName;
        lastName = user.variants[DEFAULT_TENANT_ID].lastName;
    }

    return {
        initials,
        fullName: getFullName({ firstName, lastName }),
    };
};

export const deserializeUser = (entity: UnityUser): User => {
    const deserializedVariants = deserializeVariants(entity.variants, (variant) =>
        transformValues<typeof variant, User['variants'][number]>(variant, [
            [['shortBiography', 'longBiography', 'userImage.caption'], deserializeState],
        ]),
    );

    return {
        ...entity,
        variants: deserializedVariants,
        ...getFullNameAndInitials(entity),
    };
};

export const serializeUser = (entity: User | NewUser): UnityUser | NewUnityUser => {
    const serializedVariants = serializeVariants(entity.variants, (variant) =>
        transformValues<typeof variant, UnityUser['variants'][string]>(variant, [
            [['shortBiography', 'longBiography', 'userImage.caption'], serializeState],
            [['prolitterisId'], (value: string) => (value?.length > 0 ? value : null)],
        ]),
    );

    return {
        ...entity,
        variants: serializedVariants,
    };
};

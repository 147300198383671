import isHotkey from 'is-hotkey';

import { Editor } from '@@editor/helpers';
import { HandleHotkey } from '@@editor/helpers/Editor';

const handleHotkey = (
    editor: Editor,
    handlersConfig: [string, (props: Editor) => void][],
    options = { breakPluginChain: true },
): HandleHotkey => {
    const { handleHotkey } = editor;
    const { breakPluginChain } = options;

    return (e) => {
        const handlerConfig = handlersConfig.find(([hotkey]) => isHotkey(hotkey, e.nativeEvent));

        if (handlerConfig) {
            e.preventDefault();

            const [, fn] = handlerConfig;

            fn(editor);

            if (breakPluginChain) {
                return;
            }
        }

        // Disable tab key in article editor.
        // We have to disable it because we use arrow keys to move between elements.
        // Keeping the tab key option, will result in user's losing the focus on hitting tab key.
        if (
            e.nativeEvent.key === 'Tab' &&
            editor.isArticleEditor &&
            !editor.isArticleHeadingsEditor
        ) {
            e.preventDefault();

            return;
        }

        handleHotkey(e);
    };
};

export default handleHotkey;

import { Button, ButtonProps, styled } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import FloatingBox, { Props as FloatingBoxProps } from '../FloatingBox';

const StyledButton = styled(Button)(({ theme }) => ({
    '&:hover, &:focus': {
        color: theme.palette.success.main,
    },
    '&:active': {
        color: theme.palette.success.dark,
    },
}));

type Props = PropsWithChildren<
    ButtonProps & {
        FloatingBoxProps?: FloatingBoxProps;
    }
>;

const ListActionButton = ({ children, FloatingBoxProps, ...props }: Props) => (
    <FloatingBox anchor={null} position="bottom-right" spacingX={1} {...FloatingBoxProps}>
        <StyledButton size="small" {...props}>
            {children}
        </StyledButton>
    </FloatingBox>
);

export default ListActionButton;

import { useEffect } from 'react';
// This is the only place in this stack where we use `useFieldArray` directly! Use this abstraction
// in all other places!
// eslint-disable-next-line no-restricted-imports
import { useFieldArray as originalUseFieldArray } from 'react-hook-form';

import { useReactHookFormMetaContext } from './ReactHookFormMetaContext';

const useFieldArray = (...args: Parameters<typeof originalUseFieldArray>) => {
    const [props] = args;
    const { name } = props;
    const { registerFieldArray, unregisterFieldArray } = useReactHookFormMetaContext();

    useEffect(() => {
        registerFieldArray(name);

        return () => {
            unregisterFieldArray(name);
        };
    }, []);

    return originalUseFieldArray(props);
};

export default useFieldArray;

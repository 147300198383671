import { styled } from '@mui/material';

const EmbedOverlay = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.layer2,
    backgroundColor: 'transparent',
}));

EmbedOverlay.displayName = 'EmbedOverlay';

export default EmbedOverlay;

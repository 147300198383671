import { styled, IconButton } from '@mui/material';
import update from 'immutability-helper';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from '@@store/hooks';
import { getSmartSetting, setSetting } from '@@settings/settingsSlice';
import { takeFirst } from '@@utils/index';
import { resolveApiEnv } from '@@scripts/resolveEnv';
import config, {
    MicroserviceItem,
    Microservices as MicroservicesType,
    resolveAuthenticationApiUrl,
} from '@@config';
import Icon from '@@components/Icon';
import usePrevious from '@@hooks/usePrevious';

import { MODE_ICON, MODE_SMALL, MODE_BIG } from './constants';
import SmallToolbar from './components/SmallToolbar';
import BigToolbar from './components/BigToolbar';

const HTTP_CLIENT_ERRORS = 400;

const IconWrapper = styled(IconButton)(({ theme }) => ({
    position: 'fixed',
    right: theme.fixed.toolbar.collapsed.width,
    bottom: theme.fixed.pageHeader.height,
    zIndex: theme.zIndex.layer3,
}));

type Microservice = MicroserviceItem & {
    health?: {
        status: string;
        error: string;
    };
};

export type Microservices = {
    [Property in keyof MicroservicesType]: Microservice;
};

const DeveloperToolbar = () => {
    const dispatch = useDispatch();
    const mode = useSelector((state) => getSmartSetting(state, { key: 'devToolbar' }));

    const [healthyMicroserviceCount, setHealthyMicroserviceCount] = useState(0);
    const [unhealthyMicroserviceCount, setUnhealthyMicroserviceCount] = useState(0);
    const [microservices, setMicroservices] = useState<Microservices>(config.microservices);
    const [api, setApi] = useState(resolveApiEnv());

    const previousMode = usePrevious(mode);
    const previousApi = usePrevious(api);

    const handleClickOnIconWrapper = () =>
        dispatch(setSetting({ path: 'devToolbar', value: MODE_BIG }));
    const handleClickOnCloseButton = (e) => {
        // Stop propagation to avoid triggering of handleClickOnSmallWrapper
        e.stopPropagation();

        dispatch(setSetting({ path: 'devToolbar', value: MODE_ICON }));
    };

    const handleChangeOfApi = (e, value) => setApi(value);

    const retrieveMicroservicesHealthState = () => {
        setHealthyMicroserviceCount(0);
        setUnhealthyMicroserviceCount(0);

        return Promise.all(
            Object.entries(microservices).map(([key, microservice]) =>
                fetch(microservice.healthUrl)
                    .then((response) => {
                        if (response.status >= HTTP_CLIENT_ERRORS) {
                            throw new Error(`${response.status} ${response.statusText}`);
                        }

                        return response.json();
                    })
                    .then((payload) => {
                        if (payload.status !== 'UP') {
                            throw new Error(`Unhealthy status: ${payload.status}`);
                        }

                        return payload;
                    })
                    .then((payload) => {
                        setHealthyMicroserviceCount((count) => count + 1);
                        setMicroservices((microservices) =>
                            update(microservices, {
                                [key]: {
                                    health: {
                                        $set: {
                                            status: payload.status,
                                        },
                                    },
                                },
                            }),
                        );
                    })
                    .catch((error) => {
                        setUnhealthyMicroserviceCount((count) => count + 1);
                        setMicroservices((microservices) =>
                            update(microservices, {
                                [key]: {
                                    health: {
                                        $set: {
                                            error: error.toString(),
                                        },
                                    },
                                },
                            }),
                        );
                    }),
            ),
        );
    };

    // Make sure retrieveMicroservicesHealthState is ran only once at a time
    const firstRetrieveMicroservicesHealthState = takeFirst(retrieveMicroservicesHealthState);

    useEffect(() => {
        if ([MODE_SMALL, MODE_BIG].indexOf(mode) >= 0) {
            if (previousMode !== mode) {
                firstRetrieveMicroservicesHealthState();
            }
        }
    }, [mode, firstRetrieveMicroservicesHealthState, previousMode]);

    useEffect(() => {
        if (previousApi !== api) {
            if (api) {
                localStorage.setItem('CDII_API', api);
            } else {
                localStorage.removeItem('CDII_API');
            }

            const prevApiEnv = resolveApiEnv(previousApi);
            const nextApiEnv = resolveApiEnv(api);

            if (prevApiEnv !== nextApiEnv) {
                window.location.assign(resolveAuthenticationApiUrl(nextApiEnv));
            }
        }
    }, [api, previousApi]);

    switch (mode) {
        case MODE_ICON:
            return (
                <IconWrapper color="warning" size="large" onClick={handleClickOnIconWrapper}>
                    <Icon name="circle-exclamation-sharp" size="large" />
                </IconWrapper>
            );

        case MODE_SMALL: {
            return (
                <SmallToolbar
                    healthyMicroserviceCount={healthyMicroserviceCount}
                    unhealthyMicroserviceCount={unhealthyMicroserviceCount}
                    onClickCloseButton={handleClickOnCloseButton}
                />
            );
        }

        case MODE_BIG:
            return (
                <BigToolbar
                    healthyMicroserviceCount={healthyMicroserviceCount}
                    unhealthyMicroserviceCount={unhealthyMicroserviceCount}
                    onClickCloseButton={handleClickOnCloseButton}
                    microservices={microservices}
                    api={api}
                    onChangeApi={handleChangeOfApi}
                />
            );
    }

    return null;
};

export default DeveloperToolbar;

import { Path, Text, Transforms } from 'slate';

import { DEFAULT_BLOCK, HOTKEYS } from '@@editor/constants';
import renderElement from '@@editor/plugins/utils/renderElement';
import handleHotkey from '@@editor/plugins/utils/handleHotkey';
import { Editor, Element, Node } from '@@editor/helpers';
import { ELEMENT_TYPES } from '@@editor/helpers/Element';

import {
    isEmptyListItemSelected,
    removeList,
    toggleUnorderedList,
    toggleOrderedList,
    isSelectionFollowedByList,
    isWrappedByList,
} from './utils';
import Li from './components/Li';
import TextBlock from '../components/TextBlock';

export const withList = (editor: Editor) => {
    const { deleteForward, insertBreak, normalizeNode } = editor;

    return Object.assign(editor, {
        renderElement: renderElement(editor, [
            [ELEMENT_TYPES.ORDERED_LIST, TextBlock],
            [ELEMENT_TYPES.UNORDERED_LIST, TextBlock],
            [ELEMENT_TYPES.LIST_ITEM, Li],
        ]),
        handleHotkey: handleHotkey(editor, [
            [HOTKEYS.LIST_UL, toggleUnorderedList],
            [HOTKEYS.LIST_OL, toggleOrderedList],
        ]),
        deleteForward: (unit) => {
            if (
                editor.selection &&
                Editor.isSelectionEmpty(editor) &&
                Editor.isSelectionOnEmptyLine(editor) &&
                isSelectionFollowedByList(editor)
            ) {
                // If the cursor is on an empty line, followed by a list, and the user presses the delete button,
                // we want to remove that empty line to have a proper UX. Without this, the first list item of the
                // following list would be converted into a paragraph, which is wrong (source of truth: google documents)

                const focusBlock = Editor.focusBlock(editor);

                if (focusBlock) {
                    Transforms.removeNodes(editor, { at: focusBlock[1] });

                    return;
                }
            }

            deleteForward(unit);
        },
        insertBreak: () => {
            // This check is for the case, if the user uses the enter key to get out of a list
            if (isEmptyListItemSelected(editor)) {
                removeList(editor);
            } else {
                insertBreak();
            }
        },
        normalizeNode: (entry) => {
            const [node, path] = entry;

            // Make sure lists contain only list items and make sure every list item is wrapped
            // by a list
            if (Element.isListElement(node)) {
                for (const [child, childPath] of Node.children(editor, path)) {
                    // Only allow list items inside lists
                    if (!Element.isListItemElement(child)) {
                        if (Element.isListElement(child)) {
                            // If there is a list inside a list, just remove that nested list, but keep its list items.
                            // This is what is achieved by unwrapping the child

                            Transforms.unwrapNodes(editor, {
                                at: childPath,
                            });

                            return;
                        } else if (Element.isParagraphElement(child)) {
                            // Paragraph nodes just get converted to list items

                            Transforms.setNodes(
                                editor,
                                { type: ELEMENT_TYPES.LIST_ITEM },
                                { at: childPath },
                            );

                            return;
                        } else if (Text.isText(child)) {
                            // Text nodes are not allowed as an immediate child to a list. A user cannot really
                            // create this intentionally, but in the following scenario it is generated by the editor:
                            // - Two lists underneath each other
                            // - Cursor is at the end of the last list item of the upper list
                            // - The user presses the delete button
                            Transforms.removeNodes(editor, { at: path });

                            return;
                        }

                        const nextNodePath = Path.next(path);

                        // Everything else is just moved out of the list
                        Transforms.moveNodes(editor, { at: childPath, to: nextNodePath });

                        return;
                    }
                }
            } else if (Element.isListItemElement(node)) {
                if (!isWrappedByList(editor, { at: path })) {
                    // If there are list items floating around without being wrapped in a list,
                    // convert them into paragraphs (this could happen when pressing backspace on
                    // the first list item in the very beginning)
                    Transforms.setNodes(editor, { type: DEFAULT_BLOCK.type }, { at: path });

                    return;
                }
            }

            // Fall back to the original `normalizeNode` to enforce other constraints.
            normalizeNode(entry);
        },
        toggleOrderedList: () => toggleOrderedList(editor),
        toggleUnorderedList: () => toggleUnorderedList(editor),
        isOrderedListActive: () => Editor.isElementActive(editor, ELEMENT_TYPES.ORDERED_LIST),
        isUnorderedListActive: () => Editor.isElementActive(editor, ELEMENT_TYPES.UNORDERED_LIST),
    });
};

export default withList;

import { RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        path: '',
        lazy: async () => {
            const { Layout } = await import(
                /* webpackChunkName: "kickwords" */ '@@routes/kickwords'
            );

            return { Component: Layout };
        },
        children: [
            {
                path: ':id',
                lazy: async () => {
                    const { KickwordForm } = await import(
                        /* webpackChunkName: "kickwordForm" */ '@@routes/kickwords/KickwordForm'
                    );

                    return { Component: KickwordForm };
                },
            },
        ],
    },
];

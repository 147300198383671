import React, { RefObject, useContext, useMemo, useRef } from 'react';

import { PageLayoutArea } from './types';

type PageLayoutElement = HTMLElement | null;
type ContextValue = Record<PageLayoutArea, RefObject<PageLayoutElement>>;
type PageLayoutContext = ContextValue;

const DEFAULT_CONTEXT_VALUE: ContextValue = {
    nav: React.createRef(),
    notificationBar: React.createRef(),
    head: React.createRef(),
    sidebar: React.createRef(),
    main: React.createRef(),
    toolbar: React.createRef(),
    foot: React.createRef(),
};

const PageLayoutContext = React.createContext<PageLayoutContext>(DEFAULT_CONTEXT_VALUE);

type Props = { children: React.ReactNode };

export const PageLayoutProvider = ({ children }: Props) => {
    const nav = useRef<PageLayoutElement>(null);
    const notificationBar = useRef<PageLayoutElement>(null);
    const head = useRef<PageLayoutElement>(null);
    const sidebar = useRef<PageLayoutElement>(null);
    const main = useRef<PageLayoutElement>(null);
    const toolbar = useRef<PageLayoutElement>(null);
    const foot = useRef<PageLayoutElement>(null);
    const refs = useMemo(() => ({ nav, notificationBar, head, sidebar, main, toolbar, foot }), []);

    return <PageLayoutContext.Provider value={refs}>{children}</PageLayoutContext.Provider>;
};

export const usePageLayout = () => useContext(PageLayoutContext);

import renderElement from '@@editor/plugins/utils/renderElement';
import { MIME_TYPES } from '@@editor/helpers/Element';
import { GenerateEmbedBlockData } from '@@editor/typings/Embed';

import { TYPE, NODE_TYPE } from './constants';
import { createGenerateEmbedBlock } from './../utils';
import EmbeddedPollNode from './components/EmbeddedPollNode';

export const generateEmbedBlockData: GenerateEmbedBlockData = ({ embed, originalSrc }) => ({
    mimetype: MIME_TYPES.EMBEDDED_POLL,
    embed,
    originalSrc,
});

export const generateEmbedBlock = createGenerateEmbedBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
    generateEmbedBlockData,
});

const internalWithEmbedPoll = (editor, options) =>
    Object.assign(editor, {
        renderElement: renderElement(editor, [[NODE_TYPE, EmbeddedPollNode]], options),
    });

export const withEmbedPoll = (editor, options) =>
    internalWithEmbedPoll(editor, {
        ...options,
        nodeType: [NODE_TYPE],
    });

export default withEmbedPoll;

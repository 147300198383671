import {
    TypedUseSelectorHook,
    /* eslint-disable no-restricted-imports */
    useDispatch as useOriginalDispatch,
    useSelector as useOriginalSelector,
    /* eslint-enable no-restricted-imports */
} from 'react-redux';

import { RootState, AppDispatch } from './store';

export const useDispatch = () => useOriginalDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useOriginalSelector;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { PLUGIN_CONFIG_TEMPLATES } from '@@editor/constants';
import setupPlugins from '@@editor/plugins/setup';
import usePostSpellCheck from '@@form/hooks/usePostSpellCheck';

import RichTextEditorBase from './RichTextEditorBase';

const BasicRichTextEditor = ({ tenantIds, ...props }) => {
    const spellCheck = usePostSpellCheck();

    const plugins = useMemo(() => {
        const pluginConfig = [PLUGIN_CONFIG_TEMPLATES.autoReplaceText, PLUGIN_NAMES.PARAGRAPH];

        if (props.singleLine) {
            pluginConfig.push(PLUGIN_NAMES.SINGLE_LINE);
        }

        if (props.spellCheck) {
            pluginConfig.push(PLUGIN_NAMES.SPELL_CHECKER);
        }

        return setupPlugins(pluginConfig, {
            optionsPerPlugin: {
                [PLUGIN_NAMES.SPELL_CHECKER]: {
                    spellCheck,
                    tenantIds,
                },
            },
        });
    }, [props.singleLine, props.spellCheck, spellCheck]);

    return (
        <RichTextEditorBase {...props} plugins={plugins} hideStickyToolbar hideFloatingToolbar />
    );
};

BasicRichTextEditor.propTypes = {
    singleLine: PropTypes.bool,
    tenantIds: PropTypes.array,
    spellCheck: PropTypes.bool,
};

export default BasicRichTextEditor;

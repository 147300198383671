import { isEmpty } from 'lodash';
import React from 'react';
import { styled } from '@mui/material';

import { useTenantVariant } from '@@containers/TenantSpecific/TenantVariantContext';
import CaptionEditor from '@@form/components/RichTextEditor/CaptionEditor';
import PreviewImage from '@@containers/PreviewImage';
import Loader from '@@components/Loader';
import isEmptyState from '@@editor/utils/isEmptyState';
import toText from '@@editor/utils/toText';
import { type SlideshowSlide, type SlideshowSlideVariant } from '@@api/services/slideshow/schemas';
import { IImageTransformationsOutput } from '@@utils/buildImageTransformationUrl';
import { getVariantById } from '@@containers/TenantSpecific/arrayUtils';
import { DEFAULT_TENANT_ID } from '@@containers/metadata/constants';
import { type Image } from '@@api/utils/schemas/schemas';

import { IMAGE_WRAPPER_HEIGHT } from './constants';

const ImageWrapper = styled('div')(({ theme }) => ({
    gridArea: 'preview',
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.black,
    minHeight: `${IMAGE_WRAPPER_HEIGHT}px`,

    img: {
        objectFit: 'contain',
        minHeight: '100%',
    },
}));

const Caption = styled('div')(({ theme }) => ({
    gridArea: 'caption',
    ...theme.typography.editorSecondarySmall,
    padding: theme.spacing(0, 2, 2, 2),
    marginTop: theme.spacing(1),
}));

const Credit = styled('span')(({ theme }) => ({
    ...theme.typography.editorSecondaryXsmall,
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
}));

const StyledCaptionEditor = styled(CaptionEditor)(({ theme }) => ({
    ...theme.typography.editorSecondarySmall,
}));

type SlideshowImageProps = {
    loading: boolean;
    image: Image;
    transformations: IImageTransformationsOutput;
};

export const renderSlideshowImage = ({ loading, image, transformations }: SlideshowImageProps) => (
    <>
        <ImageWrapper>
            {loading ? (
                <Loader />
            ) : (
                <PreviewImage
                    src={image.url}
                    {...(!isEmptyState(image.caption) ? { alt: toText(image.caption) } : {})}
                    transformations={{
                        ...image,
                        ...transformations,
                    }}
                />
            )}
        </ImageWrapper>
        {!loading && (
            <Caption>
                {!isEmptyState(image.caption) && (
                    <StyledCaptionEditor value={image.caption} readOnly />
                )}
                <Credit>{image.credit}</Credit>
            </Caption>
        )}
    </>
);

type Props = {
    slide: SlideshowSlide | undefined | null;
    transformations?: IImageTransformationsOutput;
    isLoading?: boolean;
};

const SlideshowImage = ({ slide, isLoading, transformations = {} }: Props) => {
    const { selectedVariantId } = useTenantVariant();

    const slideVariant = slide?.variants
        ? getVariantById(slide.variants, selectedVariantId) ||
          getVariantById(slide.variants, DEFAULT_TENANT_ID)
        : ({} as SlideshowSlideVariant);

    return renderSlideshowImage({
        loading: isLoading || isEmpty(slide?.variants),
        transformations,
        image: slideVariant.image,
    });
};

export default SlideshowImage;

import { RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        index: true,
        lazy: async () => {
            const { Home } = await import(/* webpackChunkName: "home" */ '@@routes/home/Home');

            return { Component: Home };
        },
    },
];

import React from 'react';

import { ModalContextType } from './types';

const initialContextValue: ModalContextType = {
    state: {
        modals: [],
    },
    actions: {
        register: () => {
            throw new Error('Cannot register a modal outside of a `ModalProvider`!');
        },
        unregister: () => {},
        show: () => {},
        hide: () => {},
        updateProps: () => {},
    },
};

const ModalContext = React.createContext<ModalContextType>(initialContextValue);

export default ModalContext;

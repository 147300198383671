import { castArray, first, flow, omit, map, partialRight, pick, cloneDeep } from 'lodash';

import { Element } from '@@editor/helpers';
import { ParagraphElement, PollElement, ELEMENT_TYPES } from '@@editor/helpers/Element';
import {
    isUnityPoll,
    UnityElement,
    UnityParagraph,
    UnityPoll,
    UnityPollItem,
} from '@@editor/typings/UnityElements';
import transformValues from '@@editor/serialization/transformValues';

import { CURRENT_VERSION } from '../constants';
import { createElement } from '../deserializeNodes';
import { SerializerOptions } from '../types';

type UnityParagraphWithPollItemId = UnityParagraph & {
    pollItemId: Id;
};

type ParagraphElementWithPollItemId = ParagraphElement & {
    pollItemId: Id;
};

const stripPollItem = (pollItem): ParagraphElementWithPollItemId => ({
    ...pollItem.text,
    pollItemId: pollItem.id,
});

const recreatePollItem = (text: UnityParagraphWithPollItemId): UnityPollItem => ({
    type: 'poll-item',
    version: CURRENT_VERSION,
    text: omit(text, 'pollItemId'),
    id: text.pollItemId,
});

const serializeInlinePollData = (children: Element[], data: PollElement['data']) => {
    const newData = cloneDeep(data);
    const question = children.find(Element.isPollQuestionElement);
    const answers = children.filter(Element.isPollAnswerElement);

    if (question) {
        newData.question = [{ ...data.question[0], children: question.children }];
    }

    if (answers.length > 0) {
        newData.answers = answers.map((answer, index) => {
            if (data.answers[index]) {
                return [
                    {
                        ...data.answers[index][0],
                        type: ELEMENT_TYPES.PARAGRAPH,
                        children: answer.children,
                    },
                ];
            }

            return [{ ...answer, type: ELEMENT_TYPES.PARAGRAPH }];
        });
    }

    return newData;
};

const pollRules = {
    deserialize: (element: UnityElement, next, options?): PollElement | undefined => {
        if (!isUnityPoll(element)) {
            return;
        }

        const values = pick(element, ['question', 'answers', 'templateElement']);

        return createElement<PollElement>(
            ELEMENT_TYPES.POLL,
            transformValues<typeof values, PollElement['data']>(values, [
                ['question', flow(stripPollItem, next, castArray)],
                ['answers', partialRight(map, flow(stripPollItem, next, castArray))],
            ]),
            options,
        );
    },

    serialize: (node: Element, next, options: SerializerOptions = {}): UnityPoll | undefined => {
        if (!Element.isPollElement(node)) {
            return;
        }

        const { type, data, children } = node;
        const { useInlineEditing } = options;
        const newData = useInlineEditing ? serializeInlinePollData(children, data) : data;

        return {
            version: CURRENT_VERSION,
            type,
            ...transformValues<typeof newData, Omit<UnityPoll, 'version' | 'type'>>(newData, [
                ['question', flow(first, next, recreatePollItem)],
                ['answers', partialRight(map, flow(first, next, recreatePollItem))],
            ]),
        };
    },
};

export default pollRules;

import { isVideoCMSEmbedCode, hasValidVideoCMSVideoId } from '@@utils/socialMedia/videocms';

export default (value) => {
    if (!isVideoCMSEmbedCode(value)) {
        // t('validator.message.videoCMSError');
        return ['validator.message.videoCMSError'];
    }

    if (!hasValidVideoCMSVideoId(value)) {
        // t('validator.message.videoCMSVideoIdError');
        return ['validator.message.videoCMSVideoIdError'];
    }

    /*
    if (!hasValidVideoCMSClient(value)) {
        return [ 'validator.message.videoCMSClientError' ];
    }
    */
};

import { Path, Range, Transforms } from 'slate';

import { HistoryEditor } from '@@editor/helpers';

const wrapText = (editor, text) => {
    const { selection } = editor;
    const [textBefore, textAfter] = text;
    const isCollapsed = Range.isCollapsed(selection);

    HistoryEditor.asSingleHistoryEntry(editor, () => {
        // Collapse selection to the end of the original selection
        Transforms.select(editor, Range.end(selection));
        // Insert `textAfter` (at the end of the original selection)
        editor.insertText(textAfter);
        // Collapse selection to the start of the original selection
        Transforms.select(editor, Range.start(selection));
        // Insert `textBefore` (at the start of the original selection)
        editor.insertText(textBefore);

        // If there was text selected initially, restore that selection now
        if (!isCollapsed) {
            // Restore initial selection
            Transforms.select(editor, selection);

            // Since we have inserted `textBefore` before the initial selection, we have to move
            // the selection now accordingly, since `textBefore` must not be selected
            Transforms.move(editor, {
                distance: textBefore.length,
                unit: 'character',
                edge: 'start',
            });

            // If the selection ends on the same line as it starts, we have to move the end
            // of the selection as well, since `textAfter` must not be selected
            if (Path.equals(selection.anchor.path, selection.focus.path)) {
                Transforms.move(editor, {
                    distance: textBefore.length,
                    unit: 'character',
                    edge: 'end',
                });
            }
        }
    });
};

export const withQuotationMarks = (editor) =>
    Object.assign(editor, {
        wrapText: (text) => wrapText(editor, text),
    });

export default withQuotationMarks;

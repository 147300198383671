import { omit } from 'lodash';
import { TFunction } from 'i18next';
import React from 'react';
import { styled } from '@mui/material';

import { deserializeImage, serializeImage } from '@@api/utils/transformers';
import { Element, ImageElement } from '@@editor/helpers/Element';
import createImage from '@@form/components/ImageEditor/createImage';
import ImageField from '@@form/fields/ImageField';
import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { UseReactHookFormProps } from '@@containers/ReactHookForm/types';

import { MAX_HEIGHT, MAX_WIDTH } from '../../constants';

const DEFAULT_STYLE_IMAGE_TYPE = 'image';

// element data needs to be transformed back into form data
const transformElementToFormValues = (element) => {
    // default
    if (!element) {
        return { embed: createImage({ style: { imageType: DEFAULT_STYLE_IMAGE_TYPE } }) };
    }

    const data = element.data;
    const image = data.embed;

    const newData = {
        embed: {
            ...omit(image, 'src'),
            style: {
                imageType: image.style?.imageType || DEFAULT_STYLE_IMAGE_TYPE,
            },
            url: Element.isTemplateElement(element) ? '' : data.src || image.url,
            mimetype: data.mimetype,
        },
    };

    return deserializeImage(newData, 'embed');
};

type Props = {
    className?: string;
    element: ImageElement;
    renderButtons: (button: any) => JSX.Element;
    onCancel: VoidFunction;
    onSubmit: UseReactHookFormProps['onSubmit'];
    t: TFunction;
    inlineEdited: boolean;
};

export const EmbeddedContentForm = ({
    onCancel,
    onSubmit,
    renderButtons,
    element,
    className,
    t,
    inlineEdited,
}: Props) => {
    const handleClickOnCancel = () => {
        onCancel();
    };

    const handleSubmit: UseReactHookFormProps['onSubmit'] = (data, form, options) => {
        const newData = inlineEdited
            ? {
                  ...data,
                  embed: {
                      ...omit(data.embed, ['caption', 'credit']),
                  },
              }
            : data;

        const serializedData = serializeImage(newData, 'embed');

        onSubmit(serializedData, form, options);
    };

    return (
        <ReactHookForm
            formName="EmbedImage"
            values={transformElementToFormValues(element)}
            onSubmit={handleSubmit}
            onCancel={handleClickOnCancel}
            renderButtons={renderButtons}
            hasCancelButton
            hasActiveCancelButton
            alwaysShowCancelButton
            className={className}
        >
            {inlineEdited ? (
                <ImageField
                    name="embed"
                    label={t('editor.imageform.image')}
                    noDropArea
                    hasButtonGroup={false}
                    showCaption={false}
                    showCredit={false}
                    showImageType
                    transformations={{ maxWidth: MAX_WIDTH, maxHeight: MAX_HEIGHT }}
                />
            ) : (
                <ImageField
                    name="embed"
                    label={t('editor.imageform.image')}
                    required
                    showImageType
                    transformations={{ maxWidth: MAX_WIDTH, maxHeight: MAX_HEIGHT }}
                />
            )}
        </ReactHookForm>
    );
};

export default styled(EmbeddedContentForm)({
    width: '640px',
});

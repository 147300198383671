import React from 'react';
import { FieldValues } from 'react-hook-form';
import { InputBaseComponentProps, styled } from '@mui/material';

import { Element } from '@@editor/helpers';
import { Wrapper } from '@@editor/toolbars/stickyToolbar/Toolbar';

import TextInputField, { TextInputFieldProps } from '../TextInput';

const StyledTextField = styled(TextInputField)(({ theme }) => ({
    '&:hover': {
        [Wrapper]: {
            zIndex: theme.fixed.editor.stickyToolbar.hoveredZindex,
        },
    },
})) as typeof TextInputField;

// https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
const InputComponent = React.forwardRef<any, InputBaseComponentProps>((props, ref) => {
    const { component: Component, ...rest } = props;

    React.useImperativeHandle(ref, () => ({
        focus() {},
    }));

    return <Component {...rest} />;
});

type Props<TFieldValues extends FieldValues = FieldValues> = Omit<
    TextInputFieldProps<TFieldValues>,
    'component' | 'onChange'
> & {
    component: React.ComponentType<any>;
    onChange?: (value: Element[], onFieldChange: (...event: any[]) => void) => void;
};

export type RichTextFieldProps<TFieldValues extends FieldValues = FieldValues> = Omit<
    Props<TFieldValues>,
    'component'
>;

const RichTextField = <TFieldValues extends FieldValues = FieldValues>(
    props: Props<TFieldValues>,
) => {
    const { component: Component, inputProps, ...rest } = props;

    return (
        <StyledTextField
            {...rest}
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                inputComponent: InputComponent,
                inputProps: {
                    ...inputProps,
                    component: Component,
                },
                sx: {
                    paddingTop: '4px',
                },
            }}
            multiline
        />
    );
};

export default RichTextField;

import { z } from 'zod';

import { ELEMENT_TYPES } from '@@editor/helpers/Element';

import { Entity, RichText, UnityRichText } from '../../utils/schemas/schemas';

const RTElementBase = Entity.extend({
    id: z.string().uuid(),
    name: z.string().optional(),
    type: z.literal(ELEMENT_TYPES.SNIPPET),
    tenantIds: z.array(z.number()).nullish(),
});

export const RTElement = RTElementBase.extend({
    element: RichText,
});
export const UnityRTElement = RTElementBase.extend({
    element: UnityRichText,
});

export type RTElement = z.infer<typeof RTElement>;
export type UnityRTElement = z.infer<typeof UnityRTElement>;

export const NewRTElement = RTElement.omit({ id: true });
export type NewRTElement = z.infer<typeof NewRTElement>;

export const NewUnityRTElement = UnityRTElement.omit({ id: true });
export type NewUnityRTElement = z.infer<typeof NewUnityRTElement>;

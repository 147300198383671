import { get } from 'lodash';

import { type Metadata } from '@@api/services/metadata/schemas';
import ContentStatus from '@@constants/ContentStatus';
import PublicationStatus from '@@constants/PublicationStatus';

import { TEASERS_KEY } from './constants';
import { Setting } from './components/AdditionalSettings';

export const getTeasersFromMetadata = (metadata) => get(metadata, TEASERS_KEY);

export const getWithoutAdditionalSettings = (resourceName?: string): Setting[] => {
    if (resourceName === 'links') {
        return [
            'feedbackEnabled',
            'commentingEnabled',
            'sharingEnabled',
            'newsletterSubscribingEnabled',
            'adsEnabled',
            'allowRobots',
            'publicationDateExposed',
            'authorBiographyDisplayed',
        ];
    }

    if (resourceName !== 'articles') {
        return ['authorBiographyDisplayed'];
    }

    return [];
};

type PublicationRelatedMetadata = Pick<
    Metadata,
    'contentStatus' | 'publicationStatus' | 'scheduledPlacing'
>;

export const isReadyForPublication = (metadata: PublicationRelatedMetadata) => {
    const { contentStatus, publicationStatus } = metadata;

    return (
        contentStatus === ContentStatus.READY_TO_PUBLISH &&
        publicationStatus !== PublicationStatus.PUBLISHED
    );
};

export const isScheduledForPublication = (metadata: PublicationRelatedMetadata) => {
    const { publicationStatus, scheduledPlacing } = metadata;

    return (
        publicationStatus !== PublicationStatus.PUBLISHED &&
        Boolean(scheduledPlacing?.publicationTime)
    );
};

export const isPublishedOrScheduledForIt = (metadata: PublicationRelatedMetadata) => {
    const { publicationStatus } = metadata;

    return publicationStatus === PublicationStatus.PUBLISHED || isScheduledForPublication(metadata);
};

const BASE_URL = 'https://embed.zattoo.com';
const ZATTOO_CHANNELS = {
    '3+': `${BASE_URL}/3plus/`,
    '3Sat': `${BASE_URL}/3sat/`,
    ARD: `${BASE_URL}/ard/`,
    'ARTE (Fr)': `${BASE_URL}/DE_arte/`,
    'Al Jazeera': `${BASE_URL}/al-jazeera/`,
    'BBC 1': `${BASE_URL}/bbc-one/`,
    'BBC 2': `${BASE_URL}/bbc2/`,
    'BBC 4': `${BASE_URL}/bbc-four/`,
    'BBC News': `${BASE_URL}/bbc_news/`,
    'BBC Parliament': `${BASE_URL}/bbc-parliament/`,
    'BBC Arabic': `${BASE_URL}/bbc-arabic/`,
    'CNN International': `${BASE_URL}/cnn-international/`,
    'Canale 5': `${BASE_URL}/canale-5/`,
    DMAX: `${BASE_URL}/dmax/`,
    'DSF / Sport1': `${BASE_URL}/dsf/`,
    'Eurosport 1': `${BASE_URL}/eurosport/`,
    'France 2': `${BASE_URL}/france-2/`,
    'France 3': `${BASE_URL}/france-3/`,
    'Kabel eins': `${BASE_URL}/kabel-eins/`,
    'M6 Suisse': `${BASE_URL}/m6-suisse/`,
    MTV: `${BASE_URL}/mtv/`,
    'N-TV': `${BASE_URL}/n-tv/`,
    'N24 / Welt': `${BASE_URL}/n24/`,
    'ORF 1': `${BASE_URL}/orf-1/`,
    'ORF 2': `${BASE_URL}/orf-2/`,
    Phoenix: `${BASE_URL}/phoenix/`,
    'Pro7 Schweiz': `${BASE_URL}/prosieben/`,
    'Pro7Maxx Schweiz': `${BASE_URL}/pro7maxx_ch/`,
    RTL: `${BASE_URL}/rtl/`,
    'RTL II': `${BASE_URL}/rtl-2/`,
    'Rai Uno': `${BASE_URL}/rai-uno/`,
    'SRF 1': `${BASE_URL}/sf-1/`,
    'SRF 2': `${BASE_URL}/sf-2/`,
    'SRF info': `${BASE_URL}/sf-info/`,
    'Sat.1 Schweiz': `${BASE_URL}/sat1/`,
    StarTV: `${BASE_URL}/startv/`,
    'Sky Sport News': `${BASE_URL}/sky_sport_news_de/`,
    'Teleclub Zoom': `${BASE_URL}/teleclub_zoom/`,
    TF1: `${BASE_URL}/tf1/`,
    TV24: `${BASE_URL}/tv24/`,
    'RSI La1': `${BASE_URL}/rsi-la1/`,
    'RSI La2': `${BASE_URL}/rsi-la2/`,
    'RTS 1': `${BASE_URL}/tsr1/`,
    'RTS 2': `${BASE_URL}/tsr2/`,
    Telezueri: `${BASE_URL}/telezueri/`,
    VOX: `${BASE_URL}/vox/`,
    ZDF: `${BASE_URL}/zdf/`,
};

export default ZATTOO_CHANNELS;

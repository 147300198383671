import { Range } from 'slate';

type Key = number;

class TextRangeIdIndex<V extends number | string = string> extends Map<Key, V[]> {
    valuesByStartIndex(startIndex: number) {
        const values: V[] = [];

        this.forEach((ids, index) => {
            if (index >= startIndex) {
                values.push(...ids);
            }
        });

        return values;
    }

    valuesByIndices(indices: number[]) {
        const values: V[] = [];

        indices.forEach((index) => {
            const ids = this.get(index);

            if (ids) {
                values.push(...ids);
            }
        });

        return values;
    }

    valuesByRange(range: Range) {
        const values: V[] = [];

        const [
            {
                path: [startIndex],
            },
            {
                path: [endIndex],
            },
        ] = Range.edges(range);

        for (let index = startIndex; index <= endIndex; index++) {
            const ids = this.get(index);

            if (ids) {
                values.push(...ids);
            }
        }

        return values;
    }

    addByRange(range: Range, id: V) {
        const [
            {
                path: [startIndex],
            },
            {
                path: [endIndex],
            },
        ] = Range.edges(range);

        for (let index = startIndex; index <= endIndex; index++) {
            const ids = this.get(index) ?? [];
            const nextIds = ids.concat(id);

            this.set(index, nextIds);
        }
    }

    removeByRange(range: Range, id: V) {
        const [
            {
                path: [startIndex],
            },
            {
                path: [endIndex],
            },
        ] = Range.edges(range);

        for (let index = startIndex; index <= endIndex; index++) {
            const ids = this.get(index) ?? [];
            const nextIds = ids.filter((indexedId) => indexedId !== id);

            this.set(index, nextIds);
        }
    }
}

export default TextRangeIdIndex;

import { useState } from 'react';

const useSteps = (defaultStep: number) => {
    const [currentStep, setCurrentStep] = useState(defaultStep);

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const resetSteps = () => {
        setCurrentStep(defaultStep);
    };

    return { currentStep, nextStep, prevStep, resetSteps };
};

export default useSteps;

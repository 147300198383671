import { initContract } from '@ts-rest/core';
import { initQueryClient } from '@ts-rest/react-query';
import { z } from 'zod';
import { useStore } from 'react-redux';

import config from '@@config';
import { DefaultHeaders, PatchDefaultHeaders } from '@@api/headers';
import {
    Kickword,
    Metadata,
    NewKickword,
    NewTag,
    PrintMetadata,
    Tag,
    UnityKickword,
    UnityMetadata,
} from '@@api/services/metadata/schemas';
import {
    deserializeKickword,
    deserializeMetadata,
    deserializeTag,
    serializeKickword,
    serializeMetadata,
    serializeTag,
} from '@@api/services/metadata/transformers';
import { paginated } from '@@api/utils/schemas/utils';
import { PaginationParams, SearchParams } from '@@api/utils/schemas/queryParams';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { commonResponses } from '@@api/commonResponses';
import { RootState } from '@@scripts/store/store';
import { getQueryKeys } from '@@api/utils/queryKeys';

const contract = initContract();

const router = contract.router(
    {
        metadata: contract.router({
            get: {
                method: 'GET',
                path: '/metadata/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                query: z.object({
                    includePreviewUrl: z.literal(true).optional(),
                }),
                responses: {
                    200: UnityMetadata.transform(deserializeMetadata),
                },
            },
            getAll: {
                method: 'GET',
                path: '/metadata',
                query: z.object({
                    ...SearchParams.shape,
                    ...PaginationParams.shape,
                    useSearch: z.literal(true).optional(),
                    publishedOrReady: z.boolean().optional(),
                    excludeIds: z.string().optional(),
                }),
                responses: {
                    200: paginated(UnityMetadata.transform(deserializeMetadata)),
                },
            },
            patch: {
                method: 'PATCH',
                path: '/metadata/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: UnityMetadata.pick({
                    publicationStatus: true,
                    contentStatus: true,
                }).partial(),
                responses: {
                    200: UnityMetadata.transform(deserializeMetadata),
                },
                headers: PatchDefaultHeaders,
            },
            put: {
                method: 'PUT',
                path: '/metadata/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: Metadata.transform(serializeMetadata),
                responses: {
                    200: UnityMetadata.transform(deserializeMetadata),
                },
            },
            getList: {
                method: 'GET',
                path: '/metadata/in',
                query: z.object({
                    ids: z.string(),
                    size: z.coerce.number(),
                }),
                responses: {
                    200: paginated(UnityMetadata.transform(deserializeMetadata)),
                },
            },
        }),
        tags: contract.router({
            getAll: {
                method: 'GET',
                path: '/tags',
                query: z.object({
                    ...SearchParams.pick({ q: true }).shape,
                    ...PaginationParams.shape,
                    active: z.boolean().optional(),
                    includeIds: z.string().optional(),
                }),
                responses: {
                    200: paginated(Tag.transform(deserializeTag)),
                },
            },
            get: {
                method: 'GET',
                path: '/tags/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                responses: {
                    200: Tag.transform(deserializeTag),
                },
            },
            head: {
                method: 'GET',
                metadata: {
                    method: 'HEAD',
                },
                path: '/tags',
                query: z.object({
                    name: z.string(),
                    excludeId: z.number(),
                }),
                responses: {
                    200: z.never(),
                    404: z.never(),
                },
            },
            put: {
                method: 'PUT',
                path: '/tags/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: Tag.transform(serializeTag),
                responses: {
                    200: Tag.transform(deserializeTag),
                },
            },
            post: {
                method: 'POST',
                path: '/tags',
                body: NewTag.transform(serializeTag),
                responses: {
                    201: Tag.transform(deserializeTag),
                },
            },
            delete: {
                method: 'DELETE',
                path: '/tags/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
        }),
        kickwords: contract.router({
            getAll: {
                method: 'GET',
                path: '/kickwords',
                query: z.object({
                    ...SearchParams.pick({ q: true }).shape,
                    ...PaginationParams.shape,
                }),
                responses: {
                    200: paginated(UnityKickword.transform(deserializeKickword)),
                },
            },
            get: {
                method: 'GET',
                path: '/kickwords/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                responses: {
                    200: UnityKickword.transform(deserializeKickword),
                },
            },
            head: {
                method: 'GET',
                metadata: {
                    method: 'HEAD',
                },
                path: '/kickwords',
                query: z.object({
                    name: z.string(),
                    excludeId: z.number(),
                }),
                responses: {
                    200: z.never(),
                    404: z.never(),
                },
            },
            put: {
                method: 'PUT',
                path: '/kickwords/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: Kickword.transform(serializeKickword),
                responses: {
                    200: UnityKickword.transform(deserializeKickword),
                },
            },
            post: {
                method: 'POST',
                path: '/kickwords',
                body: NewKickword.transform(serializeKickword),
                responses: {
                    201: UnityKickword.transform(deserializeKickword),
                },
            },
            delete: {
                method: 'DELETE',
                path: '/kickwords/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
        }),
        printMetadata: contract.router({
            patch: {
                method: 'PATCH',
                path: '/metadata/:id/printMetadata',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: PrintMetadata.partial(),
                responses: {
                    200: PrintMetadata,
                },
                headers: PatchDefaultHeaders,
            },
        }),
        targetTextLengthOptions: contract.router({
            get: {
                method: 'GET',
                path: '/targetTextLengthOptions',
                responses: {
                    200: z.array(z.number()),
                },
            },
        }),
        slugify: {
            method: 'GET',
            path: '/slugify',
            query: z.object({
                text: z.string(),
            }),
            responses: {
                200: z.string(),
            },
        },
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

export type MetadataRouter = typeof router;

export const metadataClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.metadataUrl,
        ...clientDefaults(state),
    };

    const client = initQueryClient(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export type MetadataClient = ReturnType<typeof metadataClient>['client'];

export const useMetadataClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return metadataClient(state);
};

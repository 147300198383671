import { mapValues } from 'lodash';
import { useContext } from 'react';

import type { LeavePromptContextType } from './types';

const useLeavePrompt = (LeavePromptContext): LeavePromptContextType => {
    const context = useContext(LeavePromptContext);

    if (!context) {
        return context as LeavePromptContextType;
    }

    const { state, actions } = context as LeavePromptContextType;

    return {
        state,
        actions: mapValues(actions, (action) => (config) => action(config)),
    };
};

export default useLeavePrompt;

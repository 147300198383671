import React from 'react';

import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { Editor } from '@@editor/helpers';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import { ELEMENT_TYPES, MIME_TYPES, SlideshowElement } from '@@editor/helpers/Element';

import { generateEmbedBlockData } from './utils';
import { createGenerateEmbedBlock } from './../utils';
import EmbedWrapper from './../components/EmbedWrapper';
import EditorWithEmbedModal from './../components/EditorWithEmbedModal';
import SlideshowForm from './components/SlideshowForm';
import ContentstationSlideshow from './containers/ContentstationSlideshow';
import Slideshow from './containers/Slideshow';

const TYPE = PLUGIN_NAMES.SLIDESHOW;
const NODE_TYPE = ELEMENT_TYPES.SLIDESHOW;
const CONTENTSTATION_TYPE = TYPE;

const mimetypeConfigs = {
    [MIME_TYPES.SLIDESHOW]: {
        Component: Slideshow,
    },
};

const toolbarBaseConfig = {
    infos: {
        iconName: PLUGIN_ICON_NAMES[TYPE],
        title: 'Slideshow',
    },
    actions: [],
};

type Props = {
    editor: Editor;
    element: SlideshowElement;
};

const SlideshowNode = (props: Props) => {
    const data = props.element.data;

    // identify CS slideshow by the non-existence of a mimetype
    const isContentstationImport = !data.mimetype;
    const embedProps = {
        ...(isContentstationImport ? { component: ContentstationSlideshow } : {}),
        toolbarConfig: {
            ...toolbarBaseConfig,
            actions: ['edit', 'delete'],
        },
    };

    return (
        <EmbedWrapper
            {...props}
            {...embedProps}
            type={TYPE}
            mimetypeConfigs={mimetypeConfigs}
            placeholderText={props.editor.t('editor.plugin.slideshow.placeholderText')}
            placeholderIconName={PLUGIN_ICON_NAMES[TYPE]}
        />
    );
};

const generateEmbedBlock = createGenerateEmbedBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
    generateEmbedBlockData,
});

const internalWithSlideshow = (editor, options) =>
    Object.assign(editor, {
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    formComponent={SlideshowForm}
                    generateEmbedBlock={generateEmbedBlock}
                    type={TYPE}
                />
            ),
            options,
        ),
        renderElement: renderElement(
            editor,
            [
                [NODE_TYPE, SlideshowNode],
                [CONTENTSTATION_TYPE, SlideshowNode],
            ],
            options,
        ),
    });

export const withSlideshow = (editor, options) =>
    internalWithSlideshow(editor, {
        ...options,
        nodeType: [NODE_TYPE, CONTENTSTATION_TYPE],
    });

export default withSlideshow;

import { omit } from 'lodash';

import { isInstagramUrl } from '@@utils/socialMedia/instagram';
import { MIME_TYPES, EmbedBaseData } from '@@editor/helpers/Element';
import { GenerateEmbedBlockData } from '@@editor/typings/Embed';

export const generateEmbedBlockData: GenerateEmbedBlockData = ({ src, originalSrc, embed }) => {
    if (!isInstagramUrl(src)) {
        return;
    }

    return {
        mimetype: MIME_TYPES.INSTAGRAM,
        src: (src as URL).href,
        originalSrc,
        embed: omit<EmbedBaseData>(embed, 'src'),
    };
};

export const SectionTypes = {
    DEFAULT: 'default',
    CATEGORY: 'category',
    CUSTOM: 'custom',
} as const;

export const SectionLayouts = {
    DEFAULT: 'default',
    LESERBILDER: 'leserbilder',
    SERIES: 'series',
    LEMATIN: 'lematin',
    TOP_UNBALANCED: 'topUnbalanced',
    TOP_BALANCED: 'topBalanced',
    STANDARD_UNBALANCED: 'standardUnbalanced',
    STANDARD_BALANCED: 'standardBalanced',
    SPECIAL: 'special',
    INCENTIVES: 'incentives',
    LIFESTYLE: 'lifestyle',
    EDITORIAL_PICK: 'editorialPick',
    VENTY: 'venty',
    FRIDAY: 'friday',
    CAROUSEL: 'carousel',
    SUBNAVIGATION: 'subnavigation',
    ZUERITIPP: 'zueriTipp',
    DASMAGAZIN: 'dasMagazin',
    VIDEO: 'video',
    SIGNE_GENEVE: 'signeGeneve',
    FOCUS: 'focus',
    BREAKING: 'breaking',
    HIGHLIGHT: 'highlight',
    NEWFRONT_BUNDLE: 'newfront_bundle',
    NEWFRONT_COMPACT: 'newfront_compact',
    NEWFRONT_DROPDOWN: 'newfront_dropdown',
    NEWFRONT_FOCUS: 'newfront_focus',
    NEWFRONT_HIGHLIGHT: 'newfront_highlight',
    NEWFRONT_HORIZONTALSCROLL: 'newfront_horizontal-scroll',
    NEWFRONT_LIST: 'newfront_list',
    NEWFRONT_MICRO: 'newfront_micro',
    NEWFRONT_MOSTREAD: 'newfront_most-read',
    NEWFRONT_OPENER: 'newfront_opener',
    NEWFRONT_OPENER_LIST: 'newfront_opener-list',
    NEWFRONT_OPENER_TOP: 'newfront_opener-top',
    NEWFRONT_STANDARD: 'newfront_standard',
    NEWFRONT_STANDARD_EVEN: 'newfront_standard-even',
    NEWFRONT_STANDARD_UNEVEN: 'newfront_standard-uneven',
} as const;

// TODO: move to shared UnitySDK
export const PlacementItemTypes = {
    DIRECT_PLACEMENT: 'direct_placement',
    PLACEHOLDER: 'placeholder',
} as const;

export const PlaceholderTypes = {
    CATEGORY: 'category',
    CUSTOM: 'custom',
};

export const ItemTypes = {
    CONTENT_ITEM: 'CONTENT_ITEM',
    PLACEMENT_ITEM: 'PLACEMENT_ITEM',
};

import { RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        index: true,
        lazy: async () => {
            const CuratedList = await import(
                /* webpackChunkName: "curatedList" */ '@@routes/curatedList'
            );

            return { Component: CuratedList.default };
        },
    },
];

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { styled } from '@mui/material';

import { isReplaceDropMode, Wrapper } from '@@containers/ContentItem/styles';
import { useTenantVariant } from '@@containers/TenantSpecific/TenantVariantContext';
import { willTriggerRtpValidation } from '@@containers/metadata/hooks/useRtpValidation';
import { IdType } from '@@utils/types';
import PlaceholderText from '@@containers/ContentItem/PlaceholderText';
import ContentItemContent from '@@containers/ContentItem/ContentItemContent';
import HoverUICollapsed from '@@containers/ContentItem/HoverUICollapsed';
import HoverUIUncollapsed from '@@containers/ContentItem/HoverUIUncollapsed';
import showPreview from '@@utils/showPreview';
import PublicationStatus from '@@constants/PublicationStatus';
import ListActionButton from '@@components/buttonImplementations/ListActionButton';
import Icon from '@@components/Icon';
import FloatingToggleButton from '@@components/buttonImplementations/FloatingToggleButton';

const InnerWrapper = styled('div')({
    display: 'flex',
    flexFlow: 'row',
});

// eslint-disable-next-line complexity
export const ContentItem = ({
    onDelete,
    entity,
    dragSourceItemProps,
    onBookmark,
    onClickPin,
    onPublish,
    onEdit,
    position,
    className,
    collapsed,
    dropMode,
    hidePins,
    placementOptions,
    renderImage,
    canDrag,
    isPlaceholder,
    style,
    fixedHeight,
    getDate,
    datePrefix,
    showActions = true,
    showPublishButton = true,
    renderRow = ContentItemContent,
    forwardedRef,
    readOnly,
}) => {
    const [showUI, setShowUI] = useState(false);
    const { t } = useTranslation();
    const { variantId } = useTenantVariant();
    const { tenantId } = dragSourceItemProps || {};

    const handleClickDelete = (e) => {
        e.stopPropagation();
        onDelete(entity.id);
    };

    const handleClickEdit = (e) => {
        e.stopPropagation();
        onEdit(e);
    };

    const handleClickPreview = (e) => {
        e.stopPropagation();

        // when in collapsed mode (inside a placement category column) tenantId is defined
        // when in un-collapsed mode (in the left column) tenantId is undefined
        // when inside placement tool, use tenantId, when inside a form with variants, use variantId
        showPreview(entity.id, tenantId ?? variantId);
    };

    const handleClickBookmark = (e) => {
        e.stopPropagation();
        onBookmark(entity.id);
    };

    const handleClickPin = (e) => {
        e.stopPropagation();
        onClickPin(entity.id);
    };

    const handleClickPublish = () => onPublish(entity.id);

    const handleMouseOver = () => {
        setShowUI(true);
    };
    const handleMouseLeave = () => {
        setShowUI(false);
    };

    const canBePinned = collapsed && !hidePins && placementOptions;

    const showPinButton = canBePinned || placementOptions?.pinned;

    // We do not want to trigger an rtp validation here, so only show the publish button for unpublished
    // contents, which cannot trigger another rtp validation, because IF at all, it was already triggered
    // in the past for that entity
    const isNotPublishedAndRtpValidationFree =
        entity.publicationStatus !== PublicationStatus.PUBLISHED &&
        willTriggerRtpValidation(entity);

    const isUnpublishedContent =
        placementOptions &&
        Boolean(placementOptions.deletedDate || placementOptions.unpublishedDate);

    const reducedOpacity = isUnpublishedContent || isReplaceDropMode(dropMode);

    return (
        <Wrapper
            {...{ className, fixedHeight, canDrag, collapsed, dropMode, isPlaceholder, style }}
            ref={forwardedRef}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
        >
            {isPlaceholder && (
                <PlaceholderText
                    type={placementOptions.placeholderType}
                    title={placementOptions.placeholderTitle}
                    id={placementOptions.placeholderCategoryId}
                    reducedOpacity={reducedOpacity}
                />
            )}

            <InnerWrapper>
                {renderRow({
                    renderImage,
                    position,
                    collapsed,
                    entity,
                    getDate,
                    datePrefix,
                    placementOptions,
                    isPlaceholder,
                    reducedOpacity,
                })}
                {showActions && !collapsed && (
                    <HoverUIUncollapsed
                        onClickPreview={handleClickPreview}
                        onClickEdit={handleClickEdit}
                        onClickBookmark={handleClickBookmark}
                        entity={entity}
                        showUI={showUI}
                    />
                )}
                {showPublishButton && !collapsed && isNotPublishedAndRtpValidationFree && (
                    <ListActionButton onClick={handleClickPublish}>
                        {t('contentitem.publishButton')}
                    </ListActionButton>
                )}
                {showActions && collapsed && showUI && (
                    <HoverUICollapsed
                        onClickPreview={handleClickPreview}
                        onClickDelete={handleClickDelete}
                        onClickEdit={handleClickEdit}
                        isPlaceholder={isPlaceholder}
                        readOnly={readOnly}
                    />
                )}

                {showPinButton && (
                    <FloatingToggleButton
                        disabled={isPlaceholder}
                        // Toggle buttons do not look disabled when selected. Therefore we are tricking the
                        // component here and tell it to not render as selected if it is a placeholder (because we want
                        // to make it look disabled for placeholders).
                        selected={!isPlaceholder && placementOptions.pinned}
                        size="small"
                        onClick={handleClickPin}
                        FloatingBoxProps={{
                            anchor: placementOptions.pinned ? null : Wrapper,
                            position: 'bottom-left',
                        }}
                    >
                        <Icon name={'thumbtack'} size="small" />
                    </FloatingToggleButton>
                )}
            </InnerWrapper>
        </Wrapper>
    );
};

// We need to set this displayName explicitly because of a bug related
// to the --coverage flag in our jest unit tests.
// Maybe related to this issue: https://github.com/facebook/jest/issues/4021
ContentItem.displayName = 'ContentItem';

const dnd = {
    propTypes: {
        canDrag: PropTypes.bool,
    },
    defaultProps: {
        canDrag: true,
    },
};

ContentItem.propTypes = {
    ...dnd.propTypes,
    entity: PropTypes.shape({
        id: IdType.isRequired,
        title: PropTypes.string,
        updatedAt: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
    collapsed: PropTypes.bool,
    datePrefix: PropTypes.string,
    dropMode: PropTypes.string,
    getDate: PropTypes.func,
    hidePins: PropTypes.bool,
    placementOptions: PropTypes.object,
    position: PropTypes.number,
    readOnly: PropTypes.bool,
    renderImage: PropTypes.func,
    onBookmark: PropTypes.func,
    onClick: PropTypes.func,
    onClickPin: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onPublish: PropTypes.func,
};

ContentItem.defaultProps = {
    ...dnd.defaultProps,
};

export default React.forwardRef((props, ref) => <ContentItem {...props} forwardedRef={ref} />);

import { CSSObject, Theme } from '@mui/material';

import { DropMode } from '@@hooks/useDropItem';

const INDICATOR_THICKNESS = 6;

const dragAndDropIndicatorStyle = ({
    theme,
    dropMode,
}: {
    theme: Theme;
    dropMode?: DropMode | null;
}): CSSObject => ({
    position: 'relative',
    ...(dropMode === DropMode.REPLACE && {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.primary['50'],
    }),
    '&::after': {
        display: 'none',
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        height: `${INDICATOR_THICKNESS}px`,
        backgroundColor: theme.palette.secondary.main,
        pointerEvents: 'none',
        zIndex: theme.zIndex.layer2,
        ...(dropMode === DropMode.INSERT_BEFORE && {
            display: 'block',
            top: 0,
            transform: 'translateY(-50%)',
        }),
        ...(dropMode === DropMode.INSERT_AFTER && {
            display: 'block',
            bottom: 0,
            transform: 'translateY(50%)',
        }),
    },
});

export default dragAndDropIndicatorStyle;

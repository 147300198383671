import { useRef, useEffect } from 'react';

const usePrevious = <T>(value: T): T | null => {
    const ref = useRef<T | null>(null);

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

export default usePrevious;

import React, { Ref } from 'react';
import {
    FormControl,
    FormControlProps,
    FormHelperText,
    FormHelperTextProps,
    FormLabel,
    FormLabelProps,
    RadioGroup as MuiRadioGroup,
    RadioGroupProps as MuiRadioGroupProps,
    styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import RadioInput, { RadioInputProps } from '@@form/components/RadioInput';
import { FormFieldError } from '@@form/hooks/useReactHookFormFieldError';

export type Value = string | number | boolean | string[] | number[] | boolean[];

const StyledFormLabel = styled(FormLabel)({
    display: 'flex',
    flexDirection: 'row',
});

export type RadioGroupProps = Omit<MuiRadioGroupProps, 'children'> & {
    label?: FormLabelProps['children'];
    error?: FormFieldError;
    required?: FormControlProps['required'];
    disabled?: FormControlProps['disabled'];
    helperText?: FormHelperTextProps['children'];
    preserveErrorSpace?: boolean;
    inputRef?: Ref<HTMLDivElement>;
} & (
        | { fields: RadioInputProps[]; children?: never }
        | { fields?: never; children: MuiRadioGroupProps['children'] }
    );
const RadioGroup = (props: RadioGroupProps) => {
    const {
        disabled,
        error,
        label,
        name,
        required,
        children,
        helperText,
        preserveErrorSpace,
        fields,
        inputRef,
        ...rest
    } = props;
    const { t } = useTranslation();

    const errorMessage = error?.[0]?.[0];

    const customHelperText =
        (errorMessage && t(errorMessage)) || helperText || (preserveErrorSpace && ' ');

    return (
        <FormControl
            error={Boolean(error)}
            required={required}
            disabled={disabled}
            ref={inputRef}
            fullWidth
        >
            {label && <StyledFormLabel id={name}>{label}</StyledFormLabel>}

            <MuiRadioGroup aria-labelledby={label ? name : undefined} name={name} {...rest}>
                {children ||
                    fields?.map((field, index) => (
                        <RadioInput key={index} {...field} disabled={disabled} />
                    ))}
            </MuiRadioGroup>
            {customHelperText && <FormHelperText>{customHelperText}</FormHelperText>}
        </FormControl>
    );
};

export default RadioGroup;

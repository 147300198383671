import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

import { ElementAttributes, EmbedElement } from '@@editor/helpers/Element';
import { Editor, Element, Node, ReactEditor } from '@@editor/helpers';

import { getInputPlaceholderStyle } from '../../styles';

type Props = {
    attributes: ElementAttributes;
    children: React.ReactNode;
    editor: Editor;
    element: EmbedElement;
};

type StyledQuoteTextProps = {
    $templateElement: boolean;
    $placeholderText: string;
};

const StyledQuoteText = styled('div')<StyledQuoteTextProps>(
    ({ $templateElement, $placeholderText, theme }) => ({
        ...theme.typography.editorSecondaryXlarge,
        padding: theme.spacing(1, 2),
        textAlign: 'center',
        ...($templateElement && {
            ...getInputPlaceholderStyle({ theme, placeholderText: $placeholderText }),
            '&&::before': {
                left: '50%',
                transform: 'translate(-50%, 0)',
            },
        }),
    }),
);

const QuoteText = (props: Props) => {
    const { attributes, children, editor, element } = props;
    const { t } = useTranslation();
    const path = ReactEditor.findPath(editor, element);
    const parentNode = Node.parent(editor, path);

    return (
        <>
            <StyledQuoteText
                {...attributes}
                $templateElement={
                    Element.isTemplateElement(parentNode) ||
                    Element.isEmptyQuoteTextElement(element)
                }
                $placeholderText={t('editor.plugin.quote.quotePlaceholderText')}
            >
                {children}
            </StyledQuoteText>
        </>
    );
};

export default QuoteText;

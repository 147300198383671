import { Element } from '@@editor/helpers';

export type CompareElement = Element;
export type CompareElementOrNull = CompareElement | null | undefined;

export enum CompareOperationEnum {
    ADD = 'ADD',
    IDENTITY = 'IDENTITY',
    UPDATE = 'UPDATE',
    REMOVE = 'REMOVE',
    MOVE_START = 'MOVE_START',
    MOVE_END = 'MOVE_END',
}

export type AddOperation = {
    operation: CompareOperationEnum.ADD;
    element: CompareElement;
};

/** Content and position did not change */
export type IdentityOperation = {
    operation: CompareOperationEnum.IDENTITY;
};

export type UpdateOperation = {
    operation: CompareOperationEnum.UPDATE;
    element: CompareElement;
};

export type RemoveOperation = {
    operation: CompareOperationEnum.REMOVE;
};

export type MoveStart = {
    operation: CompareOperationEnum.MOVE_START;
    to: number;
};

export type MoveEnd =
    | {
          operation: CompareOperationEnum.MOVE_END;
          from: number;
      }
    | {
          operation: CompareOperationEnum.MOVE_END;
          from: number;
          updated: true;
          element: CompareElement;
      };

export type MoveOperation = MoveStart | MoveEnd;

export type ActionOperation = AddOperation | UpdateOperation;

/**
 * All possible operations
 */
export type UnityCompareOperation =
    | IdentityOperation
    | RemoveOperation
    | ActionOperation
    | MoveOperation;

import React from 'react';

type Props = {
    attributes: UnknownObject;
    children: React.ReactNode;
};

const Li = (props: Props) => <li {...props.attributes}>{props.children}</li>;

export default Li;

import React from 'react';
import { useTranslation } from 'react-i18next';

import PublicationStatus from '@@constants/PublicationStatus';
import { type Slideshow as SlideshowType } from '@@api/services/slideshow/schemas';
import { useSlideshowClient } from '@@api/services/slideshow/client';
import { useMetadataClient } from '@@api/services/metadata/client';

import Slideshow from './Slideshow';

type Props = {
    slideshowId: SlideshowType['id'];
    readOnly: boolean;
};

const UnitySlideshow = ({ slideshowId, readOnly }: Props) => {
    const { t } = useTranslation();
    const { client: slideshowClient, queryKeys: slideshowKeys } = useSlideshowClient();
    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();

    const params = { id: slideshowId };
    const { data: slideshowData } = slideshowClient.slideshow.get.useQuery(
        slideshowKeys.slideshow.get({ params }),
        { params },
    );
    const slideshow = slideshowData?.body;

    const metadataId = slideshow?.metadataId;

    const metadataParams = { id: metadataId! };
    const { data: metadataData } = metadataClient.metadata.get.useQuery(
        metadataKeys.metadata.get({ params: metadataParams, query: {} }),
        { params: metadataParams },
        { enabled: Boolean(metadataId) },
    );
    const metadata = metadataData?.body;

    if (!metadata) {
        return null;
    }

    const disabled = metadata.publicationStatus !== PublicationStatus.PUBLISHED;
    const disabledMessage = readOnly
        ? t('slideshow.disabled.withcontentname', {
              slideshowName: metadata.contentName,
          })
        : t('slideshow.disabled');

    return (
        <Slideshow
            slideshowId={slideshowId}
            disabled={disabled}
            disabledMessage={disabledMessage}
            readOnly={readOnly}
        />
    );
};

export default UnitySlideshow;

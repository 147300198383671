import { Element } from '@@editor/helpers';
import { ELEMENT_TYPES, ParagraphElement } from '@@editor/helpers/Element';

const createSimpleTextState = (text = '') => [
    Element.create<ParagraphElement>({
        type: ELEMENT_TYPES.PARAGRAPH,
        data: {},
        children: [
            {
                text,
            },
        ],
    }),
];

/**
 * Creating a valid state for Slate editor.
 * @param  {any} value  Can be an Slate structured object or json, or just a
 *                      string containing text or html
 * @return {object}     Returns a valid Slate state
 */
const makeState = (value?: Element[] | string | null | undefined): Element[] => {
    if (Element.isElementList(value)) {
        if (value.length > 0) {
            return value;
        }

        return createSimpleTextState();
    }

    if (typeof value === 'string') {
        return createSimpleTextState(value);
    }

    if (value == null) {
        return createSimpleTextState();
    }

    throw new Error('Could not create a valid state for passed value: ' + value);
};

export default makeState;

import React from 'react';

import { FacebookElement } from '@@editor/helpers/Element';

import FacebookEmbed from './FacebookEmbed';

type Props = {
    element: FacebookElement;
};

// Facebook API: https://developers.facebook.com/docs/plugins/page-plugin
// NOTE: data-width is not able to support 'auto' widht, see link above.
const FacebookPage = ({ element }: Props) => (
    <FacebookEmbed element={element}>
        {({
            src,
            width,
            height,
            // Hide cover photo in the header
            hideCover,
            // Show profile photos when friends like this
            showFacepile,
            // Hide the custom call to action button (if available)
            hideCta,
            smallHeader,
            tabs,
            ...rest
        }) => {
            const fbTabs = tabs || ['timeline'];

            return (
                <div
                    {...rest}
                    className="fb-page"
                    data-href={src}
                    data-width={width}
                    data-height={height}
                    data-adapt-container-width
                    data-tabs={fbTabs && fbTabs.join ? fbTabs.join(' ') : fbTabs}
                    data-hide-cover={hideCover || false}
                    data-show-facepile={showFacepile || false}
                    data-hide-cta={hideCta || false}
                    data-small-header={smallHeader || true}
                />
            );
        }}
    </FacebookEmbed>
);

export default FacebookPage;

export const isOptaEmbedCode = (code?: string) => {
    const result = parseOptaEmbedCode(code);

    return Boolean(result);
};

export const parseOptaEmbedCode = (code?: string) => {
    if (code?.startsWith('<opta-widget') && code?.endsWith('</opta-widget>')) {
        return code;
    }

    return undefined;
};

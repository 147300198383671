import React from 'react';
import { styled } from '@mui/material';

import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { OptaElement } from '@@editor/helpers/Element';
import TextareaField from '@@form/fields/Textarea';
import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { FormProps, UseReactHookFormProps } from '@@containers/ReactHookForm/types';

type FormValues = {
    embed: {
        originalSrc: string;
    };
};

type Props = {
    type: typeof PLUGIN_NAMES.OPTA;
    element: OptaElement;
    renderButtons: FormProps['renderButtons'];
    validate: Validator[];
    onCancel: VoidFunction;
    onSubmit: UseReactHookFormProps['onSubmit'];
    className?: string;
    // not using WithTranslation here to make the editor agnostic of the i18n framework
    t: (t: string) => string;
};

// element data needs to be transformed back into form data
const transformElementToFormValues = (element: OptaElement): FormValues => ({
    embed: {
        originalSrc: element?.data?.originalSrc || '',
    },
});

const OptaForm = ({
    renderButtons,
    className,
    element,
    onSubmit,
    onCancel,
    validate,
    t,
}: Props) => {
    const handleCancel = () => onCancel();

    return (
        <ReactHookForm
            formName="OptaForm"
            values={transformElementToFormValues(element)}
            onSubmit={onSubmit}
            onCancel={handleCancel}
            renderButtons={renderButtons}
            hasCancelButton
            hasActiveCancelButton
            alwaysShowCancelButton
            className={className}
        >
            <TextareaField
                name="embed.originalSrc"
                label={t('embed.code')}
                required
                validate={validate}
            />
        </ReactHookForm>
    );
};

export default styled(OptaForm)({
    width: '450px',
});
